// SF TODO: refractor/edit for new account layout:
.theme-screamingfrog {
	.wrap-account .woocommerce-MyAccount-navigation {
		background-color: $sf-grey--dark;
		padding: 0 20px;
		width: 100% !important;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul li {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		border-left: 1px solid #6d6d6d;
		background: none;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul li:first-child,
	.wrap-account .woocommerce-MyAccount-navigation ul li.is-active,
	.wrap-account .woocommerce-MyAccount-navigation ul li.is-active + li {
		border-left: 0;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul li a {
		color: #eadbdb;
		padding: 15px 20px;
		display: block;
		font-size: 16px;
		line-height: 20px;
		font-weight: $sf-weight--med;
		position: relative;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul li.is-active a,
	.wrap-account .woocommerce-MyAccount-navigation ul li a:hover {
		color: #fff;
		background-color: #75b42c;
	}

	.wrap-account .woocommerce-MyAccount-navigation ul li.is-active a:after {
		content: "";
		position: absolute;
		left: 50%;
		bottom: -14px;
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 20px solid #75b42c;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	@include respond-to-max("bs-md") {
		.wrap-account .woocommerce-MyAccount-navigation {
			padding: 0 !important;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul {
			flex-wrap: wrap;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li {
			width: 100%;
			display: block;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li + li {
			border-top: 1px solid #6f6f6f;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			font-size: 14px;
			padding: 10px 15px;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li.is-active a:after {
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid #fff;
			bottom: auto;
			top: 50%;
			right: 15px;
			left: auto;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	@media (max-width: 900px) {
		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			font-size: 11px;
			padding: 15px 10px;
		}
	}

	@media (max-width: 980px) {
		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			font-size: 13px;
		}
	}

	@media (max-width: 1023px) {
		.wrap-account .woocommerce-MyAccount-navigation {
			padding: 0;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			font-size: 13px;
			padding: 15px 12px;
		}
	}

	@media (max-width: 1200px) {
		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			font-size: 14px;
		}
	}

	@media (max-width: 1280px) {
		.wrap-account .woocommerce-MyAccount-navigation ul {
			padding: 0;
		}

		.wrap-account .woocommerce-MyAccount-navigation ul li a {
			padding: 15px 15px;
		}
	}
}
