//@import "../config/config";

///*=====================================================================</editor-fold>=
// CONTACT TABS
///=====================================================================<editor-fold>=*/

#contacts-tabs {
	padding: 90px 0;
}

#contacts-tabs p {
	color: #969696;
	font-weight: $sf-weight--light;
	padding-bottom: 50px;
}

#contacts-tabs ul.tabs li > label[for="tab2"] {
	left: 130px;
}

@media (max-width: 500px) {
	#contacts-tabs ul.tabs li > label[for="tab2"] {
		left: 50%;
	}
}

#contacts-tabs .register-buttons {
	width: 100%;
	display: block;
}

#contacts-tabs .register-buttons input {
	float: left;
	margin: 0;
}

#contacts-tabs .register-buttons input:last-child {
	float: right;
	background: $sf-green;
}

#contacts-tabs .register-buttons input:last-child:hover {
	background: #599f08;
}

@media (max-width: 500px) {
	#contacts-tabs .register-buttons input {
		margin: 20px 0;
	}
}

#contacts-tabs .reset-pw {
	padding: 10px 0 30px;
}

#contacts-tabs .reset-pw a {
	color: #afafaf;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding-left: 20px;
	position: relative;
}

#contacts-tabs .reset-pw a:before {
	content: "\f023";
	font-family: fontAwesome;
	display: block;
	position: absolute;
	left: 0;
	top: 1px;
}

#contacts-tabs .reset-pw a:hover {
	color: $sf-font-grey;
}
//</editor-fold>
