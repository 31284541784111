.sf-badge {
	width: auto;
	height: 140px;
	display: block;

	&--google {
		border: 1px solid #666c71;
		background-color: #666c71;
		background-size: calc(100% + 12px);
		background-position: center;
		background-repeat: no-repeat;
		width: 145px;
	}
}
