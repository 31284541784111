//@import "../config/config";

///*=====================================================================</editor-fold>=
// WP CHECKOUT PLUGIN SUMMARY BOX
///=====================================================================<editor-fold>=*/


@media (max-width: 500px) {
  .wpcoordersummarybox {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.wpcoordersummarybox table tbody tr th {
  vertical-align: middle;
}

.wpcoordersummarybox table tbody tr td.wpco_columntitle {
  padding: 20px !important;
}

.wpcoordersummarybox table tbody tr th[colspan="2"],
.wpcoordersummarybox table tbody tr td[colspan="2"] {
  display: none;
}

@media (max-width: 480px) {
  .wpcoordersummarybox table tbody tr th.wpco_columnprice,
  .wpcoordersummarybox table tbody tr td.wpco_columnprice,
  .wpcoordersummarybox table tbody tr th.checkout_head_price,
  .wpcoordersummarybox table tbody tr td.checkout_head_price {
    display: none;
  }

  .wpcoordersummarybox table tbody tr th[colspan="2"],
  .wpcoordersummarybox table tbody tr td[colspan="2"] {
    display: table-cell;
  }

  .wpcoordersummarybox table tbody tr th[colspan="3"],
  .wpcoordersummarybox table tbody tr td[colspan="3"] {
    display: none;
  }
}

@media (max-width: 480px) {
  .wpcoordersummarybox table thead th:nth-child(2) {
    display: none;
  }
}
//</editor-fold>
