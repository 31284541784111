.sf-results {
	padding: $pad--small;
	&__title {
		@include h2();
		text-align: center;
	}
	&__text {
		width: 100%;
		margin: auto;
		max-width: 700px;
		margin-top: $pad--min;
	}
	&__img {
		width: 100%;
		margin-top: $pad--small;
		box-shadow: $sf-shadow;
	}
	@include respond-to-min(xl) {
		padding: $pad--large;
		&__img {
			margin-top: $pad--large;
		}
	}
}
