.sf-hide {
	@include respond-to-max(sm) {
		&--below-sm {
			display: none !important;
		}
	}
	@include respond-to-max(md) {
		&--below-md {
			display: none !important;
		}
	}
	@include respond-to-max(lg) {
		&--below-lg {
			display: none !important;
		}
	}
	@include respond-to-max(xl) {
		&--below-xl {
			display: none !important;
		}
	}
	@include respond-to-min(sm) {
		&--above-sm {
			display: none !important;
		}
		@include respond-to-min(md) {
			&--above-md {
				display: none !important;
			}
			@include respond-to-min(lg) {
				&--above-lg {
					display: none !important;
				}
				@include respond-to-min(xl) {
					&--above-xl {
						display: none !important;
					}
				}
			}
		}
	}
}
