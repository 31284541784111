//@import "../config/config";

///*=====================================================================</editor-fold>=
// GRAPH
///=====================================================================<editor-fold>=*/

svg.graph {
	height: 300px;
	width: 1500px;
}

svg.graph .points {
	stroke: #8dca47;
	stroke-width: 2;
	fill: $sf-green;
}

svg.graph .first_set {
	fill: #7db838;
	stroke: #8dca47;
}

svg.graph .first_set circle:hover {
	stroke-width: 5;
}

#highcharts-0 svg g path {
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
//</editor-fold>
