.sf-standard-typography p.sf-breadcrumbs {
	font-style: italic !important;
	font-weight: $sf-weight--heavy !important;
	.sf-breadcrumbs__item {
		white-space: nowrap;
	}
	.sf-breadcrumbs__separator {
		font-style: normal !important;
		position: relative;
		top: 1px;
	}
}
