.sfwc-checkout {
	background-color: #fff;

	.update-all-subscriptions-payment-method-wrap {
		.form-row {
			text-align: center;
		}
		@include checkbox() {
			top: 5px; // adjust to position with the text
			left: -5px; // adjust to position with the text
		}
	}

	// TEMP
	.payment_box {
		margin-top: $pad--min;

		p:not(.form-row-wide) {
			display: none;
		}

		a.sv-wc-payment-gateway-payment-form-manage-payment-methods {
			display: none !important; // hide the button (we use our own)

			// @include link($sf-blue--light, $sf-blue--dark);

			// &:before {
			// 	@include remixicon($ri-arrow-right-line);
			// 	display: inline-block;
			// 	margin-right: 5px;
			// 	font-size: 1.2em;
			// }
			// &:hover {
			// 	&:before {
			// 		@include animate(slide-in-left);
			// 	}
			// }
		}
		label.sv-wc-payment-gateway-payment-form-saved-payment-method,
		label[for="wc-braintree-credit-card-use-new-payment-method"],
		label[for="wc-braintree-paypal-use-new-payment-method"] {
			display: block;
			width: 100%;
			margin-left: 0 !important;
			transition: color 0.3s;

			+ br {
				display: none; // hide line breaks inserted between <label> elements
			}

			&:before {
				margin-right: 5px;
				font-size: 1.2em;
				display: inline-block;
				@include remixicon($ri-checkbox-blank-circle-line);
				position: relative;
				top: 2px;
			}
			&:hover {
				// color: $sf-green;
				&:before {
					@include remixicon($ri-checkbox-circle-line);
					// @include animate(rotate-in);
				}
			}

			.nickname {
				font-weight: $sf-weight--heavy;
			}

			img {
				display: none !important;
			}
		}
		input[type="radio"]:checked {
			+ label.sv-wc-payment-gateway-payment-form-saved-payment-method,
			+ label[for="wc-braintree-credit-card-use-new-payment-method"],
			+ label[for="wc-braintree-paypal-use-new-payment-method"] {
				color: $sf-green;
				pointer-events: none;
				&:before {
					@include remixicon($ri-checkbox-circle-fill);
					@include animate(bounce-in);
				}
			}
		}
	}
	//END TEMP

	// handle error messages From wooCommerce Plugins:
	.sf-message, // We override WooCommerce Default messages to use our own .sf-message markup/classes
  .woocommerce-NoticeGroup, // this...
	.woocommerce-NoticeGroup-checkout, // ... and this is the container for a group of messsages from WooCommerce
	.woocommerce-error, // some WooCommerce Plugins still throw errors with this class and not get overidden with .sf-message (E.g errors on payment inputs)
	.woocommerce-message // other general messages that might get displayed from WooCommerce
	{
		order: -1; // ensure top of the page
		width: 100%; // prevent breaking the flex layout by occupying the full width of the container (i.e the first row with flex-wrap: wrap)
	}

	// style generic messages to be the same as our notification messages:
	.woocommerce-message {
		@include notification-message();
	}

	// style error messages to be the same as our error messages:
	.woocommerce-error {
		@include notification-message($sf-color--error);
	}

	h2 {
		@include h4() {
			margin-bottom: $pad--min;
		}
	}

	.recurring-totals {
		h2 {
			margin-top: $pad--min;
		}
	}

	.form-row p,
	p {
		@include p();
	}

	&-box {
		background-color: $checkout-light-grey;
		box-shadow: $sf-shadow;
		padding: $pad--min;
		margin: $pad--min 0 $pad--min * 2;
		@include respond-min-to-max($checkout-breakpoint, lg) {
			margin: $pad--min 0;
		}
		width: 100%;

		&--billing {
			.sf-assistive-action {
				@include hideWhenFormis(open);
			}
		}

		&--payment {
			@include hideWhenFormis(open);
		}

		&--message {
			p {
				font-weight: 400;
			}
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-content: center;
			align-items: center;
			padding-bottom: 15px;

			h2 {
				margin: 0;
			}
		}
	}

	&-billing-form {
		@include hideWhenFormis(closed);

		.billing-tax-id-field {
			display: none !important;
		}

		&__tax-acknowledgement {
			display: grid;
			grid-template-columns: 20px 1fr;
			grid-template-rows: auto;
			column-gap: $pad--min;
			align-items: center;
			margin: $pad--min 0;

			@include checkbox();
		}

		position: relative;
		.form-loading & {
			@include loadingSpinner();
		}

		&__submit {
			&--loading {
				@include loadingDots();
			}
		}
	}

	&-billing-review {
		@include hideWhenFormis(open);

		padding-left: 45px;
		p {
			margin-bottom: 5px;
		}
		position: relative;

		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: $checkout-icon--w;
			left: 0;
			background-image: url("../images/checkout/location.png");
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center top;
		}
	}

	&__message {
		position: relative;
		background: transparent;

		padding: 5px 5px 5px 10px;

		[data-severity="0"] & {
			&::after {
				border-right: 5px solid $sushi;
			}
		}
		[data-severity="1"] & {
			&::after {
				border-right: 5px solid $sf-orange--light;
			}
		}
		[data-severity="2"] & {
			&::after {
				border-right: 5px solid $bittersweet;
			}
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: $checkout-light-grey;
			border-right: 5px solid grey;
			animation: checkoutMessageReveal 0.3s ease;
		}
	}

	.form-row {
		@include checkoutFormRow();
	}

	.blockUI.blockOverlay {
		@include loadingSpinner($pos: absolute);
		opacity: 1 !important;
		background-color: transparent !important;
	}

	.woocommerce-checkout-payment {
		min-height: 100px;
		p {
			@include p($font-size: 15px);
		}

		//SF TODO: bring back in when required
		//to hide "save securely to account"
		.wc-braintree-paypal-new-payment-method-form,
		.wc-braintree-credit-card-new-payment-method-form {
			.form-row:last-of-type {
				// display: none;
			}
		}

		.form-row {
			padding: 0 !important;
		}

		//for sandbox messages
		.payment_box {
			> p {
				// font-size: 10px;
				&:first-child {
					// padding: 5px 0;
				}
				&.form-row {
					// display: none;
				}
			}
		}

		.wc_payment_methods {
			@include border(1px solid $checkout-border-grey, top, bottom);
			li {
				padding: 5px 0;
				&:first-child {
					@include border(1px solid $checkout-border-grey, bottom);
				}

				input[type="radio"] {
					display: none;
				}
				label {
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					align-content: center;
					align-items: center;
					flex-wrap: wrap;
					.sv-wc-payment-gateway-card-icons {
						margin: 0;
						img {
							margin: 0 0 0 5px;
						}
						@include respond-to-max(510px) {
							padding: 5px 0;
							display: flex;
							justify-content: space-between;
							width: 100%;
							img {
								width: calc(100% / 7 - 1px) !important;
								max-width: 40px;
								height: auto !important;
								margin: 0 1px 0 0;
							}
						}
					}
				}
			}
		}
	}

	.sfwc-checkout-user-details {
		&__row {
			display: flex;
			align-items: center;
			align-content: center;

			img {
				width: $checkout-icon--w;
				margin-right: 5px;
			}
			&--name {
				margin-bottom: $pad--min;
				img {
					padding-right: 7px;
				}
			}
			&--email {
				p {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				&:hover {
					p {
						white-space: inherit;
					}
				}
			}
		}
	}

	.sfwc-checkout-product-details {
		@include p();

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			padding-bottom: $pad--min;

			h2 {
				margin-bottom: 0;
			}

			// a {
			// 	@include pill-btn--outline($sf-blue--dark, #fff, 4px);
			// 	display: inline-block;
			// }
		}

		table {
			width: 100%;
			margin-bottom: $pad--min;
			@include border($checkout-border, bottom);

			th,
			td {
				text-align: left;
				padding: 5px;
			}

			td {
				white-space: nowrap;
				@include border($checkout-border, left);
			}

			tr {
				@include border($checkout-border, top);
			}

			tfoot {
				tr {
					&.order-total {
						background-color: $checkout-light-grey;
						font-weight: 600;
					}
				}
			}

			th {
				div {
					display: inline;
					.product-quantity {
						white-space: nowrap;
					}
				}
			}

			thead {
				display: none;
			}
		}
	}

	.sfwc-checkout-submit-btn {
		@include pill-btn--fill($sushi);
		@include p(#fff);
		text-transform: capitalize;
		width: 100%;

		&--save-form {
			&:disabled {
				color: $sf-green; // hide the button text against the bg colour,
			}
		}

		&:disabled {
			opacity: 0.2;
			pointer-events: none;
		}
	}

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	@include respond-to-max($checkout-breakpoint) {
		margin-top: $pad--min * 2;
		margin-bottom: $pad--min * 2;
	}

	.sfwc-checkout-container {
		width: 100%;

		&--details {
			padding-top: $pad--min;
			//space for box shadows:
			padding-right: 2px;
			padding-left: 2px;
			@include respond-to-max($checkout-breakpoint) {
				order: -1;
			}
		}
	}

	@include respond-to-min($checkout-breakpoint) {
		.sfwc-checkout-container {
			&--details {
				width: 40%;
				padding-left: $pad--min;
				margin-top: $pad--min;

				position: sticky;
				top: 0;
				max-height: 100vh;
				// overflow-y: auto;
			}
			&--information {
				width: 60%;
			}
		}
		.sfwc-checkout-submit-btn {
			&--place-order {
				margin-bottom: $pad--min;
			}
		}

		@include respond-to-min(lg) {
			margin-top: $pad--min;
			.sfwc-checkout-container {
				&--information {
					padding-right: $pad--min;
				}
			}
			.sfwc-checkout-submit-btn--place-order,
			.sfwc-checkout-product-details table {
				margin-bottom: $pad--min * 2;
			}

			@include respond-to-min(xl) {
				.sfwc-checkout-container {
					&--details {
						padding-left: $pad--min * 2;
						&:before {
							content: "";
							position: absolute;
							top: $pad--min;
							bottom: $pad--min * 2;
							left: 0;
							width: 1px;
							background-color: $checkout-border-grey;
						}
					}
					&--information {
						padding-right: $pad--min * 2;
					}
				}
			}
		}
	}

	.sfwc-checkout {
		// ... existing code ...

		a.sv-wc-payment-gateway-payment-form-manage-payment-methods {
			// Ensure the container has a defined size
			width: 100px; // or any specific width
			height: 100px; // or any specific height
			display: flex;
			justify-content: center;
			align-items: center;

			&:before {
				// Center the icon
				display: inline-block;
				margin-right: 5px;
				font-size: 1.2em;
				// Remove any positioning that might affect centering
				position: static;
			}
		}

		// ... existing code ...
	}
}
