//@import "../config/config";

///*=====================================================================</editor-fold>=
// GLOBAL
///=====================================================================<editor-fold>=*/

*,
*:after,
*:before {
	box-sizing: border-box;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
	background: #eb8a23;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #eb8a23;
	color: #fff;
	text-shadow: none;
}

html {
	overflow-x: hidden;
	width: 100%;
	height: 100%;
}

.hidden {
	display: none;
}

body {
	width: 100%;
	min-width: 320px;
	height: 100%;
	font-size: 15px;
	font-weight: 400;
	color: #000;
	background: #fff;
	-webkit-overflow-scrolling: touch;
	// word-break: break-word;
}

body,
input,
textarea,
button {
	font-family: $sf-font-family;
}

input,
textarea {
	border-radius: 0;
	appearance: none; // remove default stylings for all inputs...
	// &[type="radio"] {
	// 	appearance: radio; // ...except radio buttons...
	// }
	&[type="checkbox"] {
		appearance: checkbox; //...and checkboxes...
	}
	// -webkit-appearance: none;
}

.wrap {
	max-width: $sf-wrap--max-w;
	padding: 0 20px;
	margin: 0 auto;
	width: 100%;
}

.drop {
	box-shadow: 0 11px 65px 0 rgba(0, 0, 0, 0.07);
}

.strike-out {
	text-decoration: line-through !important;
}

.aligncenter {
	display: block;
	margin-left: auto !important;
	margin-right: auto !important;
}

.alignleft {
	display: block;
	margin-right: auto !important;
}

.alignright {
	display: block;
	margin-left: auto !important;
}

.copy-content {
	cursor: pointer;
}
//</editor-fold>

// NEW
$h1-6: h1, h2, h3, h4, h5, h6;
$body-text-elements: p, ul li, ol li, span, a, code, "span.code", button;
$all-typography-elements: $h1-6, $body-text-elements;
%sf-standard-typography--no-box-shadow {
	img,
	iframe,
	video,
	code {
		&:not(.sf-shadow) {
			box-shadow: none;
		}
	}
}
%sf-standard-typography--p-spacing {
	p {
		@include all-but-last() {
			margin-bottom: $pad--min;
		}
	}
}
%sf-standard-typography {
	img,
	iframe,
	video {
		width: 100%;
		box-shadow: $sf-shadow;
		max-width: 100%;
	}
	&.sf-standard-typography--no-box-shadow {
		@extend %sf-standard-typography--no-box-shadow;
	}
	hr {
		margin: $pad--small $pad--min;
		border: none;
		border-top: $sf-border;
	}
	h1 {
		@include h1();
		// remove link styling for nested links in h1 elements:
		a,
		a:hover {
			color: inherit !important;
			text-decoration: inherit !important;
		}
	}
	h2 {
		@include h2() {
			margin-top: $pad--small;
			margin-bottom: $pad--small;
		}
		// remove link styling for nested links in h2 elements:
		a,
		a:hover {
			color: inherit !important;
			text-decoration: inherit !important;
		}
	}
	h3 {
		@include h3() {
			margin-top: $pad--small;
			margin-bottom: $pad--min;
		}
	}
	h4 {
		@include h4() {
			margin-top: $pad--small;
			margin-bottom: $pad--min;
		}
	}
	.sf-page-title {
		@include h1($color: $sf-font-grey, $font-weight: $sf-weight--med);
	}
	@include sf-list();
	p {
		@include p();
	}
	a {
		&:not(.sf-pill-btn) {
			@include link();
		}
	}

	@for $i from 1 through 9 {
		#{$all-typography-elements} {
			&.sf-weight--#{$i}00 {
				font-weight: #{$i}00;
			}
		}
	}
	.sf-pill-btn {
		display: inline-block;
		&--block {
			display: block;
		}

		@include pill-btn--fill($bg: $sf-green, $bg-hover: $sf-green--dark);
		&.sf-pill-btn--outline {
			@include pill-btn--outline($color: $sf-green, $bg: #fff);
		}
		&.sf-pill-btn--orange {
			@include pill-btn--fill($bg: $sf-orange--light, $bg-hover: $sf-orange--dark);
			&.sf-pill-btn--outline {
				@include pill-btn--outline($color: $sf-orange--dark, $bg: #fff);
			}
		}
		&.sf-pill-btn--blue {
			@include pill-btn--fill($bg: $sf-blue--light, $bg-hover: $sf-blue--dark);
			&.sf-pill-btn--outline {
				@include pill-btn--outline($color: $sf-blue--dark, $bg: #fff);
			}
		}
		&.sf-pill-btn--white {
			@include pill-btn--fill($bg: #fff, $bg-hover: #fff);
			&.sf-pill-btn--white {
				@include pill-btn--outline($color: #fff, $bg: $sf-green);
			}
		}
	}
	code,
	span.code {
		background-color: $sf-grey--lightest;
		color: $sf-green;
		font-family: inherit !important;
	}
	span.code {
		display: inline-block;
		padding: 0 5px;
	}
	code {
		padding: 15px;
		display: block;
		box-shadow: $sf-shadow;
	}
	&.sf-standard-typography--centered {
		text-align: center;
	}
	&.sf-standard-typography--p-spacing {
		@extend %sf-standard-typography--p-spacing;
	}
	&.sf-standard-typography--h-bold {
		#{$h1-6} {
			font-weight: $sf-weight--heavy;
		}
	}
	&.sf-standard-typography--weight-light {
		#{$body-text-elements} {
			font-weight: $sf-weight--light;
		}
	}
	&.sf-standard-typography--weight-med {
		#{$body-text-elements} {
			font-weight: $sf-weight--med;
		}
	}
	&.sf-standard-typography--weight-heavy {
		#{$body-text-elements} {
			font-weight: $sf-weight--heavy;
		}
	}
	&.sf-standard-typography--weight-x-heavy {
		#{$body-text-elements} {
			font-weight: $sf-weight--x-heavy;
		}
	}
	&.sf-standard-typography--p-large {
		#{$body-text-elements} {
			font-size: $sf-font-size--large;
		}
	}
	&.sf-standard-typography--p-xlarge {
		#{$body-text-elements} {
			font-size: $sf-font-size--x-large;
		}
	}
	&.sf-standard-typography--h-min-marg-bottom {
		#{$h1-6} {
			margin-bottom: $pad--min;
		}
	}
	&.sf-standard-typography--first-child-no-margin-top {
		#{$h1-6},
		ol,
		ul,
		p {
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&.sf-standard-typography--img-spacing {
		img,
		iframe,
		code {
			margin-top: $pad--small;
			margin-bottom: $pad--small;
		}
	}

	blockquote {
		@include quote();
	}
}
.sf-standard-typography {
	@extend %sf-standard-typography;
}

.sf-align-center {
	text-align: center;
}

.sf-no-dropshadow {
}

.sf-font-size {
	font-size: $sf-font-size--standard;
	&.sf-font-size--x-small {
		font-size: $sf-font-size--x-small;
	}
	&.sf-font-size--small {
		font-size: $sf-font-size--small;
	}
	&.sf-font-size--large {
		font-size: $sf-font-size--large;
	}
	&.sf-font-size--x-large {
		font-size: $sf-font-size--x-large;
	}
}

.sf-nowrap {
	white-space: nowrap;
}

.sf-max-height {
	&.sf-max-height--minus-min-pad {
		max-height: calc(100vh - 30px);
	}
	max-height: 100vh;
	overflow-y: auto;
}

.sf-sticky {
	position: sticky;
	top: 0;
	z-index: 1;
}

.sf-border {
	border: $sf-border;
	&.sf-border--t,
	&.sf-border--b,
	&.sf-border--l,
	&.sf-border--r,
	&.sf-border--v,
	&.sf-border--h {
		border: none;
	}
	&.sf-border--t {
		border-top: $sf-border;
	}
	&.sf-border--b {
		border-bottom: $sf-border;
	}
	&.sf-border--l {
		border-left: $sf-border;
	}
	&.sf-border--r {
		border-right: $sf-border;
	}
	&.sf-border--v {
		border-top: $sf-border;
		border-bottom: $sf-border;
	}
	&.sf-border--h {
		border-left: $sf-border;
		border-right: $sf-border;
	}
}

// remove search cancel button
input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

// ensure all instances of strong have same level of bold
strong {
	font-weight: $sf-weight--heavy;
}

///*=====================================================================</editor-fold>=
// TOOLTIPS (TIPPY.JS)
///=====================================================================<editor-fold>=*/

.tippy-box[data-theme~="sf-grey"],
.tippy-box[data-theme~="sf-green"] {
	background-color: transparent;

	.tippy-content {
		background-color: #fff;
		color: $sf-font-grey;
		border: 1px solid $checkout-border-grey;
		border-radius: 5px;
		font-size: 12px;
		font-weight: $sf-weight--light;
		padding: 5px;

		ul {
			list-style-type: initial;
			li {
				display: list-item;
				list-style: inside;
			}
		}
	}

	&[data-placement^="top"] > .tippy-arrow::before {
		border-top-color: $scorpion;
	}

	&[data-placement^="bottom"] > .tippy-arrow::before {
		border-bottom-color: $scorpion;
	}

	&[data-placement^="left"] > .tippy-arrow::before {
		border-left-color: $scorpion;
	}

	&[data-placement^="right"] > .tippy-arrow::before {
		border-right-color: $scorpion;
	}
}

.tippy-box[data-theme~="sf-grey"] {
	.tippy-content {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	}
}

.tippy-box[data-theme~="sf-green"] {
	.tippy-content {
		box-shadow: 0px 0px 5px 0px rgba($sf-green, 0.5);
	}
}
