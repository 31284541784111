$mobile-nav-item--h: 60px;
// used to set the padding on nested mobile nav items, to reduce lots of repeated code for different Media Queries
@mixin mobile-nav-tiered-padding-left($pad: $pad--min) {
	// top-level menu items
	> li > a {
		padding-left: $pad;
	}
	// one tier deep
	.sub-menu {
		> li > a {
			padding-left: $pad * 2;
		}

		// two tiers deep
		> li > .sub-menu {
			> li > a {
				padding-left: $pad * 3;
			}
		}
	}
}

// mobile nav:
.sf-mobile-nav {
	background-color: #fff;
	padding: $pad--small $pad--min $mobile-nav-item--h * 2; // padding bottom to match two lines of mobile nav items, to ensure items aren't hidden behind the login button

	// hide download buttons, as must be on desktop to do so.
	[href="#download"],
	[href="#lfa-download"] {
		display: none;
	}

	// target all menu items:
	ul,
	li,
	a {
		display: block; // ensure block-level elements to apply padding etc.
		white-space: nowrap; // ensure no line breaks
	}

	// target all list items in the menu and sub-menus:
	li {
		position: relative; // ensure relative positioning to position pseudo elements
		&:not(:first-child) {
			border-top: 1px solid $sf-border--grey; // add border to all but the first item in any list
		}

		// > a:before {
		// 	content: "";
		// 	position: absolute;
		// 	display: block;
		// 	top: 0;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 5px;
		// 	background-color: $sf-green;
		// 	display: none;
		// 	// transition: all 0.3s;
		// }

		// target current menu item and it's ancestor nav items (set by wordpress):
		&.current-menu-item,
		&.current-menu-ancestor,
		&.current_page_parent {
			//exclude login button and target direct child link:
			&:not(.menu-item-5003) > a {
				// add a left border (as pseudo element) to indicate current item:
				&:before {
					content: "";
					position: absolute;
					display: block;
					top: 0;
					bottom: 0;
					left: 0;
					width: 5px;
					background-color: $sf-green;
				}
			}
		}

		// target login button:
		&.menu-item-5003 {
			position: fixed; // ensure it's always at the bottom of the screen
			bottom: 0;
			left: 0;
			right: 0;
			display: none; // hide by default, display with CSS later (when data-mobilemenu is set to true via JS)
			padding: $pad--min;
			z-index: 99; // ensure it's above all other content
			background-color: #fff; // set background color to hide items behind.
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); // add a shadow to make it stand out
			border-top: none; // unset border from list-item
			> a {
				@include pill-btn--fill($sf-green); // set as a pill btn
				justify-content: center; // center text
				justify-items: center; // center text
				font-weight: $sf-weight--x-heavy !important; // make text bold, override default styles
			}
		}
	}

	// target all links in the menu and sub-menus:
	a {
		padding: $pad--min; // apply padding to all sides for consistent spacing
		// position: relative; // ensure relative positioning to position pseudo elements
		display: flex; // align items vertically
		align-items: center;
		align-content: center;
		height: $mobile-nav-item--h; // set height to ensure consistent height for all items
		@include p(
			$font-weight: $sf-weight--heavy,
			$font-size: $sf-font-size--large,
			$color: $sf-nav-grey
		); // apply paragraph styles to all links for consistency
		transition: all 0.3s; // add a transition to all properties to ensure smooth animations

		&:hover {
			background-color: rgba($sf-grey--lighter, 0.2); // add a light background color on hover
		}
	}

	// target all list-items with children:
	li.menu-item-has-children {
		// target direct link (parent link):
		> a {
			margin-right: $mobile-nav-item--h; // add space for the arrow icon on the right, which will be square, so same width as height of list-item
			pointer-events: none; // ensure the parent link is not clickable, to be unset when expanded
		}

		// target the direct child sub-menu of all list-items with children:
		> .sub-menu {
			display: none; // hide by default
			position: relative; // ensure relative positioning to z-index correctly
			z-index: 1; // ensure it's brought z-index is broight forward to offset pseudo element being sent to back;
			overflow: hidden; // hide over-flow of pseudo element
			background-color: #fff; // prevent shadows darkness from stacking on nested sub-menus
			// add a shadow to top/bottom of sub-menu to emphasise depth:
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0; // fill heigh
				bottom: 0; // fill height
				left: -15px; // position to the left of the sub-menu so left shadow is not visible
				right: -15px; // position to the right of the sub-menu so right shadow is not visible
				box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.25); // add inset shadow to all sides
				z-index: -1; // send to back
			}
		}

		// add a triangle to list items with children to indicate a dropdown to sub-menus:
		&:after {
			width: $mobile-nav-item--h; // set width to height to create a square
			height: $mobile-nav-item--h; // set height to height to create a square
			font-size: 30px;
			font-weight: 100;
			color: $sf-green;
			position: absolute;
			top: 0; // position to the top of the list-item
			right: 0; // position to the right of the list-item

			// center the icon in the pseudo element:
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			align-content: center;

			transition: all 0.3s; // add a transition to all properties to ensure smooth animations
			@include remixicon($ri-arrow-down-s-line); // remixicon font icon
			content: "\ea4e";
		}
		// add a border to the left of the arrow pseudo element to separate it from the parent link (not a full height border, hense using another pseudo instead of border style) :
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: $pad--min; // offset from the top of the list-item
			height: calc(
				#{$mobile-nav-item--h} - #{$pad--min * 2}
			); // fill the height of the list-item, minus the padding
			right: $mobile-nav-item--h; // position to the right of the arrow, which will be square, so same width as height of list-item
			width: 1px;
			background-color: $sf-border--grey;
		}

		// target hover state of list items with children:
		&:hover {
			// target direct link (parent link) of list items with children:
			> a {
				background-color: transparent; // remove default grey background color on hover as it's default is to be unclickable (until it's expanded)
			}
			// add a light grey background color on hover to the arrow pseudo element to indicate it as a dropdown button:
			&:after {
				background-color: rgba($sf-grey--lighter, 0.2);
			}
		}

		// target .expanded-sub-menu list items (class added with JS):
		&.expanded-sub-menu {
			// target direct link (parent link) of expanded list items with children:
			> a {
				color: $sf-green; // highlight it
				pointer-events: unset; // allow link to page to be clickable now the sub-menu is expanded
			}

			> .sub-menu {
				display: block; // show the direct child sub-menu
			}

			// target the arrow pseudo element of expanded list items with children:
			&:after {
				color: $sf-font-grey; // change color to grey to indicate it'll close the sub-menu
				transform: rotateX(180deg); // rotate the arrow to indicate it'll close the sub-menu
			}

			// target hover state of arrow pseudo element on expanded list items with children:
			&:hover:after {
				background-color: transparent; // no longer highlight the arrow pseudo element on hover, as it's not the target functionality anymore (it's expanded - so the link to the page is clickable)
			}
			// target direct link (parent link) of expanded list items with children:
			> a:hover {
				background-color: rgba($sf-grey--lighter, 0.1);
				// ^ add a light background color on hover to the parent link to indicate it's expanded
			}
		}
	}

	// target all sub-menus:
	.sub-menu {
		// target all items in each sub-menu:
		li {
			// add animation to each item in the sub-menu:
			@extend %animated;
			animation-name: fade-in;
			> a {
				@extend %animated;
				animation-name: slide-in-left;
				&:before {
					@extend %animated;
					animation-name: fade-in;
				}
			}
			&:before,
			&:after {
				@extend %animated;
				animation-name: fade-in;
			}
			// delay animations based on the index (order) of the item (i.e later items in the DOM animate in marginally later):
			@for $i from 1 through 30 {
				$duration: ($i * 200);
				&:nth-child(#{$i}) {
					animation-delay: $duration + 0ms;
					> a,
					&:before,
					&:after {
						animation-delay: $duration + 0ms;
					}
					> a:before {
						// delay the border animation by another 1s to ensure run after the parent elements' animations are complete:
						animation-delay: $duration + 1000ms;
					}
				}
			}
		}
	}

	// add padding to nested mobile nav items:
	@include mobile-nav-tiered-padding-left();
	@include respond-to-min(sm) {
		// adjsut padding to nested mobile nav for larger screens:
		@include mobile-nav-tiered-padding-left($pad--small);
		@include respond-to-min(md) {
			// adjust padding to mobile nav container for larger screens:
			padding: $pad--small $pad--small $mobile-nav-item--h * 2;
			// adjust padding to login button for larger screens:
			li.menu-item-5003 {
				padding: $pad--min $pad--small;
			}
		}
	}
}
