.sf-operating-system {
	display: inline-flex;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
	margin: $pad--min 0;
	span {
		@include h3();
		text-transform: capitalize;
		display: block;
		margin: 0 10px;
	}
	img {
		width: 50px;
	}
}
