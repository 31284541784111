@mixin h1($color: #fff, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h1;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin h2($color: $sf-font-grey, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h2;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin h3($color: $sf-font-grey, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h3;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin h4($color: $sf-font-grey, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h4;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin h5($color: $sf-font-grey, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h5;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin h6($color: $sf-font-grey, $font-weight: $sf-weight--med) {
	font-size: $sf-font-size--h6;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	line-height: 1.5em;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin quote-text(
	$color: $sf-font-grey,
	$font-weight: $sf-weight--light,
	$font-size: $sf-font-size--x-large
) {
	@include p($color, $font-weight, $font-size);
	line-height: 1.4em;
	@content;
}

@mixin quote() {
	@include quote-text();
	* {
		@include quote-text();
	}
	margin-bottom: $pad--small;
	font-style: italic;

	background-image: url(publicImagePath("components/blocks/testimonial/quotation-mark--start.png")),
		url(publicImagePath("components/blocks/testimonial/quotation-mark--end.png"));
	background-position: top left, bottom right;
	background-repeat: no-repeat;

	padding: $pad--min 0;
	@include respond-to-min(sm) {
		padding: $pad--min;
		@include respond-to-min(xl) {
			padding: $pad--small;
		}
	}
}

@mixin p(
	$color: $sf-font-grey,
	$font-weight: $sf-weight--light,
	$font-size: $sf-font-size--standard
) {
	font-size: $font-size;
	line-height: 1.5em;
	font-weight: $font-weight;
	font-family: $sf-font-family;
	@if $color != "unset" or $color != unset {
		color: $color;
	}
	@content;
}

@mixin link($color: $sf-green, $hover-color: $sf-green--dark, $underline: true) {
	color: $color;
	cursor: pointer;

	@if ($underline == false or $underline == "on-hover") {
		@include ensureLinkNoUnderline($allowOnHover: $underline == "on-hover");
	} @else {
		text-decoration: underline;
	}
	&:hover {
		@if ($hover-color) {
			color: $hover-color;
		} @else {
			color: darken($color, 5%);
		}

		@if ($underline == "on-hover") {
			text-decoration: underline;
		}
	}
	@content;
}

@mixin truncate($truncation-boundary: false) {
	@if $truncation-boundary {
		max-width: $truncation-boundary;
	}
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@content;
}

@mixin text-input($border: true, $dark: false, $font-size: $sf-font-size--standard) {
	@if ($dark) {
		@include p($color: $sf-grey--lighter, $font-size: $font-size);
		background-color: transparent;
	} @else {
		@include p($font-size: $font-size);
		background-color: #fff;
	}
	&::placeholder {
		color: $sf-grey--light;
		font-weight: $sf-weight--med;
	}
	&:focus::placeholder {
		color: $sf-grey;
	}

	@if ($border) {
		@if ($dark) {
			border: 1px solid $sf-grey--light;
			&:focus {
				border-color: $sf-grey--lighter;
			}
		} @else {
			border: 1px solid $sf-grey--lighter;
			&:focus {
				border-color: $sf-grey--light;
			}
		}
	} @else {
		border: none;
	}
	@content;
}
