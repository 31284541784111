.main-blog--posts.vacancies {
	width: 100%;
	margin: 0;
}

@media (max-width: 1360px) {
	.main-blog--posts.vacancies {
		padding: 0 40px;
	}
}

@media (max-width: 1110px) {
	.main-blog--posts.vacancies {
		padding: 0 20px;
	}
}

@include respond-to-max("bs-md") {
	.main-blog--posts.vacancies {
		padding: 0;
	}
}

.main-blog--posts.vacancies .main-blog--posts_single {
	margin-right: 0;
}

.main-blog--posts.vacancies .main-blog--posts_single p strong {
	font-weight: $sf-weight--med;
}

.main-blog--posts_single.single-post .vacancy-wrap h2.vacancy-title {
	font-size: 32px;
	font-weight: $sf-weight--light;
	line-height: 46px;
	margin-bottom: 35px;
}

.main-blog--posts_single.single-post .vacancy-wrap h2.vacancy-title span {
	color: #75b42c;
}

.main-blog--posts_single.single-post .vacancy-wrap h3 {
	color: $sf-font-grey;
	font-size: 16px;
}

@media (max-width: 1050px) {
	.main-blog--posts_single.single-post .vacancy-wrap_right-title h2.vacancy-title {
		display: none;
	}
}

.main-blog--posts_single.single-post .vacancy-about {
	padding: 40px;
}

.main-blog--posts_single.single-post .vacancy-about_left,
.main-blog--posts_single.single-post .vacancy-about_right {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 46.5%;
	margin-left: 0%;
	margin-right: 7.000000000000001%;
}

.main-blog--posts_single.single-post .vacancy-about_left:before,
.main-blog--posts_single.single-post .vacancy-about_right:before,
.main-blog--posts_single.single-post .vacancy-about_left:after,
.main-blog--posts_single.single-post .vacancy-about_right:after {
	content: "";
	display: table;
}

.main-blog--posts_single.single-post .vacancy-about_left:after,
.main-blog--posts_single.single-post .vacancy-about_right:after {
	clear: both;
}

.main-blog--posts_single.single-post .vacancy-about_left:nth-child(2n),
.main-blog--posts_single.single-post .vacancy-about_right:nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.main-blog--posts_single.single-post .vacancy-about_left:nth-child(2n + 1),
.main-blog--posts_single.single-post .vacancy-about_right:nth-child(2n + 1) {
	clear: both;
}

@media (max-width: $screaming-breakpoint) {
	.main-blog--posts_single.single-post .vacancy-about_left,
	.main-blog--posts_single.single-post .vacancy-about_right {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		margin-left: 0%;
		margin-right: 3%;
		width: 100%;
	}

	.main-blog--posts_single.single-post .vacancy-about_left:before,
	.main-blog--posts_single.single-post .vacancy-about_right:before,
	.main-blog--posts_single.single-post .vacancy-about_left:after,
	.main-blog--posts_single.single-post .vacancy-about_right:after {
		content: "";
		display: table;
	}

	.main-blog--posts_single.single-post .vacancy-about_left:after,
	.main-blog--posts_single.single-post .vacancy-about_right:after {
		clear: both;
	}

	.main-blog--posts_single.single-post .vacancy-about_left:nth-child(2n),
	.main-blog--posts_single.single-post .vacancy-about_right:nth-child(2n) {
		margin-right: 3%;
		float: left;
	}

	.main-blog--posts_single.single-post .vacancy-about_left:nth-child(2n + 1),
	.main-blog--posts_single.single-post .vacancy-about_right:nth-child(2n + 1) {
		clear: none;
	}

	.main-blog--posts_single.single-post .vacancy-about_left:last-child,
	.main-blog--posts_single.single-post .vacancy-about_right:last-child {
		margin-right: 0%;
	}
}

@media (max-width: $screaming-breakpoint) {
	.main-blog--posts_single.single-post .vacancy-about_left {
		margin-bottom: 30px;
	}
}

.main-blog--posts_single.single-post .vacancy-about_left h3 {
	font-size: 28px;
	color: #6f6f6f;
	line-height: 45px;
	font-weight: $sf-weight--light;
	margin-bottom: 0;
}

.main-blog--posts_single.single-post .vacancy-about_right p {
	font-size: 16px;
	color: $sf-font-grey;
	line-height: 30px;
	margin-bottom: 30px;
}

.main-blog--posts_single.single-post .vacancy-about_images {
	clear: both;
	display: inline-block;
	background: #fff;
	width: 100%;
	margin-bottom: -4px;
}

.main-blog--posts_single.single-post .vacancy-about_images img:first-child {
	float: left;
	padding: 15px 10px 15px 20px;
	width: 50%;
	max-width: 620px;
}

.main-blog--posts_single.single-post .vacancy-about_images img:last-child {
	float: right;
	padding: 15px 20px 15px 10px;
	width: 50%;
	max-width: 620px;
}

.main-careers {
	max-width: 1600px;
	margin: 0 auto;
	position: relative;
}

.main-careers:after {
	content: "";
	background: #f8f8f8;
	width: 43px;
	height: 43px;
	position: absolute;
	bottom: -20px;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 2;
	border-radius: 50px;
}

.main-careers:before {
	content: "\f103";
	font-family: fontAwesome;
	color: #75b42c;
	position: absolute;
	bottom: -20px;
	display: block;
	z-index: 3;
	font-size: 36px;
	left: 50%;
	margin: auto auto auto -11px;
}

@media (max-width: 900px) {
	.main-careers {
		padding: 20px 0;
		background: #f8f8f8;
	}
}

.main-careers__cover-image {
	display: block;
	width: 100%;
	max-width: 100%;
	position: relative;
	z-index: 1;
}

.main-careers--cover {
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.main-careers--middle {
	background: #fff;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 9;
	max-width: 1300px;
	margin: 0 auto;
	left: 0;
	right: 0;
}

.main-careers--middle_left,
.main-careers--middle_right {
	width: 50%;
	float: left;
}

.main-careers--middle_left {
	padding: 50px 35px 30px;
}

.main-careers--middle_left p {
	color: $sf-font-grey;
	line-height: 25px;
	font-weight: $sf-weight--light;
}

.main-careers--middle_left p.large-recruiment {
	font-size: 32px;
	color: $sf-font-grey;
	line-height: 40px;
	margin-bottom: 25px;
}

.main-careers--middle_left-links {
	margin-top: 25px;
}

.main-careers--middle_left-links a {
	padding: 17px 0;
	font-size: 15px;
	display: inline-block;
	margin: 6px 25px 0 0;
	background: #75b42c;
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 255px;
}

.main-careers--middle_left-links a:hover {
	background: #599f08;
}

.main-careers--middle_left-links a.contact {
	background: #7180e4;
	max-width: 145px;
}

.main-careers--middle_left-links a.contact:hover {
	background: #3f52d5;
}

.main-careers--middle_right {
	padding: 50px 50px 50px 60px;
	background: #f7f7f7;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0%, #f7f7f7),
		color-stop(12%, #fff)
	);
	background: linear-gradient(to right, #f7f7f7 0%, #fff 12%);
}

.main-careers--middle_right ul li {
	display: block;
	color: $sf-font-grey;
	margin-bottom: 24px;
	font-weight: $sf-weight--light;
}

.main-careers--middle_right ul li:last-child {
	margin-bottom: 0;
}

.main-careers--middle_right ul li:before {
	content: "\f00c";
	font-family: fontAwesome;
	color: #75b42c;
	display: block;
	float: left;
	margin-right: 25px;
}

@media (max-width: 900px) {
	.main-careers__cover-image {
		position: relative;
		display: none;
	}

	.main-careers--middle {
		position: relative;
		top: 0;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		margin-top: 40px;
	}
}

.main-careers--vacancies {
	background: #f8f8f8;
}

.main-careers--vacancies h1 {
	text-align: center;
	font-size: 32px;
	color: $sf-font-grey;
	margin-bottom: 30px;
	font-weight: $sf-weight--light;
}

.main-careers--vacancies_single {
	background: #fff;
	max-width: 800px;
	padding: 30px;
	margin: 0 auto 25px;
}

.main-careers--vacancies_single h2 {
	float: left;
	font-size: 22px;
	color: $sf-font-grey;
	line-height: 46px;
}

.main-careers--vacancies_single a {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 140px;
	background: #75b42c;
	float: right;
	padding: 14px;
	display: inline-block;
}

.main-careers--vacancies_single a:hover {
	background: #599f08;
}

@media (max-width: 550px) {
	.main-careers--vacancies_single h2 {
		float: none;
		width: 100%;
	}

	.main-careers--vacancies_single a {
		float: none;
		margin-top: 15px;
	}
}

.vacancy-wrap_right,
.vacancy-wrap_left {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 46.5%;
	margin-left: 0%;
	margin-right: 7.000000000000001%;
}

.vacancy-wrap_right:before,
.vacancy-wrap_left:before,
.vacancy-wrap_right:after,
.vacancy-wrap_left:after {
	content: "";
	display: table;
}

.vacancy-wrap_right:after,
.vacancy-wrap_left:after {
	clear: both;
}

.vacancy-wrap_right:last-child,
.vacancy-wrap_left:last-child {
	margin-right: 0%;
}

.vacancy-wrap_right h3,
.vacancy-wrap_left h3 {
	color: $sf-font-grey;
	font-size: 16px;
}

@media (max-width: $screaming-breakpoint) {
	.vacancy-wrap_right,
	.vacancy-wrap_left {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		margin-left: 0%;
		margin-right: 3%;
		width: 100%;
	}

	.vacancy-wrap_right:before,
	.vacancy-wrap_left:before,
	.vacancy-wrap_right:after,
	.vacancy-wrap_left:after {
		content: "";
		display: table;
	}

	.vacancy-wrap_right:after,
	.vacancy-wrap_left:after {
		clear: both;
	}

	.vacancy-wrap_right:last-child,
	.vacancy-wrap_left:last-child {
		margin-right: 0%;
	}
}

.vacancy-wrap_right h2 {
	float: left;
}

.vacancy-wrap_right-title a {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 220px;
	background: #75b42c;
	float: right;
	padding: 14px 0;
}

.vacancy-wrap_right-title a:hover {
	colors: #599f08;
}

@media (max-width: 1050px) {
	.vacancy-wrap_right-title a {
		display: none;
	}
}

.vacancy-wrap_right p {
	margin-bottom: 30px;
}

.vacancy-wrap_left ul {
	padding: 0;
	margin-top: 20px;
}

.vacancy-wrap_left ul li {
	color: $sf-font-grey;
	font-size: 16px;
}

.vacancy-wrap_left p {
	margin-bottom: 20px;
}

.vacancy-contact .wrap {
	padding: 0;
}

.vacancy-contact .clients-contact--inner_right h3 {
	font-size: 38px;
	color: #6f6f6f;
	text-align: center;
}

.vacancy-contact .clients-contact--inner_right > p {
	padding: 35px 0;
	text-align: center;
}

.vacancy-contact .clients-contact--inner_right p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 26px;
}

.vacancy-contact .clients-contact--inner_right p a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.vacancy-contact .clients-contact--inner_right p a:hover {
	color: #599f08;
}

.vacancy-cv span.file-879 {
	display: none;
}

.vacancy-cv label[for="upload-cv"] {
	border: 1px solid #e1e1e1;
	padding: 12px 20px;
	font-size: 15px;
	margin-bottom: 20px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	display: block;
	cursor: pointer;
}

.vacancy-cv label[for="upload-cv"] span.browse {
	float: right;
	color: #75b42c;
	font-weight: 600;
}

p.vacancy-testimonial {
	font-size: 24px !important;
	line-height: 36px !important;
}

.vacancy-cv {
	label[for="cv"] {
		.browse {
			display: none;
		}
		&[data-filename] {
			.filename {
				display: none;
			}
			&::before {
				content: "Uploaded file: ";
				font-weight: $sf-weight--heavy;
			}
			&::after {
				content: attr(data-filename);
			}
		}
	}
	span.cv {
		border-radius: 5px;
		border: 1px solid #75b42c;
		color: #75b42c;
		cursor: pointer;
		display: block;
		font-size: 20px;
		font-weight: bold;
		margin: 5px 0 0 0;
		padding: 0;
		position: relative;
		transition: background 0.2s ease-in-out;
		input {
			cursor: pointer;
			height: 100%;
			margin: 0;
			opacity: 0;
			padding: 1em;
		}
		&::before {
			content: "Upload CV";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		&:hover {
			background: #75b42c;
			color: #fff;
		}
	}
}
