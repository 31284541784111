@mixin learn-seo-post-button($arrow-direction: down) {
	$learn-more-btn-arrow: 40px;
	border: none;
	&:not(:first-child) {
		@include border($sf-border, top);
	}
	padding: 0 $pad--min + $learn-more-btn-arrow 0 $pad--min;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	align-content: center;
	background-color: transparent;
	width: 100%;
	text-align: left;
	font-size: 18px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	box-sizing: border-box;
	transition: all 0.3s;

	span {
		display: block;
		padding: $pad--min 0;
	}

	img {
		width: 30px;
		height: 30px;
		margin-right: 5px;
	}

	&:before,
	b {
		content: "";
		position: absolute;

		.learn-seo-section--fundamentals & {
			background-color: $learnSEO-green;
		}

		.learn-seo-section--on-page & {
			background-color: $learnSEO-orange;
		}
		.learn-seo-section--technical & {
			background-color: $learnSEO-blue;
		}
	}

	&:before {
		left: 0;
		top: -1px;
		bottom: -1px;
		width: 5px;
	}

	b {
		width: $learn-more-btn-arrow;
		right: 0;
		top: 0;
		bottom: 0;
		color: #fff;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		font-size: 25px;
		transition: background-color 0.3s;

		i {
			position: relative;
			left: 0;
			top: 0;
			transition: all 0.3s;
		}
	}

	&:hover {
		b {
			i {
				@if ($arrow-direction == "down") {
					top: 3px;
				} @else if ($arrow-direction == "right") {
					left: 3px;
				}
			}
		}
	}

	@include respond-to-min(md) {
		padding: 0 $pad--min 0 $pad--min;
		b {
			display: none;
		}

		@include border($sf-border, right, top, bottom);
		width: calc(50% - #{calc($pad--min / 2)});
		@include respond-to-min(lg) {
			width: calc(#{calc(100 / 3 * 1%)} - #{calc($pad--min / 2)});
		}
		margin-bottom: $pad--min;

		&:hover {
			.learn-seo-section--fundamentals & {
				border-color: $learnSEO-green;
			}
			.learn-seo-section--on-page & {
				border-color: $learnSEO-orange;
			}
			.learn-seo-section--technical & {
				border-color: $learnSEO-blue;
			}
		}
	}
}

.learn-seo {
	color: $sf-font-grey;

	&--overview {
		background-color: #fff;
	}

	&-section {
		&__title {
			display: flex;
			align-content: center;
			align-items: center;
			padding: 0 $pad--min;

			h2 {
				font-size: 24px;
				font-weight: 400;
			}
			img {
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
		}

		&__intro-text {
			padding: $pad--min;
			p {
				font-size: 18px;
				font-weight: $sf-weight--light;
				line-height: 30px;
				margin: 10px 0 20px;
			}
		}

		&__posts {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: $pad--min;

			a {
				position: relative;
				text-decoration: none !important;
				cursor: pointer;
				background-color: #fff;
				width: 100%;
				text-align: left;
				font-size: 18px;
				font-weight: $sf-weight--light;
				color: $sf-font-grey;
				box-sizing: border-box;
				transition: all 0.3s;
				@include border($sf-border, bottom, top, right);
				display: grid;
				column-gap: $pad--min;
				align-items: center;
				grid-template-columns: 5px 30px 1fr;
				img {
					width: 100%;
				}

				&:before {
					content: "";
					height: 100%;
					background-color: $sf-green;
					.learn-seo-section--fundamentals & {
						background-color: $learnSEO-green;
					}
					.learn-seo-section--on-page & {
						background-color: $learnSEO-orange;
					}
					.learn-seo-section--technical & {
						background-color: $learnSEO-blue;
					}
				}

				span {
					display: block;
					padding: $pad--min;
					padding-left: 0;
				}

				&:hover {
					.learn-seo-section--fundamentals & {
						color: $learnSEO-green;
						border-color: $learnSEO-green;
					}
					.learn-seo-section--on-page & {
						color: $learnSEO-orange;
						border-color: $learnSEO-orange;
					}
					.learn-seo-section--technical & {
						color: $learnSEO-blue;
						border-color: $learnSEO-blue;
					}
				}
			}
		}
	}

	@include respond-to-min(md) {
		&-section {
			&__title {
				padding: 0;
			}
			&__intro-text {
				padding: $pad--min 0;
			}
		}
	}
}
