//@import "../config/config";

///*=====================================================================</editor-fold>=
// BLOG
///=====================================================================<editor-fold>=*/

.main-blog {
	background: #f8f8f8;
	padding: 75px 0;
}

.main-blog--posts {
	width: 100%;
	float: left;
	margin-right: -370px;
}

@media (max-width: 1000px) {
	.main-blog--posts {
		float: none;
		margin-right: 0;
	}
}

.main-blog--posts .main-blog--posts_single {
	// margin-right: 370px;
}

@media (max-width: 1000px) {
	.main-blog--posts .main-blog--posts_single {
		margin-right: 0;
	}
}

@include respond-to-max("bs-md") {
	.main-blog {
		padding: 25px 0;
	}

	.main-blog .wrap {
		padding: 0;
	}
}

h3.no-posts-message {
	@include h3() {
		margin-bottom: $pad--min;
	}
}

.main-blog--posts_single.single-post {
	background: #fff;
	padding: 40px;
	margin-bottom: 70px;
}

.main-blog--posts_single.single-post.no-padding {
	padding: 0;
}

.main-blog--posts_single.single-post {
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: $sf-weight--light;
		color: $sf-font-grey;
	}

	h1,
	h2.news-title {
		font-size: 32px;
		margin-top: 5px;

		@include respond-to-max("bs-md") {
			margin-top: 20px;
		}
	}

	h1 {
		margin-bottom: 20px;
	}

	h2 {
		font-size: 26px;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 24px;
		margin-bottom: 15px;
	}

	h4 {
		font-size: 22px;
		margin-bottom: 15px;
	}
}

.main-blog--posts_single p,
.main-blog--posts_single li {
	font-size: 16px;
	line-height: 30px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.main-blog--posts_single.single-post p a {
	color: #75b42c;
	border-bottom: 1px solid #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.main-blog--posts_single.single-post p a:hover {
	color: #599f08;
	border-bottom: 1px solid #599f08;
}

.main-blog--posts_single.single-post {
	@include sf-list() {
		margin-bottom: 20px;
	}
}

@media (max-width: 500px) {
	.main-blog--posts_single.single-post {
		padding: 40px 25px;
	}
}

.main-blog--posts_single-inner {
	background: #fff;
	min-height: 390px;
	position: relative;
	// margin-bottom: 60px;
}

.main-blog--posts_single-inner:after {
	display: table;
	content: "";
	line-height: 0;
	clear: both;
}

.main-blog--posts_single-inner iframe {
	max-width: 100% !important;
}

.main-blog--posts_single-inner--text--inner--text--inner-faqs h3 {
	font-size: 24px;
}

.main-blog--posts_single-inner--text--content-faqs p {
	margin-bottom: 30px;
}

.main-blog--posts_single-inner--text--content-faqs p a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.main-blog--posts_single-inner--text--content-faqs p a:hover {
	color: #599f08;
}

.main-blog--posts_single-inner--text--content-faqs p iframe {
	max-width: 100%;
}

.main-blog--posts_single-inner--text--content-faqs img {
	margin-bottom: 30px;
}

.main-blog--posts_single-inner--text--content-faqs ul {
	list-style-type: disc;
	padding: 20px 0 20px 20px;
}

.main-blog--posts_single-inner--text--content-faqs ul li {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 30px;
}

.main-blog--posts_single-inner--text--content-faqs ul li a {
	color: #75b42c;
}

.main-blog--posts_single-inner--text--content-faqs ul li a:hover {
	color: #599f08;
}

.main-blog--posts_single-inner--text--content-faqs pre {
	font-family: "Courier new", Courier, "Andale Mono", monospace;
	padding: 20px;
	background: #f0f0f0;
	margin-bottom: 30px;
}

.faq-answers--inner_answer,
.main-blog--posts_single-inner--text--content-faqs {
	code {
		font-family: "Courier new", Courier, "Andale Mono", monospace;
		color: #424242;
	}
}

.main-blog--posts_single--inside {
	color: $sf-font-grey;
}

.main-blog--posts_single--inside h2 {
	font-size: 32px;
	color: $sf-font-grey;
}

.main-blog--posts_single--inside p {
	margin-bottom: 20px;
}

.main-blog--posts_single--inside p:first-child {
	font-size: 21px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	padding: 25px 0;
	margin-bottom: 0;
}

.main-blog--posts_single--inside img {
	max-width: 100%;
	height: auto;
	margin: 20px 0 40px 0;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 2px -2px rgba(0, 0, 0, 0.15);
}

.main-blog--posts_single--inside_author {
	background: #fff;
	padding: 40px;
	margin-bottom: 70px;
	margin-right: 370px;
}

@media (max-width: 1000px) {
	.main-blog--posts_single--inside_author {
		margin-right: 0;
	}
}

.main-blog--posts_single--inside_author .avatar-image img {
	max-width: 150px;
	width: 100%;
	border-radius: 150px;
	border: 2px solid #f0f0f0;
	float: left;
}

.main-blog--posts_single--inside_author-details {
	padding: 10px 0 10px 40px;
	width: 100%;
}

.main-blog--posts_single--inside_author-details .author-details--social {
	line-height: 30px;
}

.main-blog--posts_single--inside_author-details .author-details--social a {
	font-size: 22px;
	color: $sf-font-grey;
	display: block;
	float: left;
}

.main-blog--posts_single--inside_author-details .author-details--social a img {
	max-width: 25px;
	margin-right: 10px;
}

.main-blog--posts_single--inside_author-details .author-details--social ul {
	margin-left: 25px;
	padding-top: 2px;
	max-height: 30px;
	float: left;
}

.main-blog--posts_single--inside_author-details .author-details--social ul li {
	display: inline-block;
}

.main-blog--posts_single--inside_author-details .author-details--description {
	float: left;
	clear: left;
	color: $sf-font-grey;
	line-height: 29px;
	margin-top: 15px;
	font-weight: $sf-weight--light;
}

.avatar-image + .main-blog--posts_single--inside_author-details {
	width: 80.6875%;
}

@media (max-width: 600px) {
	.main-blog--posts_single--inside_author .avatar-image {
		float: none;
		width: 50%;
		margin-bottom: 20px;
	}

	.main-blog--posts_single--inside_author-details,
	.avatar-image + .main-blog--posts_single--inside_author-details {
		float: none;
		width: 100%;
		padding-left: 0;
	}

	.main-blog--posts_single--inside_author-details ul,
	.avatar-image + .main-blog--posts_single--inside_author-details ul {
		margin-top: 10px;
		margin-left: 0;
		width: 100%;
	}
}

.main-blog--posts_single--inside--guides pre {
	font-family: "Courier new", Courier, "Andale Mono", monospace;
	padding: 20px;
	background: #f0f0f0;
	margin-bottom: 40px;
}

//</editor-fold>
