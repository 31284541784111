//SF TODO: TIDY (NEILS CODE)
.theme-screamingfrog {
	.thankyou-page {
		padding: 50px 0;

		.woocommerce-order-details {
			p {
				@include p();
			}
		}

		p.woocommerce-notice,
		h2.woocommerce-order-details__title,
		.woocommerce-order-details header h2,
		.woocommerce-customer-details h2.woocommerce-column__title {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 10px !important;
		}

		.view_invoice a {
			font-weight: 600;
			display: inline-block;
			margin-top: $pad--min;
			@include pill-btn--fill($sf-blue--light, #fff !important, $sf-blue--dark);
		}

		.woocommerce-order-overview.order_details {
			margin: 0 0 35px !important;
			border: 1px solid #e1e1e1;
			display: inline-flex;
			padding: 0;
			background-color: #f9f9f9;

			li {
				margin: 0;
				padding: 15px 25px;
				font-size: 14px;
				font-weight: 600;
				text-transform: none;
				color: #000;

				strong {
					display: block;
					border-top: 1px solid #e1e1e1;
					padding-top: 5px;
					margin-top: 5px;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		p {
			margin-bottom: 20px;
		}

		.woocommerce-customer-details {
			address {
				padding: 20px;
				line-height: 24px;

				.woocommerce-customer-details--phone,
				.woocommerce-customer-details--email {
					padding-top: 15px;

					&:before {
						color: #599f08;
					}
				}
			}
		}

		.woocommerce-order-details {
			p a {
				color: $sushi;
				&:hover {
					text-decoration: underline;
				}
			}

			h2.woocommerce-order-details__title {
				margin: 20px 0 $pad--min !important;
			}

			table {
				width: 100%;
				td,
				th {
					text-align: left;
				}
			}
		}

		table.shop_table {
			&.woocommerce-table--order-details {
				display: block;

				tfoot,
				tbody,
				thead {
					display: flex;
					flex-direction: column;
				}

				tr {
					width: 100%;
					display: table;

					th,
					td {
						width: 66%;
					}
				}

				tfoot tr:nth-child(3) {
					order: 5;
				}
			}

			&,
			td,
			th {
				border: 0;
				border-collapse: collapse;
				word-break: normal;
				border-radius: 0;
			}

			td,
			th {
				border: 1px solid rgba(0, 0, 0, 0.1) !important;
				border-bottom: none !important;
			}

			border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

			thead,
			tbody {
				td,
				th {
					font-size: 16px;
					color: $sf-font-grey;
					padding: 10px 15px;
				}
			}

			thead {
				td,
				th {
					background-color: #f5f5f5;
					margin-bottom: 15px;
				}
			}

			tbody {
				tr + tr {
					td,
					th {
						border-top: 1px solid #d6d6d6;
					}
				}
			}

			tfoot {
				font-size: 18px;
				color: $sf-font-grey;

				th,
				td {
					border: 0;
				}

				th {
					background-color: #f5f5f5;
					padding: 10px 15px;
					font-weight: 400;
					font-size: 16px;
					vertical-align: middle;
				}

				td {
					color: $sf-font-grey;
					padding: 15px;
					font-weight: 400;
				}

				tr:last-of-type {
					td,
					th {
						font-weight: $sf-weight--heavy;
					}
				}
			}

			@media (max-width: 600px) {
				&.licence-list-table,
				&.related-subscriptions-table {
					&,
					td,
					tbody {
						display: block;
						border: none !important;
						width: 100%;
						text-align: right;
						white-space: normal;
					}

					tr {
						display: block;
						border: 1px solid rgba(0, 0, 0, 0.1) !important;
						width: 100%;

						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}

					thead {
						display: none;
					}

					td {
						&[data-title] {
							&:before {
								font-weight: $sf-weight--heavy;
								float: left;
								margin-right: 5px;
								content: attr(data-title) ":";
							}
						}
					}
				}
			}
		}
	}
}
