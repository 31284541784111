//@import "../config/config";

///*=====================================================================</editor-fold>=
// SUMMARY
///=====================================================================<editor-fold>=*/

.spider-summary {
	background: #fff;
	padding: 90px 0;
}

@include respond-to-max("bs-md") {
	.spider-summary {
		padding: 30px;
	}
}

.spider-summary > h2 {
	font-size: 32px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	text-align: center;
}

.spider-summary > p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	max-width: 710px;
	text-align: center;
	margin: 15px auto 90px;
	line-height: 30px;
}

@media (max-width: 700px) {
	.spider-summary > p {
		margin-bottom: 40px;
	}
}

.spider-summary_left {
	float: left;
}

.spider-summary_left,
.spider-summary_right {
	*zoom: 1;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	max-width: 550px;
}

.spider-summary_left:before,
.spider-summary_right:before,
.spider-summary_left:after,
.spider-summary_right:after {
	content: "";
	display: table;
}

.spider-summary_left:after,
.spider-summary_right:after {
	clear: both;
}

.spider-summary_left:last-child,
.spider-summary_right:last-child {
	margin-right: 0%;
}

@media (max-width: 750px) {
	.spider-summary_left,
	.spider-summary_right {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 100%;
		margin-left: 0%;
		margin-right: 3%;
		max-width: 100%;
	}

	.spider-summary_left:before,
	.spider-summary_right:before,
	.spider-summary_left:after,
	.spider-summary_right:after {
		content: "";
		display: table;
	}

	.spider-summary_left:after,
	.spider-summary_right:after {
		clear: both;
	}

	.spider-summary_left:last-child,
	.spider-summary_right:last-child {
		margin-right: 0%;
	}
}

.spider-summary_left ul li,
.spider-summary_right ul li {
	color: $sf-font-grey;
	margin-bottom: 10px;
	font-weight: $sf-weight--light;
	position: relative;
	line-height: 25px;
	padding-left: 20px;
}

.spider-summary_left ul li:before,
.spider-summary_right ul li:before {
	content: "";
	background: #75b42c;
	width: 5px;
	height: 5px;
	border-radius: 50px;
	display: block;
	float: left;
	position: absolute;
	left: 0;
	top: 9px;
}

.spider-summary_left ul li strong,
.spider-summary_right ul li strong {
	font-weight: $sf-weight--med;
}

.spider-summary_right {
	float: right;
}
