//@import "../config/config";

///*=====================================================================</editor-fold>=
// ABOUT
///=====================================================================<editor-fold>=*/

.about-us {
	background: #f8f8f8;
	padding: 90px 0;
}

.about-us--inner {
	background: #fff;
}

.about-us--inner_left {
	padding: 50px 60px;
}

@media (max-width: 1188px) {
	.about-us--inner_left {
		padding: 30px 60px;
	}
}

@media (max-width: 1065px) {
	.about-us--inner_left {
		float: none;
		width: 100%;
	}
}

@media (max-width: 500px) {
	.about-us--inner_left {
		padding: 30px;
	}
}

.about-us--inner_left p {
	color: $sf-font-grey;
	line-height: 29px;
	font-weight: $sf-weight--light;
}

.about-us--inner_left p:first-child {
	font-size: 32px;
	color: $sf-font-grey;
	line-height: 42px;
	margin-bottom: 20px;
}

@media (max-width: 1065px) {
	.about-us--inner_right {
		float: none;
		clear: both;
		width: 100%;
	}
}

.about-us--inner_right a {
	display: block;
}

@media (max-width: 1065px) {
	.about-us--inner_right a {
		float: left;
		width: 50%;
	}
}

@media (max-width: 910px) {
	.about-us--inner_right a {
		width: 100%;
		float: none;
	}
}

.about-us--inner_right--top,
.about-us--inner_right--bottom {
	width: 100%;
	min-height: 225px;
	max-width: 600px;
	display: block;
	padding-right: 50px;
}

.about-us--inner_right--top_text,
.about-us--inner_right--bottom_text {
	float: right;
	margin-top: 70px;
	width: calc(100% - 200px);
}

@media (max-width: 1188px) {
	.about-us--inner_right--top_text,
	.about-us--inner_right--bottom_text {
		margin-top: 58px;
	}
}

.about-us--inner_right--top_text h2,
.about-us--inner_right--bottom_text h2 {
	font-size: 22px;
	color: #fff;
	margin-bottom: 5px;
}

.about-us--inner_right--top_text p {
	color: #c5c5c5;
	line-height: 25px;
}

.about-us--inner_right--bottom_text p {
	line-height: 25px;
}

@media (max-width: 500px) {
	.about-us--inner_right--top,
	.about-us--inner_right--bottom {
		padding-right: 0;
	}

	.about-us--inner_right--top_text,
	.about-us--inner_right--bottom_text {
		float: none;
		margin-top: 0;
		width: 100%;
		padding: 210px 30px 50px;
	}
}

.about-us--inner_right--top {
	background: url("../../public/images/about-story.png") no-repeat;
	background-size: 594px 224px;
}

.about-us--inner_right--bottom {
	background: url("../../public/images/about-team.png") no-repeat;
	background-size: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.about-us--inner_right--bottom {
		background: url("../../public/images/about-team@2x.png") no-repeat;
		background-size: 100%;
	}
}

.about-us--inner_right--bottom_text p {
	color: #d1ff9b;
}

@media (max-width: 500px) {
	.about-us--inner_right--bottom {
		background-size: 600px;
	}
}

.about-us--choose {
	background: #fff;
	padding: 60px 0 0;
}

.about-us--choose h2 {
	font-size: 32px;
	color: $sf-font-grey;
	text-align: center;
	font-weight: $sf-weight--light;
}

.about-us--choose ul {
	padding-top: 90px;
}

.about-us--choose ul:after {
	display: table;
	content: "";
	line-height: 0;
	clear: both;
}

.about-us--choose ul li {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	line-height: 30px;
	margin-bottom: 50px;
	font-size: 16px;
	position: relative;
}

.about-us--choose ul li:before {
	content: "";
	display: table;
}

.about-us--choose ul li:after {
	content: "";
	display: table;
	clear: both;
}

.about-us--choose ul li:nth-child(2n) {
	margin-right: 0%;
}

.about-us--choose ul li:nth-child(2n + 1) {
	clear: both;
}

@media (max-width: 825px) {
	.about-us--choose ul li {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 100%;
		margin-left: 0%;
		margin-right: 3%;
	}

	.about-us--choose ul li:before {
		content: "";
		display: table;
	}

	.about-us--choose ul li:after {
		content: "";
		display: table;
		clear: both;
	}

	.about-us--choose ul li:nth-child(2n) {
		margin-right: 3%;
		float: left;
	}

	.about-us--choose ul li:nth-child(2n + 1) {
		clear: none;
	}

	.about-us--choose ul li:nth-child(1n) {
		margin-right: 0%;
		float: right;
	}

	.about-us--choose ul li:nth-child(1n + 1) {
		clear: both;
	}
}

.about-us--choose ul li:nth-child(2n + 1) {
	padding-right: 20px;
	padding-left: 40px;
}

.about-us--choose ul li:nth-child(2n) {
	padding-left: 40px;
	float: right;
}

.about-us--choose ul li:before {
	content: "";
	background: url("../../public/images/frog-bullet.png");
	position: absolute;
	left: 0;
	width: 26px;
	height: 30px;
	display: block;
}

.about-us--choose_client {
	clear: both;
}

.about-us--choose_client_single {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 14.166666666666664%;
	margin-left: 0%;
	margin-right: 3%;
}

.about-us--choose_client_single:before {
	content: "";
	display: table;
}

.about-us--choose_client_single:after {
	content: "";
	display: table;
	clear: both;
}

.about-us--choose_client_single:nth-child(6n) {
	margin-right: 0%;
	float: right;
}

.about-us--choose_client_single:nth-child(6n + 1) {
	clear: both;
}

@media (max-width: 1060px) {
	.about-us--choose_client_single {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 22.75%;
		margin-left: 0%;
		margin-right: 3%;
	}

	.about-us--choose_client_single:before {
		content: "";
		display: table;
	}

	.about-us--choose_client_single:after {
		content: "";
		display: table;
		clear: both;
	}

	.about-us--choose_client_single:nth-child(6n) {
		margin-right: 3%;
		float: left;
	}

	.about-us--choose_client_single:nth-child(6n + 1) {
		clear: none;
	}

	.about-us--choose_client_single:nth-child(4n) {
		margin-right: 0%;
		float: right;
	}

	.about-us--choose_client_single:nth-child(4n + 1) {
		clear: both;
	}
}

@media (max-width: 660px) {
	.about-us--choose_client_single {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 31.33333333333333%;
		margin-left: 0%;
		margin-right: 3%;
	}

	.about-us--choose_client_single:before {
		content: "";
		display: table;
	}

	.about-us--choose_client_single:after {
		content: "";
		display: table;
		clear: both;
	}

	.about-us--choose_client_single:nth-child(4n) {
		margin-right: 3%;
		float: left;
	}

	.about-us--choose_client_single:nth-child(4n + 1) {
		clear: none;
	}

	.about-us--choose_client_single:nth-child(3n) {
		margin-right: 0%;
		float: right;
	}

	.about-us--choose_client_single:nth-child(3n + 1) {
		clear: both;
	}
}

@media (max-width: 450px) {
	.about-us--choose_client_single {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 48.5%;
		margin-left: 0%;
		margin-right: 3%;
	}

	.about-us--choose_client_single:before {
		content: "";
		display: table;
	}

	.about-us--choose_client_single:after {
		content: "";
		display: table;
		clear: both;
	}

	.about-us--choose_client_single:nth-child(3n) {
		margin-right: 3%;
		float: left;
	}

	.about-us--choose_client_single:nth-child(3n + 1) {
		clear: none;
	}

	.about-us--choose_client_single:nth-child(2n) {
		margin-right: 0%;
		float: right;
	}

	.about-us--choose_client_single:nth-child(2n + 1) {
		clear: both;
	}
}

.about-us--choose_client_single img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
}

@include respond-to-max("bs-md") {
	.about-us,
	.about-us .wrap {
		padding: 0;
	}

	.about-us--inner_left p:first-child {
		font-size: 26px;
		line-height: 36px;
	}
}
//</editor-fold>
