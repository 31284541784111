@import "../../../../config/config";

///*=====================================================================</editor-fold>=
// GUIDES
///=====================================================================<editor-fold>=*/

.guide {
	.main-blog {
		@include respond-to-max(bs-md) {
			padding-top: 0;
		}
		@include respond-min-to-max(bs-md, bs-lg) {
			padding-top: 20px;
		}

		&--posts_single-inner_faqs {
			padding-top: 35px;
			margin-bottom: 0;
		}
	}

	&-nav {
		margin: 0 !important;
		// overflow-y: auto;
		// max-height: 100vh;
		background-color: #fff;

		@include respond-to-max(bs-lg) {
			box-shadow: 0px 5px 5px 0px rgba(#000, 0.2);
			position: sticky;
			top: 0;
		}

		&__container {
			padding: $pad--min $pad--min + 20px;
		}

		&__mobile-control {
			@include product-nav-select-text;
			width: 100%;
			z-index: 1;

			cursor: pointer;
			position: sticky;
			top: 0;
			background-color: #fff;
			padding: $pad--min $pad--min + 20px;
			box-shadow: 0px 5px 5px 0px rgba(#000, 0.2);

			&:after {
				@include product-nav-dropdown-arrow($pad--min + 2px, $pad--min + 20px);
			}
		}

		&__panel {
			&-content {
				padding-left: 5px;
			}
		}

		&__title {
			color: $sf-font-grey;
			padding: 5px 0;

			&:hover {
				color: $sf-orange--light;
			}

			&--cat {
				font-size: 18px;
				cursor: pointer;
			}

			&--subcat {
				font-size: 15px;
				font-weight: $sf-weight--light;
				cursor: pointer;
			}
		}

		&__list {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 5px;
				bottom: 5px;
				left: 5px;
				width: 2px;
				background-color: $sf-orange--light;
			}
		}

		&__link {
			color: $screaming-green;
			display: inline-block;
			padding: 5px 5px 5px 20px;
			position: relative;

			&:hover {
				color: $sf-orange--light;
			}

			&--current {
				font-weight: $sf-weight--heavy;
				pointer-events: none;

				&:before {
					content: "";
					position: absolute;
					left: 5px;
					width: 10px;
					height: 2px;
					top: calc(50% - 1px);
					background-color: $sf-orange--light;
				}
			}
		}
	}

	&__adjacent-guides {
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 0 !important;
		padding-top: 25px;
		border-top: $sf-border;

		&-btn {
			white-space: nowrap;
			display: block;
			flex-grow: 1;
			margin-top: 10px;
			@include truncate();

			&:before,
			&:after {
				font-family: fontAwesome;
				display: inline-block;
				font-size: 10px;
				top: -1px;
				transition: all 0.3s;
				position: relative;
				left: 0;
			}

			&--prev {
				padding: 0 10px 0 5px;

				&:before {
					content: $fa-left-chevron;
				}

				&:hover {
					&:before {
						left: -3px;
					}
				}
			}

			&--next {
				padding: 0 5px 0 10px;
				text-align: right;

				&:after {
					content: $fa-right-chevron;
				}

				&:hover {
					&:after {
						left: 3px;
					}
				}
			}
		}

		&--no-next {
			&:after {
				content: "";
			}
		}

		&--no-prev {
			&:before {
				content: "";
			}
		}
	}

	@include respond-to-min(bs-md) {
		@include respond-to-min(bs-lg) {
			&-nav {
				// max-height: calc(100vh - 20px);

				&__mobile.accordion__panel--content {
					display: block;
				}

				&__mobile-control {
					display: none;
				}
			}
		}
	}
}

//</editor-fold>
