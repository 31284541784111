$side-bar-width-desktop: 330px;

.main-blog {
	.sticky-sidebar {
		display: flex;
		position: relative;

		@include respond-to-max(bs-lg) {
			flex-direction: column;
		}

		.main-blog--posts {
			@include respond-to-max(bs-lg) {
				order: 1;
			}

			@include respond-to-min(bs-lg) {
				float: none;
				margin: 0;
				width: calc(100% - #{$side-bar-width-desktop});
			}

			&_single {
				margin: 0;

				&--inside_author {
					margin: 50px 0;
					width: 100%;
				}
			}
		}
	}
}
