.sf-portfolio-content {
	&__grid {
		// border: 1px solid red;
		display: grid;
		grid-template-columns: 1fr;
		column-gap: $pad--small;
		row-gap: $pad--small;
		@include respond-to-min(sm) {
			row-gap: $pad--large;
			@include respond-to-min(md) {
				grid-template-columns: repeat(3, 1fr);
				@include respond-to-min(xl) {
					padding: $pad--small;
					column-gap: $pad--large;
				}
			}
		}

		&-item {
			aspect-ratio: 1 / 1;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			box-shadow: $sf-shadow;
			max-width: 400px;
			margin: 0 auto;
			justify-self: center;
			width: 100%;
			&[href] {
				transition: all 0.3s;
				transform: scale(1);
				&:hover {
					box-shadow: $sf-shadow--green;
					transform: scale(1.05);
				}
			}
		}
	}
}
