.content-width {
	max-width: $max-content-width;
	margin: 0 auto;
	width: 100%;
}

.hero {
	height: 285px;
	padding: calc($gutter / 2);
	position: relative;
	width: 100%;

	@media (min-width: $screaming-breakpoint) {
		padding: calc($gutter * 1.25) calc($gutter / 2) calc($gutter / 2) calc($gutter / 2);
	}

	&--training {
		background: url("../../public/images/training/hero-training.jpg") no-repeat;
		background-position: 30% 55%;
		background-size: cover;
		color: #fff;

		@media (min-width: $screaming-breakpoint) {
			background-position: 30% 35%;
		}

		h1 {
			font-size: 27px;
			margin: 0 0 calc($gutter / 2) 0;
			text-transform: capitalize;
			@media (min-width: 375px) {
				font-size: 30px;
			}
			@media (min-width: 768px) {
				font-size: 45px;
			}
			@media (min-width: $screaming-breakpoint) {
				font-size: 60px;
			}
		}

		blockquote {
			font-style: italic;
			font-size: 16px;

			@media (min-width: 767px) and (max-width: $screaming-breakpoint) {
				display: none;
			}

			@media (min-width: $screaming-breakpoint) {
				width: 50%;
				line-height: 1.8;
			}
		}
	}

	&__inner {
		@extend .content-width;
		padding: 0 ($gutter * 0.625);
	}
}
