//@import "../config/config";

///*=====================================================================</editor-fold>=
// LICENSE QUESTIONS
///=====================================================================<editor-fold>=*/

.licence-questions_left,
.licence-questions_right {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	max-width: 610px;
}

.licence-questions_left:before,
.licence-questions_right:before,
.licence-questions_left:after,
.licence-questions_right:after {
	content: "";
	display: table;
}

.licence-questions_left:after,
.licence-questions_right:after {
	clear: both;
}

.licence-questions_left:nth-child(2n),
.licence-questions_right:nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.licence-questions_left:nth-child(2n + 1),
.licence-questions_right:nth-child(2n + 1) {
	clear: both;
}

.licence-questions_left {
	padding: 50px;
	background: #fff;
}

.licence-questions_left h3 {
	font-size: 24px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 20px;
}

.licence-questions_left p {
	color: $sf-font-grey;
	line-height: 28px;
	font-weight: $sf-weight--light;
	margin-bottom: 25px;
}

.licence-questions_left a {
	color: #75b42c;
	text-decoration: underline;
	clear: both;
	float: left;
	margin-bottom: 7px;
}

.licence-questions_right_top {
	background: #fff;
	padding: 50px;
	margin-bottom: 70px;
}

.licence-questions_right_top h3 {
	font-size: 24px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 20px;
}

.licence-questions_right_top a {
	color: #75b42c;
	text-decoration: underline;
	clear: both;
	float: left;
	margin-bottom: 7px;
}

.licence-questions_right_bottom {
	padding: 50px;
	background: #75b42c;
	text-align: center;
}

.licence-questions_right_bottom p {
	color: #fff;
	font-size: 21px;
	line-height: 40px;
	font-weight: $sf-weight--light;
}

@media (max-width: 1000px) {
	.licence-questions_left,
	.licence-questions_right {
		max-width: none;
		width: 100%;
		float: none;
	}

	.licence-questions_right {
		margin-top: 70px;
	}
}

@include respond-to-max("bs-md") {
	.licence-questions_left p {
		padding: 0;
	}

	.licence-questions_right {
		margin-top: 40px;
	}

	.licence-questions_right_top {
		margin-bottom: 0;
	}

	.licence-questions_right_bottom p {
		padding: 0;
	}
}

@media (max-width: 500px) {
	.licence-questions_left,
	.licence-questions_right_top,
	.licence-questions_right_bottom {
		padding: 50px 20px;
	}
}
//</editor-fold>
