//override emailoctopus default (ineditable) styles
.inline-container {
	margin: 0 auto !important;
}
.emailoctopus-success-message {
	@include h4();
	font-weight: 900 !important;
}
.emailoctopus-form {
	.emailoctopus-form-row,
	.btn {
		margin-left: 0 !important;
		margin-right: 0 !important;
		&:hover {
			background-color: $sf-blue--dark;
		}
	}
	input {
		border-radius: 0 !important;
	}
}
