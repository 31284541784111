.sf-main-nav {
	// target all menu items:
	ul,
	li,
	a {
		display: block; // ensure block-level elements to apply padding etc.
		white-space: nowrap; // ensure no line breaks
	}

	display: flex; // aling items horizontally
	justify-content: center;
	justify-items: center; // center items horizontally

	// target top-level menu items:
	> li {
		padding: $pad--min 0; // apply padding to top/bottom to ensure hover state remains active whilst access sub-menu (but remain centered)
		> a {
			padding: $pad--min; // apply padding to all sides for consistent spacing
		}

		// target top-level menu items with children to add arrow icon as pseudo element:
		&.menu-item-has-children > a:after {
			margin-left: 5px; // add space between text and icon
			color: $sf-nav-grey !important; // ensure icon color is consistent
			@include remixicon("\ea4e"); // remixicon font icon
		}

		// target login button:
		&.menu-item-5003 {
			margin-left: $pad--min; // add space from other menu items

			> a {
				@include pill-btn--fill($sf-green); // set as a pill btn
			}
		}
	}

	// target all list-items in the menu and sub-menus:
	li {
		> a {
			// apply paragraph styles to all links for consistency:
			@include p($font-weight: $sf-weight--heavy, $color: $sf-nav-grey);
		}

		// exclude login button:
		&:not(.menu-item-5003) {
			// only highlight when hovering over the actual link
			> a:hover {
				color: $sf-green; // ensure consistent style for hover state
			}
			// exclude free download links

			// target current and ancestor items:
			&.current-page-ancestor,
			&.current_page_parent,
			&.current-menu-ancestor,
			&.current-menu-item {
				// target only the direct child link
				> a {
					color: $sf-green; // ensure consistent style for current and ancestor items (highlighted)
				}
			}
		}
	}

	// target all sub-menus:
	.sub-menu {
		display: none; // hide by default
		min-width: 200px; // set a minimum width to ensure content is not too squashed require multiple lines

		position: absolute;
		background-color: #fff;
		border-bottom: 5px solid $sf-orange--light;
		box-shadow: $sf-block-shadow--md;

		padding: $pad--min 0;
		li {
			padding: $pad--min $pad--small;
			@include h-respond-to-max(800px) {
				padding: calc($pad--min / 2) $pad--small; // reduce padding on screens with a smaller height to ensure it all fits above the fold
			}

			// target sub-menu items with children that are at the top or bottom of the list:
			&.menu-item-has-children {
				&:first-child,
				&:last-child {
					// add a pseudo element to the first and last items in the list to maintain hover state when hovering over the padding at the top/bottom of the parent sub-menu (i.e maintain accessing sub-sub-menu from these list items)
					&:before {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						height: $pad--min;
						background: transparent;
					}
				}
				&:first-child:before {
					bottom: 100%;
				}
				&:last-child:before {
					top: 100%;
				}
			}
		}
	}

	// target all items with children:
	.menu-item-has-children {
		position: relative; // required for the sub-menus & pseudo elements to be positioned correctly

		&:hover {
			// target the direct child sub-menu to show
			> .sub-menu {
				display: block; // show the sub-menu
				left: 50%; // position the sub-menu in the center of the parent item
				transform: translateX(-50%); // position the sub-menu in the center of the parent item
				margin-top: 10px; // add some space between the parent item and the sub-menu (less than the vertical padding on the parent item, to ensure hover state remains active whilst accessing sub-menu)

				// add a triangle to the top of sub-menu as a pseudo element:
				&:after {
					position: absolute;
					bottom: 100%;
					right: 50%;
					transform: translateX(50%);
					@include triangle(#fff, up, 10px);
				}

				// target nested sub-menu (2 levels deep):
				.sub-menu {
					left: 100%; // position the sub-menu to the right of the parent item
					top: $pad--min * -1; // position the sub-menu at the top of the parent item
					transform: none; // reset any previous transforms
					margin-top: 0; // reset any previous margin-top

					// add a triangle to the left of sub-menu as a pseudo element:
					&:after {
						position: absolute;
						bottom: calc(100% - #{$pad--small});
						right: 100%;
						transform: translateY(100%);
						@include triangle(#fff, left, 10px);
					}
				}
			}
		}
	}
}
