///*=====================================================================</editor-fold>=
// DISABLED INPUTS
///=====================================================================<editor-fold>=*/

.disabled-input-container {
	opacity: 0.3;
	pointer-events: none;
	input {
		cursor: not-allowed;
	}
}
//</editor-fold>
