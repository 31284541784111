.sf-block-banner {
	// &--frog {
	// 	@include frog-wallpaper();
	// }
	// &--green {
	// 	background-color: $sf-green;
	// }
	// &--orange {
	// 	background-color: $sf-orange--light;
	// }
	// &--blue {
	// 	background-color: $sf-blue--light;
	// }

	@extend %sf-block-shadow;
	padding: $pad--large $pad--small;
	text-align: center;

	h2 {
		@include h2(#fff);
	}
	a {
		@include link(#fff, $sf-orange--light, true);
	}
}
