[data-lightbox-content] img[src],
[data-lightbox] {
	cursor: pointer; // Indicate that lightbox images are clickable
}

.sf-lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw; // fill the viewport
	height: 100vh; // fill the viewport
	background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black background
	z-index: 1000; // Ensure it appears above other content

	// allow container (used for animating in the image) to fill the space:
	&__container {
		width: 100%;
		height: 100%;
	}

	&__img {
		max-width: calc(100vw - #{$pad--min});
		max-height: calc(100vh - #{$pad--min});
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); // Optional: add a shadow for effect
		background-color: #fff;

		// enlarge scalable SVGs:
		&[src$=".svg"] {
			@include respond-to-orientation("landscape") {
				min-height: calc(100vh - #{$pad--min});
			}
			@include respond-to-orientation("portrait") {
				min-width: calc(100vw - #{$pad--min});
			}
		}
	}
}
