//SF TODO: remove once new guides live as redundant? check for usage?
.table-of-contents {
	display: none;

	@include respond-to-min(bs-lg) {
		display: block;
		color: $color-accent3;
		background: $white;
		max-height: calc(100vh - calc($pad--min * 2));
		overflow: auto;
	}

	&-title {
		padding: 20px 10px 20px 10px;
		text-align: center;
		position: sticky;
		top: 0;
		background: $white;
		box-shadow: 0px 3px 10px -10px rgba(0, 0, 0, 0.75);
		h4 {
			font-size: 22px;
			margin: 0 0 10px;
			font-weight: $sf-weight--med;
		}
	}

	&-link {
		transition: background 0.1s ease;
		border-bottom: 1px solid rgba($color-accent3, 0.2);

		&:last-of-type {
			border: none;
		}

		a {
			color: $color-accent3;
			height: 100%;
			width: 100%;
			display: block;
			padding: 12.5px;
			transition: color 0.1s ease;
			&:hover {
				color: $white;
			}

			&.active {
				background: $screaming-green;
				color: $white;
			}
		}

		&:hover {
			background: $screaming-green;
		}
	}
}

.main-blog--posts_single.single-post {
	.table-of-contents {
		width: 100%;
		padding: 15px 15px 0 15px;
		border: none;
		margin: 50px 0;
		height: auto !important;

		h2 {
			margin-bottom: 0;
		}

		ul {
			margin-top: 10px;
			margin-bottom: 0;
			padding: 10px;

			b {
				font-weight: $sf-weight--med;
			}
		}

		a {
			cursor: pointer;
			border-bottom: none !important;
			font-weight: $sf-weight--light;

			&:hover {
				border-bottom: 1px solid $sf-green !important;
			}
		}

		p {
			margin-bottom: 20px;
		}
	}
}
