//@import "../config/config";

///*=====================================================================</editor-fold>=
// ORDERS AND INVOICES
///=====================================================================<editor-fold>=*/

.licence-orders {
	padding: 50px;
}

p .savepdfinvoicelink {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 200px;
	padding: 14px 0;
	display: block;
	margin: 0 auto;
	background: #ffbc2b;
	float: right;
}

p .savepdfinvoicelink:hover {
	background: #f2a600;
}

form#your-profile input[type="submit"] {
	margin: 30px auto;
}

.login.profile p.message {
	position: relative;
	margin: -80px auto 150px;
	max-width: 670px;
	text-align: center;
	font-size: 24px;
	font-weight: $sf-weight--light;
	background: #75b42c;
	padding: 30px 0;
	color: #fff;
}

table a {
	color: #75b42c;
	text-decoration: underline;
}
//</editor-fold>
