$sf-header--h--lg: 130px;
$sf-header--h: 75px;

.sf-header {
	background-color: #fff;
	z-index: 99;
	position: relative;

	&__content {
		display: flex;
		height: $sf-header--h;
		align-content: center;
		align-items: center;
		justify-items: space-between;
		justify-content: space-between;
		z-index: 99;
		position: relative;
	}

	&__logo {
		width: 200px;
		margin-right: $pad--min;
		img {
			width: 100%;
		}
	}

	&__main-nav {
		display: none;
	}

	&__mobile-nav {
		height: 0;
		transition: height 500ms;
		overflow: auto;

		&-control {
			display: block;
			cursor: pointer;
			border: none;
			background-color: transparent;
			font-size: 30px;
			color: $sf-nav-grey;
			transition: all 0.3s;
			&:hover {
				color: $sf-green;
			}

			&-item {
				&--hambuger {
					display: inline-block;
				}
				&--close {
					display: none;
				}
			}

			// .ri-close-line {
			// 	display: none !important;
			// }
		}
	}

	@include respond-to-min(lg) {
		&__content {
			height: $sf-header--h--lg;
		}
		&__logo {
			width: unset;
			max-width: 250px;
		}
		&__main-nav {
			display: block;
		}
		&__mobile-nav-control {
			display: none;
		}
	}
}

@include respond-to-max(lg) {
	[data-mobilemenu="true"] {
		main.page-content,
		footer.footer {
			display: none;
		}
		.sf-header {
			&__content {
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
			}
			&__mobile-nav {
				height: calc(100vh - #{$sf-header--h});

				li.menu-item-5003 {
					display: block;
				}

				&-control {
					&-item {
						&--close {
							display: inline-block;
						}
						&--hamburger {
							display: none;
						}
					}
				}
			}
		}
	}
}
