.sf-contact {
	padding: $pad--min;
	&__content {
		padding: $pad--min;
		> p {
			@include p();
			&:not(:last-child) {
				margin-bottom: $pad--min;
			}
			a {
				@include link();
			}
		}
		h2 {
			@include h2();
			margin-bottom: $pad--min;
		}
	}

	@include respond-to-min(lg) {
		padding: $pad--small 0;
		&__content {
			padding: 0 $pad--small;
		}
		@include respond-to-min(xl) {
			padding: $pad--large $pad--small;
		}
	}
}
