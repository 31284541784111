$sf-adwords-history__sidebar-btn--marg: calc($pad--min / 2);

.sf-adwords-history {
	&__sidebar {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		//to show the last buttons border-bottom
		padding-bottom: 1px;

		@include respond-to-min(lg) {
			margin: 0 calc($sf-adwords-history__sidebar-btn--marg * -1);
		}
		@include respond-to-max(lg) {
			padding: 0 $pad--min;
			&:before {
				content: "Filter Updates:";
				@include h3() {
					margin-bottom: $pad--min;
					padding: 0 $sf-adwords-history__sidebar-btn--marg;
					width: 100%;
				}
			}
		}

		&-btn {
			@include p($font-size: $sf-font-size--standard);
			display: block;
			box-sizing: border-box;
			text-align: center;
			background-color: #fff;
			cursor: pointer;
			padding: $pad--min;
			flex-grow: 1;
			&:hover,
			&--active {
				border-color: $sf-green !important;
				background-color: $sf-green;
				color: #fff;
			}
			&--filter {
				border: 1px solid $sf-border--grey;
				margin: 0 $sf-adwords-history__sidebar-btn--marg $pad--min;
				&:not(.sf-adwords-history__sidebar-btn--main-reset) {
					max-width: 50%;
				}
			}
			&--year {
				width: 100%;
				margin: 0 $sf-adwords-history__sidebar-btn--marg;
				@include border(1px solid $sf-border--grey, top, left, right);
				&:last-child {
					border-bottom: 1px solid $sf-border--grey;
				}
				@include respond-to-max(lg) {
					display: none;
				}
			}
		}
	}
	&__year-title {
		// @include h2($margin-bottom: 0);
		@include h2();
		padding: $pad--small 0;
		border-bottom: 1px solid $sf-border--grey;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 1;
	}
	&__content {
		border-top: 1px solid $sf-border--grey;
		padding: $pad--small 0;
		position: relative;
		top: -1px;
		h3 {
			@include p($font-weight: $sf-weight--heavy);
			margin-bottom: $pad--min;
		}
		p {
			@include p();
		}
		a {
			@include link($underline: "on-hover") {
				font-weight: 400;
			}
		}
	}
}
