/**
Shorthand classes for users to add to content via wordpress
*/

.tldr {
	padding: 10px;
	background: $color-accent4;
	color: $sf-font-grey;
}

.hidden {
	display: none;
}

.no-shadow {
	box-shadow: none !important;
}
