.sf-screaming-frog-alternatives {
	.sf-screaming-frog-alternatives-intro {
		padding: $pad--small;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: $pad--min;

		@extend %sf-standard-typography;
		@extend %sf-standard-typography--p-spacing;
		@extend %sf-standard-typography--no-box-shadow;

		&__right {
			padding-top: $pad--small;
			img {
				width: 100%;
			}
		}

		@include respond-to-min(md) {
			&__right {
				display: flex;
				align-items: flex-end;
				align-content: flex-end;
				justify-content: flex-end;
				justify-items: flex-end;
				padding-top: 0;
			}

			@include respond-to-min(800px) {
				grid-template-columns: 1.5fr 1fr;
				column-gap: $pad--min;
				@include respond-to-min(lg) {
					column-gap: $pad--small;
					grid-template-columns: 1fr 400px;
					p {
						font-size: $sf-font-size--large;
					}
					@include respond-to-min(xl) {
						column-gap: $pad--large;
						grid-template-columns: 1fr 500px;
					}
				}
			}
		}
	}

	.sf-animal-intro {
		&__left {
			width: 200px;
		}

		&__bottom {
			padding-top: $pad--small;
			width: 100%;
			@include stretch-across-grid(); // span the full width of the grid
		}

		@include respond-to-min(md) {
			display: grid;
			grid-template-columns: 200px 1fr;
			grid-gap: $pad--small;
			&__left {
				display: flex;
				align-content: center;
				align-items: center;
				flex-wrap: wrap;
			}
		}
	}

	.sf-animal-comparison-table {
		margin: $pad--large 0;

		table,
		thead,
		tbody {
			display: block;
			width: 100%; /* Ensures the table takes the full width as block elements normally do */
		}
		tr {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			border-bottom: 1px solid $sf-border--grey;
		}

		th,
		td {
			padding: $pad--min;

			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			align-content: center;

			@include p($font-size: $sf-font-size--small);
			@include respond-to-min(sm) {
				font-size: $sf-font-size--standard;
				@include respond-to-min(md) {
					font-size: $sf-font-size--x-large;
				}
			}

			&:first-child {
				min-width: 150px; // set the first column to be a fixed width to prevent collapse on small screens
				justify-content: flex-start; // let the first column be left aligned
				justify-items: flex-start;
			}

			&:nth-child(even) {
				background-color: $sf-bg--grey; // alternate col background colour white/grey
			}

			i {
				font-size: 30px; // icon size
				&.ri-close-circle-fill {
					color: $sf-color--error; // red cross
				}
				&.ri-checkbox-circle-fill {
					color: $sf-green; // green tick
				}
			}

			// header image size:
			img {
				width: 100%;
				@include respond-to-min(sm) {
					width: 50px;
				}
			}
		}

		@include respond-to-max(sm) {
			table {
				overflow-x: auto; /* Adds horizontal scrolling if the table overflows the viewport */
			}
			thead,
			tbody {
				min-width: 280px;
			}
		}
		@include respond-to-min(md) {
			padding: 0 $pad--small;
		}
	}
}
