$cta-offset--sm: 117px;

.sf-training {
	&-hero {
		&__top {
			background-image: backgroundImageURL("training/hero.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 20%;
			@include respond-to-min(sm) {
				background-position: 30%;
				@include respond-to-min(md) {
					background-position: 20% bottom;
				}
			}

			padding: $pad--small 0;
			@include respond-to-min(lg) {
				padding: $pad--large 0 $pad--small;
			}

			@include respond-to-max(md) {
				margin-bottom: $cta-offset--sm * -1;
			}
		} // end .sf-training-hero__top

		&__bottom {
			@include frog-wallpaper(true);
			padding: $cta-offset--sm 0 $pad--min;
			@include respond-to-min(md) {
				padding: 0 0 $pad--min;
				@include respond-to-min(lg) {
					padding: $pad--min 0 $pad--small;
				} // end respond-to-min(lg)
			} // end respond-to-min(md)
		} // end .sf-training-hero__bottom

		&__grid {
			&-item {
				position: relative;
				&--titles {
					h1 {
						@include h1();
						margin-bottom: $pad--small;
						@include respond-to-min(md) {
							white-space: nowrap;
						}
					}
					p {
						@include p(
							$color: #fff,
							$font-weight: $sf-weight--med,
							$font-size: $sf-font-size--large
						);
						max-width: 600px;
						margin-bottom: $pad--small;
					}
				} // end .sf-training-hero__grid-item--titles

				&--details {
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					align-items: center;
					justify-content: center;
					justify-items: center;
					@include respond-to-min(md) {
						justify-content: flex-start;
						justify-items: flex-start;
					}

					p {
						display: flex;
						justify-content: center;
						justify-items: center;
						align-content: center;
						align-items: center;

						margin-top: $pad--min;
						margin-right: $pad--min;
						@include respond-to-min(lg) {
							margin-right: $pad--small;
						}

						@include p(
							$color: #fff,
							$font-weight: $sf-weight--med,
							$font-size: $sf-font-size--large
						);
					}
					i {
						margin-right: 10px;
						font-size: 1.5em;
					}
				} // end .sf-training-hero__grid-item--details
			} // end .sf-training-hero__grid-item

			@include respond-to-min(md) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: $pad--small;
				@include respond-to-min(lg) {
					grid-template-columns: 1fr 400px;
					grid-gap: $pad--large;
				}
			} // end respond-to-min(md)
		} // end .sf-training-hero__grid
	} // end .sf-training-hero

	&-cta {
		position: relative;
		@include frog-wallpaper(true);
		border-radius: $pad--min;
		box-shadow: $sf-block-shadow;
		&__top {
			background-image: backgroundImageURL("training/wavemask.svg");
			background-position: right bottom;
			background-repeat: no-repeat;
			background-size: cover;

			padding: $pad--min;

			h2 {
				margin: $pad--min 0 $pad--small;
				color: #fff;
				font-size: 40px;
				line-height: 40px;
				font-weight: $sf-weight--x-heavy;
				font-family: $sf-font-family;
			}
			span {
				display: inline-block;
				position: relative;
				&:after {
					font-size: 15px;
					line-height: 15px;
					content: " + VAT";
					position: absolute;
					left: 100%;
					top: 0;
					white-space: nowrap;
				}
			}
		} // end .sf-training-cta__top

		&__social {
			display: flex;
			justify-content: flex-end;
			align-content: center;
			a {
				display: flex;
				justify-content: center;
				justify-items: center;
				align-items: center;
				align-content: center;

				width: 25px;

				padding: 5px;
				border-radius: 100%;

				color: #fff;
				font-size: 15px;

				margin-left: 5px;
				&:first-child {
					margin-left: 0;
				}

				transition: background-color 0.3s;
				background-color: $sf-blue--light;
				&:hover {
					background-color: $sf-blue--dark;
				}
			}
		} // end .sf-training-cta__social

		&__bottom {
			padding: $pad--min;
			a {
				@include p($color: #fff, $font-weight: $sf-weight--med, $font-size: $sf-font-size--large);
				text-align: center;

				display: block;
				width: 100%;
				padding: $pad--min;

				transition: background-color 0.3s;
				background-color: $sf-orange--light;
				&:hover {
					background-color: $sf-orange--dark;
				}
			}
		} // end .sf-training-cta__bottom

		@include respond-to-min(md) {
			position: absolute;
			width: 100%;
			bottom: $cta-offset--sm * -1;
			@include respond-to-min(lg) {
				bottom: $cta-offset--sm * -0.75;
			}
		} // end respond-to-min(md)
	} // end .sf-training-cta

	&-host {
		display: grid;
		grid-template-columns: 100px 1fr;
		row-gap: $pad--min;
		column-gap: $pad--min;
		&__avatar {
			img {
				width: 100%;
				border-radius: 100%;
			}
		} // end .sf-training-host__avatar

		&__titles {
			display: flex;
			flex-direction: column;
			justify-content: center;
			justify-items: center;
			h2 {
				@include h3($sf-green);
			}
			h3 {
				@include h3();
			}
		} // end .sf-training-host__titles

		&__bio {
			@include respond-to-max(md) {
				@include stretch-across-grid();
			}

			p {
				@include p();
				margin-bottom: $pad--min;
			}
		} // end .sf-training-host__bio

		@include respond-to-min(sm) {
			grid-template-columns: 150px 1fr;
			column-gap: $pad--small;
			row-gap: $pad--small;
			&__titles {
				h3 {
					@include h2();
				}
			} // end .sf-training-host__titles

			@include respond-to-min(md) {
				grid-template-columns: 250px 1fr;
				column-gap: $pad--small;
				row-gap: $pad--min;
				grid-template-areas:
					"left right-top"
					"left right-bottom";

				&__titles {
					grid-area: right-top;
				} // end .sf-training-host__titles

				&__bio {
					grid-area: right-bottom;
				} // end .sf-training-host__bio

				&__avatar {
					grid-area: left;
					img {
						border-radius: 20px;
						box-shadow: $sf-shadow;
					}
				} // end .sf-training-host__avatar

				@include respond-to-min(lg) {
					grid-template-columns: 300px 1fr;
					column-gap: $pad--large;
					row-gap: $pad--small;
				} // end respond-to-min(xl)
			} // end respond-to-min(md)
		} // end respond-to-min(sm)
	} // end .sf-training-host

	&-details {
		z-index: 1;
		position: relative;
		&:before {
			content: "";
			display: block;

			background-image: backgroundImageURL("training/footer.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top right;
			@include respond-min-to-max(md, lg) {
				background-position: top center;
			}

			min-height: 150px;
			@include respond-to-min(sm) {
				min-height: 250px;
				@include respond-to-min(md) {
					min-height: unset;
					position: absolute;
					top: 0;
					left: 50%;
					bottom: 0;
					right: 0;
				}
			}
			// z-index: -1;
		} // end &:before

		&__grid {
			@include respond-to-min(md) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: $pad--large;
			}
		} // end .sf-training-details__grid

		&__list {
			h2 {
				@include h2();
				margin-bottom: $pad--small;
			}
			&-item {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-template-areas:
					"left top-right"
					"left bottom-right";
				column-gap: $pad--min;
				row-gap: 5px;
				&:not(:last-child) {
					margin-bottom: $pad--small;
				}
				i,
				h3 {
					@include h4();
					line-height: $sf-font-size--h4 + 10px;
				}
				i {
					color: $sf-green;
					font-size: $sf-font-size--h4 + 10px;
				}
				p {
					@include p();
					grid-area: bottom-right;
				}
			}
		} // end .sf-training-details__list

		&__cta {
			a {
				display: inline-block;
				@include pill-btn--fill($sf-orange--light, #fff, $sf-orange--dark, $pad--min, 4);
			}

			@include respond-to-min(md) {
				padding: $pad--small 0 0;
			}
		} // end .sf-training-details__cta
	} // end .sf-training-details
} // end .sf-training
