.sf-contact-dialog {
	position: relative;
	width: 100%;
	max-width: 600px;
	@include respond-to-min(600px) {
		min-width: 600px;
	}
	margin-left: auto;
	margin-right: auto;
	padding: $pad--small;
	text-align: center;
	h2 {
		@include h2();
		margin-bottom: $pad--min;
	}
	p {
		@include p($font-size: $sf-font-size--large);
		margin-bottom: $pad--min;
		a {
			@include link();
		}
	}
	&__form {
		// margin: $pad--small 0 0;
		input[type="text"],
		input[type="email"],
		input[type="number"],
		textarea {
			margin-bottom: $pad--min;
			@include text-input();
			width: 100%;
			padding: $pad--min;
		}
		input[type="submit"] {
			@include pill-btn--fill($bg: $sf-green, $bg-hover: $sf-green--dark);
			display: inline-block;
			font-size: $sf-font-size--large;
			// margin: $pad--min auto 0;
			width: 100%;
			max-width: 300px;
		}
	}

	@include respond-to-min(xl) {
		padding: $pad--small $pad--large;
	}
}
