.sf-accreditations {
	padding: $pad--small $pad--small $pad--min;
	&__header {
		text-align: center;
		max-width: $sf-max-p-width;
		margin: 0 auto;
		h2 {
			@include h2();
		}
		p {
			@include p();
			margin: $pad--min;
		}
		a {
			@include link();
		}
	}
	&__grid {
		// display: inline-grid;
		// margin: 0 auto;
		// grid-template-columns: repeat(3, 140px);
		// column-gap: $pad--small;
		// row-gap: $pad--small;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;

		&-item {
			// width: 140px;
			margin: $pad--min;
		}
	}
	@include respond-to-min(xl) {
		padding: $pad--large $pad--large $pad--small;
		&__grid-item {
			margin: $pad--small;
		}
	}
}
