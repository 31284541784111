.sf-team {
	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: $pad--small;
		row-gap: $pad--small;
		padding: $pad--small;
		@include respond-to-min(500px) {
			grid-template-columns: repeat(2, 1fr);
			@include respond-to-min(900px) {
				grid-template-columns: repeat(3, 1fr);
				@include respond-to-min(lg) {
					row-gap: $pad--large;
					padding: $pad--large;
					@include respond-to-min(1200px) {
						column-gap: $pad--large;
						grid-template-columns: repeat(4, 1fr);
					}
				}
			}
		}
	}
	&__member {
		text-align: center;
		&-image {
			height: 150px;
			width: 150px;
			border-radius: 1000px;
			background-image: url("../images/avatars/default-avatar.jpg");
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			display: flex;
			justify-content: center;
			justify-items: center;
			align-content: center;
			align-items: center;
			position: relative;
			margin: 0 auto $pad--min;
			overflow: hidden;
			cursor: pointer;

			i {
				font-size: 40px;
				position: relative;
				z-index: 1;
				color: #fff;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba($sf-green, 0.5);
				opacity: 0;
				transition: opacity 0.5s ease;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
			}
		}
		h2 {
			@include h4();
		}
		h3 {
			@include h5($font-weight: $sf-weight--light);
		}
		p {
			display: none;
			@include p();
			&:not(:last-child) {
				margin-bottom: $pad--min;
			}
			a {
				@include link();
			}
		}
	}
}
