$sf-author-info-avatar--w: 200px;
.sf-author-info {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: $pad--min;
	&__avatar {
		padding: $pad--min;
		width: $sf-author-info-avatar--w;
	}
	&__details {
		padding: $pad--min;

		flex-grow: 1;
		width: calc(100% - $sf-author-info-avatar--w);
		min-width: 400px; // ensure container wraps on smaller screens
		@include respond-to-max(500px) {
			min-width: 100%; // set to full width on mobile and prevent overflow
		}

		h2 {
			@include h2();
			margin-bottom: $pad--min;
			a {
				@include link();
			}
		}
		p {
			@include p();
		}
	}
}
