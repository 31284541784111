.sf-contact {
	.wpcf7-form {
		.wpcf7-form-control-wrap {
			input,
			select,
			textarea {
				@include text-input();
				padding: $pad--min;
				width: 100%;
				margin-bottom: $pad--min;
			}
		}
		.wpcf7-submit {
			@include pill-btn--fill($sf-green);
			font-size: $sf-font-size--standard !important;
		}
	}
}
// .sf-modal {
.wpcf7-form {
	p {
		position: relative;
		.wpcf7-spinner {
			position: absolute !important; // ensure position is absolute (not relative as set in loadingDots mixin)
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			background-color: transparent;
			&:before {
				display: none; // hide default spinner
			}
			@include loadingDots($sf-green); // add our own spinner
		}
	}
	&.submitting {
		input[type="submit"] {
			opacity: 0.1;
		}
	}
	input[type="submit"] {
		width: 100%;
	}
}
// }
