.sf-related-issues {
	display: flex;
	flex-direction: column;
	.sf-related-posts-nav {
		order: 2;
		&.sf-related-posts-nav--current {
			order: 1; // bring the current nav list to the top
		}
	}
}
