//.sf-upload-input class added via DB using contact-form-7 plugin (support, lfa-support and vacancies forms)
.sf-upload-input {
	margin: 0 0 $pad--min;
	@include text-input();
	display: flex;
	cursor: pointer;

	&:before {
		@include truncate();
		content: attr(data-upload);
		flex-grow: 1;
		text-align: left;
		padding: $pad--min;
		color: $sf-grey--light;
	}
	&--uploaded {
		&:before {
			color: $sf-grey;
		}
	}

	&:after {
		color: #fff;
		font-size: $sf-font-size--large;
		content: "Browse";
		text-align: center;
		background-color: $sf-green;
		white-space: nowrap;
		padding: $pad--min;
		@include respond-to-min(sm) {
			padding: $pad--min $pad--small;
		}
	}
	&:hover {
		&:after {
			background-color: $sf-green--dark;
		}
	}

	input[type="file"] {
		display: none;
	}
}
