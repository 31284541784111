///*=====================================================================</editor-fold>=
// TESTIMONIAL BLOCK
///=====================================================================<editor-fold>=*/
.sf-testimonial {
	display: block;
	&__item {
		background-color: #fff;
		padding: $pad--small;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include respond-to-max(lg) {
			&:not(:last-child) {
				margin-bottom: $block-spacing-v--sm;
				// SF TODO: change 'bs-md' to 'md' when all other elements are made into components:
				@include respond-to-min(md) {
					margin-bottom: $block-spacing-v--md;
				}
			}
		}
	}

	@include respond-to-min(lg) {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		align-content: stretch;
		&__item {
			width: calc(50% - calc(#{$block-spacing-v--md} / 2));
			.sf-testimonial--1-item & {
				width: 100%;
			}
		}
	}
}
