.sf-coverage {
	$stats--max-w: 300px;
	padding: $pad--small;

	&__intro {
		text-align: center;
		width: 100%;
		margin: 0 auto $pad--small;
		max-width: 700px;
		h2 {
			@include h2();
		}
		p {
			@include p();
		}
		p,
		h2 {
			margin: 0 0 $pad--min;
		}
	}
	&__outlets {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: $pad--min * -1;
		img {
			box-shadow: none;
			width: 20%;
			min-width: 200px;
			padding: $pad--min;
		}
	}
	&__stats {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: $pad--small;
		row-gap: $pad--small;
		margin: $pad--small auto 0;
		width: 100%;
		&--no-margin-top {
			margin-top: 0 !important;
		}
		&-item {
			border: 1px solid $sf-border--grey;
			max-width: $stats--max-w;
			justify-self: center;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			justify-items: center;
			align-items: center;
			align-content: center;
			padding: $pad--small;

			h3 {
				@include h2($sf-green);
				margin: 0;
			}
			p {
				@include p();
				text-align: center;
				margin: 0 0 $pad--min;
			}
		}
	}
	@include respond-to-min(md) {
		&__stats {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond-to-min(xl) {
			padding: $pad--large;
			&__stats {
				column-gap: $pad--large;
				row-gap: $pad--large;
				max-width: $stats--max-w * 3 + $pad--large * 2;
				margin-top: $pad--large;
			}
			&__intro {
				margin-bottom: $pad--large;
			}
		}
	}
}
