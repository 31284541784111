.sf-purchase-download {
	&__content {
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
	}

	&--banner {
		.sf-purchase-download__btn {
			@include pill-btn--outline(#fff, $sf-green);
			font-size: $sf-font-size--large;
			margin: calc($pad--small / 2);
			width: 350px;
		}
	}

	&--block {
		.sf-purchase-download__btn {
			&--download {
				@include pill-btn--fill($sf-blue--light, #fff, $sf-blue--dark);
			}
			&--purchase {
				@include pill-btn--fill($sf-orange--light, #fff, $sf-orange--dark);
			}
			font-size: $sf-font-size--large;
			margin: calc($pad--min / 2);
			width: 300px;
		}
	}
}
