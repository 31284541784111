//@import "../config/config";

///*=====================================================================</editor-fold>=
// MAP
///=====================================================================<editor-fold>=*/

.map-tooltip {
	clear: both;
	width: 280px;
	color: #fff;
	padding: 20px;
}

.map-tooltip h2 {
	font-size: 20px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 20px;
}

.map-tooltip ul li {
	font-size: 15px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 10px;
}

.map-tooltip ul li:last-child {
	margin-bottom: 0;
}

span.wpcf7-not-valid-tip {
	display: none !important;
}
//</editor-fold>
