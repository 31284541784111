//markup added via contact-form-7 plugin : "Contact Page" form
.sf-service-required {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: $sf-shadow;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #fff;
	z-index: 1;
	padding: $pad--small;
	h2 {
		@include h2();
		margin-bottom: $pad--small;
	}
	h3 {
		@include h4();
		margin: $pad--min 0;
	}
	&__grid {
		display: grid;
		column-gap: $pad--min;
		row-gap: $pad--min;
		grid-template-columns: 1fr;
		margin-bottom: $pad--small;
		@include respond-to-min(sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		br {
			display: none !important; //remove line breaks which are added by WordPress and disrupt the grid layout
		}

		label {
			display: flex;
			width: 100%;
			height: 100%;
		}
		span {
			margin: 0 !important;
			display: block;
			height: 100%;
		}
		input[type="checkbox"] {
			display: none;
			+ span {
				@include text-input();
				padding: $pad--min;
				display: flex;
				justify-content: center;
				justify-items: center;
				align-content: center;
				align-items: center;
				cursor: pointer;
				width: 100%;
				text-align: center;
			}
			&:hover + span {
				border-color: $sf-green;
				color: $sf-green;
			}
			&:checked + span {
				background-color: $sf-green;
				color: #fff;
				border: 1px solid $sf-green;
			}
		}
	}
	&__submit {
		@include pill-btn--fill($sf-green, #fff);
		display: block;
		margin: 0 auto;
	}
}
