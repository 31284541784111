// .homepage-testimonial {
// 	background: #f5f5f5;
// 	padding: 120px 0;
// }

// SF TODO: remove all of this file when .homepage-testimonial--inner_testimonial-client removed from single-vacancies.php (last location of this class)
.homepage-testimonial--inner_clients {
	padding-right: 60px;
}

.homepage-testimonial--inner_clients h2 {
	font-size: 32px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 40px;
}

.homepage-testimonial--inner_clients p {
	font-size: 16px;
	line-height: 32px;
	color: $sf-font-grey;
	margin-bottom: 35px;
	font-weight: $sf-weight--light;
}

.homepage-testimonial--inner_clients p a {
	border-bottom: 2px solid #75b42c;
	color: $sf-font-grey;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.homepage-testimonial--inner_clients p a:hover {
	border-bottom: 2px solid #599f08;
	color: #599f08;
}

.homepage-testimonial--inner_clients-logos img {
	width: 100%;
	max-width: 557px;
}

@media (max-width: 940px) {
	.homepage-testimonial--inner_clients {
		display: block;
		clear: both;
		float: none;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-left: center;
		max-width: 750px;
		text-align: center;
		margin-bottom: 70px;
		padding-right: 0;
	}

	.homepage-testimonial--inner_clients:first-child {
		margin-left: auto;
	}

	.homepage-testimonial--inner_clients:last-child {
		margin-right: auto;
	}

	.homepage-testimonial--inner_clients-logos {
		display: block;
		margin: 0 auto;
	}
}

@media (max-width: 500px) {
	.homepage-testimonial--inner_clients h2 {
		margin-bottom: 25px;
	}
}

.homepage-testimonial--inner_testimonial {
	position: relative;
}

.homepage-testimonial--inner_testimonial:after {
	content: "";
	background: url("../../public/images/quotation-mark-close.png");
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 107px;
	height: 79px;
	background-size: 100%;
}

@media (max-width: 600px) {
	.homepage-testimonial--inner_testimonial:after {
		background: 0 0;
		width: 0;
		height: 0;
	}
}

.homepage-testimonial--inner_testimonial > p {
	font-size: 31px;
	color: #6d6d6d;
	font-weight: $sf-weight--light;
	margin-bottom: 40px;
	line-height: 42px;
}

.homepage-testimonial--inner_testimonial-client {
	position: relative;
}

.homepage-testimonial--inner_testimonial-client > img {
	display: inline-block;
	margin-right: 45px;
	max-width: 110px;
	vertical-align: middle;
}

.homepage-testimonial--inner_testimonial-client-name {
	display: inline-block;
	vertical-align: middle;
}

.homepage-testimonial--inner_testimonial-client-name p {
	font-size: 22px;
	color: #75b42c;
	line-height: 28px;
	margin-bottom: 8px;
	margin-top: 15px;
}

.homepage-testimonial--inner_testimonial-client-name span {
	font-size: 14px;
	color: #a8a8a8;
}

@media (max-width: 500px) {
	.homepage-testimonial--inner_testimonial-client > img {
		display: block;
		margin: 0 auto;
	}

	.homepage-testimonial--inner_testimonial-client-name {
		display: block;
		text-align: center;
	}
}

@media (max-width: 940px) {
	.homepage-testimonial--inner_testimonial {
		display: block;
		clear: both;
		float: none;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-left: center;
		padding-right: center;
		max-width: 750px;
	}

	.homepage-testimonial--inner_testimonial:first-child {
		margin-left: auto;
	}

	.homepage-testimonial--inner_testimonial:last-child {
		margin-right: auto;
	}
}

@media (max-width: 700px) {
	.homepage-testimonial--inner_testimonial > p {
		font-size: 21px;
		line-height: 32px;
	}
}

@media (max-width: 940px) {
	.homepage-testimonial {
		padding: 50px 0;
	}
}
