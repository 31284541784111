$tutorials-refine-bar--input-h: 59px;
$tutorials-refine-bar--input-border-color: $sf-grey--light;
$tutorials-refine-bar--input-bg: #fff;
.sf-tutorials {
	&__refine-bar {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		* {
			font-size: 18px;
			color: $sf-font-grey;
		}

		> * {
			margin: 5px;
		}
		@include respond-to-max(md) {
			// margin: -5px;
			padding: 0 $pad--min;
		}
	}

	&__dropdown {
		height: $tutorials-refine-bar--input-h;
		flex-grow: 1;
		position: relative;
		z-index: 99;

		&:hover {
			.sf-tutorials__dropdown-list {
				max-height: 100vh;
				opacity: 1;
			}
		}

		&-btn {
			width: 100%;
			padding: 10px 30px 10px 20px;
			min-width: 200px;
			text-align: left;
			height: $tutorials-refine-bar--input-h;
			border: 1px solid $tutorials-refine-bar--input-border-color;
			position: relative;
			background-color: $tutorials-refine-bar--input-bg;
			z-index: 1;
			cursor: pointer;

			$dropdown-arrow-h: 10px;

			img {
				height: $dropdown-arrow-h;
				position: absolute;
				right: $dropdown-arrow-h;
				top: calc(50% - #{calc($dropdown-arrow-h / 2)});
			}
		}

		&-list {
			background-color: $tutorials-refine-bar--input-bg;
			border: 1px solid $tutorials-refine-bar--input-border-color;
			padding: 0;
			position: relative;
			top: -10px;
			max-height: 0;
			overflow: hidden;
			transition: all 0.5s;

			&-item {
				padding: $pad--min $pad--small;
				cursor: pointer;
				display: block;

				&:hover {
					background-color: $screaming-green;
					color: #fff;
				}

				&:first-child {
					margin-top: 20px;
				}

				&:last-child {
					margin-bottom: 10px;
				}
			}
		}

		@include respond-to-min(md) {
			display: inline-block;
			min-width: 200px;
		}
	}

	&__radio {
		display: flex;
		align-content: center;
		align-items: center;
		height: $tutorials-refine-bar--input-h;
		flex-grow: 1;

		&-btn {
			height: $tutorials-refine-bar--input-h;
			padding: 10px 15px;
			@include border(1px solid $tutorials-refine-bar--input-border-color, left, top, bottom);
			display: flex;
			align-content: center;
			align-items: center;
			background-color: $tutorials-refine-bar--input-bg;
			flex-grow: 1;
			justify-content: center;

			&:hover {
				background-color: $sf-green;
				color: #fff;
			}

			&.current {
				background-color: $sf-green;
				color: #fff;
				pointer-events: none;
			}

			&:last-child {
				@include border(1px solid $tutorials-refine-bar--input-border-color, right);
			}

			@include respond-to-max(sm) {
				font-size: 12px;
			}
			@include respond-to-min(lg) {
				padding: 10px 20px;
			}
		}
	}

	&__grid {
		padding: $pad--small $pad--min;

		display: grid;
		grid-template-columns: 1fr;
		grid-row: auto;
		row-gap: $pad--small;
		@include respond-to-min(md) {
			padding: $pad--large 0;

			grid-template-columns: repeat(2, 1fr);
			column-gap: $pad--large;
			row-gap: $pad--large;
			@include respond-to-min(xl) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
		&-item {
			&--no-posts {
				@include stretch-across-grid(); //stretch to full width
			}
		}
		// &-item {
		// 	// page navigation is the last child
		// 	&:last-child {
		// 		@include stretch-across-grid();
		// 	}
		// }
	}
}
