.sf-passage {
	padding: $pad--min;
	&__img-container {
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		padding: $pad--min;
	}
	&__content {
		padding: $pad--min;
	}
	&__btn {
		display: inline-block;
		margin-right: $pad--min;
		margin-top: $pad--min;
	}

	@include respond-to-min(xl) {
		padding: $pad--small;
		&__content,
		&__img-container {
			padding: $pad--small;
		}
	}
}
