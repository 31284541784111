.sf-careers {
	&-success-stories {
		$success-story--path-icon--w: 100px;

		h2 {
			@include h2();
		}

		h3 {
			@include h3();
			padding: 0 $pad--min $pad--small;
			@include respond-to-min(xl) {
				padding: 0 $pad--small $pad--small;
			}
		}

		h4 {
			@include h4();
			padding: $pad--small 0;
			margin-top: $pad--small;
			border-top: 1px solid $sf-border--grey;
			@include respond-to-min(md) {
				text-align: center;
			}
		}

		&__path {
			position: relative;
			&::before {
				content: "";
				display: block;
				background-color: $sf-green;
				position: absolute;
				width: 4px;
				left: calc($success-story--path-icon--w / 2) - 2px;
				top: 12.5%;
				bottom: 12.5%;
			}
			&-item {
				z-index: 1;
				position: relative;
				display: flex;
				justify-items: flex-start;
				justify-content: flex-start;
				align-items: center;
				align-content: center;
				width: 100%;
				padding: $pad--small 0;
				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
				}
				&:not(:last-child) {
					&:after {
						content: "";
						position: absolute;
						width: 25px;
						height: 25px;
						top: 100%;
						left: calc($success-story--path-icon--w / 2);
						transform: translate(-50%, -50%);
						background-position: center;
						background-repeat: no-repeat;
						background-image: backgroundImageURL("/careers/arrow-down-double-line.svg");
					}
				}

				img {
					border-radius: 100%;
					border: 4px solid $sf-green;
					width: 100px;
					background-color: $sf-bg--grey;
				}

				p {
					@include p();
					padding: $pad--min;
					width: 100%;
				}
			}
		}

		@include respond-to-min(sm) {
			&__path {
				&-item {
					p {
						font-size: $sf-font-size--x-large;
					}
				}
			}

			@include respond-to-min(md) {
				&__path {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;

					&::before {
						width: unset;
						height: 4px;
						top: calc($success-story--path-icon--w / 2) - 2px;
						bottom: unset;
						left: 12.5%;
						right: 12.5%;
					}

					&-item {
						flex-direction: column;
						padding: 0;

						&:not(:last-child):after {
							top: calc($success-story--path-icon--w / 2);
							left: 100%;
							transform: rotate(-90deg) translate(50%, -50%);
						}

						p {
							font-size: $sf-font-size--standard;
							text-align: center;
						}
						@include respond-to-min(lg) {
							p {
								font-size: $sf-font-size--large;
							}
						}
					}
				}
			}
		}
	}

	&-vacancy-list {
		&-item {
			padding: $pad--min;
			border-left: 5px solid $sf-green;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: center;
			align-items: center;
		}
		h2 {
			@include h2();
		}
		h3 {
			margin: $pad--min;
			@include h3();
		}
		a {
			margin: $pad--min;
			@include pill-btn--fill($bg: $sf-green, $bg-hover: $sf-green--dark);
		}
	}
}
