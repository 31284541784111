$iPhone5: 320px;
$iPhone6: 375px;
$iPad: 768px;
$iPadPro: 1024px;
$screaming-breakpoint: 1020px;

$breakpoints: (
	sm: 400px,
	md: 700px,
	lg: 1000px,
	lg-2: 1100px,
	xl: 1300px,
	bs-sm: 576px,
	// xs: < 576px, sm: >= 576px
	bs-md: 768px,
	// md: >= 768px
	bs-lg: 992px,
	// lg >= 992px
	bs-xl: 1200px,
	// xl >= 1200px
);

@function getValue($value) {
	@if map-has-key($breakpoints, $value) {
		@return map-get($breakpoints, $value);
	} @else {
		@return $value;
	}
}

///***********************************
// WIDTH MQ
///***********************************/
@mixin respond-to-min($breakpoint, $media: "all") {
	$value: getValue($breakpoint) + 1;

	@media #{$media} and (min-width: $value) {
		@content;
	}
}

@mixin respond-to-max($breakpoint, $media: "all") {
	$value: getValue($breakpoint);

	@media #{$media} and (max-width: $value) {
		@content;
	}
}

@mixin respond-min-to-max($breakpoint-min, $breakpoint-max, $media: "all") {
	$min-value: getValue($breakpoint-min) + 1;
	$max-value: getValue($breakpoint-max);

	@media #{$media} and (min-width: $min-value) and (max-width: $max-value) {
		@content;
	}
}

///***********************************
// HEIGHT MQ
///***********************************/

@mixin h-respond-to-min($breakpoint, $media: "all") {
	$value: getValue($breakpoint) + 1;

	@media #{$media} and (min-height: $value) {
		@content;
	}
}

@mixin h-respond-to-max($breakpoint, $media: "all") {
	$value: getValue($breakpoint);

	@media #{$media} and (max-height: $value) {
		@content;
	}
}

@mixin h-respond-min-to-max($breakpoint-min, $breakpoint-max, $media: "all") {
	$min-value: getValue($breakpoint-min) + 1;
	$max-value: getValue($breakpoint-max);

	@media #{$media} and (min-height: $min-value) and (max-height: $max-value) {
		@content;
	}
}

///***********************************
// HEIGHT AND WIDTH MQ
///***********************************/

@mixin wh-respond-to-min($breakpoint-w, $breakpoint-h, $media: "all") {
	$value-w: getValue($breakpoint-w) + 1;
	$value-h: getValue($breakpoint-h) + 1;

	@media #{$media} and (min-width: $value-w) and (min-height: $value-h) {
		@content;
	}
}

@mixin wh-respond-to-max($breakpoint-w, $breakpoint-h, $media: "all") {
	$value-w: getValue($breakpoint-w);
	$value-h: getValue($breakpoint-h);

	@media #{$media} and (max-width: $value-w) and (max-height: $value-h) {
		@content;
	}
}

///***********************************
// WIDTH OR HEIGHT MQ
///***********************************/

@mixin w-or-h-respond-to-min($breakpoint-w, $breakpoint-h, $media: "all") {
	$value-w: getValue($breakpoint-w) + 1;
	$value-h: getValue($breakpoint-h) + 1;

	@media #{$media} and (min-width: $value-w), #{$media} and (min-height: $value-h) {
		@content;
	}
}

@mixin w-or-h-respond-to-max($breakpoint-w, $breakpoint-h, $media: "all") {
	$value-w: getValue($breakpoint-w);
	$value-h: getValue($breakpoint-h);

	@media #{$media} and (max-width: $value-w), #{$media}  and (max-height: $value-h) {
		@content;
	}
}

///***********************************
// ORIENTATION MQ
///***********************************/
@mixin respond-to-orientation($orientation: "portrait", $media: "all") {
	@media #{$media} and (orientation: $orientation) {
		@content;
	}
}

///***********************************</editor-fold>*
//MAX IF SMALLEST VAL, MIN OTHERWISE
//************************************<editor-fold>*/
// Media queries
@mixin mq($break) {
	$value: map-get($breakpoints, $break);
	$sm: map-get($breakpoints, "sm");

	@if $value == null {
		@error "`#{$break}` is not a valid breakpoint name.";
	} @else if $value < $sm {
		@media (max-width: $value) {
			@content;
		}
	} @else {
		@media (min-width: $value) {
			@content;
		}
	}
}

///*=====================================================================</editor-fold>=
// responsive-ratio
///=====================================================================<editor-fold>=*/

@mixin responsive-ratio($x, $y, $pseudo: false) {
	$padding: unquote(($y / $x) * 100 + "%");
	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}
//</editor-fold>
