@keyframes slideDownKeepCenter {
	0% {
		transform: translate(0, -200%);
	}

	100% {
		transform: translate(0, -50%);
	}
}

@keyframes highlight-orange {
	0% {
		background: rgba($sf-orange--light, 0.3);
	}

	100% {
		background: transparent;
	}
}

@keyframes highlight-red {
	0% {
		background: rgba($sf-color--error, 0.3);
	}

	100% {
		background: transparent;
	}
}

@keyframes slide-in-left {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes loading-spinner {
	100% {
		transform: rotate(1turn);
	}
}

@keyframes loading-dots {
	20% {
		background-position: 0% 0%, 50% 50%, 100% 50%;
	}
	40% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	60% {
		background-position: 0% 50%, 50% 100%, 100% 0%;
	}
	80% {
		background-position: 0% 50%, 50% 50%, 100% 100%;
	}
}
