.sf-client-square {
	width: 100%;
	border: 1px solid $sf-border--grey;
	background-color: #fff;
	@include respond-to-max(sm) {
		max-width: 250px;
		margin: 0 auto;
	}
	aspect-ratio: 1 / 1;
	padding: $pad--min;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
	img {
		object-fit: cover;
		width: 100%;
	}
}
