//styles wp_page_navi plugin
.sf-page-control {
	background: #fff;
	padding: 45px 20px;
	text-align: center;
	//stretch across grid layouts:
	@include stretch-across-grid();

	.pages {
		display: block;
		padding: 0;
		margin: 0 0 30px;
	}

	span {
		border: 0;
	}

	a {
		border: 1px solid #f0f0f0;
		font-size: 15px;
		padding: 14px 24px;
		margin: 0 10px;

		&:hover {
			border-color: #9f9f9e;
		}
	}

	span,
	a {
		color: #9f9f9e;
		display: inline-block;

		&.current {
			border: 1px solid #f0f0f0;
			font-size: 15px;
			padding: 14px 24px;
			margin: 0 10px;
			background: #f0f0f0;
		}

		&.extend {
			display: none;

			@include respond-min-to-max(1000px, 1200px) {
				+ .page {
					display: none;
				}
			}
		}
	}

	.first,
	.last,
	.page,
	.current {
		display: none;
	}

	@include respond-to-max(lg) {
		.page,
		.current {
			margin: 0 5px;
		}

		.previouspostslink {
			margin-left: 0;
		}

		.nextpostslink {
			margin-right: 0;
		}
	}

	@include respond-to-min(sm) {
		.first,
		.last {
			display: inline-block;
		}

		@include respond-to-min(md) {
			.page,
			.current {
				display: inline-block;
			}

			.previouspostslink,
			.nextpostslink {
				display: none;
			}

			@include respond-to-min(lg) {
				@include respond-to-min(xl) {
					@include respond-to-min(1350px) {
						.previouspostslink,
						.nextpostslink {
							display: inline-block;
						}
					}
				}
			}
		}
	}
}
