//@import "../config/config";

///*=====================================================================</editor-fold>=
// WP CHECKOUT PLUGIN
///=====================================================================<editor-fold>=*/

#wpco_cartform:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}

#wpcobillingform .stepsholder {
  margin-top: 50px;
  border-bottom: 1px solid #e1e1e1;
}

#wpcobillingform .shippingbuttons {
  padding: 30px 50px;
  margin: 0;
  display: block;
  width: 100%;
}

@media (max-width: 500px) {
  #wpcobillingform .shippingbuttons {
    padding-right: 30px;
    padding-left: 30px;
  }
}

#wpcobillingform .shippingbuttons input:last-child {
  float: right;
  cursor: pointer;
  background: #75b42c;
}

#wpcobillingform .shippingbuttons input:last-child:hover {
  background: #599f08;
}
//</editor-fold>
