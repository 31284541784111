$sf-overlay-back-to-top--w: 30px;

.sf-overlay-back-to-top {
	border: 1px solid $checkout-border-grey;
	background-color: #fff;
	cursor: pointer;
	border-radius: 5px;
	color: $sf-grey--light;
	z-index: 99;

	position: fixed;
	bottom: $pad--min;
	right: $pad--min;
	width: $sf-overlay-back-to-top--w;
	height: $sf-overlay-back-to-top--w;
	overflow: hidden;
	box-sizing: content-box;

	i {
		position: absolute;
		left: 0;
		top: 0;
		font-size: 20px;
		width: $sf-overlay-back-to-top--w;
		height: $sf-overlay-back-to-top--w;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		// border: 1px solid red;
		&:nth-child(2) {
			top: 100%;
		}
	}
	&:hover {
		color: $sf-green;
		box-shadow: 0px 0px 10px 0px rgba($sf-green, 0.75);
		i {
			transition: all 0.5s;
			transform: translateY(-100%);
		}
	}
}
