.sf-user-guide-menu {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	row-gap: $pad--small;
	column-gap: $pad--small;
	padding: $pad--small 0;
	@include respond-to-min(md) {
		padding: $pad--small 0;
		grid-template-columns: repeat(2, 1fr);
		&--lfa {
			.sf-user-guide-menu__section {
				height: 100%;
			}
		}
		&--spider {
			grid-template-areas: "a1 b" "a2 b" "a3 b";
			.sf-user-guide-menu__section {
				&--general {
					grid-area: a1;
				}
				&--tabs {
					grid-area: a2;
				}
				&--how-to {
					grid-area: a3;
				}
				&--configuration {
					grid-area: b;
				}
			}
		}
		@include respond-to-min(lg) {
			padding: $pad--large 0;
			column-gap: $pad--large;
			row-gap: $pad--large;
			@include respond-to-min(xl) {
				&--spider {
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: auto auto 1fr 1fr;
					grid-template-areas: "a1 b c" "a2 b c" ". b c" ". b c";
					.sf-user-guide-menu__section {
						&--general {
							grid-area: a1;
						}
						&--how-to {
							grid-area: a2;
						}
						&--configuration {
							grid-area: b;
						}
						&--tabs {
							grid-area: c;
						}
					}
				}
			}
		}
	}
	h2 {
		@include h3($font-weight: $sf-weight--heavy);
	}
	&--spider {
		h2 {
			margin-bottom: $pad--small;
		}
	}
	&--lfa {
		h2 {
			margin-bottom: $pad--min;
		}
	}
	h3 {
		@include h5($font-weight: $sf-weight--heavy);
		margin-top: $pad--small;
		margin-bottom: $pad--min;
	}
	p {
		@include p($font-size: $sf-font-size--large);
		a {
			@include link();
		}
	}
}
