//@import "../config/config";

///*=====================================================================</editor-fold>=
// TOP
///=====================================================================<editor-fold>=*/

.services-top {
	@include frog-wallpaper();
	height: 285px;
	padding: 20px;
	display: table;
	width: 100%;
}

.services-top--inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-weight: $sf-weight--light;
}

.services-top--inner h1 {
	color: #fff;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 15px;
	font-weight: $sf-weight--light;
}

.services-top--inner p {
	color: #d1ff9b;
	max-width: 735px;
	margin: 0 auto;
	line-height: 28px;
}

.services-top--inner p a {
	border-bottom: 2px solid #fff;
	color: #fff;
}
//</editor-fold>
