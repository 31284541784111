.sf-awards {
	padding: $pad--min;

	&__gallery {
		padding: $pad--min 0 0 $pad--min;

		// display: inline-grid;
		// grid-template-columns: repeat(2, 1fr);
		// column-gap: $pad--min;
		// row-gap: $pad--min;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		justify-items: center;
		&-item {
			width: calc(50% - $pad--min);
			margin-right: $pad--min;
			margin-bottom: $pad--min;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	@include respond-to-min(sm) {
		&__gallery {
			// grid-template-columns: repeat(3, 1fr);
			&-item {
				width: calc(33.33333% - $pad--min);
			}
		}

		@include respond-to-min(md) {
			&__gallery {
				padding: 0;
				// column-gap: $pad--small;
				// row-gap: $pad--small;
				// grid-template-columns: repeat(4, 1fr);
				&-item {
					width: calc(
						25% - $pad--small
					); // $pad-small is to account for $pad--min margin either side of the award image
					margin: $pad--min;
				}
			}
			@include respond-to-min(xl) {
				&__gallery {
					// column-gap: $pad--small;
					// row-gap: $pad--small;
					// grid-template-columns: repeat(5, 1fr);
					&-item {
						width: calc(
							20% - $pad--small
						); // $pad-small is to account for $pad--min margin either side of the award image
					}
				}
				// @include respond-to-min(xl) {
				// 	padding: $pad--small;
				// 	&__gallery {
				// 		padding: $pad--small;
				// 		column-gap: $pad--small;
				// 		row-gap: $pad--small;
				// 		grid-template-columns: repeat(5, auto);
				// 	}
				// }
			}
		}
	}
}
