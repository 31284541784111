//@import "../config/config";

///*=====================================================================</editor-fold>=
// FEATURES
///=====================================================================<editor-fold>=*/

.spider-features {
	background: #f8f8f8;
	padding: 1px 0;
}

@media (max-width: 900px) {
	.spider-features {
		padding: 1px 0 30px;
	}
}

.spider-features.pricing-features {
	padding: 50px 0;
}

@include respond-to-max("bs-md") {
	.spider-features.pricing-features {
		padding: 1px 0 0;
	}
}

.spider-features > h2 {
	font-size: 32px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	text-align: center;
}

.spider-features > p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	max-width: 710px;
	text-align: center;
	margin: 15px auto;
	line-height: 30px;
}

.spider-features--inner {
	background: #fff;
	padding: 50px 60px;
	margin-top: 80px;
}

@media (max-width: 500px) {
	.spider-features--inner {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.spider-features--inner.spider-tool {
	padding: 70px 60px 20px;
}

.spider-features--inner.features-single {
	padding: 0;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.spider-features--inner_single {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	margin-bottom: 50px;
	max-width: 520px;
}

.spider-features--inner_single:before {
	content: "";
	display: table;
}

.spider-features--inner_single:after {
	content: "";
	display: table;
	clear: both;
}

.spider-features--inner_single:nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.spider-features--inner_single:nth-child(2n + 1) {
	clear: both;
}

.spider-features--inner_single h3 {
	font-size: 22px;
	color: $sf-font-grey;
	font-weight: $sf-weight--med;
	margin-bottom: 15px;
}

.spider-features--inner_single p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 26px;
}

.spider-features--inner_single img {
	float: left;
	max-width: 57px;
}

.spider-features--inner_single-content {
	float: right;
	width: 82%;
}

@media (max-width: 800px) {
	.spider-features--inner_single {
		*zoom: 1;
		clear: none;
		width: 100%;
		margin: 0 auto 50px !important;
		float: none !important;
		text-align: center;
	}

	.spider-features--inner_single:before {
		content: "";
		display: table;
	}

	.spider-features--inner_single:after {
		content: "";
		display: table;
		clear: both;
	}

	.spider-features--inner_single:nth-child(2n) {
		margin-right: 3%;
		float: left;
	}

	.spider-features--inner_single:nth-child(2n + 1) {
		clear: none;
	}

	.spider-features--inner_single:last-child {
		margin-right: 0%;
	}

	.spider-features--inner_single img {
		float: none;
		display: block;
		margin: 0 auto 20px;
	}

	.spider-features--inner_single-content {
		width: 100%;
		max-width: 610px;
		float: none;
	}
}

@media (max-width: 600px) {
	.spider-features--inner {
		margin-top: 40px;
	}

	.spider-features--inner_single:last-child {
		margin-bottom: 0;
	}

	.spider-features--inner_single img {
		float: none;
		display: block;
		margin: 0 auto 30px;
	}

	.spider-features--inner_single-content {
		float: none;
		width: 100%;
	}
}

.spider-features--inner_left,
.spider-features--inner_mid {
	background: #fff;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(88%, #fff),
		color-stop(88%, #fff),
		color-stop(100%, #f7f7f7)
	);
	background: linear-gradient(to right, #fff 88%, #fff 88%, #f7f7f7 100%);
}

.spider-features--inner_left h3 {
	font-size: 28px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
}

.spider-features--inner_mid h3,
.spider-features--inner_right h3 {
	font-size: 28px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	text-align: center;
	padding: 20px 0;
}

.spider-features--inner_left ul li,
.spider-features--inner_mid ul li,
.spider-features--inner_right ul li {
	min-height: 50px;
	border-top: 1px solid #f8f8f8;
	line-height: 50px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.spider-features--inner_mid ul li,
.spider-features--inner_right ul li {
	text-align: center;
}

.spider-features--inner_left ul li span,
.spider-features--inner_mid ul li span,
.spider-features--inner_right ul li span {
	display: none;
}

.spider-features--inner_left ul li:last-child,
.spider-features--inner_mid ul li:last-child,
.spider-features--inner_right ul li:last-child {
	border-bottom: 1px solid #f8f8f8;
}

.spider-features--inner_left ul li.tick:before,
.spider-features--inner_mid ul li.tick:before,
.spider-features--inner_right ul li.tick:before {
	content: "\f00c";
	color: #75b42c;
}

.spider-features--inner_left ul li.cross:before,
.spider-features--inner_mid ul li.cross:before,
.spider-features--inner_right ul li.cross:before {
	content: "\f00d";
	color: #d64141;
}

.spider-features--inner_left ul li.cross:before,
.spider-features--inner_mid ul li.cross:before,
.spider-features--inner_right ul li.cross:before,
.spider-features--inner_left ul li.tick:before,
.spider-features--inner_mid ul li.tick:before,
.spider-features--inner_right ul li.tick:before {
	font-family: fontAwesome;
}

@media (max-width: 600px) {
	.spider-features--inner_left ul li.cross:before,
	.spider-features--inner_mid ul li.cross:before,
	.spider-features--inner_right ul li.cross:before,
	.spider-features--inner_left ul li.tick:before,
	.spider-features--inner_mid ul li.tick:before,
	.spider-features--inner_right ul li.tick:before {
		margin-right: 20px;
	}
}

.spider-features--inner_left-bottom {
	padding: 40px 60px;
}

.spider-features--inner_mid-bottom,
.spider-features--inner_right-bottom {
	padding: 40px 60px;
	text-align: center;
}

.spider-features--inner_right-bottom
	h4
	.woocommerce-Price-amount
	.woocommerce-Price-currencySymbol {
	display: none !important;
}

.spider-features--inner_left-bottom h4,
.spider-features--inner_mid-bottom h4,
.spider-features--inner_right-bottom h4 {
	font-size: 22px;
	color: $sf-font-grey;
}

.spider-features--inner_left-bottom a,
.spider-features--inner_mid-bottom a,
.spider-features--inner_right-bottom a {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	max-width: 230px;
	padding: 14px 0;
	width: 100%;
	display: block;
	margin: 12px auto 0;
}

.spider-features--inner_left-bottom,
.spider-features--inner_mid-bottom,
.spider-features--inner_right-bottom,
.spider-features--inner_left-bottom,
.spider-features--inner_mid-bottom,
.spider-features--inner_right-bottom {
	[data-modal="spider-download"],
	[data-modal="lfa-download"] {
		background: #7180e4;
		&:hover {
			background: #3f52d5;
		}
	}
}

.spider-features--inner_left-bottom a.purchase-licence,
.spider-features--inner_mid-bottom a.purchase-licence,
.spider-features--inner_right-bottom a.purchase-licence {
	background: #ffbc2b;
}

.spider-features--inner_left-bottom a.purchase-licence:hover,
.spider-features--inner_mid-bottom a.purchase-licence:hover,
.spider-features--inner_right-bottom a.purchase-licence:hover {
	background: #f2a600;
}

@media (max-width: 1000px) {
	.spider-features--inner_left-bottom a span,
	.spider-features--inner_mid-bottom a span,
	.spider-features--inner_right-bottom a span {
		display: none;
	}
}

.spider-features--inner_left-bottom p,
.spider-features--inner_mid-bottom p,
.spider-features--inner_right-bottom p {
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-top: 10px;
	line-height: 24px;
}

.spider-features--inner_left h3 {
	text-align: left;
	padding: 20px 0 20px 60px;
}

.spider-features--inner_left ul li {
	text-align: left;
	padding-left: 60px;
}

.spider-features--inner_left-bottom {
	text-align: left;
}

@media (max-width: 900px) {
	.spider-features--inner_left {
		width: 50%;
	}

	.spider-features--inner_mid,
	.spider-features--inner_right {
		width: 25%;
	}

	.spider-features--inner_mid h3 span,
	.spider-features--inner_right h3 span {
		display: none;
	}

	.spider-features--inner_mid-bottom,
	.spider-features--inner_right-bottom {
		padding: 50px 20px;
	}
}

@media (max-width: 680px) {
	.spider-features--inner_left ul li,
	.spider-features--inner_left h3,
	.spider-features--inner_left-bottom {
		padding-left: 25px;
	}
}

@media (max-width: 600px) {
	.spider-features--inner.features-single {
		background: #f8f8f8;
		box-shadow: none;
		display: block;
	}

	.spider-features--inner.features-single .spider-features--inner_left {
		background: 0 0;
	}

	.spider-features--inner.features-single .spider-features--inner_left h3 {
		padding: 0;
		text-align: center;
	}

	.spider-features--inner.features-single .spider-features--inner_left > ul,
	.spider-features--inner.features-single .spider-features--inner_left > div {
		display: none;
	}

	.spider-features--inner_left,
	.spider-features--inner_mid {
		float: none;
		width: 100%;
		margin-bottom: 40px;
	}

	.spider-features--inner_right {
		float: none;
		width: 100%;
	}

	.spider-features--inner_left ul li,
	.spider-features--inner_mid ul li,
	.spider-features--inner_right ul li {
		text-align: left;
		padding: 0 20px;
	}

	.spider-features--inner_left ul li span,
	.spider-features--inner_mid ul li span,
	.spider-features--inner_right ul li span {
		display: inline-block;
	}

	.spider-features--inner_mid,
	.spider-features--inner_right {
		box-shadow: 0 11px 65px 0 rgba(0, 0, 0, 0.07);
		background: #fff;
	}

	.spider-features--inner_right {
		margin-bottom: 0;
	}
}

@media (max-width: 995px) {
	.spider-features--inner.spider-tool {
		padding: 70px 20px 20px;
	}
}

@media (max-width: 800px) {
	.spider-features--inner.spider-tool {
		text-align: center;
	}
}

@include respond-to-max("bs-md") {
	.spider-features h2,
	.spider-features p {
		padding: 0 20px;
	}

	.spider-features .wrap,
	.spider-features--inner {
		padding: 0;
	}
}

//new

.spider-features {
	&--footnote {
		margin-top: 20px;
		color: $sf-font-grey;
	}
}
