//@import "../config/config";

///*=====================================================================</editor-fold>=
// BASE/RESET
///=====================================================================<editor-fold>=*/

html {
	font-weight: inherit;
	font-family: inherit;
}

html,
body {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-style: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

div,
span {
	font-weight: inherit;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
font,
img,
ins,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
strong {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

em {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-size: 100%;
	font-family: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

:focus,
:active {
	outline: 0;
}

ul {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.no-touch a:hover {
	opacity: 1;
}

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

* {
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.no-call-out {
	-webkit-touch-callout: none;
}

.clearfix {
	*zoom: 1;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
	line-height: 0;
}

.clearfix:after {
	clear: both;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	.x2 {
		font-size: 1px;
	}
}
//</editor-fold>
