.sfwc-no-orders {
	display: flex;
	justify-content: space-between;
	justify-items: space-between;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;

	p {
		@include p();
		margin: $pad--min $pad--min $pad--min 0; // pad each side except left, to allow for screensize shrink & wrapping
	}
	a {
		@include pill-btn--fill($sf-orange--light, #fff!important, $sf-orange--dark);
		width: 100%;
		max-width: 300px;
	}
}
