//OVERRIDES injected woocommerce multi currency styles:
.woocommerce-multi-currency.wmc-shortcode {
	// tab system layout
	&.layout9 {
		.wmc-currency-wrapper {
			//ensure it doesn't overflow it's container:
			margin-right: 30px;

			.wmc-currency {
				padding: $add-to-cart-inputs--pad--v 0 !important;
				border-color: $add-to-cart-inputs--color !important;
				color: $add-to-cart-inputs--color !important;
				transition: all 0.3s;
				&.wmc-right {
					@include round-corners(0, "left");
				}
				&.wmc-left {
					@include round-corners(0, "right");
				}
				&.wmc-current-currency {
					background-color: $sf-green !important;
					border-color: $sf-green !important;
					&:hover {
						background-color: $sf-green !important;
						border-color: $sf-green !important;
					}
					a {
						color: #fff !important;
					}
				}
				a {
					font-size: $add-to-cart-inputs--font-size !important;
					font-weight: $add-to-cart-inputs--font-weight !important;
					line-height: $add-to-cart-inputs--line-height !important;
					padding: $add-to-cart-inputs--pad--v $add-to-cart-inputs--pad--h !important;
				}
				&:hover {
					background-color: $sf-green--dark !important;
					border-color: $sf-green--dark !important;
					a {
						color: #fff !important;
					}
				}
			}
		}

		// on cart page:
		.checkout__cart & {
			float: right;
			margin: 0 10px;
			.wmc-currency-wrapper {
				.wmc-currency {
					border-color: $table-border-color !important;
					color: $sf-font-grey !important;
					&.wmc-current-currency {
						color: #fff !important;
						background-color: $sf-blue--light !important;
						border-color: $sf-blue--light !important;
						&:hover {
							background-color: $sf-blue--light !important;
							border-color: $sf-blue--light !important;
						}
					}
					&:hover {
						background-color: $sf-blue--dark !important;
						border-color: $sf-blue--dark !important;
						color: #fff !important;
					}
					a {
						font-size: 14px !important;
						line-height: 22px !important;
					}
				}
			}
		}

		// on multi licence pricing block:
		.pricing-features--multiple_left-title & {
			min-width: 145px;
			transform: scale(0.9);
			transform-origin: top right;
		}
	}
	//dropdown system layout
	&.plain-vertical.vertical-currency-symbols {
		.wmc-hidden {
			display: none !important;
		}

		//in features block
		.spider-features--inner_right-bottom & {
			.wmc-current-currency,
			.wmc-currency {
				color: $sf-font-grey !important;
				padding: 2px 2px 2px 15px !important;
			}
			.wmc-open-dropdown-currencies {
				right: unset !important;
				left: 4px !important;
			}
		}
	}
}
