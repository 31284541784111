.sf-dropdown-nav {
	&-item {
		position: relative;
		text-decoration: none !important;
		cursor: pointer;
		background-color: #fff;
		width: 100%;
		text-align: left;
		font-size: 18px;
		font-weight: $sf-weight--light;
		color: $sf-font-grey !important;
		box-sizing: border-box;
		transition: all 0.3s;
		border: none;
		@include border($sf-border, bottom);
		&--expand-btn {
			@include border($sf-border, top);
		}

		&:hover {
			background-color: $sf-grey--lightest;
		}

		//hide items when dropdown not expanded
		&:not(.sf-dropdown-nav-item--expand-btn) {
			display: none;
			[data-dropdown="expanded"] & {
				display: grid;
			}
		}

		display: grid;
		column-gap: 10px;
		align-content: center;
		align-items: center;
		grid-template-columns: 5px 1fr 40px;
		&--1-image {
			grid-template-columns: 5px 20px 1fr 40px;
		}
		&--2-image {
			grid-template-columns: 5px 20px 20px 1fr 40px;
		}
		&--3-image {
			grid-template-columns: 5px 20px 20px 20px 1fr 40px;
		}
		img {
			width: 100%;
		}

		&:before {
			content: "";
			height: 100%;
			//default (can be overridden below)
			background-color: $sf-green;
		}

		&__text {
			display: block;
			padding: $pad--min 0;
		}

		&__arrow {
			color: #fff;
			display: flex;
			justify-content: center;
			justify-items: center;
			align-content: center;
			align-items: center;
			font-size: 25px;
			transition: background-color 0.3s;
			height: 100%;
			//default (can be overridden below)
			background-color: $sf-green;

			i {
				position: relative;
				left: 0;
				top: 0;
				transition: all 0.3s;
				&:before {
					//prevent arrow background color being set in color overrides below
					background-color: transparent !important;
				}
				.sf-dropdown-nav-item:not(.sf-dropdown-nav-item--expand-btn):hover & {
					left: 3px;
				}
				.sf-dropdown-nav-item.sf-dropdown-nav-item--expand-btn:hover & {
					top: 3px;
				}
			}
		}
	}

	//dropdown styles
	&[data-dropdown="expanded"] {
		.sf-dropdown-nav-item--expand-btn {
			.sf-dropdown-nav-item__arrow {
				background-color: $flint !important;
				transform: rotate(180deg);
			}
		}
	}

	//color overrides
	&-item {
		&:before,
		&__arrow {
			.sf-dropdown-nav--green & {
				background-color: $sf-green;
			}
			.sf-dropdown-nav--orange & {
				background-color: $sf-orange--light;
			}
			.sf-dropdown-nav--blue & {
				background-color: $sf-blue--light;
			}
			//learn seo
			.sf-dropdown-nav--learn-seo-green & {
				background-color: $learnSEO-green;
			}
			.sf-dropdown-nav--learn-seo-orange & {
				background-color: $learnSEO-orange;
			}
			.sf-dropdown-nav--learn-seo-blue & {
				background-color: $learnSEO-blue;
			}
			//issues pages
			.sf-dropdown-nav--issues-blue & {
				background-color: $issues-blue;
			}
			.sf-dropdown-nav--issues-yellow & {
				background-color: $issues-yellow;
			}
			.sf-dropdown-nav--issues-red & {
				background-color: $issues-red;
			}
		}
	}
}
