$sf-banner-return-arrow--w: 52px;

.sf-banner-return {
	cursor: pointer;

	&__content {
		display: flex;
		justify-content: center;
		justify-items: center;
		align-items: center;
		align-content: center;

		h2 {
			@include h3($color: $sf-grey--dark);
		}
	}

	&__arrow {
		font-size: 50px;
		line-height: 50px;
		color: $sf-grey--dark;
		position: relative;
		&--left {
			transform: rotate(-90deg);
		}

		width: $sf-banner-return-arrow--w;
		height: $sf-banner-return-arrow--w;
		i {
			position: absolute;
			top: -5px;
			&:nth-child(2) {
				top: 5px;
			}
			left: 0;
		}
	}
}
