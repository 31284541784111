.sf-sidebar-software {
	padding: $pad--small;
	text-align: center;
	h2 {
		@include h4($color: #fff);
	}

	a {
		margin: $pad--small auto 0;
		display: block;
		max-width: 300px;
	}
	&--download {
		a {
			@include pill-btn--outline(#fff, $sf-orange--light);
		}
	}
	&--purchase {
		a {
			@include pill-btn--outline(#fff, $sf-green);
		}
	}
}
