.sf-portfolio-clients {
	padding: $pad--small;
	p {
		max-width: $sf-paragraph-max-w;
		margin: 0 auto;
	}
	&__header {
		margin-bottom: $pad--small;
	}
	&__footer {
		margin-top: $pad--small;
	}
	&__grid {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		column-gap: $pad--small;
		row-gap: $pad--small;
	}
	@include respond-to-min(sm) {
		&__grid {
			grid-template-columns: repeat(2, 1fr);
		}
		@include respond-to-min(md) {
			&__grid {
				grid-template-columns: repeat(3, 1fr);
			}
			@include respond-to-min(lg) {
				padding: $pad--large;
				&__header {
					margin-bottom: $pad--large;
				}
				&__footer {
					margin-top: $pad--large;
				}
				&__grid {
					grid-template-columns: repeat(4, 1fr);
					column-gap: $pad--large;
					row-gap: $pad--large;
				}
			}
		}
	}
}
