//@import "../config/config";

///*=====================================================================</editor-fold>=
// WP CHECKOUT PLUGIN STEPS
///=====================================================================<editor-fold>=*/

@media (max-width: 450px) {
  div.wpcosteps {
    margin-top: 40px;
  }

  div.wpcosteps ul li {
    border-right: 0;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    display: block !important;
  }
}
//</editor-fold>
