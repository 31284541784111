.support-bottom {
	background: $screaming-light-grey;
	padding: 90px 0;

	@include respond-to-max(bs-md) {
		padding: 0;

		.wrap {
			padding: 0;
		}
	}

	&--inner {
		h2 {
			font-size: 32px;
			color: $sf-font-grey;
			margin-bottom: 20px;
		}

		h3 {
			font-size: 21px;
			color: $sf-font-grey;
			margin-bottom: 20px;
			font-weight: $sf-weight--light;
		}

		p,
		li {
			color: $sf-font-grey;
			font-weight: $sf-weight--light;
			line-height: 24px;
			margin-bottom: 20px;
			font-size: 16px;

			span {
				font-weight: $sf-weight--med;
			}
		}

		ul {
			padding: 0;

			li {
				padding-left: 25px;
				position: relative;

				&:before {
					content: "";
					border-radius: 50px;
					background: $white;
					border: 2px solid $screaming-green;
					width: 10px;
					height: 10px;
					left: 0;
					top: 6px;
					position: absolute;
				}
			}
		}

		a {
			color: $screaming-green;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			text-decoration: underline;

			&:hover {
				color: $screaming-dark-green;
			}
		}

		&_left {
			margin-bottom: 50px;
		}

		&_right {
			@include respond-to-min(bs-lg) {
				display: flex;
			}

			.licence-key {
				margin-bottom: 50px;

				@include respond-to-min(bs-lg) {
					flex: 1;
					margin: 0 25px 0 0;
				}
			}

			.support-ticket {
				margin-bottom: 50px;

				@include respond-to-min(bs-lg) {
					flex: 1;
					margin: 0 0 0 25px;
				}
			}
		}

		.drop {
			padding: 40px 50px;
			background: $white;

			@media (max-width: 500px) {
				padding: 40px 30px;
			}
		}
	}
}

.support-ticket {
	margin: 40px 0 0;

	&_links {
		padding-top: 10px;

		@include respond-to-max(500px) {
			text-align: center;
		}

		a {
			display: inline-block;
			padding: 14px 0;
			text-decoration: none;
		}

		.email {
			border-radius: 50px;
			text-align: center;
			color: $white;
			font-weight: $sf-weight--heavy;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			width: 100%;
			max-width: 155px;
			background: $screaming-green;
			text-decoration: none;
			margin: 0 0 25px;

			@include respond-to-min(500px) {
				margin: 0;
			}

			&:hover {
				background: $screaming-dark-green;
				color: $white;
			}
		}

		.support {
			border-radius: 50px;
			text-align: center;
			color: $white;
			font-weight: $sf-weight--heavy;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			width: 100%;
			max-width: 220px;
			background: $screaming-blue;

			@include respond-to-min(500px) {
				margin-left: 25px;
			}

			text-decoration: none;

			&:hover {
				background: $screaming-dark-blue;
				color: $white;
			}
		}
	}
}
