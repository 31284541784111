.sf-hero {
	text-align: center;
	position: relative;
	min-height: 240px;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
	&--bg-img {
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(#000, 0.5);
		}
		.sf-hero__content {
			z-index: 1;
			position: relative;
		}
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center center !important;
		min-height: 300px;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
	}
	&__pre,
	&__post {
		margin: 0 auto;
	}
	&__snippet,
	&__back {
		max-width: 700px;
		margin: $pad--min auto 0;
		display: block;
	}
	&__back {
		text-decoration: none !important;
		i {
			display: inline-block;
			position: relative;
			top: 1px;
		}
		&:hover {
			color: #fff;
			font-weight: $sf-weight--heavy;
		}
	}

	p {
		@include p($color: #fff);
	}
	a {
		@include p($color: #fff);
		text-decoration: underline;
		&:hover {
			color: #fff;
			font-weight: $sf-weight--heavy;
		}
	}
	h1 {
		@include h1();
	}
}
