.sf-post-preview {
	overflow: hidden;
	&__hero {
		height: 200px;
		margin-bottom: -60px;
		width: 100%;
		position: relative;
		overflow: hidden;
		&-img {
			height: 100%;
			background-position: center;
			transition: all 0.5s;
			background-size: cover;
			background-repeat: no-repeat;
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
		}
		&:hover {
			.sf-post-preview__hero-img {
				transform: scale(1.05);
			}
		}
	}
	&__content {
		margin-top: $pad--min;
	}
	&__post-info,
	&__title {
		position: relative;
		background-color: #fff;
		padding: $pad--min $pad--small 0;
	}
	&__post-info {
		display: inline-block;
		max-width: 70%;
		@include p($font-size: $sf-font-size--large);
		a {
			@include link();
		}
	}
	&__title {
		a {
			@include h2($font-weight: $sf-weight--heavy);
		}
	}
	&__excerpt {
		position: relative;
		padding: $pad--small $pad--small $pad--min;
		width: 100%;
		@include p($font-size: $sf-font-size--large);
		a {
			@include link();
		}
	}
	&__cta {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 $pad--small $pad--small;
		width: 100%;

		@include p($font-size: $sf-font-size--large);
		&-link {
			@include link($underline: false);
			display: inline-block;
			// margin-top: $pad--min;
		}
	}

	&--tutorial {
		height: 100%;
		display: flex;
		flex-direction: column;
		.sf-post-preview__hero {
			margin: 0;
			height: unset;
			aspect-ratio: 300 / 192;
			@include respond-to-max(md) {
				display: none;
			}
		}
		.sf-post-preview__title {
			a {
				font-size: $sf-font-size--h5;
			}
		}
		.sf-post-preview__content {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
		.sf-post-preview__cta {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-content: flex-start;
			align-items: flex-start;
		}
	}
	&--carousel {
		min-height: 550px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-items: flex-end;
		justify-content: flex-end;
		position: relative;
		.sf-post-preview__hero {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
		.sf-post-preview__excerpt {
			padding-top: $pad--min;
			padding-bottom: $pad--min;
			min-height: 160px;
		}
	}
}
