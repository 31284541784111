@mixin nested-cols(
	$widths: (
		30,
		40,
		50,
		60,
		70
	),
	$min-max-widths: (
		200,
		300,
		400,
		500,
		600,
		700
	),
	$parent-class: ".sf-nested-cols"
) {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	&--align-center {
		align-items: center;
	}

	#{$parent-class}__right,
	#{$parent-class}__left {
		width: 50%;
		flex-grow: 1;
	}
	@each $bp in (sm, md, lg, xl) {
		&#{$parent-class}--stack-under-#{$bp} {
			@include respond-to-max($bp) {
				#{$parent-class}__right,
				#{$parent-class}__left {
					width: 100% !important;
				}
			}
		}
	}

	&#{$parent-class}--stack {
		#{$parent-class}__right,
		#{$parent-class}__left {
			//allows content to go onto multiple lines (min screen size - min padding on either side of small screens):
			min-width: calc($sf-min-screen-size - calc(2 * $pad--small));
		}
	}

	@include sticky-lateral-content($parent-class);

	/** output 30-70, 40-60, 50-50, 60-40, 70-30 left/right col widths (%)
	E.g:
	&.sf-nested-cols--30-70 {
	 	.sf-nested-cols__left {
	 		width: 30%;
	  }
	   .sf-nested-cols__right {
	    width: 70%;
	   }
	} */
	@each $w in $widths {
		$l: $w;
		$r: calc(100 - $w);
		&#{$parent-class}--#{$l}-#{$r} {
			.sf-nested-cols__left {
				width: calc($l * 1%);
			}
			.sf-nested-cols__right {
				width: calc($r * 1%);
			}
		}
	}

	@content;
}

@mixin sticky-lateral-content($parent-class: ".sf-lateral-layout") {
	@each $side in (left, right) {
		&#{$parent-class}--stick,
		&#{$parent-class}--stick-#{$side} {
			#{$parent-class}__#{$side} {
				position: sticky;
				top: $pad--min;
				&--adjust-top-to-marg {
					top: calc($block-spacing-v--md - $pad--min) * -1;
				}
			}
		}
		&#{$parent-class}--stick-#{$side} {
			#{$parent-class}__#{$side} {
				max-height: calc(100vh - calc($pad--min * 2));
				overflow-y: auto;
				overflow-x: hidden;
			}
		}
	}
}
