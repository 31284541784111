//@import "../config/config";

///*=====================================================================</editor-fold>=
// STEPS
///=====================================================================<editor-fold>=*/

.stepsholder .payment_method {
	width: 100%;
	display: block;
	padding: 0 50px 60px;
}

@media (max-width: 500px) {
	.stepsholder .payment_method {
		padding-right: 30px;
		padding-left: 30px;
	}
}

.stepsholder .payment_method input {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.stepsholder .payment_method input:checked + label:before {
	content: "\f05d";
	font-family: fontAwesome;
}

.stepsholder .payment_method label {
	float: left;
	cursor: pointer;
	position: relative;
	padding-left: 60px;
	margin-bottom: 20px;
}

.stepsholder .payment_method label:before {
	content: "\f10c";
	font-family: fontAwesome;
	color: #c2c2c2;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.stepsholder .payment_method-right {
	float: right;
	max-width: 510px;
	width: 100%;
	width: calc(100% - 226px);
}

.stepsholder .payment_method-right span {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--med;
	margin-bottom: 10px;
	display: block;
}

.stepsholder .payment_method-right p {
	font-size: 14px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 22px;
}

.stepsholder.billing-info {
	* {
		word-break: break-word;
	}
}

@media (max-width: 600px) {
	.stepsholder .payment_method-right {
		float: none;
		max-width: none;
		width: 100%;
	}

	.stepsholder .payment_method label {
		float: none;
	}
}

.stepsholder.billing-info {
	padding: 0 50px 30px;
}

@media (max-width: 500px) {
	.stepsholder.billing-info {
		padding-right: 30px;
		padding-left: 30px;
	}
}

.stepsholder .billing-input {
	margin-bottom: 30px;
	float: left;
}

.stepsholder .billing-input:nth-child(-n + 4) {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
}

.stepsholder .billing-input:nth-child(-n + 4):before {
	content: "";
	display: table;
}

.stepsholder .billing-input:nth-child(-n + 4):after {
	content: "";
	display: table;
	clear: both;
}

.stepsholder .billing-input:nth-child(-n + 4):nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.stepsholder .billing-input:nth-child(-n + 4):nth-child(2n + 1) {
	clear: both;
}

.stepsholder .billing-input:nth-child(n + 8) {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
}

.stepsholder .billing-input:nth-child(n + 8):before {
	content: "";
	display: table;
}

.stepsholder .billing-input:nth-child(n + 8):after {
	content: "";
	display: table;
	clear: both;
}

.stepsholder .billing-input:nth-child(n + 8):nth-child(2n) {
	float: left;
	margin-right: 3%;
	clear: both;
}

.stepsholder .billing-input:nth-child(n + 8):nth-child(2n + 1) {
	float: right;
	margin-right: 0%;
	clear: none;
}

.stepsholder .billing-input.wpcophone,
.stepsholder .billing-input.wpcoaddress,
.stepsholder .billing-input.wpcoaddress2 {
	width: 100%;
}

.stepsholder .billing-input > label {
	font-size: 14px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 34px;
	position: relative;
	display: inline-block;
}

.stepsholder .billing-input > label .error {
	position: absolute;
	right: -10px;
	top: -10px;
}

.stepsholder .billing-input input,
.stepsholder .billing-input select {
	font-size: 18px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	display: inline-block;
	float: left;
	width: 100%;
	padding: 13px 15px;
	border: 1px solid #e1e1e1;
}

.stepsholder .billing-input input#pass1-text,
.stepsholder .billing-input select#pass1-text {
	display: none;
}

.stepsholder .billing-input input[disabled="disabled"],
.stepsholder .billing-input select[disabled="disabled"] {
	background: #f7f7f7;
	font-style: italic;
}

.stepsholder .billing-input select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	border-radius: 0;
}

.stepsholder .billing-input.country-input {
	position: relative;
}

.stepsholder .billing-input.country-input:after {
	content: "\f078";
	font-family: fontAwesome;
	display: block;
	position: absolute;
	top: 16px;
	right: 20px;
	color: #8a8a8a;
}

.stepsholder .billing-input .description {
	display: none;
}

.stepsholder .billing-input.password-strength {
	max-width: 380px;
	float: right;
	width: 100%;
}

.stepsholder .billing-input.password-strength > label {
	font-size: 14px;
	float: left;
}

.stepsholder .billing-input.password-strength .description {
	display: block;
	clear: both;
	font-size: 13px;
	line-height: 19px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.stepsholder .billing-input.password-strength .pw-bar {
	width: 100%;
	display: block;
	max-width: 245px;
	float: right;
	font-size: 12px;
	background: #f8f8f8;
	margin-top: 13px;
	text-align: center;
}

.stepsholder .billing-input.password-strength .pw-bar > div {
	font-size: 12px;
	display: block;
	width: 100%;
	text-align: center;
	position: relative;
	padding: 5px 0;
}

.stepsholder .billing-input.password-strength .pw-bar > div p {
	opacity: 0;
	visibility: hidden;
}

.stepsholder .billing-input.password-strength .pw-bar > div:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background: #ccc;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	z-index: 0;
}

.stepsholder .billing-input.password-strength .pw-bar > div.short:before {
	width: 25%;
}

.stepsholder .billing-input.password-strength .pw-bar > div.bad:before {
	width: 50%;
}

.stepsholder .billing-input.password-strength .pw-bar > div.good:before {
	width: 75%;
}

.stepsholder .billing-input.password-strength .pw-bar > div.strong:before {
	width: 100%;
}

@media (max-width: 600px) {
	.stepsholder .billing-input:nth-child(n) {
		float: none;
		width: 100%;
	}
}
//</editor-fold>
