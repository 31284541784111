/*
* Remix Icon v4.2.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2024-02-25
*/

// SF NOTE: when updating, ensure the path is updated to the correct location of the font files (../fonts/remixicon/)
@font-face {
	font-family: "remixicon";
	src: url("../fonts/remixicon/remixicon.eot?t=1708865856766"); /* IE9*/
	src: url("../fonts/remixicon/remixicon.eot?t=1708865856766#iefix") format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/remixicon/remixicon.woff2?t=1708865856766") format("woff2"),
		url("../fonts/remixicon/remixicon.woff?t=1708865856766") format("woff"),
		url("../fonts/remixicon/remixicon.ttf?t=1708865856766") format("truetype"),
		/* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
			url("../fonts/remixicon/remixicon.svg?t=1708865856766#remixicon") format("svg"); /* iOS 4.1- */
}

[class^="ri-"],
[class*=" ri-"] {
	font-family: "remixicon" !important;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ri-24-hours-fill:before {
	content: "\ea01";
}
.ri-24-hours-line:before {
	content: "\ea02";
}
.ri-4k-fill:before {
	content: "\ea03";
}
.ri-4k-line:before {
	content: "\ea04";
}
.ri-a-b:before {
	content: "\ea05";
}
.ri-account-box-fill:before {
	content: "\ea06";
}
.ri-account-box-line:before {
	content: "\ea07";
}
.ri-account-circle-fill:before {
	content: "\ea08";
}
.ri-account-circle-line:before {
	content: "\ea09";
}
.ri-account-pin-box-fill:before {
	content: "\ea0a";
}
.ri-account-pin-box-line:before {
	content: "\ea0b";
}
.ri-account-pin-circle-fill:before {
	content: "\ea0c";
}
.ri-account-pin-circle-line:before {
	content: "\ea0d";
}
.ri-add-box-fill:before {
	content: "\ea0e";
}
.ri-add-box-line:before {
	content: "\ea0f";
}
.ri-add-circle-fill:before {
	content: "\ea10";
}
.ri-add-circle-line:before {
	content: "\ea11";
}
.ri-add-fill:before {
	content: "\ea12";
}
.ri-add-line:before {
	content: "\ea13";
}
.ri-admin-fill:before {
	content: "\ea14";
}
.ri-admin-line:before {
	content: "\ea15";
}
.ri-advertisement-fill:before {
	content: "\ea16";
}
.ri-advertisement-line:before {
	content: "\ea17";
}
.ri-airplay-fill:before {
	content: "\ea18";
}
.ri-airplay-line:before {
	content: "\ea19";
}
.ri-alarm-fill:before {
	content: "\ea1a";
}
.ri-alarm-line:before {
	content: "\ea1b";
}
.ri-alarm-warning-fill:before {
	content: "\ea1c";
}
.ri-alarm-warning-line:before {
	content: "\ea1d";
}
.ri-album-fill:before {
	content: "\ea1e";
}
.ri-album-line:before {
	content: "\ea1f";
}
.ri-alert-fill:before {
	content: "\ea20";
}
.ri-alert-line:before {
	content: "\ea21";
}
.ri-aliens-fill:before {
	content: "\ea22";
}
.ri-aliens-line:before {
	content: "\ea23";
}
.ri-align-bottom:before {
	content: "\ea24";
}
.ri-align-center:before {
	content: "\ea25";
}
.ri-align-justify:before {
	content: "\ea26";
}
.ri-align-left:before {
	content: "\ea27";
}
.ri-align-right:before {
	content: "\ea28";
}
.ri-align-top:before {
	content: "\ea29";
}
.ri-align-vertically:before {
	content: "\ea2a";
}
.ri-alipay-fill:before {
	content: "\ea2b";
}
.ri-alipay-line:before {
	content: "\ea2c";
}
.ri-amazon-fill:before {
	content: "\ea2d";
}
.ri-amazon-line:before {
	content: "\ea2e";
}
.ri-anchor-fill:before {
	content: "\ea2f";
}
.ri-anchor-line:before {
	content: "\ea30";
}
.ri-ancient-gate-fill:before {
	content: "\ea31";
}
.ri-ancient-gate-line:before {
	content: "\ea32";
}
.ri-ancient-pavilion-fill:before {
	content: "\ea33";
}
.ri-ancient-pavilion-line:before {
	content: "\ea34";
}
.ri-android-fill:before {
	content: "\ea35";
}
.ri-android-line:before {
	content: "\ea36";
}
.ri-angularjs-fill:before {
	content: "\ea37";
}
.ri-angularjs-line:before {
	content: "\ea38";
}
.ri-anticlockwise-2-fill:before {
	content: "\ea39";
}
.ri-anticlockwise-2-line:before {
	content: "\ea3a";
}
.ri-anticlockwise-fill:before {
	content: "\ea3b";
}
.ri-anticlockwise-line:before {
	content: "\ea3c";
}
.ri-app-store-fill:before {
	content: "\ea3d";
}
.ri-app-store-line:before {
	content: "\ea3e";
}
.ri-apple-fill:before {
	content: "\ea3f";
}
.ri-apple-line:before {
	content: "\ea40";
}
.ri-apps-2-fill:before {
	content: "\ea41";
}
.ri-apps-2-line:before {
	content: "\ea42";
}
.ri-apps-fill:before {
	content: "\ea43";
}
.ri-apps-line:before {
	content: "\ea44";
}
.ri-archive-drawer-fill:before {
	content: "\ea45";
}
.ri-archive-drawer-line:before {
	content: "\ea46";
}
.ri-archive-fill:before {
	content: "\ea47";
}
.ri-archive-line:before {
	content: "\ea48";
}
.ri-arrow-down-circle-fill:before {
	content: "\ea49";
}
.ri-arrow-down-circle-line:before {
	content: "\ea4a";
}
.ri-arrow-down-fill:before {
	content: "\ea4b";
}
.ri-arrow-down-line:before {
	content: "\ea4c";
}
.ri-arrow-down-s-fill:before {
	content: "\ea4d";
}
.ri-arrow-down-s-line:before {
	content: "\ea4e";
}
.ri-arrow-drop-down-fill:before {
	content: "\ea4f";
}
.ri-arrow-drop-down-line:before {
	content: "\ea50";
}
.ri-arrow-drop-left-fill:before {
	content: "\ea51";
}
.ri-arrow-drop-left-line:before {
	content: "\ea52";
}
.ri-arrow-drop-right-fill:before {
	content: "\ea53";
}
.ri-arrow-drop-right-line:before {
	content: "\ea54";
}
.ri-arrow-drop-up-fill:before {
	content: "\ea55";
}
.ri-arrow-drop-up-line:before {
	content: "\ea56";
}
.ri-arrow-go-back-fill:before {
	content: "\ea57";
}
.ri-arrow-go-back-line:before {
	content: "\ea58";
}
.ri-arrow-go-forward-fill:before {
	content: "\ea59";
}
.ri-arrow-go-forward-line:before {
	content: "\ea5a";
}
.ri-arrow-left-circle-fill:before {
	content: "\ea5b";
}
.ri-arrow-left-circle-line:before {
	content: "\ea5c";
}
.ri-arrow-left-down-fill:before {
	content: "\ea5d";
}
.ri-arrow-left-down-line:before {
	content: "\ea5e";
}
.ri-arrow-left-fill:before {
	content: "\ea5f";
}
.ri-arrow-left-line:before {
	content: "\ea60";
}
.ri-arrow-left-right-fill:before {
	content: "\ea61";
}
.ri-arrow-left-right-line:before {
	content: "\ea62";
}
.ri-arrow-left-s-fill:before {
	content: "\ea63";
}
.ri-arrow-left-s-line:before {
	content: "\ea64";
}
.ri-arrow-left-up-fill:before {
	content: "\ea65";
}
.ri-arrow-left-up-line:before {
	content: "\ea66";
}
.ri-arrow-right-circle-fill:before {
	content: "\ea67";
}
.ri-arrow-right-circle-line:before {
	content: "\ea68";
}
.ri-arrow-right-down-fill:before {
	content: "\ea69";
}
.ri-arrow-right-down-line:before {
	content: "\ea6a";
}
.ri-arrow-right-fill:before {
	content: "\ea6b";
}
.ri-arrow-right-line:before {
	content: "\ea6c";
}
.ri-arrow-right-s-fill:before {
	content: "\ea6d";
}
.ri-arrow-right-s-line:before {
	content: "\ea6e";
}
.ri-arrow-right-up-fill:before {
	content: "\ea6f";
}
.ri-arrow-right-up-line:before {
	content: "\ea70";
}
.ri-arrow-up-circle-fill:before {
	content: "\ea71";
}
.ri-arrow-up-circle-line:before {
	content: "\ea72";
}
.ri-arrow-up-down-fill:before {
	content: "\ea73";
}
.ri-arrow-up-down-line:before {
	content: "\ea74";
}
.ri-arrow-up-fill:before {
	content: "\ea75";
}
.ri-arrow-up-line:before {
	content: "\ea76";
}
.ri-arrow-up-s-fill:before {
	content: "\ea77";
}
.ri-arrow-up-s-line:before {
	content: "\ea78";
}
.ri-artboard-2-fill:before {
	content: "\ea79";
}
.ri-artboard-2-line:before {
	content: "\ea7a";
}
.ri-artboard-fill:before {
	content: "\ea7b";
}
.ri-artboard-line:before {
	content: "\ea7c";
}
.ri-article-fill:before {
	content: "\ea7d";
}
.ri-article-line:before {
	content: "\ea7e";
}
.ri-aspect-ratio-fill:before {
	content: "\ea7f";
}
.ri-aspect-ratio-line:before {
	content: "\ea80";
}
.ri-asterisk:before {
	content: "\ea81";
}
.ri-at-fill:before {
	content: "\ea82";
}
.ri-at-line:before {
	content: "\ea83";
}
.ri-attachment-2:before {
	content: "\ea84";
}
.ri-attachment-fill:before {
	content: "\ea85";
}
.ri-attachment-line:before {
	content: "\ea86";
}
.ri-auction-fill:before {
	content: "\ea87";
}
.ri-auction-line:before {
	content: "\ea88";
}
.ri-award-fill:before {
	content: "\ea89";
}
.ri-award-line:before {
	content: "\ea8a";
}
.ri-baidu-fill:before {
	content: "\ea8b";
}
.ri-baidu-line:before {
	content: "\ea8c";
}
.ri-ball-pen-fill:before {
	content: "\ea8d";
}
.ri-ball-pen-line:before {
	content: "\ea8e";
}
.ri-bank-card-2-fill:before {
	content: "\ea8f";
}
.ri-bank-card-2-line:before {
	content: "\ea90";
}
.ri-bank-card-fill:before {
	content: "\ea91";
}
.ri-bank-card-line:before {
	content: "\ea92";
}
.ri-bank-fill:before {
	content: "\ea93";
}
.ri-bank-line:before {
	content: "\ea94";
}
.ri-bar-chart-2-fill:before {
	content: "\ea95";
}
.ri-bar-chart-2-line:before {
	content: "\ea96";
}
.ri-bar-chart-box-fill:before {
	content: "\ea97";
}
.ri-bar-chart-box-line:before {
	content: "\ea98";
}
.ri-bar-chart-fill:before {
	content: "\ea99";
}
.ri-bar-chart-grouped-fill:before {
	content: "\ea9a";
}
.ri-bar-chart-grouped-line:before {
	content: "\ea9b";
}
.ri-bar-chart-horizontal-fill:before {
	content: "\ea9c";
}
.ri-bar-chart-horizontal-line:before {
	content: "\ea9d";
}
.ri-bar-chart-line:before {
	content: "\ea9e";
}
.ri-barcode-box-fill:before {
	content: "\ea9f";
}
.ri-barcode-box-line:before {
	content: "\eaa0";
}
.ri-barcode-fill:before {
	content: "\eaa1";
}
.ri-barcode-line:before {
	content: "\eaa2";
}
.ri-barricade-fill:before {
	content: "\eaa3";
}
.ri-barricade-line:before {
	content: "\eaa4";
}
.ri-base-station-fill:before {
	content: "\eaa5";
}
.ri-base-station-line:before {
	content: "\eaa6";
}
.ri-basketball-fill:before {
	content: "\eaa7";
}
.ri-basketball-line:before {
	content: "\eaa8";
}
.ri-battery-2-charge-fill:before {
	content: "\eaa9";
}
.ri-battery-2-charge-line:before {
	content: "\eaaa";
}
.ri-battery-2-fill:before {
	content: "\eaab";
}
.ri-battery-2-line:before {
	content: "\eaac";
}
.ri-battery-charge-fill:before {
	content: "\eaad";
}
.ri-battery-charge-line:before {
	content: "\eaae";
}
.ri-battery-fill:before {
	content: "\eaaf";
}
.ri-battery-line:before {
	content: "\eab0";
}
.ri-battery-low-fill:before {
	content: "\eab1";
}
.ri-battery-low-line:before {
	content: "\eab2";
}
.ri-battery-saver-fill:before {
	content: "\eab3";
}
.ri-battery-saver-line:before {
	content: "\eab4";
}
.ri-battery-share-fill:before {
	content: "\eab5";
}
.ri-battery-share-line:before {
	content: "\eab6";
}
.ri-bear-smile-fill:before {
	content: "\eab7";
}
.ri-bear-smile-line:before {
	content: "\eab8";
}
.ri-behance-fill:before {
	content: "\eab9";
}
.ri-behance-line:before {
	content: "\eaba";
}
.ri-bell-fill:before {
	content: "\eabb";
}
.ri-bell-line:before {
	content: "\eabc";
}
.ri-bike-fill:before {
	content: "\eabd";
}
.ri-bike-line:before {
	content: "\eabe";
}
.ri-bilibili-fill:before {
	content: "\eabf";
}
.ri-bilibili-line:before {
	content: "\eac0";
}
.ri-bill-fill:before {
	content: "\eac1";
}
.ri-bill-line:before {
	content: "\eac2";
}
.ri-billiards-fill:before {
	content: "\eac3";
}
.ri-billiards-line:before {
	content: "\eac4";
}
.ri-bit-coin-fill:before {
	content: "\eac5";
}
.ri-bit-coin-line:before {
	content: "\eac6";
}
.ri-blaze-fill:before {
	content: "\eac7";
}
.ri-blaze-line:before {
	content: "\eac8";
}
.ri-bluetooth-connect-fill:before {
	content: "\eac9";
}
.ri-bluetooth-connect-line:before {
	content: "\eaca";
}
.ri-bluetooth-fill:before {
	content: "\eacb";
}
.ri-bluetooth-line:before {
	content: "\eacc";
}
.ri-blur-off-fill:before {
	content: "\eacd";
}
.ri-blur-off-line:before {
	content: "\eace";
}
.ri-body-scan-fill:before {
	content: "\eacf";
}
.ri-body-scan-line:before {
	content: "\ead0";
}
.ri-bold:before {
	content: "\ead1";
}
.ri-book-2-fill:before {
	content: "\ead2";
}
.ri-book-2-line:before {
	content: "\ead3";
}
.ri-book-3-fill:before {
	content: "\ead4";
}
.ri-book-3-line:before {
	content: "\ead5";
}
.ri-book-fill:before {
	content: "\ead6";
}
.ri-book-line:before {
	content: "\ead7";
}
.ri-book-marked-fill:before {
	content: "\ead8";
}
.ri-book-marked-line:before {
	content: "\ead9";
}
.ri-book-open-fill:before {
	content: "\eada";
}
.ri-book-open-line:before {
	content: "\eadb";
}
.ri-book-read-fill:before {
	content: "\eadc";
}
.ri-book-read-line:before {
	content: "\eadd";
}
.ri-booklet-fill:before {
	content: "\eade";
}
.ri-booklet-line:before {
	content: "\eadf";
}
.ri-bookmark-2-fill:before {
	content: "\eae0";
}
.ri-bookmark-2-line:before {
	content: "\eae1";
}
.ri-bookmark-3-fill:before {
	content: "\eae2";
}
.ri-bookmark-3-line:before {
	content: "\eae3";
}
.ri-bookmark-fill:before {
	content: "\eae4";
}
.ri-bookmark-line:before {
	content: "\eae5";
}
.ri-boxing-fill:before {
	content: "\eae6";
}
.ri-boxing-line:before {
	content: "\eae7";
}
.ri-braces-fill:before {
	content: "\eae8";
}
.ri-braces-line:before {
	content: "\eae9";
}
.ri-brackets-fill:before {
	content: "\eaea";
}
.ri-brackets-line:before {
	content: "\eaeb";
}
.ri-briefcase-2-fill:before {
	content: "\eaec";
}
.ri-briefcase-2-line:before {
	content: "\eaed";
}
.ri-briefcase-3-fill:before {
	content: "\eaee";
}
.ri-briefcase-3-line:before {
	content: "\eaef";
}
.ri-briefcase-4-fill:before {
	content: "\eaf0";
}
.ri-briefcase-4-line:before {
	content: "\eaf1";
}
.ri-briefcase-5-fill:before {
	content: "\eaf2";
}
.ri-briefcase-5-line:before {
	content: "\eaf3";
}
.ri-briefcase-fill:before {
	content: "\eaf4";
}
.ri-briefcase-line:before {
	content: "\eaf5";
}
.ri-bring-forward:before {
	content: "\eaf6";
}
.ri-bring-to-front:before {
	content: "\eaf7";
}
.ri-broadcast-fill:before {
	content: "\eaf8";
}
.ri-broadcast-line:before {
	content: "\eaf9";
}
.ri-brush-2-fill:before {
	content: "\eafa";
}
.ri-brush-2-line:before {
	content: "\eafb";
}
.ri-brush-3-fill:before {
	content: "\eafc";
}
.ri-brush-3-line:before {
	content: "\eafd";
}
.ri-brush-4-fill:before {
	content: "\eafe";
}
.ri-brush-4-line:before {
	content: "\eaff";
}
.ri-brush-fill:before {
	content: "\eb00";
}
.ri-brush-line:before {
	content: "\eb01";
}
.ri-bubble-chart-fill:before {
	content: "\eb02";
}
.ri-bubble-chart-line:before {
	content: "\eb03";
}
.ri-bug-2-fill:before {
	content: "\eb04";
}
.ri-bug-2-line:before {
	content: "\eb05";
}
.ri-bug-fill:before {
	content: "\eb06";
}
.ri-bug-line:before {
	content: "\eb07";
}
.ri-building-2-fill:before {
	content: "\eb08";
}
.ri-building-2-line:before {
	content: "\eb09";
}
.ri-building-3-fill:before {
	content: "\eb0a";
}
.ri-building-3-line:before {
	content: "\eb0b";
}
.ri-building-4-fill:before {
	content: "\eb0c";
}
.ri-building-4-line:before {
	content: "\eb0d";
}
.ri-building-fill:before {
	content: "\eb0e";
}
.ri-building-line:before {
	content: "\eb0f";
}
.ri-bus-2-fill:before {
	content: "\eb10";
}
.ri-bus-2-line:before {
	content: "\eb11";
}
.ri-bus-fill:before {
	content: "\eb12";
}
.ri-bus-line:before {
	content: "\eb13";
}
.ri-bus-wifi-fill:before {
	content: "\eb14";
}
.ri-bus-wifi-line:before {
	content: "\eb15";
}
.ri-cactus-fill:before {
	content: "\eb16";
}
.ri-cactus-line:before {
	content: "\eb17";
}
.ri-cake-2-fill:before {
	content: "\eb18";
}
.ri-cake-2-line:before {
	content: "\eb19";
}
.ri-cake-3-fill:before {
	content: "\eb1a";
}
.ri-cake-3-line:before {
	content: "\eb1b";
}
.ri-cake-fill:before {
	content: "\eb1c";
}
.ri-cake-line:before {
	content: "\eb1d";
}
.ri-calculator-fill:before {
	content: "\eb1e";
}
.ri-calculator-line:before {
	content: "\eb1f";
}
.ri-calendar-2-fill:before {
	content: "\eb20";
}
.ri-calendar-2-line:before {
	content: "\eb21";
}
.ri-calendar-check-fill:before {
	content: "\eb22";
}
.ri-calendar-check-line:before {
	content: "\eb23";
}
.ri-calendar-event-fill:before {
	content: "\eb24";
}
.ri-calendar-event-line:before {
	content: "\eb25";
}
.ri-calendar-fill:before {
	content: "\eb26";
}
.ri-calendar-line:before {
	content: "\eb27";
}
.ri-calendar-todo-fill:before {
	content: "\eb28";
}
.ri-calendar-todo-line:before {
	content: "\eb29";
}
.ri-camera-2-fill:before {
	content: "\eb2a";
}
.ri-camera-2-line:before {
	content: "\eb2b";
}
.ri-camera-3-fill:before {
	content: "\eb2c";
}
.ri-camera-3-line:before {
	content: "\eb2d";
}
.ri-camera-fill:before {
	content: "\eb2e";
}
.ri-camera-lens-fill:before {
	content: "\eb2f";
}
.ri-camera-lens-line:before {
	content: "\eb30";
}
.ri-camera-line:before {
	content: "\eb31";
}
.ri-camera-off-fill:before {
	content: "\eb32";
}
.ri-camera-off-line:before {
	content: "\eb33";
}
.ri-camera-switch-fill:before {
	content: "\eb34";
}
.ri-camera-switch-line:before {
	content: "\eb35";
}
.ri-capsule-fill:before {
	content: "\eb36";
}
.ri-capsule-line:before {
	content: "\eb37";
}
.ri-car-fill:before {
	content: "\eb38";
}
.ri-car-line:before {
	content: "\eb39";
}
.ri-car-washing-fill:before {
	content: "\eb3a";
}
.ri-car-washing-line:before {
	content: "\eb3b";
}
.ri-caravan-fill:before {
	content: "\eb3c";
}
.ri-caravan-line:before {
	content: "\eb3d";
}
.ri-cast-fill:before {
	content: "\eb3e";
}
.ri-cast-line:before {
	content: "\eb3f";
}
.ri-cellphone-fill:before {
	content: "\eb40";
}
.ri-cellphone-line:before {
	content: "\eb41";
}
.ri-celsius-fill:before {
	content: "\eb42";
}
.ri-celsius-line:before {
	content: "\eb43";
}
.ri-centos-fill:before {
	content: "\eb44";
}
.ri-centos-line:before {
	content: "\eb45";
}
.ri-character-recognition-fill:before {
	content: "\eb46";
}
.ri-character-recognition-line:before {
	content: "\eb47";
}
.ri-charging-pile-2-fill:before {
	content: "\eb48";
}
.ri-charging-pile-2-line:before {
	content: "\eb49";
}
.ri-charging-pile-fill:before {
	content: "\eb4a";
}
.ri-charging-pile-line:before {
	content: "\eb4b";
}
.ri-chat-1-fill:before {
	content: "\eb4c";
}
.ri-chat-1-line:before {
	content: "\eb4d";
}
.ri-chat-2-fill:before {
	content: "\eb4e";
}
.ri-chat-2-line:before {
	content: "\eb4f";
}
.ri-chat-3-fill:before {
	content: "\eb50";
}
.ri-chat-3-line:before {
	content: "\eb51";
}
.ri-chat-4-fill:before {
	content: "\eb52";
}
.ri-chat-4-line:before {
	content: "\eb53";
}
.ri-chat-check-fill:before {
	content: "\eb54";
}
.ri-chat-check-line:before {
	content: "\eb55";
}
.ri-chat-delete-fill:before {
	content: "\eb56";
}
.ri-chat-delete-line:before {
	content: "\eb57";
}
.ri-chat-download-fill:before {
	content: "\eb58";
}
.ri-chat-download-line:before {
	content: "\eb59";
}
.ri-chat-follow-up-fill:before {
	content: "\eb5a";
}
.ri-chat-follow-up-line:before {
	content: "\eb5b";
}
.ri-chat-forward-fill:before {
	content: "\eb5c";
}
.ri-chat-forward-line:before {
	content: "\eb5d";
}
.ri-chat-heart-fill:before {
	content: "\eb5e";
}
.ri-chat-heart-line:before {
	content: "\eb5f";
}
.ri-chat-history-fill:before {
	content: "\eb60";
}
.ri-chat-history-line:before {
	content: "\eb61";
}
.ri-chat-new-fill:before {
	content: "\eb62";
}
.ri-chat-new-line:before {
	content: "\eb63";
}
.ri-chat-off-fill:before {
	content: "\eb64";
}
.ri-chat-off-line:before {
	content: "\eb65";
}
.ri-chat-poll-fill:before {
	content: "\eb66";
}
.ri-chat-poll-line:before {
	content: "\eb67";
}
.ri-chat-private-fill:before {
	content: "\eb68";
}
.ri-chat-private-line:before {
	content: "\eb69";
}
.ri-chat-quote-fill:before {
	content: "\eb6a";
}
.ri-chat-quote-line:before {
	content: "\eb6b";
}
.ri-chat-settings-fill:before {
	content: "\eb6c";
}
.ri-chat-settings-line:before {
	content: "\eb6d";
}
.ri-chat-smile-2-fill:before {
	content: "\eb6e";
}
.ri-chat-smile-2-line:before {
	content: "\eb6f";
}
.ri-chat-smile-3-fill:before {
	content: "\eb70";
}
.ri-chat-smile-3-line:before {
	content: "\eb71";
}
.ri-chat-smile-fill:before {
	content: "\eb72";
}
.ri-chat-smile-line:before {
	content: "\eb73";
}
.ri-chat-upload-fill:before {
	content: "\eb74";
}
.ri-chat-upload-line:before {
	content: "\eb75";
}
.ri-chat-voice-fill:before {
	content: "\eb76";
}
.ri-chat-voice-line:before {
	content: "\eb77";
}
.ri-check-double-fill:before {
	content: "\eb78";
}
.ri-check-double-line:before {
	content: "\eb79";
}
.ri-check-fill:before {
	content: "\eb7a";
}
.ri-check-line:before {
	content: "\eb7b";
}
.ri-checkbox-blank-circle-fill:before {
	content: "\eb7c";
}
.ri-checkbox-blank-circle-line:before {
	content: "\eb7d";
}
.ri-checkbox-blank-fill:before {
	content: "\eb7e";
}
.ri-checkbox-blank-line:before {
	content: "\eb7f";
}
.ri-checkbox-circle-fill:before {
	content: "\eb80";
}
.ri-checkbox-circle-line:before {
	content: "\eb81";
}
.ri-checkbox-fill:before {
	content: "\eb82";
}
.ri-checkbox-indeterminate-fill:before {
	content: "\eb83";
}
.ri-checkbox-indeterminate-line:before {
	content: "\eb84";
}
.ri-checkbox-line:before {
	content: "\eb85";
}
.ri-checkbox-multiple-blank-fill:before {
	content: "\eb86";
}
.ri-checkbox-multiple-blank-line:before {
	content: "\eb87";
}
.ri-checkbox-multiple-fill:before {
	content: "\eb88";
}
.ri-checkbox-multiple-line:before {
	content: "\eb89";
}
.ri-china-railway-fill:before {
	content: "\eb8a";
}
.ri-china-railway-line:before {
	content: "\eb8b";
}
.ri-chrome-fill:before {
	content: "\eb8c";
}
.ri-chrome-line:before {
	content: "\eb8d";
}
.ri-clapperboard-fill:before {
	content: "\eb8e";
}
.ri-clapperboard-line:before {
	content: "\eb8f";
}
.ri-clipboard-fill:before {
	content: "\eb90";
}
.ri-clipboard-line:before {
	content: "\eb91";
}
.ri-clockwise-2-fill:before {
	content: "\eb92";
}
.ri-clockwise-2-line:before {
	content: "\eb93";
}
.ri-clockwise-fill:before {
	content: "\eb94";
}
.ri-clockwise-line:before {
	content: "\eb95";
}
.ri-close-circle-fill:before {
	content: "\eb96";
}
.ri-close-circle-line:before {
	content: "\eb97";
}
.ri-close-fill:before {
	content: "\eb98";
}
.ri-close-line:before {
	content: "\eb99";
}
.ri-closed-captioning-fill:before {
	content: "\eb9a";
}
.ri-closed-captioning-line:before {
	content: "\eb9b";
}
.ri-cloud-fill:before {
	content: "\eb9c";
}
.ri-cloud-line:before {
	content: "\eb9d";
}
.ri-cloud-off-fill:before {
	content: "\eb9e";
}
.ri-cloud-off-line:before {
	content: "\eb9f";
}
.ri-cloud-windy-fill:before {
	content: "\eba0";
}
.ri-cloud-windy-line:before {
	content: "\eba1";
}
.ri-cloudy-2-fill:before {
	content: "\eba2";
}
.ri-cloudy-2-line:before {
	content: "\eba3";
}
.ri-cloudy-fill:before {
	content: "\eba4";
}
.ri-cloudy-line:before {
	content: "\eba5";
}
.ri-code-box-fill:before {
	content: "\eba6";
}
.ri-code-box-line:before {
	content: "\eba7";
}
.ri-code-fill:before {
	content: "\eba8";
}
.ri-code-line:before {
	content: "\eba9";
}
.ri-code-s-fill:before {
	content: "\ebaa";
}
.ri-code-s-line:before {
	content: "\ebab";
}
.ri-code-s-slash-fill:before {
	content: "\ebac";
}
.ri-code-s-slash-line:before {
	content: "\ebad";
}
.ri-code-view:before {
	content: "\ebae";
}
.ri-codepen-fill:before {
	content: "\ebaf";
}
.ri-codepen-line:before {
	content: "\ebb0";
}
.ri-coin-fill:before {
	content: "\ebb1";
}
.ri-coin-line:before {
	content: "\ebb2";
}
.ri-coins-fill:before {
	content: "\ebb3";
}
.ri-coins-line:before {
	content: "\ebb4";
}
.ri-collage-fill:before {
	content: "\ebb5";
}
.ri-collage-line:before {
	content: "\ebb6";
}
.ri-command-fill:before {
	content: "\ebb7";
}
.ri-command-line:before {
	content: "\ebb8";
}
.ri-community-fill:before {
	content: "\ebb9";
}
.ri-community-line:before {
	content: "\ebba";
}
.ri-compass-2-fill:before {
	content: "\ebbb";
}
.ri-compass-2-line:before {
	content: "\ebbc";
}
.ri-compass-3-fill:before {
	content: "\ebbd";
}
.ri-compass-3-line:before {
	content: "\ebbe";
}
.ri-compass-4-fill:before {
	content: "\ebbf";
}
.ri-compass-4-line:before {
	content: "\ebc0";
}
.ri-compass-discover-fill:before {
	content: "\ebc1";
}
.ri-compass-discover-line:before {
	content: "\ebc2";
}
.ri-compass-fill:before {
	content: "\ebc3";
}
.ri-compass-line:before {
	content: "\ebc4";
}
.ri-compasses-2-fill:before {
	content: "\ebc5";
}
.ri-compasses-2-line:before {
	content: "\ebc6";
}
.ri-compasses-fill:before {
	content: "\ebc7";
}
.ri-compasses-line:before {
	content: "\ebc8";
}
.ri-computer-fill:before {
	content: "\ebc9";
}
.ri-computer-line:before {
	content: "\ebca";
}
.ri-contacts-book-2-fill:before {
	content: "\ebcb";
}
.ri-contacts-book-2-line:before {
	content: "\ebcc";
}
.ri-contacts-book-fill:before {
	content: "\ebcd";
}
.ri-contacts-book-line:before {
	content: "\ebce";
}
.ri-contacts-book-upload-fill:before {
	content: "\ebcf";
}
.ri-contacts-book-upload-line:before {
	content: "\ebd0";
}
.ri-contacts-fill:before {
	content: "\ebd1";
}
.ri-contacts-line:before {
	content: "\ebd2";
}
.ri-contrast-2-fill:before {
	content: "\ebd3";
}
.ri-contrast-2-line:before {
	content: "\ebd4";
}
.ri-contrast-drop-2-fill:before {
	content: "\ebd5";
}
.ri-contrast-drop-2-line:before {
	content: "\ebd6";
}
.ri-contrast-drop-fill:before {
	content: "\ebd7";
}
.ri-contrast-drop-line:before {
	content: "\ebd8";
}
.ri-contrast-fill:before {
	content: "\ebd9";
}
.ri-contrast-line:before {
	content: "\ebda";
}
.ri-copper-coin-fill:before {
	content: "\ebdb";
}
.ri-copper-coin-line:before {
	content: "\ebdc";
}
.ri-copper-diamond-fill:before {
	content: "\ebdd";
}
.ri-copper-diamond-line:before {
	content: "\ebde";
}
.ri-copyleft-fill:before {
	content: "\ebdf";
}
.ri-copyleft-line:before {
	content: "\ebe0";
}
.ri-copyright-fill:before {
	content: "\ebe1";
}
.ri-copyright-line:before {
	content: "\ebe2";
}
.ri-coreos-fill:before {
	content: "\ebe3";
}
.ri-coreos-line:before {
	content: "\ebe4";
}
.ri-coupon-2-fill:before {
	content: "\ebe5";
}
.ri-coupon-2-line:before {
	content: "\ebe6";
}
.ri-coupon-3-fill:before {
	content: "\ebe7";
}
.ri-coupon-3-line:before {
	content: "\ebe8";
}
.ri-coupon-4-fill:before {
	content: "\ebe9";
}
.ri-coupon-4-line:before {
	content: "\ebea";
}
.ri-coupon-5-fill:before {
	content: "\ebeb";
}
.ri-coupon-5-line:before {
	content: "\ebec";
}
.ri-coupon-fill:before {
	content: "\ebed";
}
.ri-coupon-line:before {
	content: "\ebee";
}
.ri-cpu-fill:before {
	content: "\ebef";
}
.ri-cpu-line:before {
	content: "\ebf0";
}
.ri-creative-commons-by-fill:before {
	content: "\ebf1";
}
.ri-creative-commons-by-line:before {
	content: "\ebf2";
}
.ri-creative-commons-fill:before {
	content: "\ebf3";
}
.ri-creative-commons-line:before {
	content: "\ebf4";
}
.ri-creative-commons-nc-fill:before {
	content: "\ebf5";
}
.ri-creative-commons-nc-line:before {
	content: "\ebf6";
}
.ri-creative-commons-nd-fill:before {
	content: "\ebf7";
}
.ri-creative-commons-nd-line:before {
	content: "\ebf8";
}
.ri-creative-commons-sa-fill:before {
	content: "\ebf9";
}
.ri-creative-commons-sa-line:before {
	content: "\ebfa";
}
.ri-creative-commons-zero-fill:before {
	content: "\ebfb";
}
.ri-creative-commons-zero-line:before {
	content: "\ebfc";
}
.ri-criminal-fill:before {
	content: "\ebfd";
}
.ri-criminal-line:before {
	content: "\ebfe";
}
.ri-crop-2-fill:before {
	content: "\ebff";
}
.ri-crop-2-line:before {
	content: "\ec00";
}
.ri-crop-fill:before {
	content: "\ec01";
}
.ri-crop-line:before {
	content: "\ec02";
}
.ri-css3-fill:before {
	content: "\ec03";
}
.ri-css3-line:before {
	content: "\ec04";
}
.ri-cup-fill:before {
	content: "\ec05";
}
.ri-cup-line:before {
	content: "\ec06";
}
.ri-currency-fill:before {
	content: "\ec07";
}
.ri-currency-line:before {
	content: "\ec08";
}
.ri-cursor-fill:before {
	content: "\ec09";
}
.ri-cursor-line:before {
	content: "\ec0a";
}
.ri-customer-service-2-fill:before {
	content: "\ec0b";
}
.ri-customer-service-2-line:before {
	content: "\ec0c";
}
.ri-customer-service-fill:before {
	content: "\ec0d";
}
.ri-customer-service-line:before {
	content: "\ec0e";
}
.ri-dashboard-2-fill:before {
	content: "\ec0f";
}
.ri-dashboard-2-line:before {
	content: "\ec10";
}
.ri-dashboard-3-fill:before {
	content: "\ec11";
}
.ri-dashboard-3-line:before {
	content: "\ec12";
}
.ri-dashboard-fill:before {
	content: "\ec13";
}
.ri-dashboard-line:before {
	content: "\ec14";
}
.ri-database-2-fill:before {
	content: "\ec15";
}
.ri-database-2-line:before {
	content: "\ec16";
}
.ri-database-fill:before {
	content: "\ec17";
}
.ri-database-line:before {
	content: "\ec18";
}
.ri-delete-back-2-fill:before {
	content: "\ec19";
}
.ri-delete-back-2-line:before {
	content: "\ec1a";
}
.ri-delete-back-fill:before {
	content: "\ec1b";
}
.ri-delete-back-line:before {
	content: "\ec1c";
}
.ri-delete-bin-2-fill:before {
	content: "\ec1d";
}
.ri-delete-bin-2-line:before {
	content: "\ec1e";
}
.ri-delete-bin-3-fill:before {
	content: "\ec1f";
}
.ri-delete-bin-3-line:before {
	content: "\ec20";
}
.ri-delete-bin-4-fill:before {
	content: "\ec21";
}
.ri-delete-bin-4-line:before {
	content: "\ec22";
}
.ri-delete-bin-5-fill:before {
	content: "\ec23";
}
.ri-delete-bin-5-line:before {
	content: "\ec24";
}
.ri-delete-bin-6-fill:before {
	content: "\ec25";
}
.ri-delete-bin-6-line:before {
	content: "\ec26";
}
.ri-delete-bin-7-fill:before {
	content: "\ec27";
}
.ri-delete-bin-7-line:before {
	content: "\ec28";
}
.ri-delete-bin-fill:before {
	content: "\ec29";
}
.ri-delete-bin-line:before {
	content: "\ec2a";
}
.ri-delete-column:before {
	content: "\ec2b";
}
.ri-delete-row:before {
	content: "\ec2c";
}
.ri-device-fill:before {
	content: "\ec2d";
}
.ri-device-line:before {
	content: "\ec2e";
}
.ri-device-recover-fill:before {
	content: "\ec2f";
}
.ri-device-recover-line:before {
	content: "\ec30";
}
.ri-dingding-fill:before {
	content: "\ec31";
}
.ri-dingding-line:before {
	content: "\ec32";
}
.ri-direction-fill:before {
	content: "\ec33";
}
.ri-direction-line:before {
	content: "\ec34";
}
.ri-disc-fill:before {
	content: "\ec35";
}
.ri-disc-line:before {
	content: "\ec36";
}
.ri-discord-fill:before {
	content: "\ec37";
}
.ri-discord-line:before {
	content: "\ec38";
}
.ri-discuss-fill:before {
	content: "\ec39";
}
.ri-discuss-line:before {
	content: "\ec3a";
}
.ri-dislike-fill:before {
	content: "\ec3b";
}
.ri-dislike-line:before {
	content: "\ec3c";
}
.ri-disqus-fill:before {
	content: "\ec3d";
}
.ri-disqus-line:before {
	content: "\ec3e";
}
.ri-divide-fill:before {
	content: "\ec3f";
}
.ri-divide-line:before {
	content: "\ec40";
}
.ri-donut-chart-fill:before {
	content: "\ec41";
}
.ri-donut-chart-line:before {
	content: "\ec42";
}
.ri-door-closed-fill:before {
	content: "\ec43";
}
.ri-door-closed-line:before {
	content: "\ec44";
}
.ri-door-fill:before {
	content: "\ec45";
}
.ri-door-line:before {
	content: "\ec46";
}
.ri-door-lock-box-fill:before {
	content: "\ec47";
}
.ri-door-lock-box-line:before {
	content: "\ec48";
}
.ri-door-lock-fill:before {
	content: "\ec49";
}
.ri-door-lock-line:before {
	content: "\ec4a";
}
.ri-door-open-fill:before {
	content: "\ec4b";
}
.ri-door-open-line:before {
	content: "\ec4c";
}
.ri-dossier-fill:before {
	content: "\ec4d";
}
.ri-dossier-line:before {
	content: "\ec4e";
}
.ri-douban-fill:before {
	content: "\ec4f";
}
.ri-douban-line:before {
	content: "\ec50";
}
.ri-double-quotes-l:before {
	content: "\ec51";
}
.ri-double-quotes-r:before {
	content: "\ec52";
}
.ri-download-2-fill:before {
	content: "\ec53";
}
.ri-download-2-line:before {
	content: "\ec54";
}
.ri-download-cloud-2-fill:before {
	content: "\ec55";
}
.ri-download-cloud-2-line:before {
	content: "\ec56";
}
.ri-download-cloud-fill:before {
	content: "\ec57";
}
.ri-download-cloud-line:before {
	content: "\ec58";
}
.ri-download-fill:before {
	content: "\ec59";
}
.ri-download-line:before {
	content: "\ec5a";
}
.ri-draft-fill:before {
	content: "\ec5b";
}
.ri-draft-line:before {
	content: "\ec5c";
}
.ri-drag-drop-fill:before {
	content: "\ec5d";
}
.ri-drag-drop-line:before {
	content: "\ec5e";
}
.ri-drag-move-2-fill:before {
	content: "\ec5f";
}
.ri-drag-move-2-line:before {
	content: "\ec60";
}
.ri-drag-move-fill:before {
	content: "\ec61";
}
.ri-drag-move-line:before {
	content: "\ec62";
}
.ri-dribbble-fill:before {
	content: "\ec63";
}
.ri-dribbble-line:before {
	content: "\ec64";
}
.ri-drive-fill:before {
	content: "\ec65";
}
.ri-drive-line:before {
	content: "\ec66";
}
.ri-drizzle-fill:before {
	content: "\ec67";
}
.ri-drizzle-line:before {
	content: "\ec68";
}
.ri-drop-fill:before {
	content: "\ec69";
}
.ri-drop-line:before {
	content: "\ec6a";
}
.ri-dropbox-fill:before {
	content: "\ec6b";
}
.ri-dropbox-line:before {
	content: "\ec6c";
}
.ri-dual-sim-1-fill:before {
	content: "\ec6d";
}
.ri-dual-sim-1-line:before {
	content: "\ec6e";
}
.ri-dual-sim-2-fill:before {
	content: "\ec6f";
}
.ri-dual-sim-2-line:before {
	content: "\ec70";
}
.ri-dv-fill:before {
	content: "\ec71";
}
.ri-dv-line:before {
	content: "\ec72";
}
.ri-dvd-fill:before {
	content: "\ec73";
}
.ri-dvd-line:before {
	content: "\ec74";
}
.ri-e-bike-2-fill:before {
	content: "\ec75";
}
.ri-e-bike-2-line:before {
	content: "\ec76";
}
.ri-e-bike-fill:before {
	content: "\ec77";
}
.ri-e-bike-line:before {
	content: "\ec78";
}
.ri-earth-fill:before {
	content: "\ec79";
}
.ri-earth-line:before {
	content: "\ec7a";
}
.ri-earthquake-fill:before {
	content: "\ec7b";
}
.ri-earthquake-line:before {
	content: "\ec7c";
}
.ri-edge-fill:before {
	content: "\ec7d";
}
.ri-edge-line:before {
	content: "\ec7e";
}
.ri-edit-2-fill:before {
	content: "\ec7f";
}
.ri-edit-2-line:before {
	content: "\ec80";
}
.ri-edit-box-fill:before {
	content: "\ec81";
}
.ri-edit-box-line:before {
	content: "\ec82";
}
.ri-edit-circle-fill:before {
	content: "\ec83";
}
.ri-edit-circle-line:before {
	content: "\ec84";
}
.ri-edit-fill:before {
	content: "\ec85";
}
.ri-edit-line:before {
	content: "\ec86";
}
.ri-eject-fill:before {
	content: "\ec87";
}
.ri-eject-line:before {
	content: "\ec88";
}
.ri-emotion-2-fill:before {
	content: "\ec89";
}
.ri-emotion-2-line:before {
	content: "\ec8a";
}
.ri-emotion-fill:before {
	content: "\ec8b";
}
.ri-emotion-happy-fill:before {
	content: "\ec8c";
}
.ri-emotion-happy-line:before {
	content: "\ec8d";
}
.ri-emotion-laugh-fill:before {
	content: "\ec8e";
}
.ri-emotion-laugh-line:before {
	content: "\ec8f";
}
.ri-emotion-line:before {
	content: "\ec90";
}
.ri-emotion-normal-fill:before {
	content: "\ec91";
}
.ri-emotion-normal-line:before {
	content: "\ec92";
}
.ri-emotion-sad-fill:before {
	content: "\ec93";
}
.ri-emotion-sad-line:before {
	content: "\ec94";
}
.ri-emotion-unhappy-fill:before {
	content: "\ec95";
}
.ri-emotion-unhappy-line:before {
	content: "\ec96";
}
.ri-empathize-fill:before {
	content: "\ec97";
}
.ri-empathize-line:before {
	content: "\ec98";
}
.ri-emphasis-cn:before {
	content: "\ec99";
}
.ri-emphasis:before {
	content: "\ec9a";
}
.ri-english-input:before {
	content: "\ec9b";
}
.ri-equalizer-fill:before {
	content: "\ec9c";
}
.ri-equalizer-line:before {
	content: "\ec9d";
}
.ri-eraser-fill:before {
	content: "\ec9e";
}
.ri-eraser-line:before {
	content: "\ec9f";
}
.ri-error-warning-fill:before {
	content: "\eca0";
}
.ri-error-warning-line:before {
	content: "\eca1";
}
.ri-evernote-fill:before {
	content: "\eca2";
}
.ri-evernote-line:before {
	content: "\eca3";
}
.ri-exchange-box-fill:before {
	content: "\eca4";
}
.ri-exchange-box-line:before {
	content: "\eca5";
}
.ri-exchange-cny-fill:before {
	content: "\eca6";
}
.ri-exchange-cny-line:before {
	content: "\eca7";
}
.ri-exchange-dollar-fill:before {
	content: "\eca8";
}
.ri-exchange-dollar-line:before {
	content: "\eca9";
}
.ri-exchange-fill:before {
	content: "\ecaa";
}
.ri-exchange-funds-fill:before {
	content: "\ecab";
}
.ri-exchange-funds-line:before {
	content: "\ecac";
}
.ri-exchange-line:before {
	content: "\ecad";
}
.ri-external-link-fill:before {
	content: "\ecae";
}
.ri-external-link-line:before {
	content: "\ecaf";
}
.ri-eye-2-fill:before {
	content: "\ecb0";
}
.ri-eye-2-line:before {
	content: "\ecb1";
}
.ri-eye-close-fill:before {
	content: "\ecb2";
}
.ri-eye-close-line:before {
	content: "\ecb3";
}
.ri-eye-fill:before {
	content: "\ecb4";
}
.ri-eye-line:before {
	content: "\ecb5";
}
.ri-eye-off-fill:before {
	content: "\ecb6";
}
.ri-eye-off-line:before {
	content: "\ecb7";
}
.ri-facebook-box-fill:before {
	content: "\ecb8";
}
.ri-facebook-box-line:before {
	content: "\ecb9";
}
.ri-facebook-circle-fill:before {
	content: "\ecba";
}
.ri-facebook-circle-line:before {
	content: "\ecbb";
}
.ri-facebook-fill:before {
	content: "\ecbc";
}
.ri-facebook-line:before {
	content: "\ecbd";
}
.ri-fahrenheit-fill:before {
	content: "\ecbe";
}
.ri-fahrenheit-line:before {
	content: "\ecbf";
}
.ri-feedback-fill:before {
	content: "\ecc0";
}
.ri-feedback-line:before {
	content: "\ecc1";
}
.ri-file-2-fill:before {
	content: "\ecc2";
}
.ri-file-2-line:before {
	content: "\ecc3";
}
.ri-file-3-fill:before {
	content: "\ecc4";
}
.ri-file-3-line:before {
	content: "\ecc5";
}
.ri-file-4-fill:before {
	content: "\ecc6";
}
.ri-file-4-line:before {
	content: "\ecc7";
}
.ri-file-add-fill:before {
	content: "\ecc8";
}
.ri-file-add-line:before {
	content: "\ecc9";
}
.ri-file-chart-2-fill:before {
	content: "\ecca";
}
.ri-file-chart-2-line:before {
	content: "\eccb";
}
.ri-file-chart-fill:before {
	content: "\eccc";
}
.ri-file-chart-line:before {
	content: "\eccd";
}
.ri-file-cloud-fill:before {
	content: "\ecce";
}
.ri-file-cloud-line:before {
	content: "\eccf";
}
.ri-file-code-fill:before {
	content: "\ecd0";
}
.ri-file-code-line:before {
	content: "\ecd1";
}
.ri-file-copy-2-fill:before {
	content: "\ecd2";
}
.ri-file-copy-2-line:before {
	content: "\ecd3";
}
.ri-file-copy-fill:before {
	content: "\ecd4";
}
.ri-file-copy-line:before {
	content: "\ecd5";
}
.ri-file-damage-fill:before {
	content: "\ecd6";
}
.ri-file-damage-line:before {
	content: "\ecd7";
}
.ri-file-download-fill:before {
	content: "\ecd8";
}
.ri-file-download-line:before {
	content: "\ecd9";
}
.ri-file-edit-fill:before {
	content: "\ecda";
}
.ri-file-edit-line:before {
	content: "\ecdb";
}
.ri-file-excel-2-fill:before {
	content: "\ecdc";
}
.ri-file-excel-2-line:before {
	content: "\ecdd";
}
.ri-file-excel-fill:before {
	content: "\ecde";
}
.ri-file-excel-line:before {
	content: "\ecdf";
}
.ri-file-fill:before {
	content: "\ece0";
}
.ri-file-forbid-fill:before {
	content: "\ece1";
}
.ri-file-forbid-line:before {
	content: "\ece2";
}
.ri-file-gif-fill:before {
	content: "\ece3";
}
.ri-file-gif-line:before {
	content: "\ece4";
}
.ri-file-history-fill:before {
	content: "\ece5";
}
.ri-file-history-line:before {
	content: "\ece6";
}
.ri-file-hwp-fill:before {
	content: "\ece7";
}
.ri-file-hwp-line:before {
	content: "\ece8";
}
.ri-file-info-fill:before {
	content: "\ece9";
}
.ri-file-info-line:before {
	content: "\ecea";
}
.ri-file-line:before {
	content: "\eceb";
}
.ri-file-list-2-fill:before {
	content: "\ecec";
}
.ri-file-list-2-line:before {
	content: "\eced";
}
.ri-file-list-3-fill:before {
	content: "\ecee";
}
.ri-file-list-3-line:before {
	content: "\ecef";
}
.ri-file-list-fill:before {
	content: "\ecf0";
}
.ri-file-list-line:before {
	content: "\ecf1";
}
.ri-file-lock-fill:before {
	content: "\ecf2";
}
.ri-file-lock-line:before {
	content: "\ecf3";
}
.ri-file-marked-fill:before {
	content: "\ecf4";
}
.ri-file-marked-line:before {
	content: "\ecf5";
}
.ri-file-music-fill:before {
	content: "\ecf6";
}
.ri-file-music-line:before {
	content: "\ecf7";
}
.ri-file-paper-2-fill:before {
	content: "\ecf8";
}
.ri-file-paper-2-line:before {
	content: "\ecf9";
}
.ri-file-paper-fill:before {
	content: "\ecfa";
}
.ri-file-paper-line:before {
	content: "\ecfb";
}
.ri-file-pdf-fill:before {
	content: "\ecfc";
}
.ri-file-pdf-line:before {
	content: "\ecfd";
}
.ri-file-ppt-2-fill:before {
	content: "\ecfe";
}
.ri-file-ppt-2-line:before {
	content: "\ecff";
}
.ri-file-ppt-fill:before {
	content: "\ed00";
}
.ri-file-ppt-line:before {
	content: "\ed01";
}
.ri-file-reduce-fill:before {
	content: "\ed02";
}
.ri-file-reduce-line:before {
	content: "\ed03";
}
.ri-file-search-fill:before {
	content: "\ed04";
}
.ri-file-search-line:before {
	content: "\ed05";
}
.ri-file-settings-fill:before {
	content: "\ed06";
}
.ri-file-settings-line:before {
	content: "\ed07";
}
.ri-file-shield-2-fill:before {
	content: "\ed08";
}
.ri-file-shield-2-line:before {
	content: "\ed09";
}
.ri-file-shield-fill:before {
	content: "\ed0a";
}
.ri-file-shield-line:before {
	content: "\ed0b";
}
.ri-file-shred-fill:before {
	content: "\ed0c";
}
.ri-file-shred-line:before {
	content: "\ed0d";
}
.ri-file-text-fill:before {
	content: "\ed0e";
}
.ri-file-text-line:before {
	content: "\ed0f";
}
.ri-file-transfer-fill:before {
	content: "\ed10";
}
.ri-file-transfer-line:before {
	content: "\ed11";
}
.ri-file-unknow-fill:before {
	content: "\ed12";
}
.ri-file-unknow-line:before {
	content: "\ed13";
}
.ri-file-upload-fill:before {
	content: "\ed14";
}
.ri-file-upload-line:before {
	content: "\ed15";
}
.ri-file-user-fill:before {
	content: "\ed16";
}
.ri-file-user-line:before {
	content: "\ed17";
}
.ri-file-warning-fill:before {
	content: "\ed18";
}
.ri-file-warning-line:before {
	content: "\ed19";
}
.ri-file-word-2-fill:before {
	content: "\ed1a";
}
.ri-file-word-2-line:before {
	content: "\ed1b";
}
.ri-file-word-fill:before {
	content: "\ed1c";
}
.ri-file-word-line:before {
	content: "\ed1d";
}
.ri-file-zip-fill:before {
	content: "\ed1e";
}
.ri-file-zip-line:before {
	content: "\ed1f";
}
.ri-film-fill:before {
	content: "\ed20";
}
.ri-film-line:before {
	content: "\ed21";
}
.ri-filter-2-fill:before {
	content: "\ed22";
}
.ri-filter-2-line:before {
	content: "\ed23";
}
.ri-filter-3-fill:before {
	content: "\ed24";
}
.ri-filter-3-line:before {
	content: "\ed25";
}
.ri-filter-fill:before {
	content: "\ed26";
}
.ri-filter-line:before {
	content: "\ed27";
}
.ri-filter-off-fill:before {
	content: "\ed28";
}
.ri-filter-off-line:before {
	content: "\ed29";
}
.ri-find-replace-fill:before {
	content: "\ed2a";
}
.ri-find-replace-line:before {
	content: "\ed2b";
}
.ri-finder-fill:before {
	content: "\ed2c";
}
.ri-finder-line:before {
	content: "\ed2d";
}
.ri-fingerprint-2-fill:before {
	content: "\ed2e";
}
.ri-fingerprint-2-line:before {
	content: "\ed2f";
}
.ri-fingerprint-fill:before {
	content: "\ed30";
}
.ri-fingerprint-line:before {
	content: "\ed31";
}
.ri-fire-fill:before {
	content: "\ed32";
}
.ri-fire-line:before {
	content: "\ed33";
}
.ri-firefox-fill:before {
	content: "\ed34";
}
.ri-firefox-line:before {
	content: "\ed35";
}
.ri-first-aid-kit-fill:before {
	content: "\ed36";
}
.ri-first-aid-kit-line:before {
	content: "\ed37";
}
.ri-flag-2-fill:before {
	content: "\ed38";
}
.ri-flag-2-line:before {
	content: "\ed39";
}
.ri-flag-fill:before {
	content: "\ed3a";
}
.ri-flag-line:before {
	content: "\ed3b";
}
.ri-flashlight-fill:before {
	content: "\ed3c";
}
.ri-flashlight-line:before {
	content: "\ed3d";
}
.ri-flask-fill:before {
	content: "\ed3e";
}
.ri-flask-line:before {
	content: "\ed3f";
}
.ri-flight-land-fill:before {
	content: "\ed40";
}
.ri-flight-land-line:before {
	content: "\ed41";
}
.ri-flight-takeoff-fill:before {
	content: "\ed42";
}
.ri-flight-takeoff-line:before {
	content: "\ed43";
}
.ri-flood-fill:before {
	content: "\ed44";
}
.ri-flood-line:before {
	content: "\ed45";
}
.ri-flow-chart:before {
	content: "\ed46";
}
.ri-flutter-fill:before {
	content: "\ed47";
}
.ri-flutter-line:before {
	content: "\ed48";
}
.ri-focus-2-fill:before {
	content: "\ed49";
}
.ri-focus-2-line:before {
	content: "\ed4a";
}
.ri-focus-3-fill:before {
	content: "\ed4b";
}
.ri-focus-3-line:before {
	content: "\ed4c";
}
.ri-focus-fill:before {
	content: "\ed4d";
}
.ri-focus-line:before {
	content: "\ed4e";
}
.ri-foggy-fill:before {
	content: "\ed4f";
}
.ri-foggy-line:before {
	content: "\ed50";
}
.ri-folder-2-fill:before {
	content: "\ed51";
}
.ri-folder-2-line:before {
	content: "\ed52";
}
.ri-folder-3-fill:before {
	content: "\ed53";
}
.ri-folder-3-line:before {
	content: "\ed54";
}
.ri-folder-4-fill:before {
	content: "\ed55";
}
.ri-folder-4-line:before {
	content: "\ed56";
}
.ri-folder-5-fill:before {
	content: "\ed57";
}
.ri-folder-5-line:before {
	content: "\ed58";
}
.ri-folder-add-fill:before {
	content: "\ed59";
}
.ri-folder-add-line:before {
	content: "\ed5a";
}
.ri-folder-chart-2-fill:before {
	content: "\ed5b";
}
.ri-folder-chart-2-line:before {
	content: "\ed5c";
}
.ri-folder-chart-fill:before {
	content: "\ed5d";
}
.ri-folder-chart-line:before {
	content: "\ed5e";
}
.ri-folder-download-fill:before {
	content: "\ed5f";
}
.ri-folder-download-line:before {
	content: "\ed60";
}
.ri-folder-fill:before {
	content: "\ed61";
}
.ri-folder-forbid-fill:before {
	content: "\ed62";
}
.ri-folder-forbid-line:before {
	content: "\ed63";
}
.ri-folder-history-fill:before {
	content: "\ed64";
}
.ri-folder-history-line:before {
	content: "\ed65";
}
.ri-folder-info-fill:before {
	content: "\ed66";
}
.ri-folder-info-line:before {
	content: "\ed67";
}
.ri-folder-keyhole-fill:before {
	content: "\ed68";
}
.ri-folder-keyhole-line:before {
	content: "\ed69";
}
.ri-folder-line:before {
	content: "\ed6a";
}
.ri-folder-lock-fill:before {
	content: "\ed6b";
}
.ri-folder-lock-line:before {
	content: "\ed6c";
}
.ri-folder-music-fill:before {
	content: "\ed6d";
}
.ri-folder-music-line:before {
	content: "\ed6e";
}
.ri-folder-open-fill:before {
	content: "\ed6f";
}
.ri-folder-open-line:before {
	content: "\ed70";
}
.ri-folder-received-fill:before {
	content: "\ed71";
}
.ri-folder-received-line:before {
	content: "\ed72";
}
.ri-folder-reduce-fill:before {
	content: "\ed73";
}
.ri-folder-reduce-line:before {
	content: "\ed74";
}
.ri-folder-settings-fill:before {
	content: "\ed75";
}
.ri-folder-settings-line:before {
	content: "\ed76";
}
.ri-folder-shared-fill:before {
	content: "\ed77";
}
.ri-folder-shared-line:before {
	content: "\ed78";
}
.ri-folder-shield-2-fill:before {
	content: "\ed79";
}
.ri-folder-shield-2-line:before {
	content: "\ed7a";
}
.ri-folder-shield-fill:before {
	content: "\ed7b";
}
.ri-folder-shield-line:before {
	content: "\ed7c";
}
.ri-folder-transfer-fill:before {
	content: "\ed7d";
}
.ri-folder-transfer-line:before {
	content: "\ed7e";
}
.ri-folder-unknow-fill:before {
	content: "\ed7f";
}
.ri-folder-unknow-line:before {
	content: "\ed80";
}
.ri-folder-upload-fill:before {
	content: "\ed81";
}
.ri-folder-upload-line:before {
	content: "\ed82";
}
.ri-folder-user-fill:before {
	content: "\ed83";
}
.ri-folder-user-line:before {
	content: "\ed84";
}
.ri-folder-warning-fill:before {
	content: "\ed85";
}
.ri-folder-warning-line:before {
	content: "\ed86";
}
.ri-folder-zip-fill:before {
	content: "\ed87";
}
.ri-folder-zip-line:before {
	content: "\ed88";
}
.ri-folders-fill:before {
	content: "\ed89";
}
.ri-folders-line:before {
	content: "\ed8a";
}
.ri-font-color:before {
	content: "\ed8b";
}
.ri-font-size-2:before {
	content: "\ed8c";
}
.ri-font-size:before {
	content: "\ed8d";
}
.ri-football-fill:before {
	content: "\ed8e";
}
.ri-football-line:before {
	content: "\ed8f";
}
.ri-footprint-fill:before {
	content: "\ed90";
}
.ri-footprint-line:before {
	content: "\ed91";
}
.ri-forbid-2-fill:before {
	content: "\ed92";
}
.ri-forbid-2-line:before {
	content: "\ed93";
}
.ri-forbid-fill:before {
	content: "\ed94";
}
.ri-forbid-line:before {
	content: "\ed95";
}
.ri-format-clear:before {
	content: "\ed96";
}
.ri-fridge-fill:before {
	content: "\ed97";
}
.ri-fridge-line:before {
	content: "\ed98";
}
.ri-fullscreen-exit-fill:before {
	content: "\ed99";
}
.ri-fullscreen-exit-line:before {
	content: "\ed9a";
}
.ri-fullscreen-fill:before {
	content: "\ed9b";
}
.ri-fullscreen-line:before {
	content: "\ed9c";
}
.ri-function-fill:before {
	content: "\ed9d";
}
.ri-function-line:before {
	content: "\ed9e";
}
.ri-functions:before {
	content: "\ed9f";
}
.ri-funds-box-fill:before {
	content: "\eda0";
}
.ri-funds-box-line:before {
	content: "\eda1";
}
.ri-funds-fill:before {
	content: "\eda2";
}
.ri-funds-line:before {
	content: "\eda3";
}
.ri-gallery-fill:before {
	content: "\eda4";
}
.ri-gallery-line:before {
	content: "\eda5";
}
.ri-gallery-upload-fill:before {
	content: "\eda6";
}
.ri-gallery-upload-line:before {
	content: "\eda7";
}
.ri-game-fill:before {
	content: "\eda8";
}
.ri-game-line:before {
	content: "\eda9";
}
.ri-gamepad-fill:before {
	content: "\edaa";
}
.ri-gamepad-line:before {
	content: "\edab";
}
.ri-gas-station-fill:before {
	content: "\edac";
}
.ri-gas-station-line:before {
	content: "\edad";
}
.ri-gatsby-fill:before {
	content: "\edae";
}
.ri-gatsby-line:before {
	content: "\edaf";
}
.ri-genderless-fill:before {
	content: "\edb0";
}
.ri-genderless-line:before {
	content: "\edb1";
}
.ri-ghost-2-fill:before {
	content: "\edb2";
}
.ri-ghost-2-line:before {
	content: "\edb3";
}
.ri-ghost-fill:before {
	content: "\edb4";
}
.ri-ghost-line:before {
	content: "\edb5";
}
.ri-ghost-smile-fill:before {
	content: "\edb6";
}
.ri-ghost-smile-line:before {
	content: "\edb7";
}
.ri-gift-2-fill:before {
	content: "\edb8";
}
.ri-gift-2-line:before {
	content: "\edb9";
}
.ri-gift-fill:before {
	content: "\edba";
}
.ri-gift-line:before {
	content: "\edbb";
}
.ri-git-branch-fill:before {
	content: "\edbc";
}
.ri-git-branch-line:before {
	content: "\edbd";
}
.ri-git-commit-fill:before {
	content: "\edbe";
}
.ri-git-commit-line:before {
	content: "\edbf";
}
.ri-git-merge-fill:before {
	content: "\edc0";
}
.ri-git-merge-line:before {
	content: "\edc1";
}
.ri-git-pull-request-fill:before {
	content: "\edc2";
}
.ri-git-pull-request-line:before {
	content: "\edc3";
}
.ri-git-repository-commits-fill:before {
	content: "\edc4";
}
.ri-git-repository-commits-line:before {
	content: "\edc5";
}
.ri-git-repository-fill:before {
	content: "\edc6";
}
.ri-git-repository-line:before {
	content: "\edc7";
}
.ri-git-repository-private-fill:before {
	content: "\edc8";
}
.ri-git-repository-private-line:before {
	content: "\edc9";
}
.ri-github-fill:before {
	content: "\edca";
}
.ri-github-line:before {
	content: "\edcb";
}
.ri-gitlab-fill:before {
	content: "\edcc";
}
.ri-gitlab-line:before {
	content: "\edcd";
}
.ri-global-fill:before {
	content: "\edce";
}
.ri-global-line:before {
	content: "\edcf";
}
.ri-globe-fill:before {
	content: "\edd0";
}
.ri-globe-line:before {
	content: "\edd1";
}
.ri-goblet-fill:before {
	content: "\edd2";
}
.ri-goblet-line:before {
	content: "\edd3";
}
.ri-google-fill:before {
	content: "\edd4";
}
.ri-google-line:before {
	content: "\edd5";
}
.ri-google-play-fill:before {
	content: "\edd6";
}
.ri-google-play-line:before {
	content: "\edd7";
}
.ri-government-fill:before {
	content: "\edd8";
}
.ri-government-line:before {
	content: "\edd9";
}
.ri-gps-fill:before {
	content: "\edda";
}
.ri-gps-line:before {
	content: "\eddb";
}
.ri-gradienter-fill:before {
	content: "\eddc";
}
.ri-gradienter-line:before {
	content: "\eddd";
}
.ri-grid-fill:before {
	content: "\edde";
}
.ri-grid-line:before {
	content: "\eddf";
}
.ri-group-2-fill:before {
	content: "\ede0";
}
.ri-group-2-line:before {
	content: "\ede1";
}
.ri-group-fill:before {
	content: "\ede2";
}
.ri-group-line:before {
	content: "\ede3";
}
.ri-guide-fill:before {
	content: "\ede4";
}
.ri-guide-line:before {
	content: "\ede5";
}
.ri-h-1:before {
	content: "\ede6";
}
.ri-h-2:before {
	content: "\ede7";
}
.ri-h-3:before {
	content: "\ede8";
}
.ri-h-4:before {
	content: "\ede9";
}
.ri-h-5:before {
	content: "\edea";
}
.ri-h-6:before {
	content: "\edeb";
}
.ri-hail-fill:before {
	content: "\edec";
}
.ri-hail-line:before {
	content: "\eded";
}
.ri-hammer-fill:before {
	content: "\edee";
}
.ri-hammer-line:before {
	content: "\edef";
}
.ri-hand-coin-fill:before {
	content: "\edf0";
}
.ri-hand-coin-line:before {
	content: "\edf1";
}
.ri-hand-heart-fill:before {
	content: "\edf2";
}
.ri-hand-heart-line:before {
	content: "\edf3";
}
.ri-hand-sanitizer-fill:before {
	content: "\edf4";
}
.ri-hand-sanitizer-line:before {
	content: "\edf5";
}
.ri-handbag-fill:before {
	content: "\edf6";
}
.ri-handbag-line:before {
	content: "\edf7";
}
.ri-hard-drive-2-fill:before {
	content: "\edf8";
}
.ri-hard-drive-2-line:before {
	content: "\edf9";
}
.ri-hard-drive-fill:before {
	content: "\edfa";
}
.ri-hard-drive-line:before {
	content: "\edfb";
}
.ri-hashtag:before {
	content: "\edfc";
}
.ri-haze-2-fill:before {
	content: "\edfd";
}
.ri-haze-2-line:before {
	content: "\edfe";
}
.ri-haze-fill:before {
	content: "\edff";
}
.ri-haze-line:before {
	content: "\ee00";
}
.ri-hd-fill:before {
	content: "\ee01";
}
.ri-hd-line:before {
	content: "\ee02";
}
.ri-heading:before {
	content: "\ee03";
}
.ri-headphone-fill:before {
	content: "\ee04";
}
.ri-headphone-line:before {
	content: "\ee05";
}
.ri-health-book-fill:before {
	content: "\ee06";
}
.ri-health-book-line:before {
	content: "\ee07";
}
.ri-heart-2-fill:before {
	content: "\ee08";
}
.ri-heart-2-line:before {
	content: "\ee09";
}
.ri-heart-3-fill:before {
	content: "\ee0a";
}
.ri-heart-3-line:before {
	content: "\ee0b";
}
.ri-heart-add-fill:before {
	content: "\ee0c";
}
.ri-heart-add-line:before {
	content: "\ee0d";
}
.ri-heart-fill:before {
	content: "\ee0e";
}
.ri-heart-line:before {
	content: "\ee0f";
}
.ri-heart-pulse-fill:before {
	content: "\ee10";
}
.ri-heart-pulse-line:before {
	content: "\ee11";
}
.ri-hearts-fill:before {
	content: "\ee12";
}
.ri-hearts-line:before {
	content: "\ee13";
}
.ri-heavy-showers-fill:before {
	content: "\ee14";
}
.ri-heavy-showers-line:before {
	content: "\ee15";
}
.ri-history-fill:before {
	content: "\ee16";
}
.ri-history-line:before {
	content: "\ee17";
}
.ri-home-2-fill:before {
	content: "\ee18";
}
.ri-home-2-line:before {
	content: "\ee19";
}
.ri-home-3-fill:before {
	content: "\ee1a";
}
.ri-home-3-line:before {
	content: "\ee1b";
}
.ri-home-4-fill:before {
	content: "\ee1c";
}
.ri-home-4-line:before {
	content: "\ee1d";
}
.ri-home-5-fill:before {
	content: "\ee1e";
}
.ri-home-5-line:before {
	content: "\ee1f";
}
.ri-home-6-fill:before {
	content: "\ee20";
}
.ri-home-6-line:before {
	content: "\ee21";
}
.ri-home-7-fill:before {
	content: "\ee22";
}
.ri-home-7-line:before {
	content: "\ee23";
}
.ri-home-8-fill:before {
	content: "\ee24";
}
.ri-home-8-line:before {
	content: "\ee25";
}
.ri-home-fill:before {
	content: "\ee26";
}
.ri-home-gear-fill:before {
	content: "\ee27";
}
.ri-home-gear-line:before {
	content: "\ee28";
}
.ri-home-heart-fill:before {
	content: "\ee29";
}
.ri-home-heart-line:before {
	content: "\ee2a";
}
.ri-home-line:before {
	content: "\ee2b";
}
.ri-home-smile-2-fill:before {
	content: "\ee2c";
}
.ri-home-smile-2-line:before {
	content: "\ee2d";
}
.ri-home-smile-fill:before {
	content: "\ee2e";
}
.ri-home-smile-line:before {
	content: "\ee2f";
}
.ri-home-wifi-fill:before {
	content: "\ee30";
}
.ri-home-wifi-line:before {
	content: "\ee31";
}
.ri-honor-of-kings-fill:before {
	content: "\ee32";
}
.ri-honor-of-kings-line:before {
	content: "\ee33";
}
.ri-honour-fill:before {
	content: "\ee34";
}
.ri-honour-line:before {
	content: "\ee35";
}
.ri-hospital-fill:before {
	content: "\ee36";
}
.ri-hospital-line:before {
	content: "\ee37";
}
.ri-hotel-bed-fill:before {
	content: "\ee38";
}
.ri-hotel-bed-line:before {
	content: "\ee39";
}
.ri-hotel-fill:before {
	content: "\ee3a";
}
.ri-hotel-line:before {
	content: "\ee3b";
}
.ri-hotspot-fill:before {
	content: "\ee3c";
}
.ri-hotspot-line:before {
	content: "\ee3d";
}
.ri-hq-fill:before {
	content: "\ee3e";
}
.ri-hq-line:before {
	content: "\ee3f";
}
.ri-html5-fill:before {
	content: "\ee40";
}
.ri-html5-line:before {
	content: "\ee41";
}
.ri-ie-fill:before {
	content: "\ee42";
}
.ri-ie-line:before {
	content: "\ee43";
}
.ri-image-2-fill:before {
	content: "\ee44";
}
.ri-image-2-line:before {
	content: "\ee45";
}
.ri-image-add-fill:before {
	content: "\ee46";
}
.ri-image-add-line:before {
	content: "\ee47";
}
.ri-image-edit-fill:before {
	content: "\ee48";
}
.ri-image-edit-line:before {
	content: "\ee49";
}
.ri-image-fill:before {
	content: "\ee4a";
}
.ri-image-line:before {
	content: "\ee4b";
}
.ri-inbox-archive-fill:before {
	content: "\ee4c";
}
.ri-inbox-archive-line:before {
	content: "\ee4d";
}
.ri-inbox-fill:before {
	content: "\ee4e";
}
.ri-inbox-line:before {
	content: "\ee4f";
}
.ri-inbox-unarchive-fill:before {
	content: "\ee50";
}
.ri-inbox-unarchive-line:before {
	content: "\ee51";
}
.ri-increase-decrease-fill:before {
	content: "\ee52";
}
.ri-increase-decrease-line:before {
	content: "\ee53";
}
.ri-indent-decrease:before {
	content: "\ee54";
}
.ri-indent-increase:before {
	content: "\ee55";
}
.ri-indeterminate-circle-fill:before {
	content: "\ee56";
}
.ri-indeterminate-circle-line:before {
	content: "\ee57";
}
.ri-information-fill:before {
	content: "\ee58";
}
.ri-information-line:before {
	content: "\ee59";
}
.ri-infrared-thermometer-fill:before {
	content: "\ee5a";
}
.ri-infrared-thermometer-line:before {
	content: "\ee5b";
}
.ri-ink-bottle-fill:before {
	content: "\ee5c";
}
.ri-ink-bottle-line:before {
	content: "\ee5d";
}
.ri-input-cursor-move:before {
	content: "\ee5e";
}
.ri-input-method-fill:before {
	content: "\ee5f";
}
.ri-input-method-line:before {
	content: "\ee60";
}
.ri-insert-column-left:before {
	content: "\ee61";
}
.ri-insert-column-right:before {
	content: "\ee62";
}
.ri-insert-row-bottom:before {
	content: "\ee63";
}
.ri-insert-row-top:before {
	content: "\ee64";
}
.ri-instagram-fill:before {
	content: "\ee65";
}
.ri-instagram-line:before {
	content: "\ee66";
}
.ri-install-fill:before {
	content: "\ee67";
}
.ri-install-line:before {
	content: "\ee68";
}
.ri-invision-fill:before {
	content: "\ee69";
}
.ri-invision-line:before {
	content: "\ee6a";
}
.ri-italic:before {
	content: "\ee6b";
}
.ri-kakao-talk-fill:before {
	content: "\ee6c";
}
.ri-kakao-talk-line:before {
	content: "\ee6d";
}
.ri-key-2-fill:before {
	content: "\ee6e";
}
.ri-key-2-line:before {
	content: "\ee6f";
}
.ri-key-fill:before {
	content: "\ee70";
}
.ri-key-line:before {
	content: "\ee71";
}
.ri-keyboard-box-fill:before {
	content: "\ee72";
}
.ri-keyboard-box-line:before {
	content: "\ee73";
}
.ri-keyboard-fill:before {
	content: "\ee74";
}
.ri-keyboard-line:before {
	content: "\ee75";
}
.ri-keynote-fill:before {
	content: "\ee76";
}
.ri-keynote-line:before {
	content: "\ee77";
}
.ri-knife-blood-fill:before {
	content: "\ee78";
}
.ri-knife-blood-line:before {
	content: "\ee79";
}
.ri-knife-fill:before {
	content: "\ee7a";
}
.ri-knife-line:before {
	content: "\ee7b";
}
.ri-landscape-fill:before {
	content: "\ee7c";
}
.ri-landscape-line:before {
	content: "\ee7d";
}
.ri-layout-2-fill:before {
	content: "\ee7e";
}
.ri-layout-2-line:before {
	content: "\ee7f";
}
.ri-layout-3-fill:before {
	content: "\ee80";
}
.ri-layout-3-line:before {
	content: "\ee81";
}
.ri-layout-4-fill:before {
	content: "\ee82";
}
.ri-layout-4-line:before {
	content: "\ee83";
}
.ri-layout-5-fill:before {
	content: "\ee84";
}
.ri-layout-5-line:before {
	content: "\ee85";
}
.ri-layout-6-fill:before {
	content: "\ee86";
}
.ri-layout-6-line:before {
	content: "\ee87";
}
.ri-layout-bottom-2-fill:before {
	content: "\ee88";
}
.ri-layout-bottom-2-line:before {
	content: "\ee89";
}
.ri-layout-bottom-fill:before {
	content: "\ee8a";
}
.ri-layout-bottom-line:before {
	content: "\ee8b";
}
.ri-layout-column-fill:before {
	content: "\ee8c";
}
.ri-layout-column-line:before {
	content: "\ee8d";
}
.ri-layout-fill:before {
	content: "\ee8e";
}
.ri-layout-grid-fill:before {
	content: "\ee8f";
}
.ri-layout-grid-line:before {
	content: "\ee90";
}
.ri-layout-left-2-fill:before {
	content: "\ee91";
}
.ri-layout-left-2-line:before {
	content: "\ee92";
}
.ri-layout-left-fill:before {
	content: "\ee93";
}
.ri-layout-left-line:before {
	content: "\ee94";
}
.ri-layout-line:before {
	content: "\ee95";
}
.ri-layout-masonry-fill:before {
	content: "\ee96";
}
.ri-layout-masonry-line:before {
	content: "\ee97";
}
.ri-layout-right-2-fill:before {
	content: "\ee98";
}
.ri-layout-right-2-line:before {
	content: "\ee99";
}
.ri-layout-right-fill:before {
	content: "\ee9a";
}
.ri-layout-right-line:before {
	content: "\ee9b";
}
.ri-layout-row-fill:before {
	content: "\ee9c";
}
.ri-layout-row-line:before {
	content: "\ee9d";
}
.ri-layout-top-2-fill:before {
	content: "\ee9e";
}
.ri-layout-top-2-line:before {
	content: "\ee9f";
}
.ri-layout-top-fill:before {
	content: "\eea0";
}
.ri-layout-top-line:before {
	content: "\eea1";
}
.ri-leaf-fill:before {
	content: "\eea2";
}
.ri-leaf-line:before {
	content: "\eea3";
}
.ri-lifebuoy-fill:before {
	content: "\eea4";
}
.ri-lifebuoy-line:before {
	content: "\eea5";
}
.ri-lightbulb-fill:before {
	content: "\eea6";
}
.ri-lightbulb-flash-fill:before {
	content: "\eea7";
}
.ri-lightbulb-flash-line:before {
	content: "\eea8";
}
.ri-lightbulb-line:before {
	content: "\eea9";
}
.ri-line-chart-fill:before {
	content: "\eeaa";
}
.ri-line-chart-line:before {
	content: "\eeab";
}
.ri-line-fill:before {
	content: "\eeac";
}
.ri-line-height:before {
	content: "\eead";
}
.ri-line-line:before {
	content: "\eeae";
}
.ri-link-m:before {
	content: "\eeaf";
}
.ri-link-unlink-m:before {
	content: "\eeb0";
}
.ri-link-unlink:before {
	content: "\eeb1";
}
.ri-link:before {
	content: "\eeb2";
}
.ri-linkedin-box-fill:before {
	content: "\eeb3";
}
.ri-linkedin-box-line:before {
	content: "\eeb4";
}
.ri-linkedin-fill:before {
	content: "\eeb5";
}
.ri-linkedin-line:before {
	content: "\eeb6";
}
.ri-links-fill:before {
	content: "\eeb7";
}
.ri-links-line:before {
	content: "\eeb8";
}
.ri-list-check-2:before {
	content: "\eeb9";
}
.ri-list-check:before {
	content: "\eeba";
}
.ri-list-ordered:before {
	content: "\eebb";
}
.ri-list-settings-fill:before {
	content: "\eebc";
}
.ri-list-settings-line:before {
	content: "\eebd";
}
.ri-list-unordered:before {
	content: "\eebe";
}
.ri-live-fill:before {
	content: "\eebf";
}
.ri-live-line:before {
	content: "\eec0";
}
.ri-loader-2-fill:before {
	content: "\eec1";
}
.ri-loader-2-line:before {
	content: "\eec2";
}
.ri-loader-3-fill:before {
	content: "\eec3";
}
.ri-loader-3-line:before {
	content: "\eec4";
}
.ri-loader-4-fill:before {
	content: "\eec5";
}
.ri-loader-4-line:before {
	content: "\eec6";
}
.ri-loader-5-fill:before {
	content: "\eec7";
}
.ri-loader-5-line:before {
	content: "\eec8";
}
.ri-loader-fill:before {
	content: "\eec9";
}
.ri-loader-line:before {
	content: "\eeca";
}
.ri-lock-2-fill:before {
	content: "\eecb";
}
.ri-lock-2-line:before {
	content: "\eecc";
}
.ri-lock-fill:before {
	content: "\eecd";
}
.ri-lock-line:before {
	content: "\eece";
}
.ri-lock-password-fill:before {
	content: "\eecf";
}
.ri-lock-password-line:before {
	content: "\eed0";
}
.ri-lock-unlock-fill:before {
	content: "\eed1";
}
.ri-lock-unlock-line:before {
	content: "\eed2";
}
.ri-login-box-fill:before {
	content: "\eed3";
}
.ri-login-box-line:before {
	content: "\eed4";
}
.ri-login-circle-fill:before {
	content: "\eed5";
}
.ri-login-circle-line:before {
	content: "\eed6";
}
.ri-logout-box-fill:before {
	content: "\eed7";
}
.ri-logout-box-line:before {
	content: "\eed8";
}
.ri-logout-box-r-fill:before {
	content: "\eed9";
}
.ri-logout-box-r-line:before {
	content: "\eeda";
}
.ri-logout-circle-fill:before {
	content: "\eedb";
}
.ri-logout-circle-line:before {
	content: "\eedc";
}
.ri-logout-circle-r-fill:before {
	content: "\eedd";
}
.ri-logout-circle-r-line:before {
	content: "\eede";
}
.ri-luggage-cart-fill:before {
	content: "\eedf";
}
.ri-luggage-cart-line:before {
	content: "\eee0";
}
.ri-luggage-deposit-fill:before {
	content: "\eee1";
}
.ri-luggage-deposit-line:before {
	content: "\eee2";
}
.ri-lungs-fill:before {
	content: "\eee3";
}
.ri-lungs-line:before {
	content: "\eee4";
}
.ri-mac-fill:before {
	content: "\eee5";
}
.ri-mac-line:before {
	content: "\eee6";
}
.ri-macbook-fill:before {
	content: "\eee7";
}
.ri-macbook-line:before {
	content: "\eee8";
}
.ri-magic-fill:before {
	content: "\eee9";
}
.ri-magic-line:before {
	content: "\eeea";
}
.ri-mail-add-fill:before {
	content: "\eeeb";
}
.ri-mail-add-line:before {
	content: "\eeec";
}
.ri-mail-check-fill:before {
	content: "\eeed";
}
.ri-mail-check-line:before {
	content: "\eeee";
}
.ri-mail-close-fill:before {
	content: "\eeef";
}
.ri-mail-close-line:before {
	content: "\eef0";
}
.ri-mail-download-fill:before {
	content: "\eef1";
}
.ri-mail-download-line:before {
	content: "\eef2";
}
.ri-mail-fill:before {
	content: "\eef3";
}
.ri-mail-forbid-fill:before {
	content: "\eef4";
}
.ri-mail-forbid-line:before {
	content: "\eef5";
}
.ri-mail-line:before {
	content: "\eef6";
}
.ri-mail-lock-fill:before {
	content: "\eef7";
}
.ri-mail-lock-line:before {
	content: "\eef8";
}
.ri-mail-open-fill:before {
	content: "\eef9";
}
.ri-mail-open-line:before {
	content: "\eefa";
}
.ri-mail-send-fill:before {
	content: "\eefb";
}
.ri-mail-send-line:before {
	content: "\eefc";
}
.ri-mail-settings-fill:before {
	content: "\eefd";
}
.ri-mail-settings-line:before {
	content: "\eefe";
}
.ri-mail-star-fill:before {
	content: "\eeff";
}
.ri-mail-star-line:before {
	content: "\ef00";
}
.ri-mail-unread-fill:before {
	content: "\ef01";
}
.ri-mail-unread-line:before {
	content: "\ef02";
}
.ri-mail-volume-fill:before {
	content: "\ef03";
}
.ri-mail-volume-line:before {
	content: "\ef04";
}
.ri-map-2-fill:before {
	content: "\ef05";
}
.ri-map-2-line:before {
	content: "\ef06";
}
.ri-map-fill:before {
	content: "\ef07";
}
.ri-map-line:before {
	content: "\ef08";
}
.ri-map-pin-2-fill:before {
	content: "\ef09";
}
.ri-map-pin-2-line:before {
	content: "\ef0a";
}
.ri-map-pin-3-fill:before {
	content: "\ef0b";
}
.ri-map-pin-3-line:before {
	content: "\ef0c";
}
.ri-map-pin-4-fill:before {
	content: "\ef0d";
}
.ri-map-pin-4-line:before {
	content: "\ef0e";
}
.ri-map-pin-5-fill:before {
	content: "\ef0f";
}
.ri-map-pin-5-line:before {
	content: "\ef10";
}
.ri-map-pin-add-fill:before {
	content: "\ef11";
}
.ri-map-pin-add-line:before {
	content: "\ef12";
}
.ri-map-pin-fill:before {
	content: "\ef13";
}
.ri-map-pin-line:before {
	content: "\ef14";
}
.ri-map-pin-range-fill:before {
	content: "\ef15";
}
.ri-map-pin-range-line:before {
	content: "\ef16";
}
.ri-map-pin-time-fill:before {
	content: "\ef17";
}
.ri-map-pin-time-line:before {
	content: "\ef18";
}
.ri-map-pin-user-fill:before {
	content: "\ef19";
}
.ri-map-pin-user-line:before {
	content: "\ef1a";
}
.ri-mark-pen-fill:before {
	content: "\ef1b";
}
.ri-mark-pen-line:before {
	content: "\ef1c";
}
.ri-markdown-fill:before {
	content: "\ef1d";
}
.ri-markdown-line:before {
	content: "\ef1e";
}
.ri-markup-fill:before {
	content: "\ef1f";
}
.ri-markup-line:before {
	content: "\ef20";
}
.ri-mastercard-fill:before {
	content: "\ef21";
}
.ri-mastercard-line:before {
	content: "\ef22";
}
.ri-mastodon-fill:before {
	content: "\ef23";
}
.ri-mastodon-line:before {
	content: "\ef24";
}
.ri-medal-2-fill:before {
	content: "\ef25";
}
.ri-medal-2-line:before {
	content: "\ef26";
}
.ri-medal-fill:before {
	content: "\ef27";
}
.ri-medal-line:before {
	content: "\ef28";
}
.ri-medicine-bottle-fill:before {
	content: "\ef29";
}
.ri-medicine-bottle-line:before {
	content: "\ef2a";
}
.ri-medium-fill:before {
	content: "\ef2b";
}
.ri-medium-line:before {
	content: "\ef2c";
}
.ri-men-fill:before {
	content: "\ef2d";
}
.ri-men-line:before {
	content: "\ef2e";
}
.ri-mental-health-fill:before {
	content: "\ef2f";
}
.ri-mental-health-line:before {
	content: "\ef30";
}
.ri-menu-2-fill:before {
	content: "\ef31";
}
.ri-menu-2-line:before {
	content: "\ef32";
}
.ri-menu-3-fill:before {
	content: "\ef33";
}
.ri-menu-3-line:before {
	content: "\ef34";
}
.ri-menu-4-fill:before {
	content: "\ef35";
}
.ri-menu-4-line:before {
	content: "\ef36";
}
.ri-menu-5-fill:before {
	content: "\ef37";
}
.ri-menu-5-line:before {
	content: "\ef38";
}
.ri-menu-add-fill:before {
	content: "\ef39";
}
.ri-menu-add-line:before {
	content: "\ef3a";
}
.ri-menu-fill:before {
	content: "\ef3b";
}
.ri-menu-fold-fill:before {
	content: "\ef3c";
}
.ri-menu-fold-line:before {
	content: "\ef3d";
}
.ri-menu-line:before {
	content: "\ef3e";
}
.ri-menu-unfold-fill:before {
	content: "\ef3f";
}
.ri-menu-unfold-line:before {
	content: "\ef40";
}
.ri-merge-cells-horizontal:before {
	content: "\ef41";
}
.ri-merge-cells-vertical:before {
	content: "\ef42";
}
.ri-message-2-fill:before {
	content: "\ef43";
}
.ri-message-2-line:before {
	content: "\ef44";
}
.ri-message-3-fill:before {
	content: "\ef45";
}
.ri-message-3-line:before {
	content: "\ef46";
}
.ri-message-fill:before {
	content: "\ef47";
}
.ri-message-line:before {
	content: "\ef48";
}
.ri-messenger-fill:before {
	content: "\ef49";
}
.ri-messenger-line:before {
	content: "\ef4a";
}
.ri-meteor-fill:before {
	content: "\ef4b";
}
.ri-meteor-line:before {
	content: "\ef4c";
}
.ri-mic-2-fill:before {
	content: "\ef4d";
}
.ri-mic-2-line:before {
	content: "\ef4e";
}
.ri-mic-fill:before {
	content: "\ef4f";
}
.ri-mic-line:before {
	content: "\ef50";
}
.ri-mic-off-fill:before {
	content: "\ef51";
}
.ri-mic-off-line:before {
	content: "\ef52";
}
.ri-mickey-fill:before {
	content: "\ef53";
}
.ri-mickey-line:before {
	content: "\ef54";
}
.ri-microscope-fill:before {
	content: "\ef55";
}
.ri-microscope-line:before {
	content: "\ef56";
}
.ri-microsoft-fill:before {
	content: "\ef57";
}
.ri-microsoft-line:before {
	content: "\ef58";
}
.ri-mind-map:before {
	content: "\ef59";
}
.ri-mini-program-fill:before {
	content: "\ef5a";
}
.ri-mini-program-line:before {
	content: "\ef5b";
}
.ri-mist-fill:before {
	content: "\ef5c";
}
.ri-mist-line:before {
	content: "\ef5d";
}
.ri-money-cny-box-fill:before {
	content: "\ef5e";
}
.ri-money-cny-box-line:before {
	content: "\ef5f";
}
.ri-money-cny-circle-fill:before {
	content: "\ef60";
}
.ri-money-cny-circle-line:before {
	content: "\ef61";
}
.ri-money-dollar-box-fill:before {
	content: "\ef62";
}
.ri-money-dollar-box-line:before {
	content: "\ef63";
}
.ri-money-dollar-circle-fill:before {
	content: "\ef64";
}
.ri-money-dollar-circle-line:before {
	content: "\ef65";
}
.ri-money-euro-box-fill:before {
	content: "\ef66";
}
.ri-money-euro-box-line:before {
	content: "\ef67";
}
.ri-money-euro-circle-fill:before {
	content: "\ef68";
}
.ri-money-euro-circle-line:before {
	content: "\ef69";
}
.ri-money-pound-box-fill:before {
	content: "\ef6a";
}
.ri-money-pound-box-line:before {
	content: "\ef6b";
}
.ri-money-pound-circle-fill:before {
	content: "\ef6c";
}
.ri-money-pound-circle-line:before {
	content: "\ef6d";
}
.ri-moon-clear-fill:before {
	content: "\ef6e";
}
.ri-moon-clear-line:before {
	content: "\ef6f";
}
.ri-moon-cloudy-fill:before {
	content: "\ef70";
}
.ri-moon-cloudy-line:before {
	content: "\ef71";
}
.ri-moon-fill:before {
	content: "\ef72";
}
.ri-moon-foggy-fill:before {
	content: "\ef73";
}
.ri-moon-foggy-line:before {
	content: "\ef74";
}
.ri-moon-line:before {
	content: "\ef75";
}
.ri-more-2-fill:before {
	content: "\ef76";
}
.ri-more-2-line:before {
	content: "\ef77";
}
.ri-more-fill:before {
	content: "\ef78";
}
.ri-more-line:before {
	content: "\ef79";
}
.ri-motorbike-fill:before {
	content: "\ef7a";
}
.ri-motorbike-line:before {
	content: "\ef7b";
}
.ri-mouse-fill:before {
	content: "\ef7c";
}
.ri-mouse-line:before {
	content: "\ef7d";
}
.ri-movie-2-fill:before {
	content: "\ef7e";
}
.ri-movie-2-line:before {
	content: "\ef7f";
}
.ri-movie-fill:before {
	content: "\ef80";
}
.ri-movie-line:before {
	content: "\ef81";
}
.ri-music-2-fill:before {
	content: "\ef82";
}
.ri-music-2-line:before {
	content: "\ef83";
}
.ri-music-fill:before {
	content: "\ef84";
}
.ri-music-line:before {
	content: "\ef85";
}
.ri-mv-fill:before {
	content: "\ef86";
}
.ri-mv-line:before {
	content: "\ef87";
}
.ri-navigation-fill:before {
	content: "\ef88";
}
.ri-navigation-line:before {
	content: "\ef89";
}
.ri-netease-cloud-music-fill:before {
	content: "\ef8a";
}
.ri-netease-cloud-music-line:before {
	content: "\ef8b";
}
.ri-netflix-fill:before {
	content: "\ef8c";
}
.ri-netflix-line:before {
	content: "\ef8d";
}
.ri-newspaper-fill:before {
	content: "\ef8e";
}
.ri-newspaper-line:before {
	content: "\ef8f";
}
.ri-node-tree:before {
	content: "\ef90";
}
.ri-notification-2-fill:before {
	content: "\ef91";
}
.ri-notification-2-line:before {
	content: "\ef92";
}
.ri-notification-3-fill:before {
	content: "\ef93";
}
.ri-notification-3-line:before {
	content: "\ef94";
}
.ri-notification-4-fill:before {
	content: "\ef95";
}
.ri-notification-4-line:before {
	content: "\ef96";
}
.ri-notification-badge-fill:before {
	content: "\ef97";
}
.ri-notification-badge-line:before {
	content: "\ef98";
}
.ri-notification-fill:before {
	content: "\ef99";
}
.ri-notification-line:before {
	content: "\ef9a";
}
.ri-notification-off-fill:before {
	content: "\ef9b";
}
.ri-notification-off-line:before {
	content: "\ef9c";
}
.ri-npmjs-fill:before {
	content: "\ef9d";
}
.ri-npmjs-line:before {
	content: "\ef9e";
}
.ri-number-0:before {
	content: "\ef9f";
}
.ri-number-1:before {
	content: "\efa0";
}
.ri-number-2:before {
	content: "\efa1";
}
.ri-number-3:before {
	content: "\efa2";
}
.ri-number-4:before {
	content: "\efa3";
}
.ri-number-5:before {
	content: "\efa4";
}
.ri-number-6:before {
	content: "\efa5";
}
.ri-number-7:before {
	content: "\efa6";
}
.ri-number-8:before {
	content: "\efa7";
}
.ri-number-9:before {
	content: "\efa8";
}
.ri-numbers-fill:before {
	content: "\efa9";
}
.ri-numbers-line:before {
	content: "\efaa";
}
.ri-nurse-fill:before {
	content: "\efab";
}
.ri-nurse-line:before {
	content: "\efac";
}
.ri-oil-fill:before {
	content: "\efad";
}
.ri-oil-line:before {
	content: "\efae";
}
.ri-omega:before {
	content: "\efaf";
}
.ri-open-arm-fill:before {
	content: "\efb0";
}
.ri-open-arm-line:before {
	content: "\efb1";
}
.ri-open-source-fill:before {
	content: "\efb2";
}
.ri-open-source-line:before {
	content: "\efb3";
}
.ri-opera-fill:before {
	content: "\efb4";
}
.ri-opera-line:before {
	content: "\efb5";
}
.ri-order-play-fill:before {
	content: "\efb6";
}
.ri-order-play-line:before {
	content: "\efb7";
}
.ri-organization-chart:before {
	content: "\efb8";
}
.ri-outlet-2-fill:before {
	content: "\efb9";
}
.ri-outlet-2-line:before {
	content: "\efba";
}
.ri-outlet-fill:before {
	content: "\efbb";
}
.ri-outlet-line:before {
	content: "\efbc";
}
.ri-page-separator:before {
	content: "\efbd";
}
.ri-pages-fill:before {
	content: "\efbe";
}
.ri-pages-line:before {
	content: "\efbf";
}
.ri-paint-brush-fill:before {
	content: "\efc0";
}
.ri-paint-brush-line:before {
	content: "\efc1";
}
.ri-paint-fill:before {
	content: "\efc2";
}
.ri-paint-line:before {
	content: "\efc3";
}
.ri-palette-fill:before {
	content: "\efc4";
}
.ri-palette-line:before {
	content: "\efc5";
}
.ri-pantone-fill:before {
	content: "\efc6";
}
.ri-pantone-line:before {
	content: "\efc7";
}
.ri-paragraph:before {
	content: "\efc8";
}
.ri-parent-fill:before {
	content: "\efc9";
}
.ri-parent-line:before {
	content: "\efca";
}
.ri-parentheses-fill:before {
	content: "\efcb";
}
.ri-parentheses-line:before {
	content: "\efcc";
}
.ri-parking-box-fill:before {
	content: "\efcd";
}
.ri-parking-box-line:before {
	content: "\efce";
}
.ri-parking-fill:before {
	content: "\efcf";
}
.ri-parking-line:before {
	content: "\efd0";
}
.ri-passport-fill:before {
	content: "\efd1";
}
.ri-passport-line:before {
	content: "\efd2";
}
.ri-patreon-fill:before {
	content: "\efd3";
}
.ri-patreon-line:before {
	content: "\efd4";
}
.ri-pause-circle-fill:before {
	content: "\efd5";
}
.ri-pause-circle-line:before {
	content: "\efd6";
}
.ri-pause-fill:before {
	content: "\efd7";
}
.ri-pause-line:before {
	content: "\efd8";
}
.ri-pause-mini-fill:before {
	content: "\efd9";
}
.ri-pause-mini-line:before {
	content: "\efda";
}
.ri-paypal-fill:before {
	content: "\efdb";
}
.ri-paypal-line:before {
	content: "\efdc";
}
.ri-pen-nib-fill:before {
	content: "\efdd";
}
.ri-pen-nib-line:before {
	content: "\efde";
}
.ri-pencil-fill:before {
	content: "\efdf";
}
.ri-pencil-line:before {
	content: "\efe0";
}
.ri-pencil-ruler-2-fill:before {
	content: "\efe1";
}
.ri-pencil-ruler-2-line:before {
	content: "\efe2";
}
.ri-pencil-ruler-fill:before {
	content: "\efe3";
}
.ri-pencil-ruler-line:before {
	content: "\efe4";
}
.ri-percent-fill:before {
	content: "\efe5";
}
.ri-percent-line:before {
	content: "\efe6";
}
.ri-phone-camera-fill:before {
	content: "\efe7";
}
.ri-phone-camera-line:before {
	content: "\efe8";
}
.ri-phone-fill:before {
	content: "\efe9";
}
.ri-phone-find-fill:before {
	content: "\efea";
}
.ri-phone-find-line:before {
	content: "\efeb";
}
.ri-phone-line:before {
	content: "\efec";
}
.ri-phone-lock-fill:before {
	content: "\efed";
}
.ri-phone-lock-line:before {
	content: "\efee";
}
.ri-picture-in-picture-2-fill:before {
	content: "\efef";
}
.ri-picture-in-picture-2-line:before {
	content: "\eff0";
}
.ri-picture-in-picture-exit-fill:before {
	content: "\eff1";
}
.ri-picture-in-picture-exit-line:before {
	content: "\eff2";
}
.ri-picture-in-picture-fill:before {
	content: "\eff3";
}
.ri-picture-in-picture-line:before {
	content: "\eff4";
}
.ri-pie-chart-2-fill:before {
	content: "\eff5";
}
.ri-pie-chart-2-line:before {
	content: "\eff6";
}
.ri-pie-chart-box-fill:before {
	content: "\eff7";
}
.ri-pie-chart-box-line:before {
	content: "\eff8";
}
.ri-pie-chart-fill:before {
	content: "\eff9";
}
.ri-pie-chart-line:before {
	content: "\effa";
}
.ri-pin-distance-fill:before {
	content: "\effb";
}
.ri-pin-distance-line:before {
	content: "\effc";
}
.ri-ping-pong-fill:before {
	content: "\effd";
}
.ri-ping-pong-line:before {
	content: "\effe";
}
.ri-pinterest-fill:before {
	content: "\efff";
}
.ri-pinterest-line:before {
	content: "\f000";
}
.ri-pinyin-input:before {
	content: "\f001";
}
.ri-pixelfed-fill:before {
	content: "\f002";
}
.ri-pixelfed-line:before {
	content: "\f003";
}
.ri-plane-fill:before {
	content: "\f004";
}
.ri-plane-line:before {
	content: "\f005";
}
.ri-plant-fill:before {
	content: "\f006";
}
.ri-plant-line:before {
	content: "\f007";
}
.ri-play-circle-fill:before {
	content: "\f008";
}
.ri-play-circle-line:before {
	content: "\f009";
}
.ri-play-fill:before {
	content: "\f00a";
}
.ri-play-line:before {
	content: "\f00b";
}
.ri-play-list-2-fill:before {
	content: "\f00c";
}
.ri-play-list-2-line:before {
	content: "\f00d";
}
.ri-play-list-add-fill:before {
	content: "\f00e";
}
.ri-play-list-add-line:before {
	content: "\f00f";
}
.ri-play-list-fill:before {
	content: "\f010";
}
.ri-play-list-line:before {
	content: "\f011";
}
.ri-play-mini-fill:before {
	content: "\f012";
}
.ri-play-mini-line:before {
	content: "\f013";
}
.ri-playstation-fill:before {
	content: "\f014";
}
.ri-playstation-line:before {
	content: "\f015";
}
.ri-plug-2-fill:before {
	content: "\f016";
}
.ri-plug-2-line:before {
	content: "\f017";
}
.ri-plug-fill:before {
	content: "\f018";
}
.ri-plug-line:before {
	content: "\f019";
}
.ri-polaroid-2-fill:before {
	content: "\f01a";
}
.ri-polaroid-2-line:before {
	content: "\f01b";
}
.ri-polaroid-fill:before {
	content: "\f01c";
}
.ri-polaroid-line:before {
	content: "\f01d";
}
.ri-police-car-fill:before {
	content: "\f01e";
}
.ri-police-car-line:before {
	content: "\f01f";
}
.ri-price-tag-2-fill:before {
	content: "\f020";
}
.ri-price-tag-2-line:before {
	content: "\f021";
}
.ri-price-tag-3-fill:before {
	content: "\f022";
}
.ri-price-tag-3-line:before {
	content: "\f023";
}
.ri-price-tag-fill:before {
	content: "\f024";
}
.ri-price-tag-line:before {
	content: "\f025";
}
.ri-printer-cloud-fill:before {
	content: "\f026";
}
.ri-printer-cloud-line:before {
	content: "\f027";
}
.ri-printer-fill:before {
	content: "\f028";
}
.ri-printer-line:before {
	content: "\f029";
}
.ri-product-hunt-fill:before {
	content: "\f02a";
}
.ri-product-hunt-line:before {
	content: "\f02b";
}
.ri-profile-fill:before {
	content: "\f02c";
}
.ri-profile-line:before {
	content: "\f02d";
}
.ri-projector-2-fill:before {
	content: "\f02e";
}
.ri-projector-2-line:before {
	content: "\f02f";
}
.ri-projector-fill:before {
	content: "\f030";
}
.ri-projector-line:before {
	content: "\f031";
}
.ri-psychotherapy-fill:before {
	content: "\f032";
}
.ri-psychotherapy-line:before {
	content: "\f033";
}
.ri-pulse-fill:before {
	content: "\f034";
}
.ri-pulse-line:before {
	content: "\f035";
}
.ri-pushpin-2-fill:before {
	content: "\f036";
}
.ri-pushpin-2-line:before {
	content: "\f037";
}
.ri-pushpin-fill:before {
	content: "\f038";
}
.ri-pushpin-line:before {
	content: "\f039";
}
.ri-qq-fill:before {
	content: "\f03a";
}
.ri-qq-line:before {
	content: "\f03b";
}
.ri-qr-code-fill:before {
	content: "\f03c";
}
.ri-qr-code-line:before {
	content: "\f03d";
}
.ri-qr-scan-2-fill:before {
	content: "\f03e";
}
.ri-qr-scan-2-line:before {
	content: "\f03f";
}
.ri-qr-scan-fill:before {
	content: "\f040";
}
.ri-qr-scan-line:before {
	content: "\f041";
}
.ri-question-answer-fill:before {
	content: "\f042";
}
.ri-question-answer-line:before {
	content: "\f043";
}
.ri-question-fill:before {
	content: "\f044";
}
.ri-question-line:before {
	content: "\f045";
}
.ri-question-mark:before {
	content: "\f046";
}
.ri-questionnaire-fill:before {
	content: "\f047";
}
.ri-questionnaire-line:before {
	content: "\f048";
}
.ri-quill-pen-fill:before {
	content: "\f049";
}
.ri-quill-pen-line:before {
	content: "\f04a";
}
.ri-radar-fill:before {
	content: "\f04b";
}
.ri-radar-line:before {
	content: "\f04c";
}
.ri-radio-2-fill:before {
	content: "\f04d";
}
.ri-radio-2-line:before {
	content: "\f04e";
}
.ri-radio-button-fill:before {
	content: "\f04f";
}
.ri-radio-button-line:before {
	content: "\f050";
}
.ri-radio-fill:before {
	content: "\f051";
}
.ri-radio-line:before {
	content: "\f052";
}
.ri-rainbow-fill:before {
	content: "\f053";
}
.ri-rainbow-line:before {
	content: "\f054";
}
.ri-rainy-fill:before {
	content: "\f055";
}
.ri-rainy-line:before {
	content: "\f056";
}
.ri-reactjs-fill:before {
	content: "\f057";
}
.ri-reactjs-line:before {
	content: "\f058";
}
.ri-record-circle-fill:before {
	content: "\f059";
}
.ri-record-circle-line:before {
	content: "\f05a";
}
.ri-record-mail-fill:before {
	content: "\f05b";
}
.ri-record-mail-line:before {
	content: "\f05c";
}
.ri-recycle-fill:before {
	content: "\f05d";
}
.ri-recycle-line:before {
	content: "\f05e";
}
.ri-red-packet-fill:before {
	content: "\f05f";
}
.ri-red-packet-line:before {
	content: "\f060";
}
.ri-reddit-fill:before {
	content: "\f061";
}
.ri-reddit-line:before {
	content: "\f062";
}
.ri-refresh-fill:before {
	content: "\f063";
}
.ri-refresh-line:before {
	content: "\f064";
}
.ri-refund-2-fill:before {
	content: "\f065";
}
.ri-refund-2-line:before {
	content: "\f066";
}
.ri-refund-fill:before {
	content: "\f067";
}
.ri-refund-line:before {
	content: "\f068";
}
.ri-registered-fill:before {
	content: "\f069";
}
.ri-registered-line:before {
	content: "\f06a";
}
.ri-remixicon-fill:before {
	content: "\f06b";
}
.ri-remixicon-line:before {
	content: "\f06c";
}
.ri-remote-control-2-fill:before {
	content: "\f06d";
}
.ri-remote-control-2-line:before {
	content: "\f06e";
}
.ri-remote-control-fill:before {
	content: "\f06f";
}
.ri-remote-control-line:before {
	content: "\f070";
}
.ri-repeat-2-fill:before {
	content: "\f071";
}
.ri-repeat-2-line:before {
	content: "\f072";
}
.ri-repeat-fill:before {
	content: "\f073";
}
.ri-repeat-line:before {
	content: "\f074";
}
.ri-repeat-one-fill:before {
	content: "\f075";
}
.ri-repeat-one-line:before {
	content: "\f076";
}
.ri-reply-all-fill:before {
	content: "\f077";
}
.ri-reply-all-line:before {
	content: "\f078";
}
.ri-reply-fill:before {
	content: "\f079";
}
.ri-reply-line:before {
	content: "\f07a";
}
.ri-reserved-fill:before {
	content: "\f07b";
}
.ri-reserved-line:before {
	content: "\f07c";
}
.ri-rest-time-fill:before {
	content: "\f07d";
}
.ri-rest-time-line:before {
	content: "\f07e";
}
.ri-restart-fill:before {
	content: "\f07f";
}
.ri-restart-line:before {
	content: "\f080";
}
.ri-restaurant-2-fill:before {
	content: "\f081";
}
.ri-restaurant-2-line:before {
	content: "\f082";
}
.ri-restaurant-fill:before {
	content: "\f083";
}
.ri-restaurant-line:before {
	content: "\f084";
}
.ri-rewind-fill:before {
	content: "\f085";
}
.ri-rewind-line:before {
	content: "\f086";
}
.ri-rewind-mini-fill:before {
	content: "\f087";
}
.ri-rewind-mini-line:before {
	content: "\f088";
}
.ri-rhythm-fill:before {
	content: "\f089";
}
.ri-rhythm-line:before {
	content: "\f08a";
}
.ri-riding-fill:before {
	content: "\f08b";
}
.ri-riding-line:before {
	content: "\f08c";
}
.ri-road-map-fill:before {
	content: "\f08d";
}
.ri-road-map-line:before {
	content: "\f08e";
}
.ri-roadster-fill:before {
	content: "\f08f";
}
.ri-roadster-line:before {
	content: "\f090";
}
.ri-robot-fill:before {
	content: "\f091";
}
.ri-robot-line:before {
	content: "\f092";
}
.ri-rocket-2-fill:before {
	content: "\f093";
}
.ri-rocket-2-line:before {
	content: "\f094";
}
.ri-rocket-fill:before {
	content: "\f095";
}
.ri-rocket-line:before {
	content: "\f096";
}
.ri-rotate-lock-fill:before {
	content: "\f097";
}
.ri-rotate-lock-line:before {
	content: "\f098";
}
.ri-rounded-corner:before {
	content: "\f099";
}
.ri-route-fill:before {
	content: "\f09a";
}
.ri-route-line:before {
	content: "\f09b";
}
.ri-router-fill:before {
	content: "\f09c";
}
.ri-router-line:before {
	content: "\f09d";
}
.ri-rss-fill:before {
	content: "\f09e";
}
.ri-rss-line:before {
	content: "\f09f";
}
.ri-ruler-2-fill:before {
	content: "\f0a0";
}
.ri-ruler-2-line:before {
	content: "\f0a1";
}
.ri-ruler-fill:before {
	content: "\f0a2";
}
.ri-ruler-line:before {
	content: "\f0a3";
}
.ri-run-fill:before {
	content: "\f0a4";
}
.ri-run-line:before {
	content: "\f0a5";
}
.ri-safari-fill:before {
	content: "\f0a6";
}
.ri-safari-line:before {
	content: "\f0a7";
}
.ri-safe-2-fill:before {
	content: "\f0a8";
}
.ri-safe-2-line:before {
	content: "\f0a9";
}
.ri-safe-fill:before {
	content: "\f0aa";
}
.ri-safe-line:before {
	content: "\f0ab";
}
.ri-sailboat-fill:before {
	content: "\f0ac";
}
.ri-sailboat-line:before {
	content: "\f0ad";
}
.ri-save-2-fill:before {
	content: "\f0ae";
}
.ri-save-2-line:before {
	content: "\f0af";
}
.ri-save-3-fill:before {
	content: "\f0b0";
}
.ri-save-3-line:before {
	content: "\f0b1";
}
.ri-save-fill:before {
	content: "\f0b2";
}
.ri-save-line:before {
	content: "\f0b3";
}
.ri-scales-2-fill:before {
	content: "\f0b4";
}
.ri-scales-2-line:before {
	content: "\f0b5";
}
.ri-scales-3-fill:before {
	content: "\f0b6";
}
.ri-scales-3-line:before {
	content: "\f0b7";
}
.ri-scales-fill:before {
	content: "\f0b8";
}
.ri-scales-line:before {
	content: "\f0b9";
}
.ri-scan-2-fill:before {
	content: "\f0ba";
}
.ri-scan-2-line:before {
	content: "\f0bb";
}
.ri-scan-fill:before {
	content: "\f0bc";
}
.ri-scan-line:before {
	content: "\f0bd";
}
.ri-scissors-2-fill:before {
	content: "\f0be";
}
.ri-scissors-2-line:before {
	content: "\f0bf";
}
.ri-scissors-cut-fill:before {
	content: "\f0c0";
}
.ri-scissors-cut-line:before {
	content: "\f0c1";
}
.ri-scissors-fill:before {
	content: "\f0c2";
}
.ri-scissors-line:before {
	content: "\f0c3";
}
.ri-screenshot-2-fill:before {
	content: "\f0c4";
}
.ri-screenshot-2-line:before {
	content: "\f0c5";
}
.ri-screenshot-fill:before {
	content: "\f0c6";
}
.ri-screenshot-line:before {
	content: "\f0c7";
}
.ri-sd-card-fill:before {
	content: "\f0c8";
}
.ri-sd-card-line:before {
	content: "\f0c9";
}
.ri-sd-card-mini-fill:before {
	content: "\f0ca";
}
.ri-sd-card-mini-line:before {
	content: "\f0cb";
}
.ri-search-2-fill:before {
	content: "\f0cc";
}
.ri-search-2-line:before {
	content: "\f0cd";
}
.ri-search-eye-fill:before {
	content: "\f0ce";
}
.ri-search-eye-line:before {
	content: "\f0cf";
}
.ri-search-fill:before {
	content: "\f0d0";
}
.ri-search-line:before {
	content: "\f0d1";
}
.ri-secure-payment-fill:before {
	content: "\f0d2";
}
.ri-secure-payment-line:before {
	content: "\f0d3";
}
.ri-seedling-fill:before {
	content: "\f0d4";
}
.ri-seedling-line:before {
	content: "\f0d5";
}
.ri-send-backward:before {
	content: "\f0d6";
}
.ri-send-plane-2-fill:before {
	content: "\f0d7";
}
.ri-send-plane-2-line:before {
	content: "\f0d8";
}
.ri-send-plane-fill:before {
	content: "\f0d9";
}
.ri-send-plane-line:before {
	content: "\f0da";
}
.ri-send-to-back:before {
	content: "\f0db";
}
.ri-sensor-fill:before {
	content: "\f0dc";
}
.ri-sensor-line:before {
	content: "\f0dd";
}
.ri-separator:before {
	content: "\f0de";
}
.ri-server-fill:before {
	content: "\f0df";
}
.ri-server-line:before {
	content: "\f0e0";
}
.ri-service-fill:before {
	content: "\f0e1";
}
.ri-service-line:before {
	content: "\f0e2";
}
.ri-settings-2-fill:before {
	content: "\f0e3";
}
.ri-settings-2-line:before {
	content: "\f0e4";
}
.ri-settings-3-fill:before {
	content: "\f0e5";
}
.ri-settings-3-line:before {
	content: "\f0e6";
}
.ri-settings-4-fill:before {
	content: "\f0e7";
}
.ri-settings-4-line:before {
	content: "\f0e8";
}
.ri-settings-5-fill:before {
	content: "\f0e9";
}
.ri-settings-5-line:before {
	content: "\f0ea";
}
.ri-settings-6-fill:before {
	content: "\f0eb";
}
.ri-settings-6-line:before {
	content: "\f0ec";
}
.ri-settings-fill:before {
	content: "\f0ed";
}
.ri-settings-line:before {
	content: "\f0ee";
}
.ri-shape-2-fill:before {
	content: "\f0ef";
}
.ri-shape-2-line:before {
	content: "\f0f0";
}
.ri-shape-fill:before {
	content: "\f0f1";
}
.ri-shape-line:before {
	content: "\f0f2";
}
.ri-share-box-fill:before {
	content: "\f0f3";
}
.ri-share-box-line:before {
	content: "\f0f4";
}
.ri-share-circle-fill:before {
	content: "\f0f5";
}
.ri-share-circle-line:before {
	content: "\f0f6";
}
.ri-share-fill:before {
	content: "\f0f7";
}
.ri-share-forward-2-fill:before {
	content: "\f0f8";
}
.ri-share-forward-2-line:before {
	content: "\f0f9";
}
.ri-share-forward-box-fill:before {
	content: "\f0fa";
}
.ri-share-forward-box-line:before {
	content: "\f0fb";
}
.ri-share-forward-fill:before {
	content: "\f0fc";
}
.ri-share-forward-line:before {
	content: "\f0fd";
}
.ri-share-line:before {
	content: "\f0fe";
}
.ri-shield-check-fill:before {
	content: "\f0ff";
}
.ri-shield-check-line:before {
	content: "\f100";
}
.ri-shield-cross-fill:before {
	content: "\f101";
}
.ri-shield-cross-line:before {
	content: "\f102";
}
.ri-shield-fill:before {
	content: "\f103";
}
.ri-shield-flash-fill:before {
	content: "\f104";
}
.ri-shield-flash-line:before {
	content: "\f105";
}
.ri-shield-keyhole-fill:before {
	content: "\f106";
}
.ri-shield-keyhole-line:before {
	content: "\f107";
}
.ri-shield-line:before {
	content: "\f108";
}
.ri-shield-star-fill:before {
	content: "\f109";
}
.ri-shield-star-line:before {
	content: "\f10a";
}
.ri-shield-user-fill:before {
	content: "\f10b";
}
.ri-shield-user-line:before {
	content: "\f10c";
}
.ri-ship-2-fill:before {
	content: "\f10d";
}
.ri-ship-2-line:before {
	content: "\f10e";
}
.ri-ship-fill:before {
	content: "\f10f";
}
.ri-ship-line:before {
	content: "\f110";
}
.ri-shirt-fill:before {
	content: "\f111";
}
.ri-shirt-line:before {
	content: "\f112";
}
.ri-shopping-bag-2-fill:before {
	content: "\f113";
}
.ri-shopping-bag-2-line:before {
	content: "\f114";
}
.ri-shopping-bag-3-fill:before {
	content: "\f115";
}
.ri-shopping-bag-3-line:before {
	content: "\f116";
}
.ri-shopping-bag-fill:before {
	content: "\f117";
}
.ri-shopping-bag-line:before {
	content: "\f118";
}
.ri-shopping-basket-2-fill:before {
	content: "\f119";
}
.ri-shopping-basket-2-line:before {
	content: "\f11a";
}
.ri-shopping-basket-fill:before {
	content: "\f11b";
}
.ri-shopping-basket-line:before {
	content: "\f11c";
}
.ri-shopping-cart-2-fill:before {
	content: "\f11d";
}
.ri-shopping-cart-2-line:before {
	content: "\f11e";
}
.ri-shopping-cart-fill:before {
	content: "\f11f";
}
.ri-shopping-cart-line:before {
	content: "\f120";
}
.ri-showers-fill:before {
	content: "\f121";
}
.ri-showers-line:before {
	content: "\f122";
}
.ri-shuffle-fill:before {
	content: "\f123";
}
.ri-shuffle-line:before {
	content: "\f124";
}
.ri-shut-down-fill:before {
	content: "\f125";
}
.ri-shut-down-line:before {
	content: "\f126";
}
.ri-side-bar-fill:before {
	content: "\f127";
}
.ri-side-bar-line:before {
	content: "\f128";
}
.ri-signal-tower-fill:before {
	content: "\f129";
}
.ri-signal-tower-line:before {
	content: "\f12a";
}
.ri-signal-wifi-1-fill:before {
	content: "\f12b";
}
.ri-signal-wifi-1-line:before {
	content: "\f12c";
}
.ri-signal-wifi-2-fill:before {
	content: "\f12d";
}
.ri-signal-wifi-2-line:before {
	content: "\f12e";
}
.ri-signal-wifi-3-fill:before {
	content: "\f12f";
}
.ri-signal-wifi-3-line:before {
	content: "\f130";
}
.ri-signal-wifi-error-fill:before {
	content: "\f131";
}
.ri-signal-wifi-error-line:before {
	content: "\f132";
}
.ri-signal-wifi-fill:before {
	content: "\f133";
}
.ri-signal-wifi-line:before {
	content: "\f134";
}
.ri-signal-wifi-off-fill:before {
	content: "\f135";
}
.ri-signal-wifi-off-line:before {
	content: "\f136";
}
.ri-sim-card-2-fill:before {
	content: "\f137";
}
.ri-sim-card-2-line:before {
	content: "\f138";
}
.ri-sim-card-fill:before {
	content: "\f139";
}
.ri-sim-card-line:before {
	content: "\f13a";
}
.ri-single-quotes-l:before {
	content: "\f13b";
}
.ri-single-quotes-r:before {
	content: "\f13c";
}
.ri-sip-fill:before {
	content: "\f13d";
}
.ri-sip-line:before {
	content: "\f13e";
}
.ri-skip-back-fill:before {
	content: "\f13f";
}
.ri-skip-back-line:before {
	content: "\f140";
}
.ri-skip-back-mini-fill:before {
	content: "\f141";
}
.ri-skip-back-mini-line:before {
	content: "\f142";
}
.ri-skip-forward-fill:before {
	content: "\f143";
}
.ri-skip-forward-line:before {
	content: "\f144";
}
.ri-skip-forward-mini-fill:before {
	content: "\f145";
}
.ri-skip-forward-mini-line:before {
	content: "\f146";
}
.ri-skull-2-fill:before {
	content: "\f147";
}
.ri-skull-2-line:before {
	content: "\f148";
}
.ri-skull-fill:before {
	content: "\f149";
}
.ri-skull-line:before {
	content: "\f14a";
}
.ri-skype-fill:before {
	content: "\f14b";
}
.ri-skype-line:before {
	content: "\f14c";
}
.ri-slack-fill:before {
	content: "\f14d";
}
.ri-slack-line:before {
	content: "\f14e";
}
.ri-slice-fill:before {
	content: "\f14f";
}
.ri-slice-line:before {
	content: "\f150";
}
.ri-slideshow-2-fill:before {
	content: "\f151";
}
.ri-slideshow-2-line:before {
	content: "\f152";
}
.ri-slideshow-3-fill:before {
	content: "\f153";
}
.ri-slideshow-3-line:before {
	content: "\f154";
}
.ri-slideshow-4-fill:before {
	content: "\f155";
}
.ri-slideshow-4-line:before {
	content: "\f156";
}
.ri-slideshow-fill:before {
	content: "\f157";
}
.ri-slideshow-line:before {
	content: "\f158";
}
.ri-smartphone-fill:before {
	content: "\f159";
}
.ri-smartphone-line:before {
	content: "\f15a";
}
.ri-snapchat-fill:before {
	content: "\f15b";
}
.ri-snapchat-line:before {
	content: "\f15c";
}
.ri-snowy-fill:before {
	content: "\f15d";
}
.ri-snowy-line:before {
	content: "\f15e";
}
.ri-sort-asc:before {
	content: "\f15f";
}
.ri-sort-desc:before {
	content: "\f160";
}
.ri-sound-module-fill:before {
	content: "\f161";
}
.ri-sound-module-line:before {
	content: "\f162";
}
.ri-soundcloud-fill:before {
	content: "\f163";
}
.ri-soundcloud-line:before {
	content: "\f164";
}
.ri-space-ship-fill:before {
	content: "\f165";
}
.ri-space-ship-line:before {
	content: "\f166";
}
.ri-space:before {
	content: "\f167";
}
.ri-spam-2-fill:before {
	content: "\f168";
}
.ri-spam-2-line:before {
	content: "\f169";
}
.ri-spam-3-fill:before {
	content: "\f16a";
}
.ri-spam-3-line:before {
	content: "\f16b";
}
.ri-spam-fill:before {
	content: "\f16c";
}
.ri-spam-line:before {
	content: "\f16d";
}
.ri-speaker-2-fill:before {
	content: "\f16e";
}
.ri-speaker-2-line:before {
	content: "\f16f";
}
.ri-speaker-3-fill:before {
	content: "\f170";
}
.ri-speaker-3-line:before {
	content: "\f171";
}
.ri-speaker-fill:before {
	content: "\f172";
}
.ri-speaker-line:before {
	content: "\f173";
}
.ri-spectrum-fill:before {
	content: "\f174";
}
.ri-spectrum-line:before {
	content: "\f175";
}
.ri-speed-fill:before {
	content: "\f176";
}
.ri-speed-line:before {
	content: "\f177";
}
.ri-speed-mini-fill:before {
	content: "\f178";
}
.ri-speed-mini-line:before {
	content: "\f179";
}
.ri-split-cells-horizontal:before {
	content: "\f17a";
}
.ri-split-cells-vertical:before {
	content: "\f17b";
}
.ri-spotify-fill:before {
	content: "\f17c";
}
.ri-spotify-line:before {
	content: "\f17d";
}
.ri-spy-fill:before {
	content: "\f17e";
}
.ri-spy-line:before {
	content: "\f17f";
}
.ri-stack-fill:before {
	content: "\f180";
}
.ri-stack-line:before {
	content: "\f181";
}
.ri-stack-overflow-fill:before {
	content: "\f182";
}
.ri-stack-overflow-line:before {
	content: "\f183";
}
.ri-stackshare-fill:before {
	content: "\f184";
}
.ri-stackshare-line:before {
	content: "\f185";
}
.ri-star-fill:before {
	content: "\f186";
}
.ri-star-half-fill:before {
	content: "\f187";
}
.ri-star-half-line:before {
	content: "\f188";
}
.ri-star-half-s-fill:before {
	content: "\f189";
}
.ri-star-half-s-line:before {
	content: "\f18a";
}
.ri-star-line:before {
	content: "\f18b";
}
.ri-star-s-fill:before {
	content: "\f18c";
}
.ri-star-s-line:before {
	content: "\f18d";
}
.ri-star-smile-fill:before {
	content: "\f18e";
}
.ri-star-smile-line:before {
	content: "\f18f";
}
.ri-steam-fill:before {
	content: "\f190";
}
.ri-steam-line:before {
	content: "\f191";
}
.ri-steering-2-fill:before {
	content: "\f192";
}
.ri-steering-2-line:before {
	content: "\f193";
}
.ri-steering-fill:before {
	content: "\f194";
}
.ri-steering-line:before {
	content: "\f195";
}
.ri-stethoscope-fill:before {
	content: "\f196";
}
.ri-stethoscope-line:before {
	content: "\f197";
}
.ri-sticky-note-2-fill:before {
	content: "\f198";
}
.ri-sticky-note-2-line:before {
	content: "\f199";
}
.ri-sticky-note-fill:before {
	content: "\f19a";
}
.ri-sticky-note-line:before {
	content: "\f19b";
}
.ri-stock-fill:before {
	content: "\f19c";
}
.ri-stock-line:before {
	content: "\f19d";
}
.ri-stop-circle-fill:before {
	content: "\f19e";
}
.ri-stop-circle-line:before {
	content: "\f19f";
}
.ri-stop-fill:before {
	content: "\f1a0";
}
.ri-stop-line:before {
	content: "\f1a1";
}
.ri-stop-mini-fill:before {
	content: "\f1a2";
}
.ri-stop-mini-line:before {
	content: "\f1a3";
}
.ri-store-2-fill:before {
	content: "\f1a4";
}
.ri-store-2-line:before {
	content: "\f1a5";
}
.ri-store-3-fill:before {
	content: "\f1a6";
}
.ri-store-3-line:before {
	content: "\f1a7";
}
.ri-store-fill:before {
	content: "\f1a8";
}
.ri-store-line:before {
	content: "\f1a9";
}
.ri-strikethrough-2:before {
	content: "\f1aa";
}
.ri-strikethrough:before {
	content: "\f1ab";
}
.ri-subscript-2:before {
	content: "\f1ac";
}
.ri-subscript:before {
	content: "\f1ad";
}
.ri-subtract-fill:before {
	content: "\f1ae";
}
.ri-subtract-line:before {
	content: "\f1af";
}
.ri-subway-fill:before {
	content: "\f1b0";
}
.ri-subway-line:before {
	content: "\f1b1";
}
.ri-subway-wifi-fill:before {
	content: "\f1b2";
}
.ri-subway-wifi-line:before {
	content: "\f1b3";
}
.ri-suitcase-2-fill:before {
	content: "\f1b4";
}
.ri-suitcase-2-line:before {
	content: "\f1b5";
}
.ri-suitcase-3-fill:before {
	content: "\f1b6";
}
.ri-suitcase-3-line:before {
	content: "\f1b7";
}
.ri-suitcase-fill:before {
	content: "\f1b8";
}
.ri-suitcase-line:before {
	content: "\f1b9";
}
.ri-sun-cloudy-fill:before {
	content: "\f1ba";
}
.ri-sun-cloudy-line:before {
	content: "\f1bb";
}
.ri-sun-fill:before {
	content: "\f1bc";
}
.ri-sun-foggy-fill:before {
	content: "\f1bd";
}
.ri-sun-foggy-line:before {
	content: "\f1be";
}
.ri-sun-line:before {
	content: "\f1bf";
}
.ri-superscript-2:before {
	content: "\f1c0";
}
.ri-superscript:before {
	content: "\f1c1";
}
.ri-surgical-mask-fill:before {
	content: "\f1c2";
}
.ri-surgical-mask-line:before {
	content: "\f1c3";
}
.ri-surround-sound-fill:before {
	content: "\f1c4";
}
.ri-surround-sound-line:before {
	content: "\f1c5";
}
.ri-survey-fill:before {
	content: "\f1c6";
}
.ri-survey-line:before {
	content: "\f1c7";
}
.ri-swap-box-fill:before {
	content: "\f1c8";
}
.ri-swap-box-line:before {
	content: "\f1c9";
}
.ri-swap-fill:before {
	content: "\f1ca";
}
.ri-swap-line:before {
	content: "\f1cb";
}
.ri-switch-fill:before {
	content: "\f1cc";
}
.ri-switch-line:before {
	content: "\f1cd";
}
.ri-sword-fill:before {
	content: "\f1ce";
}
.ri-sword-line:before {
	content: "\f1cf";
}
.ri-syringe-fill:before {
	content: "\f1d0";
}
.ri-syringe-line:before {
	content: "\f1d1";
}
.ri-t-box-fill:before {
	content: "\f1d2";
}
.ri-t-box-line:before {
	content: "\f1d3";
}
.ri-t-shirt-2-fill:before {
	content: "\f1d4";
}
.ri-t-shirt-2-line:before {
	content: "\f1d5";
}
.ri-t-shirt-air-fill:before {
	content: "\f1d6";
}
.ri-t-shirt-air-line:before {
	content: "\f1d7";
}
.ri-t-shirt-fill:before {
	content: "\f1d8";
}
.ri-t-shirt-line:before {
	content: "\f1d9";
}
.ri-table-2:before {
	content: "\f1da";
}
.ri-table-alt-fill:before {
	content: "\f1db";
}
.ri-table-alt-line:before {
	content: "\f1dc";
}
.ri-table-fill:before {
	content: "\f1dd";
}
.ri-table-line:before {
	content: "\f1de";
}
.ri-tablet-fill:before {
	content: "\f1df";
}
.ri-tablet-line:before {
	content: "\f1e0";
}
.ri-takeaway-fill:before {
	content: "\f1e1";
}
.ri-takeaway-line:before {
	content: "\f1e2";
}
.ri-taobao-fill:before {
	content: "\f1e3";
}
.ri-taobao-line:before {
	content: "\f1e4";
}
.ri-tape-fill:before {
	content: "\f1e5";
}
.ri-tape-line:before {
	content: "\f1e6";
}
.ri-task-fill:before {
	content: "\f1e7";
}
.ri-task-line:before {
	content: "\f1e8";
}
.ri-taxi-fill:before {
	content: "\f1e9";
}
.ri-taxi-line:before {
	content: "\f1ea";
}
.ri-taxi-wifi-fill:before {
	content: "\f1eb";
}
.ri-taxi-wifi-line:before {
	content: "\f1ec";
}
.ri-team-fill:before {
	content: "\f1ed";
}
.ri-team-line:before {
	content: "\f1ee";
}
.ri-telegram-fill:before {
	content: "\f1ef";
}
.ri-telegram-line:before {
	content: "\f1f0";
}
.ri-temp-cold-fill:before {
	content: "\f1f1";
}
.ri-temp-cold-line:before {
	content: "\f1f2";
}
.ri-temp-hot-fill:before {
	content: "\f1f3";
}
.ri-temp-hot-line:before {
	content: "\f1f4";
}
.ri-terminal-box-fill:before {
	content: "\f1f5";
}
.ri-terminal-box-line:before {
	content: "\f1f6";
}
.ri-terminal-fill:before {
	content: "\f1f7";
}
.ri-terminal-line:before {
	content: "\f1f8";
}
.ri-terminal-window-fill:before {
	content: "\f1f9";
}
.ri-terminal-window-line:before {
	content: "\f1fa";
}
.ri-test-tube-fill:before {
	content: "\f1fb";
}
.ri-test-tube-line:before {
	content: "\f1fc";
}
.ri-text-direction-l:before {
	content: "\f1fd";
}
.ri-text-direction-r:before {
	content: "\f1fe";
}
.ri-text-spacing:before {
	content: "\f1ff";
}
.ri-text-wrap:before {
	content: "\f200";
}
.ri-text:before {
	content: "\f201";
}
.ri-thermometer-fill:before {
	content: "\f202";
}
.ri-thermometer-line:before {
	content: "\f203";
}
.ri-thumb-down-fill:before {
	content: "\f204";
}
.ri-thumb-down-line:before {
	content: "\f205";
}
.ri-thumb-up-fill:before {
	content: "\f206";
}
.ri-thumb-up-line:before {
	content: "\f207";
}
.ri-thunderstorms-fill:before {
	content: "\f208";
}
.ri-thunderstorms-line:before {
	content: "\f209";
}
.ri-ticket-2-fill:before {
	content: "\f20a";
}
.ri-ticket-2-line:before {
	content: "\f20b";
}
.ri-ticket-fill:before {
	content: "\f20c";
}
.ri-ticket-line:before {
	content: "\f20d";
}
.ri-time-fill:before {
	content: "\f20e";
}
.ri-time-line:before {
	content: "\f20f";
}
.ri-timer-2-fill:before {
	content: "\f210";
}
.ri-timer-2-line:before {
	content: "\f211";
}
.ri-timer-fill:before {
	content: "\f212";
}
.ri-timer-flash-fill:before {
	content: "\f213";
}
.ri-timer-flash-line:before {
	content: "\f214";
}
.ri-timer-line:before {
	content: "\f215";
}
.ri-todo-fill:before {
	content: "\f216";
}
.ri-todo-line:before {
	content: "\f217";
}
.ri-toggle-fill:before {
	content: "\f218";
}
.ri-toggle-line:before {
	content: "\f219";
}
.ri-tools-fill:before {
	content: "\f21a";
}
.ri-tools-line:before {
	content: "\f21b";
}
.ri-tornado-fill:before {
	content: "\f21c";
}
.ri-tornado-line:before {
	content: "\f21d";
}
.ri-trademark-fill:before {
	content: "\f21e";
}
.ri-trademark-line:before {
	content: "\f21f";
}
.ri-traffic-light-fill:before {
	content: "\f220";
}
.ri-traffic-light-line:before {
	content: "\f221";
}
.ri-train-fill:before {
	content: "\f222";
}
.ri-train-line:before {
	content: "\f223";
}
.ri-train-wifi-fill:before {
	content: "\f224";
}
.ri-train-wifi-line:before {
	content: "\f225";
}
.ri-translate-2:before {
	content: "\f226";
}
.ri-translate:before {
	content: "\f227";
}
.ri-travesti-fill:before {
	content: "\f228";
}
.ri-travesti-line:before {
	content: "\f229";
}
.ri-treasure-map-fill:before {
	content: "\f22a";
}
.ri-treasure-map-line:before {
	content: "\f22b";
}
.ri-trello-fill:before {
	content: "\f22c";
}
.ri-trello-line:before {
	content: "\f22d";
}
.ri-trophy-fill:before {
	content: "\f22e";
}
.ri-trophy-line:before {
	content: "\f22f";
}
.ri-truck-fill:before {
	content: "\f230";
}
.ri-truck-line:before {
	content: "\f231";
}
.ri-tumblr-fill:before {
	content: "\f232";
}
.ri-tumblr-line:before {
	content: "\f233";
}
.ri-tv-2-fill:before {
	content: "\f234";
}
.ri-tv-2-line:before {
	content: "\f235";
}
.ri-tv-fill:before {
	content: "\f236";
}
.ri-tv-line:before {
	content: "\f237";
}
.ri-twitch-fill:before {
	content: "\f238";
}
.ri-twitch-line:before {
	content: "\f239";
}
.ri-twitter-fill:before {
	content: "\f23a";
}
.ri-twitter-line:before {
	content: "\f23b";
}
.ri-typhoon-fill:before {
	content: "\f23c";
}
.ri-typhoon-line:before {
	content: "\f23d";
}
.ri-u-disk-fill:before {
	content: "\f23e";
}
.ri-u-disk-line:before {
	content: "\f23f";
}
.ri-ubuntu-fill:before {
	content: "\f240";
}
.ri-ubuntu-line:before {
	content: "\f241";
}
.ri-umbrella-fill:before {
	content: "\f242";
}
.ri-umbrella-line:before {
	content: "\f243";
}
.ri-underline:before {
	content: "\f244";
}
.ri-uninstall-fill:before {
	content: "\f245";
}
.ri-uninstall-line:before {
	content: "\f246";
}
.ri-unsplash-fill:before {
	content: "\f247";
}
.ri-unsplash-line:before {
	content: "\f248";
}
.ri-upload-2-fill:before {
	content: "\f249";
}
.ri-upload-2-line:before {
	content: "\f24a";
}
.ri-upload-cloud-2-fill:before {
	content: "\f24b";
}
.ri-upload-cloud-2-line:before {
	content: "\f24c";
}
.ri-upload-cloud-fill:before {
	content: "\f24d";
}
.ri-upload-cloud-line:before {
	content: "\f24e";
}
.ri-upload-fill:before {
	content: "\f24f";
}
.ri-upload-line:before {
	content: "\f250";
}
.ri-usb-fill:before {
	content: "\f251";
}
.ri-usb-line:before {
	content: "\f252";
}
.ri-user-2-fill:before {
	content: "\f253";
}
.ri-user-2-line:before {
	content: "\f254";
}
.ri-user-3-fill:before {
	content: "\f255";
}
.ri-user-3-line:before {
	content: "\f256";
}
.ri-user-4-fill:before {
	content: "\f257";
}
.ri-user-4-line:before {
	content: "\f258";
}
.ri-user-5-fill:before {
	content: "\f259";
}
.ri-user-5-line:before {
	content: "\f25a";
}
.ri-user-6-fill:before {
	content: "\f25b";
}
.ri-user-6-line:before {
	content: "\f25c";
}
.ri-user-add-fill:before {
	content: "\f25d";
}
.ri-user-add-line:before {
	content: "\f25e";
}
.ri-user-fill:before {
	content: "\f25f";
}
.ri-user-follow-fill:before {
	content: "\f260";
}
.ri-user-follow-line:before {
	content: "\f261";
}
.ri-user-heart-fill:before {
	content: "\f262";
}
.ri-user-heart-line:before {
	content: "\f263";
}
.ri-user-line:before {
	content: "\f264";
}
.ri-user-location-fill:before {
	content: "\f265";
}
.ri-user-location-line:before {
	content: "\f266";
}
.ri-user-received-2-fill:before {
	content: "\f267";
}
.ri-user-received-2-line:before {
	content: "\f268";
}
.ri-user-received-fill:before {
	content: "\f269";
}
.ri-user-received-line:before {
	content: "\f26a";
}
.ri-user-search-fill:before {
	content: "\f26b";
}
.ri-user-search-line:before {
	content: "\f26c";
}
.ri-user-settings-fill:before {
	content: "\f26d";
}
.ri-user-settings-line:before {
	content: "\f26e";
}
.ri-user-shared-2-fill:before {
	content: "\f26f";
}
.ri-user-shared-2-line:before {
	content: "\f270";
}
.ri-user-shared-fill:before {
	content: "\f271";
}
.ri-user-shared-line:before {
	content: "\f272";
}
.ri-user-smile-fill:before {
	content: "\f273";
}
.ri-user-smile-line:before {
	content: "\f274";
}
.ri-user-star-fill:before {
	content: "\f275";
}
.ri-user-star-line:before {
	content: "\f276";
}
.ri-user-unfollow-fill:before {
	content: "\f277";
}
.ri-user-unfollow-line:before {
	content: "\f278";
}
.ri-user-voice-fill:before {
	content: "\f279";
}
.ri-user-voice-line:before {
	content: "\f27a";
}
.ri-video-add-fill:before {
	content: "\f27b";
}
.ri-video-add-line:before {
	content: "\f27c";
}
.ri-video-chat-fill:before {
	content: "\f27d";
}
.ri-video-chat-line:before {
	content: "\f27e";
}
.ri-video-download-fill:before {
	content: "\f27f";
}
.ri-video-download-line:before {
	content: "\f280";
}
.ri-video-fill:before {
	content: "\f281";
}
.ri-video-line:before {
	content: "\f282";
}
.ri-video-upload-fill:before {
	content: "\f283";
}
.ri-video-upload-line:before {
	content: "\f284";
}
.ri-vidicon-2-fill:before {
	content: "\f285";
}
.ri-vidicon-2-line:before {
	content: "\f286";
}
.ri-vidicon-fill:before {
	content: "\f287";
}
.ri-vidicon-line:before {
	content: "\f288";
}
.ri-vimeo-fill:before {
	content: "\f289";
}
.ri-vimeo-line:before {
	content: "\f28a";
}
.ri-vip-crown-2-fill:before {
	content: "\f28b";
}
.ri-vip-crown-2-line:before {
	content: "\f28c";
}
.ri-vip-crown-fill:before {
	content: "\f28d";
}
.ri-vip-crown-line:before {
	content: "\f28e";
}
.ri-vip-diamond-fill:before {
	content: "\f28f";
}
.ri-vip-diamond-line:before {
	content: "\f290";
}
.ri-vip-fill:before {
	content: "\f291";
}
.ri-vip-line:before {
	content: "\f292";
}
.ri-virus-fill:before {
	content: "\f293";
}
.ri-virus-line:before {
	content: "\f294";
}
.ri-visa-fill:before {
	content: "\f295";
}
.ri-visa-line:before {
	content: "\f296";
}
.ri-voice-recognition-fill:before {
	content: "\f297";
}
.ri-voice-recognition-line:before {
	content: "\f298";
}
.ri-voiceprint-fill:before {
	content: "\f299";
}
.ri-voiceprint-line:before {
	content: "\f29a";
}
.ri-volume-down-fill:before {
	content: "\f29b";
}
.ri-volume-down-line:before {
	content: "\f29c";
}
.ri-volume-mute-fill:before {
	content: "\f29d";
}
.ri-volume-mute-line:before {
	content: "\f29e";
}
.ri-volume-off-vibrate-fill:before {
	content: "\f29f";
}
.ri-volume-off-vibrate-line:before {
	content: "\f2a0";
}
.ri-volume-up-fill:before {
	content: "\f2a1";
}
.ri-volume-up-line:before {
	content: "\f2a2";
}
.ri-volume-vibrate-fill:before {
	content: "\f2a3";
}
.ri-volume-vibrate-line:before {
	content: "\f2a4";
}
.ri-vuejs-fill:before {
	content: "\f2a5";
}
.ri-vuejs-line:before {
	content: "\f2a6";
}
.ri-walk-fill:before {
	content: "\f2a7";
}
.ri-walk-line:before {
	content: "\f2a8";
}
.ri-wallet-2-fill:before {
	content: "\f2a9";
}
.ri-wallet-2-line:before {
	content: "\f2aa";
}
.ri-wallet-3-fill:before {
	content: "\f2ab";
}
.ri-wallet-3-line:before {
	content: "\f2ac";
}
.ri-wallet-fill:before {
	content: "\f2ad";
}
.ri-wallet-line:before {
	content: "\f2ae";
}
.ri-water-flash-fill:before {
	content: "\f2af";
}
.ri-water-flash-line:before {
	content: "\f2b0";
}
.ri-webcam-fill:before {
	content: "\f2b1";
}
.ri-webcam-line:before {
	content: "\f2b2";
}
.ri-wechat-2-fill:before {
	content: "\f2b3";
}
.ri-wechat-2-line:before {
	content: "\f2b4";
}
.ri-wechat-fill:before {
	content: "\f2b5";
}
.ri-wechat-line:before {
	content: "\f2b6";
}
.ri-wechat-pay-fill:before {
	content: "\f2b7";
}
.ri-wechat-pay-line:before {
	content: "\f2b8";
}
.ri-weibo-fill:before {
	content: "\f2b9";
}
.ri-weibo-line:before {
	content: "\f2ba";
}
.ri-whatsapp-fill:before {
	content: "\f2bb";
}
.ri-whatsapp-line:before {
	content: "\f2bc";
}
.ri-wheelchair-fill:before {
	content: "\f2bd";
}
.ri-wheelchair-line:before {
	content: "\f2be";
}
.ri-wifi-fill:before {
	content: "\f2bf";
}
.ri-wifi-line:before {
	content: "\f2c0";
}
.ri-wifi-off-fill:before {
	content: "\f2c1";
}
.ri-wifi-off-line:before {
	content: "\f2c2";
}
.ri-window-2-fill:before {
	content: "\f2c3";
}
.ri-window-2-line:before {
	content: "\f2c4";
}
.ri-window-fill:before {
	content: "\f2c5";
}
.ri-window-line:before {
	content: "\f2c6";
}
.ri-windows-fill:before {
	content: "\f2c7";
}
.ri-windows-line:before {
	content: "\f2c8";
}
.ri-windy-fill:before {
	content: "\f2c9";
}
.ri-windy-line:before {
	content: "\f2ca";
}
.ri-wireless-charging-fill:before {
	content: "\f2cb";
}
.ri-wireless-charging-line:before {
	content: "\f2cc";
}
.ri-women-fill:before {
	content: "\f2cd";
}
.ri-women-line:before {
	content: "\f2ce";
}
.ri-wubi-input:before {
	content: "\f2cf";
}
.ri-xbox-fill:before {
	content: "\f2d0";
}
.ri-xbox-line:before {
	content: "\f2d1";
}
.ri-xing-fill:before {
	content: "\f2d2";
}
.ri-xing-line:before {
	content: "\f2d3";
}
.ri-youtube-fill:before {
	content: "\f2d4";
}
.ri-youtube-line:before {
	content: "\f2d5";
}
.ri-zcool-fill:before {
	content: "\f2d6";
}
.ri-zcool-line:before {
	content: "\f2d7";
}
.ri-zhihu-fill:before {
	content: "\f2d8";
}
.ri-zhihu-line:before {
	content: "\f2d9";
}
.ri-zoom-in-fill:before {
	content: "\f2da";
}
.ri-zoom-in-line:before {
	content: "\f2db";
}
.ri-zoom-out-fill:before {
	content: "\f2dc";
}
.ri-zoom-out-line:before {
	content: "\f2dd";
}
.ri-zzz-fill:before {
	content: "\f2de";
}
.ri-zzz-line:before {
	content: "\f2df";
}
.ri-arrow-down-double-fill:before {
	content: "\f2e0";
}
.ri-arrow-down-double-line:before {
	content: "\f2e1";
}
.ri-arrow-left-double-fill:before {
	content: "\f2e2";
}
.ri-arrow-left-double-line:before {
	content: "\f2e3";
}
.ri-arrow-right-double-fill:before {
	content: "\f2e4";
}
.ri-arrow-right-double-line:before {
	content: "\f2e5";
}
.ri-arrow-turn-back-fill:before {
	content: "\f2e6";
}
.ri-arrow-turn-back-line:before {
	content: "\f2e7";
}
.ri-arrow-turn-forward-fill:before {
	content: "\f2e8";
}
.ri-arrow-turn-forward-line:before {
	content: "\f2e9";
}
.ri-arrow-up-double-fill:before {
	content: "\f2ea";
}
.ri-arrow-up-double-line:before {
	content: "\f2eb";
}
.ri-bard-fill:before {
	content: "\f2ec";
}
.ri-bard-line:before {
	content: "\f2ed";
}
.ri-bootstrap-fill:before {
	content: "\f2ee";
}
.ri-bootstrap-line:before {
	content: "\f2ef";
}
.ri-box-1-fill:before {
	content: "\f2f0";
}
.ri-box-1-line:before {
	content: "\f2f1";
}
.ri-box-2-fill:before {
	content: "\f2f2";
}
.ri-box-2-line:before {
	content: "\f2f3";
}
.ri-box-3-fill:before {
	content: "\f2f4";
}
.ri-box-3-line:before {
	content: "\f2f5";
}
.ri-brain-fill:before {
	content: "\f2f6";
}
.ri-brain-line:before {
	content: "\f2f7";
}
.ri-candle-fill:before {
	content: "\f2f8";
}
.ri-candle-line:before {
	content: "\f2f9";
}
.ri-cash-fill:before {
	content: "\f2fa";
}
.ri-cash-line:before {
	content: "\f2fb";
}
.ri-contract-left-fill:before {
	content: "\f2fc";
}
.ri-contract-left-line:before {
	content: "\f2fd";
}
.ri-contract-left-right-fill:before {
	content: "\f2fe";
}
.ri-contract-left-right-line:before {
	content: "\f2ff";
}
.ri-contract-right-fill:before {
	content: "\f300";
}
.ri-contract-right-line:before {
	content: "\f301";
}
.ri-contract-up-down-fill:before {
	content: "\f302";
}
.ri-contract-up-down-line:before {
	content: "\f303";
}
.ri-copilot-fill:before {
	content: "\f304";
}
.ri-copilot-line:before {
	content: "\f305";
}
.ri-corner-down-left-fill:before {
	content: "\f306";
}
.ri-corner-down-left-line:before {
	content: "\f307";
}
.ri-corner-down-right-fill:before {
	content: "\f308";
}
.ri-corner-down-right-line:before {
	content: "\f309";
}
.ri-corner-left-down-fill:before {
	content: "\f30a";
}
.ri-corner-left-down-line:before {
	content: "\f30b";
}
.ri-corner-left-up-fill:before {
	content: "\f30c";
}
.ri-corner-left-up-line:before {
	content: "\f30d";
}
.ri-corner-right-down-fill:before {
	content: "\f30e";
}
.ri-corner-right-down-line:before {
	content: "\f30f";
}
.ri-corner-right-up-fill:before {
	content: "\f310";
}
.ri-corner-right-up-line:before {
	content: "\f311";
}
.ri-corner-up-left-double-fill:before {
	content: "\f312";
}
.ri-corner-up-left-double-line:before {
	content: "\f313";
}
.ri-corner-up-left-fill:before {
	content: "\f314";
}
.ri-corner-up-left-line:before {
	content: "\f315";
}
.ri-corner-up-right-double-fill:before {
	content: "\f316";
}
.ri-corner-up-right-double-line:before {
	content: "\f317";
}
.ri-corner-up-right-fill:before {
	content: "\f318";
}
.ri-corner-up-right-line:before {
	content: "\f319";
}
.ri-cross-fill:before {
	content: "\f31a";
}
.ri-cross-line:before {
	content: "\f31b";
}
.ri-edge-new-fill:before {
	content: "\f31c";
}
.ri-edge-new-line:before {
	content: "\f31d";
}
.ri-equal-fill:before {
	content: "\f31e";
}
.ri-equal-line:before {
	content: "\f31f";
}
.ri-expand-left-fill:before {
	content: "\f320";
}
.ri-expand-left-line:before {
	content: "\f321";
}
.ri-expand-left-right-fill:before {
	content: "\f322";
}
.ri-expand-left-right-line:before {
	content: "\f323";
}
.ri-expand-right-fill:before {
	content: "\f324";
}
.ri-expand-right-line:before {
	content: "\f325";
}
.ri-expand-up-down-fill:before {
	content: "\f326";
}
.ri-expand-up-down-line:before {
	content: "\f327";
}
.ri-flickr-fill:before {
	content: "\f328";
}
.ri-flickr-line:before {
	content: "\f329";
}
.ri-forward-10-fill:before {
	content: "\f32a";
}
.ri-forward-10-line:before {
	content: "\f32b";
}
.ri-forward-15-fill:before {
	content: "\f32c";
}
.ri-forward-15-line:before {
	content: "\f32d";
}
.ri-forward-30-fill:before {
	content: "\f32e";
}
.ri-forward-30-line:before {
	content: "\f32f";
}
.ri-forward-5-fill:before {
	content: "\f330";
}
.ri-forward-5-line:before {
	content: "\f331";
}
.ri-graduation-cap-fill:before {
	content: "\f332";
}
.ri-graduation-cap-line:before {
	content: "\f333";
}
.ri-home-office-fill:before {
	content: "\f334";
}
.ri-home-office-line:before {
	content: "\f335";
}
.ri-hourglass-2-fill:before {
	content: "\f336";
}
.ri-hourglass-2-line:before {
	content: "\f337";
}
.ri-hourglass-fill:before {
	content: "\f338";
}
.ri-hourglass-line:before {
	content: "\f339";
}
.ri-javascript-fill:before {
	content: "\f33a";
}
.ri-javascript-line:before {
	content: "\f33b";
}
.ri-loop-left-fill:before {
	content: "\f33c";
}
.ri-loop-left-line:before {
	content: "\f33d";
}
.ri-loop-right-fill:before {
	content: "\f33e";
}
.ri-loop-right-line:before {
	content: "\f33f";
}
.ri-memories-fill:before {
	content: "\f340";
}
.ri-memories-line:before {
	content: "\f341";
}
.ri-meta-fill:before {
	content: "\f342";
}
.ri-meta-line:before {
	content: "\f343";
}
.ri-microsoft-loop-fill:before {
	content: "\f344";
}
.ri-microsoft-loop-line:before {
	content: "\f345";
}
.ri-nft-fill:before {
	content: "\f346";
}
.ri-nft-line:before {
	content: "\f347";
}
.ri-notion-fill:before {
	content: "\f348";
}
.ri-notion-line:before {
	content: "\f349";
}
.ri-openai-fill:before {
	content: "\f34a";
}
.ri-openai-line:before {
	content: "\f34b";
}
.ri-overline:before {
	content: "\f34c";
}
.ri-p2p-fill:before {
	content: "\f34d";
}
.ri-p2p-line:before {
	content: "\f34e";
}
.ri-presentation-fill:before {
	content: "\f34f";
}
.ri-presentation-line:before {
	content: "\f350";
}
.ri-replay-10-fill:before {
	content: "\f351";
}
.ri-replay-10-line:before {
	content: "\f352";
}
.ri-replay-15-fill:before {
	content: "\f353";
}
.ri-replay-15-line:before {
	content: "\f354";
}
.ri-replay-30-fill:before {
	content: "\f355";
}
.ri-replay-30-line:before {
	content: "\f356";
}
.ri-replay-5-fill:before {
	content: "\f357";
}
.ri-replay-5-line:before {
	content: "\f358";
}
.ri-school-fill:before {
	content: "\f359";
}
.ri-school-line:before {
	content: "\f35a";
}
.ri-shining-2-fill:before {
	content: "\f35b";
}
.ri-shining-2-line:before {
	content: "\f35c";
}
.ri-shining-fill:before {
	content: "\f35d";
}
.ri-shining-line:before {
	content: "\f35e";
}
.ri-sketching:before {
	content: "\f35f";
}
.ri-skip-down-fill:before {
	content: "\f360";
}
.ri-skip-down-line:before {
	content: "\f361";
}
.ri-skip-left-fill:before {
	content: "\f362";
}
.ri-skip-left-line:before {
	content: "\f363";
}
.ri-skip-right-fill:before {
	content: "\f364";
}
.ri-skip-right-line:before {
	content: "\f365";
}
.ri-skip-up-fill:before {
	content: "\f366";
}
.ri-skip-up-line:before {
	content: "\f367";
}
.ri-slow-down-fill:before {
	content: "\f368";
}
.ri-slow-down-line:before {
	content: "\f369";
}
.ri-sparkling-2-fill:before {
	content: "\f36a";
}
.ri-sparkling-2-line:before {
	content: "\f36b";
}
.ri-sparkling-fill:before {
	content: "\f36c";
}
.ri-sparkling-line:before {
	content: "\f36d";
}
.ri-speak-fill:before {
	content: "\f36e";
}
.ri-speak-line:before {
	content: "\f36f";
}
.ri-speed-up-fill:before {
	content: "\f370";
}
.ri-speed-up-line:before {
	content: "\f371";
}
.ri-tiktok-fill:before {
	content: "\f372";
}
.ri-tiktok-line:before {
	content: "\f373";
}
.ri-token-swap-fill:before {
	content: "\f374";
}
.ri-token-swap-line:before {
	content: "\f375";
}
.ri-unpin-fill:before {
	content: "\f376";
}
.ri-unpin-line:before {
	content: "\f377";
}
.ri-wechat-channels-fill:before {
	content: "\f378";
}
.ri-wechat-channels-line:before {
	content: "\f379";
}
.ri-wordpress-fill:before {
	content: "\f37a";
}
.ri-wordpress-line:before {
	content: "\f37b";
}
.ri-blender-fill:before {
	content: "\f37c";
}
.ri-blender-line:before {
	content: "\f37d";
}
.ri-emoji-sticker-fill:before {
	content: "\f37e";
}
.ri-emoji-sticker-line:before {
	content: "\f37f";
}
.ri-git-close-pull-request-fill:before {
	content: "\f380";
}
.ri-git-close-pull-request-line:before {
	content: "\f381";
}
.ri-instance-fill:before {
	content: "\f382";
}
.ri-instance-line:before {
	content: "\f383";
}
.ri-megaphone-fill:before {
	content: "\f384";
}
.ri-megaphone-line:before {
	content: "\f385";
}
.ri-pass-expired-fill:before {
	content: "\f386";
}
.ri-pass-expired-line:before {
	content: "\f387";
}
.ri-pass-pending-fill:before {
	content: "\f388";
}
.ri-pass-pending-line:before {
	content: "\f389";
}
.ri-pass-valid-fill:before {
	content: "\f38a";
}
.ri-pass-valid-line:before {
	content: "\f38b";
}
.ri-ai-generate:before {
	content: "\f38c";
}
.ri-calendar-close-fill:before {
	content: "\f38d";
}
.ri-calendar-close-line:before {
	content: "\f38e";
}
.ri-draggable:before {
	content: "\f38f";
}
.ri-font-family:before {
	content: "\f390";
}
.ri-font-mono:before {
	content: "\f391";
}
.ri-font-sans-serif:before {
	content: "\f392";
}
.ri-font-sans:before {
	content: "\f393";
}
.ri-hard-drive-3-fill:before {
	content: "\f394";
}
.ri-hard-drive-3-line:before {
	content: "\f395";
}
.ri-kick-fill:before {
	content: "\f396";
}
.ri-kick-line:before {
	content: "\f397";
}
.ri-list-check-3:before {
	content: "\f398";
}
.ri-list-indefinite:before {
	content: "\f399";
}
.ri-list-ordered-2:before {
	content: "\f39a";
}
.ri-list-radio:before {
	content: "\f39b";
}
.ri-openbase-fill:before {
	content: "\f39c";
}
.ri-openbase-line:before {
	content: "\f39d";
}
.ri-planet-fill:before {
	content: "\f39e";
}
.ri-planet-line:before {
	content: "\f39f";
}
.ri-prohibited-fill:before {
	content: "\f3a0";
}
.ri-prohibited-line:before {
	content: "\f3a1";
}
.ri-quote-text:before {
	content: "\f3a2";
}
.ri-seo-fill:before {
	content: "\f3a3";
}
.ri-seo-line:before {
	content: "\f3a4";
}
.ri-slash-commands:before {
	content: "\f3a5";
}
.ri-archive-2-fill:before {
	content: "\f3a6";
}
.ri-archive-2-line:before {
	content: "\f3a7";
}
.ri-inbox-2-fill:before {
	content: "\f3a8";
}
.ri-inbox-2-line:before {
	content: "\f3a9";
}
.ri-shake-hands-fill:before {
	content: "\f3aa";
}
.ri-shake-hands-line:before {
	content: "\f3ab";
}
.ri-supabase-fill:before {
	content: "\f3ac";
}
.ri-supabase-line:before {
	content: "\f3ad";
}
.ri-water-percent-fill:before {
	content: "\f3ae";
}
.ri-water-percent-line:before {
	content: "\f3af";
}
.ri-yuque-fill:before {
	content: "\f3b0";
}
.ri-yuque-line:before {
	content: "\f3b1";
}
.ri-crosshair-2-fill:before {
	content: "\f3b2";
}
.ri-crosshair-2-line:before {
	content: "\f3b3";
}
.ri-crosshair-fill:before {
	content: "\f3b4";
}
.ri-crosshair-line:before {
	content: "\f3b5";
}
.ri-file-close-fill:before {
	content: "\f3b6";
}
.ri-file-close-line:before {
	content: "\f3b7";
}
.ri-infinity-fill:before {
	content: "\f3b8";
}
.ri-infinity-line:before {
	content: "\f3b9";
}
.ri-rfid-fill:before {
	content: "\f3ba";
}
.ri-rfid-line:before {
	content: "\f3bb";
}
.ri-slash-commands-2:before {
	content: "\f3bc";
}
.ri-user-forbid-fill:before {
	content: "\f3bd";
}
.ri-user-forbid-line:before {
	content: "\f3be";
}
.ri-beer-fill:before {
	content: "\f3bf";
}
.ri-beer-line:before {
	content: "\f3c0";
}
.ri-circle-fill:before {
	content: "\f3c1";
}
.ri-circle-line:before {
	content: "\f3c2";
}
.ri-dropdown-list:before {
	content: "\f3c3";
}
.ri-file-image-fill:before {
	content: "\f3c4";
}
.ri-file-image-line:before {
	content: "\f3c5";
}
.ri-file-pdf-2-fill:before {
	content: "\f3c6";
}
.ri-file-pdf-2-line:before {
	content: "\f3c7";
}
.ri-file-video-fill:before {
	content: "\f3c8";
}
.ri-file-video-line:before {
	content: "\f3c9";
}
.ri-folder-image-fill:before {
	content: "\f3ca";
}
.ri-folder-image-line:before {
	content: "\f3cb";
}
.ri-folder-video-fill:before {
	content: "\f3cc";
}
.ri-folder-video-line:before {
	content: "\f3cd";
}
.ri-hexagon-fill:before {
	content: "\f3ce";
}
.ri-hexagon-line:before {
	content: "\f3cf";
}
.ri-menu-search-fill:before {
	content: "\f3d0";
}
.ri-menu-search-line:before {
	content: "\f3d1";
}
.ri-octagon-fill:before {
	content: "\f3d2";
}
.ri-octagon-line:before {
	content: "\f3d3";
}
.ri-pentagon-fill:before {
	content: "\f3d4";
}
.ri-pentagon-line:before {
	content: "\f3d5";
}
.ri-rectangle-fill:before {
	content: "\f3d6";
}
.ri-rectangle-line:before {
	content: "\f3d7";
}
.ri-robot-2-fill:before {
	content: "\f3d8";
}
.ri-robot-2-line:before {
	content: "\f3d9";
}
.ri-shapes-fill:before {
	content: "\f3da";
}
.ri-shapes-line:before {
	content: "\f3db";
}
.ri-square-fill:before {
	content: "\f3dc";
}
.ri-square-line:before {
	content: "\f3dd";
}
.ri-tent-fill:before {
	content: "\f3de";
}
.ri-tent-line:before {
	content: "\f3df";
}
.ri-threads-fill:before {
	content: "\f3e0";
}
.ri-threads-line:before {
	content: "\f3e1";
}
.ri-tree-fill:before {
	content: "\f3e2";
}
.ri-tree-line:before {
	content: "\f3e3";
}
.ri-triangle-fill:before {
	content: "\f3e4";
}
.ri-triangle-line:before {
	content: "\f3e5";
}
.ri-twitter-x-fill:before {
	content: "\f3e6";
}
.ri-twitter-x-line:before {
	content: "\f3e7";
}
.ri-verified-badge-fill:before {
	content: "\f3e8";
}
.ri-verified-badge-line:before {
	content: "\f3e9";
}
.ri-armchair-fill:before {
	content: "\f3ea";
}
.ri-armchair-line:before {
	content: "\f3eb";
}
.ri-bnb-fill:before {
	content: "\f3ec";
}
.ri-bnb-line:before {
	content: "\f3ed";
}
.ri-bread-fill:before {
	content: "\f3ee";
}
.ri-bread-line:before {
	content: "\f3ef";
}
.ri-btc-fill:before {
	content: "\f3f0";
}
.ri-btc-line:before {
	content: "\f3f1";
}
.ri-calendar-schedule-fill:before {
	content: "\f3f2";
}
.ri-calendar-schedule-line:before {
	content: "\f3f3";
}
.ri-dice-1-fill:before {
	content: "\f3f4";
}
.ri-dice-1-line:before {
	content: "\f3f5";
}
.ri-dice-2-fill:before {
	content: "\f3f6";
}
.ri-dice-2-line:before {
	content: "\f3f7";
}
.ri-dice-3-fill:before {
	content: "\f3f8";
}
.ri-dice-3-line:before {
	content: "\f3f9";
}
.ri-dice-4-fill:before {
	content: "\f3fa";
}
.ri-dice-4-line:before {
	content: "\f3fb";
}
.ri-dice-5-fill:before {
	content: "\f3fc";
}
.ri-dice-5-line:before {
	content: "\f3fd";
}
.ri-dice-6-fill:before {
	content: "\f3fe";
}
.ri-dice-6-line:before {
	content: "\f3ff";
}
.ri-dice-fill:before {
	content: "\f400";
}
.ri-dice-line:before {
	content: "\f401";
}
.ri-drinks-fill:before {
	content: "\f402";
}
.ri-drinks-line:before {
	content: "\f403";
}
.ri-equalizer-2-fill:before {
	content: "\f404";
}
.ri-equalizer-2-line:before {
	content: "\f405";
}
.ri-equalizer-3-fill:before {
	content: "\f406";
}
.ri-equalizer-3-line:before {
	content: "\f407";
}
.ri-eth-fill:before {
	content: "\f408";
}
.ri-eth-line:before {
	content: "\f409";
}
.ri-flower-fill:before {
	content: "\f40a";
}
.ri-flower-line:before {
	content: "\f40b";
}
.ri-glasses-2-fill:before {
	content: "\f40c";
}
.ri-glasses-2-line:before {
	content: "\f40d";
}
.ri-glasses-fill:before {
	content: "\f40e";
}
.ri-glasses-line:before {
	content: "\f40f";
}
.ri-goggles-fill:before {
	content: "\f410";
}
.ri-goggles-line:before {
	content: "\f411";
}
.ri-image-circle-fill:before {
	content: "\f412";
}
.ri-image-circle-line:before {
	content: "\f413";
}
.ri-info-i:before {
	content: "\f414";
}
.ri-money-rupee-circle-fill:before {
	content: "\f415";
}
.ri-money-rupee-circle-line:before {
	content: "\f416";
}
.ri-news-fill:before {
	content: "\f417";
}
.ri-news-line:before {
	content: "\f418";
}
.ri-robot-3-fill:before {
	content: "\f419";
}
.ri-robot-3-line:before {
	content: "\f41a";
}
.ri-share-2-fill:before {
	content: "\f41b";
}
.ri-share-2-line:before {
	content: "\f41c";
}
.ri-sofa-fill:before {
	content: "\f41d";
}
.ri-sofa-line:before {
	content: "\f41e";
}
.ri-svelte-fill:before {
	content: "\f41f";
}
.ri-svelte-line:before {
	content: "\f420";
}
.ri-vk-fill:before {
	content: "\f421";
}
.ri-vk-line:before {
	content: "\f422";
}
.ri-xrp-fill:before {
	content: "\f423";
}
.ri-xrp-line:before {
	content: "\f424";
}
.ri-xtz-fill:before {
	content: "\f425";
}
.ri-xtz-line:before {
	content: "\f426";
}
.ri-archive-stack-fill:before {
	content: "\f427";
}
.ri-archive-stack-line:before {
	content: "\f428";
}
.ri-bowl-fill:before {
	content: "\f429";
}
.ri-bowl-line:before {
	content: "\f42a";
}
.ri-calendar-view:before {
	content: "\f42b";
}
.ri-carousel-view:before {
	content: "\f42c";
}
.ri-code-block:before {
	content: "\f42d";
}
.ri-color-filter-fill:before {
	content: "\f42e";
}
.ri-color-filter-line:before {
	content: "\f42f";
}
.ri-contacts-book-3-fill:before {
	content: "\f430";
}
.ri-contacts-book-3-line:before {
	content: "\f431";
}
.ri-contract-fill:before {
	content: "\f432";
}
.ri-contract-line:before {
	content: "\f433";
}
.ri-drinks-2-fill:before {
	content: "\f434";
}
.ri-drinks-2-line:before {
	content: "\f435";
}
.ri-export-fill:before {
	content: "\f436";
}
.ri-export-line:before {
	content: "\f437";
}
.ri-file-check-fill:before {
	content: "\f438";
}
.ri-file-check-line:before {
	content: "\f439";
}
.ri-focus-mode:before {
	content: "\f43a";
}
.ri-folder-6-fill:before {
	content: "\f43b";
}
.ri-folder-6-line:before {
	content: "\f43c";
}
.ri-folder-check-fill:before {
	content: "\f43d";
}
.ri-folder-check-line:before {
	content: "\f43e";
}
.ri-folder-close-fill:before {
	content: "\f43f";
}
.ri-folder-close-line:before {
	content: "\f440";
}
.ri-folder-cloud-fill:before {
	content: "\f441";
}
.ri-folder-cloud-line:before {
	content: "\f442";
}
.ri-gallery-view-2:before {
	content: "\f443";
}
.ri-gallery-view:before {
	content: "\f444";
}
.ri-hand:before {
	content: "\f445";
}
.ri-import-fill:before {
	content: "\f446";
}
.ri-import-line:before {
	content: "\f447";
}
.ri-information-2-fill:before {
	content: "\f448";
}
.ri-information-2-line:before {
	content: "\f449";
}
.ri-kanban-view-2:before {
	content: "\f44a";
}
.ri-kanban-view:before {
	content: "\f44b";
}
.ri-list-view:before {
	content: "\f44c";
}
.ri-lock-star-fill:before {
	content: "\f44d";
}
.ri-lock-star-line:before {
	content: "\f44e";
}
.ri-puzzle-2-fill:before {
	content: "\f44f";
}
.ri-puzzle-2-line:before {
	content: "\f450";
}
.ri-puzzle-fill:before {
	content: "\f451";
}
.ri-puzzle-line:before {
	content: "\f452";
}
.ri-ram-2-fill:before {
	content: "\f453";
}
.ri-ram-2-line:before {
	content: "\f454";
}
.ri-ram-fill:before {
	content: "\f455";
}
.ri-ram-line:before {
	content: "\f456";
}
.ri-receipt-fill:before {
	content: "\f457";
}
.ri-receipt-line:before {
	content: "\f458";
}
.ri-shadow-fill:before {
	content: "\f459";
}
.ri-shadow-line:before {
	content: "\f45a";
}
.ri-sidebar-fold-fill:before {
	content: "\f45b";
}
.ri-sidebar-fold-line:before {
	content: "\f45c";
}
.ri-sidebar-unfold-fill:before {
	content: "\f45d";
}
.ri-sidebar-unfold-line:before {
	content: "\f45e";
}
.ri-slideshow-view:before {
	content: "\f45f";
}
.ri-sort-alphabet-asc:before {
	content: "\f460";
}
.ri-sort-alphabet-desc:before {
	content: "\f461";
}
.ri-sort-number-asc:before {
	content: "\f462";
}
.ri-sort-number-desc:before {
	content: "\f463";
}
.ri-stacked-view:before {
	content: "\f464";
}
.ri-sticky-note-add-fill:before {
	content: "\f465";
}
.ri-sticky-note-add-line:before {
	content: "\f466";
}
.ri-swap-2-fill:before {
	content: "\f467";
}
.ri-swap-2-line:before {
	content: "\f468";
}
.ri-swap-3-fill:before {
	content: "\f469";
}
.ri-swap-3-line:before {
	content: "\f46a";
}
.ri-table-3:before {
	content: "\f46b";
}
.ri-table-view:before {
	content: "\f46c";
}
.ri-text-block:before {
	content: "\f46d";
}
.ri-text-snippet:before {
	content: "\f46e";
}
.ri-timeline-view:before {
	content: "\f46f";
}
.ri-blogger-fill:before {
	content: "\f470";
}
.ri-blogger-line:before {
	content: "\f471";
}
.ri-chat-thread-fill:before {
	content: "\f472";
}
.ri-chat-thread-line:before {
	content: "\f473";
}
.ri-discount-percent-fill:before {
	content: "\f474";
}
.ri-discount-percent-line:before {
	content: "\f475";
}
.ri-exchange-2-fill:before {
	content: "\f476";
}
.ri-exchange-2-line:before {
	content: "\f477";
}
.ri-git-fork-fill:before {
	content: "\f478";
}
.ri-git-fork-line:before {
	content: "\f479";
}
.ri-input-field:before {
	content: "\f47a";
}
.ri-progress-1-fill:before {
	content: "\f47b";
}
.ri-progress-1-line:before {
	content: "\f47c";
}
.ri-progress-2-fill:before {
	content: "\f47d";
}
.ri-progress-2-line:before {
	content: "\f47e";
}
.ri-progress-3-fill:before {
	content: "\f47f";
}
.ri-progress-3-line:before {
	content: "\f480";
}
.ri-progress-4-fill:before {
	content: "\f481";
}
.ri-progress-4-line:before {
	content: "\f482";
}
.ri-progress-5-fill:before {
	content: "\f483";
}
.ri-progress-5-line:before {
	content: "\f484";
}
.ri-progress-6-fill:before {
	content: "\f485";
}
.ri-progress-6-line:before {
	content: "\f486";
}
.ri-progress-7-fill:before {
	content: "\f487";
}
.ri-progress-7-line:before {
	content: "\f488";
}
.ri-progress-8-fill:before {
	content: "\f489";
}
.ri-progress-8-line:before {
	content: "\f48a";
}
.ri-remix-run-fill:before {
	content: "\f48b";
}
.ri-remix-run-line:before {
	content: "\f48c";
}
.ri-signpost-fill:before {
	content: "\f48d";
}
.ri-signpost-line:before {
	content: "\f48e";
}
.ri-time-zone-fill:before {
	content: "\f48f";
}
.ri-time-zone-line:before {
	content: "\f490";
}
.ri-arrow-down-wide-fill:before {
	content: "\f491";
}
.ri-arrow-down-wide-line:before {
	content: "\f492";
}
.ri-arrow-left-wide-fill:before {
	content: "\f493";
}
.ri-arrow-left-wide-line:before {
	content: "\f494";
}
.ri-arrow-right-wide-fill:before {
	content: "\f495";
}
.ri-arrow-right-wide-line:before {
	content: "\f496";
}
.ri-arrow-up-wide-fill:before {
	content: "\f497";
}
.ri-arrow-up-wide-line:before {
	content: "\f498";
}
.ri-bluesky-fill:before {
	content: "\f499";
}
.ri-bluesky-line:before {
	content: "\f49a";
}
.ri-expand-height-fill:before {
	content: "\f49b";
}
.ri-expand-height-line:before {
	content: "\f49c";
}
.ri-expand-width-fill:before {
	content: "\f49d";
}
.ri-expand-width-line:before {
	content: "\f49e";
}
.ri-forward-end-fill:before {
	content: "\f49f";
}
.ri-forward-end-line:before {
	content: "\f4a0";
}
.ri-forward-end-mini-fill:before {
	content: "\f4a1";
}
.ri-forward-end-mini-line:before {
	content: "\f4a2";
}
.ri-friendica-fill:before {
	content: "\f4a3";
}
.ri-friendica-line:before {
	content: "\f4a4";
}
.ri-git-pr-draft-fill:before {
	content: "\f4a5";
}
.ri-git-pr-draft-line:before {
	content: "\f4a6";
}
.ri-play-reverse-fill:before {
	content: "\f4a7";
}
.ri-play-reverse-line:before {
	content: "\f4a8";
}
.ri-play-reverse-mini-fill:before {
	content: "\f4a9";
}
.ri-play-reverse-mini-line:before {
	content: "\f4aa";
}
.ri-rewind-start-fill:before {
	content: "\f4ab";
}
.ri-rewind-start-line:before {
	content: "\f4ac";
}
.ri-rewind-start-mini-fill:before {
	content: "\f4ad";
}
.ri-rewind-start-mini-line:before {
	content: "\f4ae";
}
.ri-scroll-to-bottom-fill:before {
	content: "\f4af";
}
.ri-scroll-to-bottom-line:before {
	content: "\f4b0";
}
.ri-add-large-fill:before {
	content: "\f4b1";
}
.ri-add-large-line:before {
	content: "\f4b2";
}
.ri-aed-electrodes-fill:before {
	content: "\f4b3";
}
.ri-aed-electrodes-line:before {
	content: "\f4b4";
}
.ri-aed-fill:before {
	content: "\f4b5";
}
.ri-aed-line:before {
	content: "\f4b6";
}
.ri-alibaba-cloud-fill:before {
	content: "\f4b7";
}
.ri-alibaba-cloud-line:before {
	content: "\f4b8";
}
.ri-align-item-bottom-fill:before {
	content: "\f4b9";
}
.ri-align-item-bottom-line:before {
	content: "\f4ba";
}
.ri-align-item-horizontal-center-fill:before {
	content: "\f4bb";
}
.ri-align-item-horizontal-center-line:before {
	content: "\f4bc";
}
.ri-align-item-left-fill:before {
	content: "\f4bd";
}
.ri-align-item-left-line:before {
	content: "\f4be";
}
.ri-align-item-right-fill:before {
	content: "\f4bf";
}
.ri-align-item-right-line:before {
	content: "\f4c0";
}
.ri-align-item-top-fill:before {
	content: "\f4c1";
}
.ri-align-item-top-line:before {
	content: "\f4c2";
}
.ri-align-item-vertical-center-fill:before {
	content: "\f4c3";
}
.ri-align-item-vertical-center-line:before {
	content: "\f4c4";
}
.ri-apps-2-add-fill:before {
	content: "\f4c5";
}
.ri-apps-2-add-line:before {
	content: "\f4c6";
}
.ri-close-large-fill:before {
	content: "\f4c7";
}
.ri-close-large-line:before {
	content: "\f4c8";
}
.ri-collapse-diagonal-2-fill:before {
	content: "\f4c9";
}
.ri-collapse-diagonal-2-line:before {
	content: "\f4ca";
}
.ri-collapse-diagonal-fill:before {
	content: "\f4cb";
}
.ri-collapse-diagonal-line:before {
	content: "\f4cc";
}
.ri-dashboard-horizontal-fill:before {
	content: "\f4cd";
}
.ri-dashboard-horizontal-line:before {
	content: "\f4ce";
}
.ri-expand-diagonal-2-fill:before {
	content: "\f4cf";
}
.ri-expand-diagonal-2-line:before {
	content: "\f4d0";
}
.ri-expand-diagonal-fill:before {
	content: "\f4d1";
}
.ri-expand-diagonal-line:before {
	content: "\f4d2";
}
.ri-firebase-fill:before {
	content: "\f4d3";
}
.ri-firebase-line:before {
	content: "\f4d4";
}
.ri-flip-horizontal-2-fill:before {
	content: "\f4d5";
}
.ri-flip-horizontal-2-line:before {
	content: "\f4d6";
}
.ri-flip-horizontal-fill:before {
	content: "\f4d7";
}
.ri-flip-horizontal-line:before {
	content: "\f4d8";
}
.ri-flip-vertical-2-fill:before {
	content: "\f4d9";
}
.ri-flip-vertical-2-line:before {
	content: "\f4da";
}
.ri-flip-vertical-fill:before {
	content: "\f4db";
}
.ri-flip-vertical-line:before {
	content: "\f4dc";
}
.ri-formula:before {
	content: "\f4dd";
}
.ri-function-add-fill:before {
	content: "\f4de";
}
.ri-function-add-line:before {
	content: "\f4df";
}
.ri-goblet-2-fill:before {
	content: "\f4e0";
}
.ri-goblet-2-line:before {
	content: "\f4e1";
}
.ri-golf-ball-fill:before {
	content: "\f4e2";
}
.ri-golf-ball-line:before {
	content: "\f4e3";
}
.ri-group-3-fill:before {
	content: "\f4e4";
}
.ri-group-3-line:before {
	content: "\f4e5";
}
.ri-heart-add-2-fill:before {
	content: "\f4e6";
}
.ri-heart-add-2-line:before {
	content: "\f4e7";
}
.ri-id-card-fill:before {
	content: "\f4e8";
}
.ri-id-card-line:before {
	content: "\f4e9";
}
.ri-information-off-fill:before {
	content: "\f4ea";
}
.ri-information-off-line:before {
	content: "\f4eb";
}
.ri-java-fill:before {
	content: "\f4ec";
}
.ri-java-line:before {
	content: "\f4ed";
}
.ri-layout-grid-2-fill:before {
	content: "\f4ee";
}
.ri-layout-grid-2-line:before {
	content: "\f4ef";
}
.ri-layout-horizontal-fill:before {
	content: "\f4f0";
}
.ri-layout-horizontal-line:before {
	content: "\f4f1";
}
.ri-layout-vertical-fill:before {
	content: "\f4f2";
}
.ri-layout-vertical-line:before {
	content: "\f4f3";
}
.ri-menu-fold-2-fill:before {
	content: "\f4f4";
}
.ri-menu-fold-2-line:before {
	content: "\f4f5";
}
.ri-menu-fold-3-fill:before {
	content: "\f4f6";
}
.ri-menu-fold-3-line:before {
	content: "\f4f7";
}
.ri-menu-fold-4-fill:before {
	content: "\f4f8";
}
.ri-menu-fold-4-line:before {
	content: "\f4f9";
}
.ri-menu-unfold-2-fill:before {
	content: "\f4fa";
}
.ri-menu-unfold-2-line:before {
	content: "\f4fb";
}
.ri-menu-unfold-3-fill:before {
	content: "\f4fc";
}
.ri-menu-unfold-3-line:before {
	content: "\f4fd";
}
.ri-menu-unfold-4-fill:before {
	content: "\f4fe";
}
.ri-menu-unfold-4-line:before {
	content: "\f4ff";
}
.ri-mobile-download-fill:before {
	content: "\f500";
}
.ri-mobile-download-line:before {
	content: "\f501";
}
.ri-nextjs-fill:before {
	content: "\f502";
}
.ri-nextjs-line:before {
	content: "\f503";
}
.ri-nodejs-fill:before {
	content: "\f504";
}
.ri-nodejs-line:before {
	content: "\f505";
}
.ri-pause-large-fill:before {
	content: "\f506";
}
.ri-pause-large-line:before {
	content: "\f507";
}
.ri-play-large-fill:before {
	content: "\f508";
}
.ri-play-large-line:before {
	content: "\f509";
}
.ri-play-reverse-large-fill:before {
	content: "\f50a";
}
.ri-play-reverse-large-line:before {
	content: "\f50b";
}
.ri-police-badge-fill:before {
	content: "\f50c";
}
.ri-police-badge-line:before {
	content: "\f50d";
}
.ri-prohibited-2-fill:before {
	content: "\f50e";
}
.ri-prohibited-2-line:before {
	content: "\f50f";
}
.ri-shopping-bag-4-fill:before {
	content: "\f510";
}
.ri-shopping-bag-4-line:before {
	content: "\f511";
}
.ri-snowflake-fill:before {
	content: "\f512";
}
.ri-snowflake-line:before {
	content: "\f513";
}
.ri-square-root:before {
	content: "\f514";
}
.ri-stop-large-fill:before {
	content: "\f515";
}
.ri-stop-large-line:before {
	content: "\f516";
}
.ri-tailwind-css-fill:before {
	content: "\f517";
}
.ri-tailwind-css-line:before {
	content: "\f518";
}
.ri-tooth-fill:before {
	content: "\f519";
}
.ri-tooth-line:before {
	content: "\f51a";
}
.ri-video-off-fill:before {
	content: "\f51b";
}
.ri-video-off-line:before {
	content: "\f51c";
}
.ri-video-on-fill:before {
	content: "\f51d";
}
.ri-video-on-line:before {
	content: "\f51e";
}
.ri-webhook-fill:before {
	content: "\f51f";
}
.ri-webhook-line:before {
	content: "\f520";
}
.ri-weight-fill:before {
	content: "\f521";
}
.ri-weight-line:before {
	content: "\f522";
}

$ri-24-hours-fill: "\ea01";
$ri-24-hours-line: "\ea02";
$ri-4k-fill: "\ea03";
$ri-4k-line: "\ea04";
$ri-a-b: "\ea05";
$ri-account-box-fill: "\ea06";
$ri-account-box-line: "\ea07";
$ri-account-circle-fill: "\ea08";
$ri-account-circle-line: "\ea09";
$ri-account-pin-box-fill: "\ea0a";
$ri-account-pin-box-line: "\ea0b";
$ri-account-pin-circle-fill: "\ea0c";
$ri-account-pin-circle-line: "\ea0d";
$ri-add-box-fill: "\ea0e";
$ri-add-box-line: "\ea0f";
$ri-add-circle-fill: "\ea10";
$ri-add-circle-line: "\ea11";
$ri-add-fill: "\ea12";
$ri-add-line: "\ea13";
$ri-admin-fill: "\ea14";
$ri-admin-line: "\ea15";
$ri-advertisement-fill: "\ea16";
$ri-advertisement-line: "\ea17";
$ri-airplay-fill: "\ea18";
$ri-airplay-line: "\ea19";
$ri-alarm-fill: "\ea1a";
$ri-alarm-line: "\ea1b";
$ri-alarm-warning-fill: "\ea1c";
$ri-alarm-warning-line: "\ea1d";
$ri-album-fill: "\ea1e";
$ri-album-line: "\ea1f";
$ri-alert-fill: "\ea20";
$ri-alert-line: "\ea21";
$ri-aliens-fill: "\ea22";
$ri-aliens-line: "\ea23";
$ri-align-bottom: "\ea24";
$ri-align-center: "\ea25";
$ri-align-justify: "\ea26";
$ri-align-left: "\ea27";
$ri-align-right: "\ea28";
$ri-align-top: "\ea29";
$ri-align-vertically: "\ea2a";
$ri-alipay-fill: "\ea2b";
$ri-alipay-line: "\ea2c";
$ri-amazon-fill: "\ea2d";
$ri-amazon-line: "\ea2e";
$ri-anchor-fill: "\ea2f";
$ri-anchor-line: "\ea30";
$ri-ancient-gate-fill: "\ea31";
$ri-ancient-gate-line: "\ea32";
$ri-ancient-pavilion-fill: "\ea33";
$ri-ancient-pavilion-line: "\ea34";
$ri-android-fill: "\ea35";
$ri-android-line: "\ea36";
$ri-angularjs-fill: "\ea37";
$ri-angularjs-line: "\ea38";
$ri-anticlockwise-2-fill: "\ea39";
$ri-anticlockwise-2-line: "\ea3a";
$ri-anticlockwise-fill: "\ea3b";
$ri-anticlockwise-line: "\ea3c";
$ri-app-store-fill: "\ea3d";
$ri-app-store-line: "\ea3e";
$ri-apple-fill: "\ea3f";
$ri-apple-line: "\ea40";
$ri-apps-2-fill: "\ea41";
$ri-apps-2-line: "\ea42";
$ri-apps-fill: "\ea43";
$ri-apps-line: "\ea44";
$ri-archive-drawer-fill: "\ea45";
$ri-archive-drawer-line: "\ea46";
$ri-archive-fill: "\ea47";
$ri-archive-line: "\ea48";
$ri-arrow-down-circle-fill: "\ea49";
$ri-arrow-down-circle-line: "\ea4a";
$ri-arrow-down-fill: "\ea4b";
$ri-arrow-down-line: "\ea4c";
$ri-arrow-down-s-fill: "\ea4d";
$ri-arrow-down-s-line: "\ea4e";
$ri-arrow-drop-down-fill: "\ea4f";
$ri-arrow-drop-down-line: "\ea50";
$ri-arrow-drop-left-fill: "\ea51";
$ri-arrow-drop-left-line: "\ea52";
$ri-arrow-drop-right-fill: "\ea53";
$ri-arrow-drop-right-line: "\ea54";
$ri-arrow-drop-up-fill: "\ea55";
$ri-arrow-drop-up-line: "\ea56";
$ri-arrow-go-back-fill: "\ea57";
$ri-arrow-go-back-line: "\ea58";
$ri-arrow-go-forward-fill: "\ea59";
$ri-arrow-go-forward-line: "\ea5a";
$ri-arrow-left-circle-fill: "\ea5b";
$ri-arrow-left-circle-line: "\ea5c";
$ri-arrow-left-down-fill: "\ea5d";
$ri-arrow-left-down-line: "\ea5e";
$ri-arrow-left-fill: "\ea5f";
$ri-arrow-left-line: "\ea60";
$ri-arrow-left-right-fill: "\ea61";
$ri-arrow-left-right-line: "\ea62";
$ri-arrow-left-s-fill: "\ea63";
$ri-arrow-left-s-line: "\ea64";
$ri-arrow-left-up-fill: "\ea65";
$ri-arrow-left-up-line: "\ea66";
$ri-arrow-right-circle-fill: "\ea67";
$ri-arrow-right-circle-line: "\ea68";
$ri-arrow-right-down-fill: "\ea69";
$ri-arrow-right-down-line: "\ea6a";
$ri-arrow-right-fill: "\ea6b";
$ri-arrow-right-line: "\ea6c";
$ri-arrow-right-s-fill: "\ea6d";
$ri-arrow-right-s-line: "\ea6e";
$ri-arrow-right-up-fill: "\ea6f";
$ri-arrow-right-up-line: "\ea70";
$ri-arrow-up-circle-fill: "\ea71";
$ri-arrow-up-circle-line: "\ea72";
$ri-arrow-up-down-fill: "\ea73";
$ri-arrow-up-down-line: "\ea74";
$ri-arrow-up-fill: "\ea75";
$ri-arrow-up-line: "\ea76";
$ri-arrow-up-s-fill: "\ea77";
$ri-arrow-up-s-line: "\ea78";
$ri-artboard-2-fill: "\ea79";
$ri-artboard-2-line: "\ea7a";
$ri-artboard-fill: "\ea7b";
$ri-artboard-line: "\ea7c";
$ri-article-fill: "\ea7d";
$ri-article-line: "\ea7e";
$ri-aspect-ratio-fill: "\ea7f";
$ri-aspect-ratio-line: "\ea80";
$ri-asterisk: "\ea81";
$ri-at-fill: "\ea82";
$ri-at-line: "\ea83";
$ri-attachment-2: "\ea84";
$ri-attachment-fill: "\ea85";
$ri-attachment-line: "\ea86";
$ri-auction-fill: "\ea87";
$ri-auction-line: "\ea88";
$ri-award-fill: "\ea89";
$ri-award-line: "\ea8a";
$ri-baidu-fill: "\ea8b";
$ri-baidu-line: "\ea8c";
$ri-ball-pen-fill: "\ea8d";
$ri-ball-pen-line: "\ea8e";
$ri-bank-card-2-fill: "\ea8f";
$ri-bank-card-2-line: "\ea90";
$ri-bank-card-fill: "\ea91";
$ri-bank-card-line: "\ea92";
$ri-bank-fill: "\ea93";
$ri-bank-line: "\ea94";
$ri-bar-chart-2-fill: "\ea95";
$ri-bar-chart-2-line: "\ea96";
$ri-bar-chart-box-fill: "\ea97";
$ri-bar-chart-box-line: "\ea98";
$ri-bar-chart-fill: "\ea99";
$ri-bar-chart-grouped-fill: "\ea9a";
$ri-bar-chart-grouped-line: "\ea9b";
$ri-bar-chart-horizontal-fill: "\ea9c";
$ri-bar-chart-horizontal-line: "\ea9d";
$ri-bar-chart-line: "\ea9e";
$ri-barcode-box-fill: "\ea9f";
$ri-barcode-box-line: "\eaa0";
$ri-barcode-fill: "\eaa1";
$ri-barcode-line: "\eaa2";
$ri-barricade-fill: "\eaa3";
$ri-barricade-line: "\eaa4";
$ri-base-station-fill: "\eaa5";
$ri-base-station-line: "\eaa6";
$ri-basketball-fill: "\eaa7";
$ri-basketball-line: "\eaa8";
$ri-battery-2-charge-fill: "\eaa9";
$ri-battery-2-charge-line: "\eaaa";
$ri-battery-2-fill: "\eaab";
$ri-battery-2-line: "\eaac";
$ri-battery-charge-fill: "\eaad";
$ri-battery-charge-line: "\eaae";
$ri-battery-fill: "\eaaf";
$ri-battery-line: "\eab0";
$ri-battery-low-fill: "\eab1";
$ri-battery-low-line: "\eab2";
$ri-battery-saver-fill: "\eab3";
$ri-battery-saver-line: "\eab4";
$ri-battery-share-fill: "\eab5";
$ri-battery-share-line: "\eab6";
$ri-bear-smile-fill: "\eab7";
$ri-bear-smile-line: "\eab8";
$ri-behance-fill: "\eab9";
$ri-behance-line: "\eaba";
$ri-bell-fill: "\eabb";
$ri-bell-line: "\eabc";
$ri-bike-fill: "\eabd";
$ri-bike-line: "\eabe";
$ri-bilibili-fill: "\eabf";
$ri-bilibili-line: "\eac0";
$ri-bill-fill: "\eac1";
$ri-bill-line: "\eac2";
$ri-billiards-fill: "\eac3";
$ri-billiards-line: "\eac4";
$ri-bit-coin-fill: "\eac5";
$ri-bit-coin-line: "\eac6";
$ri-blaze-fill: "\eac7";
$ri-blaze-line: "\eac8";
$ri-bluetooth-connect-fill: "\eac9";
$ri-bluetooth-connect-line: "\eaca";
$ri-bluetooth-fill: "\eacb";
$ri-bluetooth-line: "\eacc";
$ri-blur-off-fill: "\eacd";
$ri-blur-off-line: "\eace";
$ri-body-scan-fill: "\eacf";
$ri-body-scan-line: "\ead0";
$ri-bold: "\ead1";
$ri-book-2-fill: "\ead2";
$ri-book-2-line: "\ead3";
$ri-book-3-fill: "\ead4";
$ri-book-3-line: "\ead5";
$ri-book-fill: "\ead6";
$ri-book-line: "\ead7";
$ri-book-marked-fill: "\ead8";
$ri-book-marked-line: "\ead9";
$ri-book-open-fill: "\eada";
$ri-book-open-line: "\eadb";
$ri-book-read-fill: "\eadc";
$ri-book-read-line: "\eadd";
$ri-booklet-fill: "\eade";
$ri-booklet-line: "\eadf";
$ri-bookmark-2-fill: "\eae0";
$ri-bookmark-2-line: "\eae1";
$ri-bookmark-3-fill: "\eae2";
$ri-bookmark-3-line: "\eae3";
$ri-bookmark-fill: "\eae4";
$ri-bookmark-line: "\eae5";
$ri-boxing-fill: "\eae6";
$ri-boxing-line: "\eae7";
$ri-braces-fill: "\eae8";
$ri-braces-line: "\eae9";
$ri-brackets-fill: "\eaea";
$ri-brackets-line: "\eaeb";
$ri-briefcase-2-fill: "\eaec";
$ri-briefcase-2-line: "\eaed";
$ri-briefcase-3-fill: "\eaee";
$ri-briefcase-3-line: "\eaef";
$ri-briefcase-4-fill: "\eaf0";
$ri-briefcase-4-line: "\eaf1";
$ri-briefcase-5-fill: "\eaf2";
$ri-briefcase-5-line: "\eaf3";
$ri-briefcase-fill: "\eaf4";
$ri-briefcase-line: "\eaf5";
$ri-bring-forward: "\eaf6";
$ri-bring-to-front: "\eaf7";
$ri-broadcast-fill: "\eaf8";
$ri-broadcast-line: "\eaf9";
$ri-brush-2-fill: "\eafa";
$ri-brush-2-line: "\eafb";
$ri-brush-3-fill: "\eafc";
$ri-brush-3-line: "\eafd";
$ri-brush-4-fill: "\eafe";
$ri-brush-4-line: "\eaff";
$ri-brush-fill: "\eb00";
$ri-brush-line: "\eb01";
$ri-bubble-chart-fill: "\eb02";
$ri-bubble-chart-line: "\eb03";
$ri-bug-2-fill: "\eb04";
$ri-bug-2-line: "\eb05";
$ri-bug-fill: "\eb06";
$ri-bug-line: "\eb07";
$ri-building-2-fill: "\eb08";
$ri-building-2-line: "\eb09";
$ri-building-3-fill: "\eb0a";
$ri-building-3-line: "\eb0b";
$ri-building-4-fill: "\eb0c";
$ri-building-4-line: "\eb0d";
$ri-building-fill: "\eb0e";
$ri-building-line: "\eb0f";
$ri-bus-2-fill: "\eb10";
$ri-bus-2-line: "\eb11";
$ri-bus-fill: "\eb12";
$ri-bus-line: "\eb13";
$ri-bus-wifi-fill: "\eb14";
$ri-bus-wifi-line: "\eb15";
$ri-cactus-fill: "\eb16";
$ri-cactus-line: "\eb17";
$ri-cake-2-fill: "\eb18";
$ri-cake-2-line: "\eb19";
$ri-cake-3-fill: "\eb1a";
$ri-cake-3-line: "\eb1b";
$ri-cake-fill: "\eb1c";
$ri-cake-line: "\eb1d";
$ri-calculator-fill: "\eb1e";
$ri-calculator-line: "\eb1f";
$ri-calendar-2-fill: "\eb20";
$ri-calendar-2-line: "\eb21";
$ri-calendar-check-fill: "\eb22";
$ri-calendar-check-line: "\eb23";
$ri-calendar-event-fill: "\eb24";
$ri-calendar-event-line: "\eb25";
$ri-calendar-fill: "\eb26";
$ri-calendar-line: "\eb27";
$ri-calendar-todo-fill: "\eb28";
$ri-calendar-todo-line: "\eb29";
$ri-camera-2-fill: "\eb2a";
$ri-camera-2-line: "\eb2b";
$ri-camera-3-fill: "\eb2c";
$ri-camera-3-line: "\eb2d";
$ri-camera-fill: "\eb2e";
$ri-camera-lens-fill: "\eb2f";
$ri-camera-lens-line: "\eb30";
$ri-camera-line: "\eb31";
$ri-camera-off-fill: "\eb32";
$ri-camera-off-line: "\eb33";
$ri-camera-switch-fill: "\eb34";
$ri-camera-switch-line: "\eb35";
$ri-capsule-fill: "\eb36";
$ri-capsule-line: "\eb37";
$ri-car-fill: "\eb38";
$ri-car-line: "\eb39";
$ri-car-washing-fill: "\eb3a";
$ri-car-washing-line: "\eb3b";
$ri-caravan-fill: "\eb3c";
$ri-caravan-line: "\eb3d";
$ri-cast-fill: "\eb3e";
$ri-cast-line: "\eb3f";
$ri-cellphone-fill: "\eb40";
$ri-cellphone-line: "\eb41";
$ri-celsius-fill: "\eb42";
$ri-celsius-line: "\eb43";
$ri-centos-fill: "\eb44";
$ri-centos-line: "\eb45";
$ri-character-recognition-fill: "\eb46";
$ri-character-recognition-line: "\eb47";
$ri-charging-pile-2-fill: "\eb48";
$ri-charging-pile-2-line: "\eb49";
$ri-charging-pile-fill: "\eb4a";
$ri-charging-pile-line: "\eb4b";
$ri-chat-1-fill: "\eb4c";
$ri-chat-1-line: "\eb4d";
$ri-chat-2-fill: "\eb4e";
$ri-chat-2-line: "\eb4f";
$ri-chat-3-fill: "\eb50";
$ri-chat-3-line: "\eb51";
$ri-chat-4-fill: "\eb52";
$ri-chat-4-line: "\eb53";
$ri-chat-check-fill: "\eb54";
$ri-chat-check-line: "\eb55";
$ri-chat-delete-fill: "\eb56";
$ri-chat-delete-line: "\eb57";
$ri-chat-download-fill: "\eb58";
$ri-chat-download-line: "\eb59";
$ri-chat-follow-up-fill: "\eb5a";
$ri-chat-follow-up-line: "\eb5b";
$ri-chat-forward-fill: "\eb5c";
$ri-chat-forward-line: "\eb5d";
$ri-chat-heart-fill: "\eb5e";
$ri-chat-heart-line: "\eb5f";
$ri-chat-history-fill: "\eb60";
$ri-chat-history-line: "\eb61";
$ri-chat-new-fill: "\eb62";
$ri-chat-new-line: "\eb63";
$ri-chat-off-fill: "\eb64";
$ri-chat-off-line: "\eb65";
$ri-chat-poll-fill: "\eb66";
$ri-chat-poll-line: "\eb67";
$ri-chat-private-fill: "\eb68";
$ri-chat-private-line: "\eb69";
$ri-chat-quote-fill: "\eb6a";
$ri-chat-quote-line: "\eb6b";
$ri-chat-settings-fill: "\eb6c";
$ri-chat-settings-line: "\eb6d";
$ri-chat-smile-2-fill: "\eb6e";
$ri-chat-smile-2-line: "\eb6f";
$ri-chat-smile-3-fill: "\eb70";
$ri-chat-smile-3-line: "\eb71";
$ri-chat-smile-fill: "\eb72";
$ri-chat-smile-line: "\eb73";
$ri-chat-upload-fill: "\eb74";
$ri-chat-upload-line: "\eb75";
$ri-chat-voice-fill: "\eb76";
$ri-chat-voice-line: "\eb77";
$ri-check-double-fill: "\eb78";
$ri-check-double-line: "\eb79";
$ri-check-fill: "\eb7a";
$ri-check-line: "\eb7b";
$ri-checkbox-blank-circle-fill: "\eb7c";
$ri-checkbox-blank-circle-line: "\eb7d";
$ri-checkbox-blank-fill: "\eb7e";
$ri-checkbox-blank-line: "\eb7f";
$ri-checkbox-circle-fill: "\eb80";
$ri-checkbox-circle-line: "\eb81";
$ri-checkbox-fill: "\eb82";
$ri-checkbox-indeterminate-fill: "\eb83";
$ri-checkbox-indeterminate-line: "\eb84";
$ri-checkbox-line: "\eb85";
$ri-checkbox-multiple-blank-fill: "\eb86";
$ri-checkbox-multiple-blank-line: "\eb87";
$ri-checkbox-multiple-fill: "\eb88";
$ri-checkbox-multiple-line: "\eb89";
$ri-china-railway-fill: "\eb8a";
$ri-china-railway-line: "\eb8b";
$ri-chrome-fill: "\eb8c";
$ri-chrome-line: "\eb8d";
$ri-clapperboard-fill: "\eb8e";
$ri-clapperboard-line: "\eb8f";
$ri-clipboard-fill: "\eb90";
$ri-clipboard-line: "\eb91";
$ri-clockwise-2-fill: "\eb92";
$ri-clockwise-2-line: "\eb93";
$ri-clockwise-fill: "\eb94";
$ri-clockwise-line: "\eb95";
$ri-close-circle-fill: "\eb96";
$ri-close-circle-line: "\eb97";
$ri-close-fill: "\eb98";
$ri-close-line: "\eb99";
$ri-closed-captioning-fill: "\eb9a";
$ri-closed-captioning-line: "\eb9b";
$ri-cloud-fill: "\eb9c";
$ri-cloud-line: "\eb9d";
$ri-cloud-off-fill: "\eb9e";
$ri-cloud-off-line: "\eb9f";
$ri-cloud-windy-fill: "\eba0";
$ri-cloud-windy-line: "\eba1";
$ri-cloudy-2-fill: "\eba2";
$ri-cloudy-2-line: "\eba3";
$ri-cloudy-fill: "\eba4";
$ri-cloudy-line: "\eba5";
$ri-code-box-fill: "\eba6";
$ri-code-box-line: "\eba7";
$ri-code-fill: "\eba8";
$ri-code-line: "\eba9";
$ri-code-s-fill: "\ebaa";
$ri-code-s-line: "\ebab";
$ri-code-s-slash-fill: "\ebac";
$ri-code-s-slash-line: "\ebad";
$ri-code-view: "\ebae";
$ri-codepen-fill: "\ebaf";
$ri-codepen-line: "\ebb0";
$ri-coin-fill: "\ebb1";
$ri-coin-line: "\ebb2";
$ri-coins-fill: "\ebb3";
$ri-coins-line: "\ebb4";
$ri-collage-fill: "\ebb5";
$ri-collage-line: "\ebb6";
$ri-command-fill: "\ebb7";
$ri-command-line: "\ebb8";
$ri-community-fill: "\ebb9";
$ri-community-line: "\ebba";
$ri-compass-2-fill: "\ebbb";
$ri-compass-2-line: "\ebbc";
$ri-compass-3-fill: "\ebbd";
$ri-compass-3-line: "\ebbe";
$ri-compass-4-fill: "\ebbf";
$ri-compass-4-line: "\ebc0";
$ri-compass-discover-fill: "\ebc1";
$ri-compass-discover-line: "\ebc2";
$ri-compass-fill: "\ebc3";
$ri-compass-line: "\ebc4";
$ri-compasses-2-fill: "\ebc5";
$ri-compasses-2-line: "\ebc6";
$ri-compasses-fill: "\ebc7";
$ri-compasses-line: "\ebc8";
$ri-computer-fill: "\ebc9";
$ri-computer-line: "\ebca";
$ri-contacts-book-2-fill: "\ebcb";
$ri-contacts-book-2-line: "\ebcc";
$ri-contacts-book-fill: "\ebcd";
$ri-contacts-book-line: "\ebce";
$ri-contacts-book-upload-fill: "\ebcf";
$ri-contacts-book-upload-line: "\ebd0";
$ri-contacts-fill: "\ebd1";
$ri-contacts-line: "\ebd2";
$ri-contrast-2-fill: "\ebd3";
$ri-contrast-2-line: "\ebd4";
$ri-contrast-drop-2-fill: "\ebd5";
$ri-contrast-drop-2-line: "\ebd6";
$ri-contrast-drop-fill: "\ebd7";
$ri-contrast-drop-line: "\ebd8";
$ri-contrast-fill: "\ebd9";
$ri-contrast-line: "\ebda";
$ri-copper-coin-fill: "\ebdb";
$ri-copper-coin-line: "\ebdc";
$ri-copper-diamond-fill: "\ebdd";
$ri-copper-diamond-line: "\ebde";
$ri-copyleft-fill: "\ebdf";
$ri-copyleft-line: "\ebe0";
$ri-copyright-fill: "\ebe1";
$ri-copyright-line: "\ebe2";
$ri-coreos-fill: "\ebe3";
$ri-coreos-line: "\ebe4";
$ri-coupon-2-fill: "\ebe5";
$ri-coupon-2-line: "\ebe6";
$ri-coupon-3-fill: "\ebe7";
$ri-coupon-3-line: "\ebe8";
$ri-coupon-4-fill: "\ebe9";
$ri-coupon-4-line: "\ebea";
$ri-coupon-5-fill: "\ebeb";
$ri-coupon-5-line: "\ebec";
$ri-coupon-fill: "\ebed";
$ri-coupon-line: "\ebee";
$ri-cpu-fill: "\ebef";
$ri-cpu-line: "\ebf0";
$ri-creative-commons-by-fill: "\ebf1";
$ri-creative-commons-by-line: "\ebf2";
$ri-creative-commons-fill: "\ebf3";
$ri-creative-commons-line: "\ebf4";
$ri-creative-commons-nc-fill: "\ebf5";
$ri-creative-commons-nc-line: "\ebf6";
$ri-creative-commons-nd-fill: "\ebf7";
$ri-creative-commons-nd-line: "\ebf8";
$ri-creative-commons-sa-fill: "\ebf9";
$ri-creative-commons-sa-line: "\ebfa";
$ri-creative-commons-zero-fill: "\ebfb";
$ri-creative-commons-zero-line: "\ebfc";
$ri-criminal-fill: "\ebfd";
$ri-criminal-line: "\ebfe";
$ri-crop-2-fill: "\ebff";
$ri-crop-2-line: "\ec00";
$ri-crop-fill: "\ec01";
$ri-crop-line: "\ec02";
$ri-css3-fill: "\ec03";
$ri-css3-line: "\ec04";
$ri-cup-fill: "\ec05";
$ri-cup-line: "\ec06";
$ri-currency-fill: "\ec07";
$ri-currency-line: "\ec08";
$ri-cursor-fill: "\ec09";
$ri-cursor-line: "\ec0a";
$ri-customer-service-2-fill: "\ec0b";
$ri-customer-service-2-line: "\ec0c";
$ri-customer-service-fill: "\ec0d";
$ri-customer-service-line: "\ec0e";
$ri-dashboard-2-fill: "\ec0f";
$ri-dashboard-2-line: "\ec10";
$ri-dashboard-3-fill: "\ec11";
$ri-dashboard-3-line: "\ec12";
$ri-dashboard-fill: "\ec13";
$ri-dashboard-line: "\ec14";
$ri-database-2-fill: "\ec15";
$ri-database-2-line: "\ec16";
$ri-database-fill: "\ec17";
$ri-database-line: "\ec18";
$ri-delete-back-2-fill: "\ec19";
$ri-delete-back-2-line: "\ec1a";
$ri-delete-back-fill: "\ec1b";
$ri-delete-back-line: "\ec1c";
$ri-delete-bin-2-fill: "\ec1d";
$ri-delete-bin-2-line: "\ec1e";
$ri-delete-bin-3-fill: "\ec1f";
$ri-delete-bin-3-line: "\ec20";
$ri-delete-bin-4-fill: "\ec21";
$ri-delete-bin-4-line: "\ec22";
$ri-delete-bin-5-fill: "\ec23";
$ri-delete-bin-5-line: "\ec24";
$ri-delete-bin-6-fill: "\ec25";
$ri-delete-bin-6-line: "\ec26";
$ri-delete-bin-7-fill: "\ec27";
$ri-delete-bin-7-line: "\ec28";
$ri-delete-bin-fill: "\ec29";
$ri-delete-bin-line: "\ec2a";
$ri-delete-column: "\ec2b";
$ri-delete-row: "\ec2c";
$ri-device-fill: "\ec2d";
$ri-device-line: "\ec2e";
$ri-device-recover-fill: "\ec2f";
$ri-device-recover-line: "\ec30";
$ri-dingding-fill: "\ec31";
$ri-dingding-line: "\ec32";
$ri-direction-fill: "\ec33";
$ri-direction-line: "\ec34";
$ri-disc-fill: "\ec35";
$ri-disc-line: "\ec36";
$ri-discord-fill: "\ec37";
$ri-discord-line: "\ec38";
$ri-discuss-fill: "\ec39";
$ri-discuss-line: "\ec3a";
$ri-dislike-fill: "\ec3b";
$ri-dislike-line: "\ec3c";
$ri-disqus-fill: "\ec3d";
$ri-disqus-line: "\ec3e";
$ri-divide-fill: "\ec3f";
$ri-divide-line: "\ec40";
$ri-donut-chart-fill: "\ec41";
$ri-donut-chart-line: "\ec42";
$ri-door-closed-fill: "\ec43";
$ri-door-closed-line: "\ec44";
$ri-door-fill: "\ec45";
$ri-door-line: "\ec46";
$ri-door-lock-box-fill: "\ec47";
$ri-door-lock-box-line: "\ec48";
$ri-door-lock-fill: "\ec49";
$ri-door-lock-line: "\ec4a";
$ri-door-open-fill: "\ec4b";
$ri-door-open-line: "\ec4c";
$ri-dossier-fill: "\ec4d";
$ri-dossier-line: "\ec4e";
$ri-douban-fill: "\ec4f";
$ri-douban-line: "\ec50";
$ri-double-quotes-l: "\ec51";
$ri-double-quotes-r: "\ec52";
$ri-download-2-fill: "\ec53";
$ri-download-2-line: "\ec54";
$ri-download-cloud-2-fill: "\ec55";
$ri-download-cloud-2-line: "\ec56";
$ri-download-cloud-fill: "\ec57";
$ri-download-cloud-line: "\ec58";
$ri-download-fill: "\ec59";
$ri-download-line: "\ec5a";
$ri-draft-fill: "\ec5b";
$ri-draft-line: "\ec5c";
$ri-drag-drop-fill: "\ec5d";
$ri-drag-drop-line: "\ec5e";
$ri-drag-move-2-fill: "\ec5f";
$ri-drag-move-2-line: "\ec60";
$ri-drag-move-fill: "\ec61";
$ri-drag-move-line: "\ec62";
$ri-dribbble-fill: "\ec63";
$ri-dribbble-line: "\ec64";
$ri-drive-fill: "\ec65";
$ri-drive-line: "\ec66";
$ri-drizzle-fill: "\ec67";
$ri-drizzle-line: "\ec68";
$ri-drop-fill: "\ec69";
$ri-drop-line: "\ec6a";
$ri-dropbox-fill: "\ec6b";
$ri-dropbox-line: "\ec6c";
$ri-dual-sim-1-fill: "\ec6d";
$ri-dual-sim-1-line: "\ec6e";
$ri-dual-sim-2-fill: "\ec6f";
$ri-dual-sim-2-line: "\ec70";
$ri-dv-fill: "\ec71";
$ri-dv-line: "\ec72";
$ri-dvd-fill: "\ec73";
$ri-dvd-line: "\ec74";
$ri-e-bike-2-fill: "\ec75";
$ri-e-bike-2-line: "\ec76";
$ri-e-bike-fill: "\ec77";
$ri-e-bike-line: "\ec78";
$ri-earth-fill: "\ec79";
$ri-earth-line: "\ec7a";
$ri-earthquake-fill: "\ec7b";
$ri-earthquake-line: "\ec7c";
$ri-edge-fill: "\ec7d";
$ri-edge-line: "\ec7e";
$ri-edit-2-fill: "\ec7f";
$ri-edit-2-line: "\ec80";
$ri-edit-box-fill: "\ec81";
$ri-edit-box-line: "\ec82";
$ri-edit-circle-fill: "\ec83";
$ri-edit-circle-line: "\ec84";
$ri-edit-fill: "\ec85";
$ri-edit-line: "\ec86";
$ri-eject-fill: "\ec87";
$ri-eject-line: "\ec88";
$ri-emotion-2-fill: "\ec89";
$ri-emotion-2-line: "\ec8a";
$ri-emotion-fill: "\ec8b";
$ri-emotion-happy-fill: "\ec8c";
$ri-emotion-happy-line: "\ec8d";
$ri-emotion-laugh-fill: "\ec8e";
$ri-emotion-laugh-line: "\ec8f";
$ri-emotion-line: "\ec90";
$ri-emotion-normal-fill: "\ec91";
$ri-emotion-normal-line: "\ec92";
$ri-emotion-sad-fill: "\ec93";
$ri-emotion-sad-line: "\ec94";
$ri-emotion-unhappy-fill: "\ec95";
$ri-emotion-unhappy-line: "\ec96";
$ri-empathize-fill: "\ec97";
$ri-empathize-line: "\ec98";
$ri-emphasis-cn: "\ec99";
$ri-emphasis: "\ec9a";
$ri-english-input: "\ec9b";
$ri-equalizer-fill: "\ec9c";
$ri-equalizer-line: "\ec9d";
$ri-eraser-fill: "\ec9e";
$ri-eraser-line: "\ec9f";
$ri-error-warning-fill: "\eca0";
$ri-error-warning-line: "\eca1";
$ri-evernote-fill: "\eca2";
$ri-evernote-line: "\eca3";
$ri-exchange-box-fill: "\eca4";
$ri-exchange-box-line: "\eca5";
$ri-exchange-cny-fill: "\eca6";
$ri-exchange-cny-line: "\eca7";
$ri-exchange-dollar-fill: "\eca8";
$ri-exchange-dollar-line: "\eca9";
$ri-exchange-fill: "\ecaa";
$ri-exchange-funds-fill: "\ecab";
$ri-exchange-funds-line: "\ecac";
$ri-exchange-line: "\ecad";
$ri-external-link-fill: "\ecae";
$ri-external-link-line: "\ecaf";
$ri-eye-2-fill: "\ecb0";
$ri-eye-2-line: "\ecb1";
$ri-eye-close-fill: "\ecb2";
$ri-eye-close-line: "\ecb3";
$ri-eye-fill: "\ecb4";
$ri-eye-line: "\ecb5";
$ri-eye-off-fill: "\ecb6";
$ri-eye-off-line: "\ecb7";
$ri-facebook-box-fill: "\ecb8";
$ri-facebook-box-line: "\ecb9";
$ri-facebook-circle-fill: "\ecba";
$ri-facebook-circle-line: "\ecbb";
$ri-facebook-fill: "\ecbc";
$ri-facebook-line: "\ecbd";
$ri-fahrenheit-fill: "\ecbe";
$ri-fahrenheit-line: "\ecbf";
$ri-feedback-fill: "\ecc0";
$ri-feedback-line: "\ecc1";
$ri-file-2-fill: "\ecc2";
$ri-file-2-line: "\ecc3";
$ri-file-3-fill: "\ecc4";
$ri-file-3-line: "\ecc5";
$ri-file-4-fill: "\ecc6";
$ri-file-4-line: "\ecc7";
$ri-file-add-fill: "\ecc8";
$ri-file-add-line: "\ecc9";
$ri-file-chart-2-fill: "\ecca";
$ri-file-chart-2-line: "\eccb";
$ri-file-chart-fill: "\eccc";
$ri-file-chart-line: "\eccd";
$ri-file-cloud-fill: "\ecce";
$ri-file-cloud-line: "\eccf";
$ri-file-code-fill: "\ecd0";
$ri-file-code-line: "\ecd1";
$ri-file-copy-2-fill: "\ecd2";
$ri-file-copy-2-line: "\ecd3";
$ri-file-copy-fill: "\ecd4";
$ri-file-copy-line: "\ecd5";
$ri-file-damage-fill: "\ecd6";
$ri-file-damage-line: "\ecd7";
$ri-file-download-fill: "\ecd8";
$ri-file-download-line: "\ecd9";
$ri-file-edit-fill: "\ecda";
$ri-file-edit-line: "\ecdb";
$ri-file-excel-2-fill: "\ecdc";
$ri-file-excel-2-line: "\ecdd";
$ri-file-excel-fill: "\ecde";
$ri-file-excel-line: "\ecdf";
$ri-file-fill: "\ece0";
$ri-file-forbid-fill: "\ece1";
$ri-file-forbid-line: "\ece2";
$ri-file-gif-fill: "\ece3";
$ri-file-gif-line: "\ece4";
$ri-file-history-fill: "\ece5";
$ri-file-history-line: "\ece6";
$ri-file-hwp-fill: "\ece7";
$ri-file-hwp-line: "\ece8";
$ri-file-info-fill: "\ece9";
$ri-file-info-line: "\ecea";
$ri-file-line: "\eceb";
$ri-file-list-2-fill: "\ecec";
$ri-file-list-2-line: "\eced";
$ri-file-list-3-fill: "\ecee";
$ri-file-list-3-line: "\ecef";
$ri-file-list-fill: "\ecf0";
$ri-file-list-line: "\ecf1";
$ri-file-lock-fill: "\ecf2";
$ri-file-lock-line: "\ecf3";
$ri-file-marked-fill: "\ecf4";
$ri-file-marked-line: "\ecf5";
$ri-file-music-fill: "\ecf6";
$ri-file-music-line: "\ecf7";
$ri-file-paper-2-fill: "\ecf8";
$ri-file-paper-2-line: "\ecf9";
$ri-file-paper-fill: "\ecfa";
$ri-file-paper-line: "\ecfb";
$ri-file-pdf-fill: "\ecfc";
$ri-file-pdf-line: "\ecfd";
$ri-file-ppt-2-fill: "\ecfe";
$ri-file-ppt-2-line: "\ecff";
$ri-file-ppt-fill: "\ed00";
$ri-file-ppt-line: "\ed01";
$ri-file-reduce-fill: "\ed02";
$ri-file-reduce-line: "\ed03";
$ri-file-search-fill: "\ed04";
$ri-file-search-line: "\ed05";
$ri-file-settings-fill: "\ed06";
$ri-file-settings-line: "\ed07";
$ri-file-shield-2-fill: "\ed08";
$ri-file-shield-2-line: "\ed09";
$ri-file-shield-fill: "\ed0a";
$ri-file-shield-line: "\ed0b";
$ri-file-shred-fill: "\ed0c";
$ri-file-shred-line: "\ed0d";
$ri-file-text-fill: "\ed0e";
$ri-file-text-line: "\ed0f";
$ri-file-transfer-fill: "\ed10";
$ri-file-transfer-line: "\ed11";
$ri-file-unknow-fill: "\ed12";
$ri-file-unknow-line: "\ed13";
$ri-file-upload-fill: "\ed14";
$ri-file-upload-line: "\ed15";
$ri-file-user-fill: "\ed16";
$ri-file-user-line: "\ed17";
$ri-file-warning-fill: "\ed18";
$ri-file-warning-line: "\ed19";
$ri-file-word-2-fill: "\ed1a";
$ri-file-word-2-line: "\ed1b";
$ri-file-word-fill: "\ed1c";
$ri-file-word-line: "\ed1d";
$ri-file-zip-fill: "\ed1e";
$ri-file-zip-line: "\ed1f";
$ri-film-fill: "\ed20";
$ri-film-line: "\ed21";
$ri-filter-2-fill: "\ed22";
$ri-filter-2-line: "\ed23";
$ri-filter-3-fill: "\ed24";
$ri-filter-3-line: "\ed25";
$ri-filter-fill: "\ed26";
$ri-filter-line: "\ed27";
$ri-filter-off-fill: "\ed28";
$ri-filter-off-line: "\ed29";
$ri-find-replace-fill: "\ed2a";
$ri-find-replace-line: "\ed2b";
$ri-finder-fill: "\ed2c";
$ri-finder-line: "\ed2d";
$ri-fingerprint-2-fill: "\ed2e";
$ri-fingerprint-2-line: "\ed2f";
$ri-fingerprint-fill: "\ed30";
$ri-fingerprint-line: "\ed31";
$ri-fire-fill: "\ed32";
$ri-fire-line: "\ed33";
$ri-firefox-fill: "\ed34";
$ri-firefox-line: "\ed35";
$ri-first-aid-kit-fill: "\ed36";
$ri-first-aid-kit-line: "\ed37";
$ri-flag-2-fill: "\ed38";
$ri-flag-2-line: "\ed39";
$ri-flag-fill: "\ed3a";
$ri-flag-line: "\ed3b";
$ri-flashlight-fill: "\ed3c";
$ri-flashlight-line: "\ed3d";
$ri-flask-fill: "\ed3e";
$ri-flask-line: "\ed3f";
$ri-flight-land-fill: "\ed40";
$ri-flight-land-line: "\ed41";
$ri-flight-takeoff-fill: "\ed42";
$ri-flight-takeoff-line: "\ed43";
$ri-flood-fill: "\ed44";
$ri-flood-line: "\ed45";
$ri-flow-chart: "\ed46";
$ri-flutter-fill: "\ed47";
$ri-flutter-line: "\ed48";
$ri-focus-2-fill: "\ed49";
$ri-focus-2-line: "\ed4a";
$ri-focus-3-fill: "\ed4b";
$ri-focus-3-line: "\ed4c";
$ri-focus-fill: "\ed4d";
$ri-focus-line: "\ed4e";
$ri-foggy-fill: "\ed4f";
$ri-foggy-line: "\ed50";
$ri-folder-2-fill: "\ed51";
$ri-folder-2-line: "\ed52";
$ri-folder-3-fill: "\ed53";
$ri-folder-3-line: "\ed54";
$ri-folder-4-fill: "\ed55";
$ri-folder-4-line: "\ed56";
$ri-folder-5-fill: "\ed57";
$ri-folder-5-line: "\ed58";
$ri-folder-add-fill: "\ed59";
$ri-folder-add-line: "\ed5a";
$ri-folder-chart-2-fill: "\ed5b";
$ri-folder-chart-2-line: "\ed5c";
$ri-folder-chart-fill: "\ed5d";
$ri-folder-chart-line: "\ed5e";
$ri-folder-download-fill: "\ed5f";
$ri-folder-download-line: "\ed60";
$ri-folder-fill: "\ed61";
$ri-folder-forbid-fill: "\ed62";
$ri-folder-forbid-line: "\ed63";
$ri-folder-history-fill: "\ed64";
$ri-folder-history-line: "\ed65";
$ri-folder-info-fill: "\ed66";
$ri-folder-info-line: "\ed67";
$ri-folder-keyhole-fill: "\ed68";
$ri-folder-keyhole-line: "\ed69";
$ri-folder-line: "\ed6a";
$ri-folder-lock-fill: "\ed6b";
$ri-folder-lock-line: "\ed6c";
$ri-folder-music-fill: "\ed6d";
$ri-folder-music-line: "\ed6e";
$ri-folder-open-fill: "\ed6f";
$ri-folder-open-line: "\ed70";
$ri-folder-received-fill: "\ed71";
$ri-folder-received-line: "\ed72";
$ri-folder-reduce-fill: "\ed73";
$ri-folder-reduce-line: "\ed74";
$ri-folder-settings-fill: "\ed75";
$ri-folder-settings-line: "\ed76";
$ri-folder-shared-fill: "\ed77";
$ri-folder-shared-line: "\ed78";
$ri-folder-shield-2-fill: "\ed79";
$ri-folder-shield-2-line: "\ed7a";
$ri-folder-shield-fill: "\ed7b";
$ri-folder-shield-line: "\ed7c";
$ri-folder-transfer-fill: "\ed7d";
$ri-folder-transfer-line: "\ed7e";
$ri-folder-unknow-fill: "\ed7f";
$ri-folder-unknow-line: "\ed80";
$ri-folder-upload-fill: "\ed81";
$ri-folder-upload-line: "\ed82";
$ri-folder-user-fill: "\ed83";
$ri-folder-user-line: "\ed84";
$ri-folder-warning-fill: "\ed85";
$ri-folder-warning-line: "\ed86";
$ri-folder-zip-fill: "\ed87";
$ri-folder-zip-line: "\ed88";
$ri-folders-fill: "\ed89";
$ri-folders-line: "\ed8a";
$ri-font-color: "\ed8b";
$ri-font-size-2: "\ed8c";
$ri-font-size: "\ed8d";
$ri-football-fill: "\ed8e";
$ri-football-line: "\ed8f";
$ri-footprint-fill: "\ed90";
$ri-footprint-line: "\ed91";
$ri-forbid-2-fill: "\ed92";
$ri-forbid-2-line: "\ed93";
$ri-forbid-fill: "\ed94";
$ri-forbid-line: "\ed95";
$ri-format-clear: "\ed96";
$ri-fridge-fill: "\ed97";
$ri-fridge-line: "\ed98";
$ri-fullscreen-exit-fill: "\ed99";
$ri-fullscreen-exit-line: "\ed9a";
$ri-fullscreen-fill: "\ed9b";
$ri-fullscreen-line: "\ed9c";
$ri-function-fill: "\ed9d";
$ri-function-line: "\ed9e";
$ri-functions: "\ed9f";
$ri-funds-box-fill: "\eda0";
$ri-funds-box-line: "\eda1";
$ri-funds-fill: "\eda2";
$ri-funds-line: "\eda3";
$ri-gallery-fill: "\eda4";
$ri-gallery-line: "\eda5";
$ri-gallery-upload-fill: "\eda6";
$ri-gallery-upload-line: "\eda7";
$ri-game-fill: "\eda8";
$ri-game-line: "\eda9";
$ri-gamepad-fill: "\edaa";
$ri-gamepad-line: "\edab";
$ri-gas-station-fill: "\edac";
$ri-gas-station-line: "\edad";
$ri-gatsby-fill: "\edae";
$ri-gatsby-line: "\edaf";
$ri-genderless-fill: "\edb0";
$ri-genderless-line: "\edb1";
$ri-ghost-2-fill: "\edb2";
$ri-ghost-2-line: "\edb3";
$ri-ghost-fill: "\edb4";
$ri-ghost-line: "\edb5";
$ri-ghost-smile-fill: "\edb6";
$ri-ghost-smile-line: "\edb7";
$ri-gift-2-fill: "\edb8";
$ri-gift-2-line: "\edb9";
$ri-gift-fill: "\edba";
$ri-gift-line: "\edbb";
$ri-git-branch-fill: "\edbc";
$ri-git-branch-line: "\edbd";
$ri-git-commit-fill: "\edbe";
$ri-git-commit-line: "\edbf";
$ri-git-merge-fill: "\edc0";
$ri-git-merge-line: "\edc1";
$ri-git-pull-request-fill: "\edc2";
$ri-git-pull-request-line: "\edc3";
$ri-git-repository-commits-fill: "\edc4";
$ri-git-repository-commits-line: "\edc5";
$ri-git-repository-fill: "\edc6";
$ri-git-repository-line: "\edc7";
$ri-git-repository-private-fill: "\edc8";
$ri-git-repository-private-line: "\edc9";
$ri-github-fill: "\edca";
$ri-github-line: "\edcb";
$ri-gitlab-fill: "\edcc";
$ri-gitlab-line: "\edcd";
$ri-global-fill: "\edce";
$ri-global-line: "\edcf";
$ri-globe-fill: "\edd0";
$ri-globe-line: "\edd1";
$ri-goblet-fill: "\edd2";
$ri-goblet-line: "\edd3";
$ri-google-fill: "\edd4";
$ri-google-line: "\edd5";
$ri-google-play-fill: "\edd6";
$ri-google-play-line: "\edd7";
$ri-government-fill: "\edd8";
$ri-government-line: "\edd9";
$ri-gps-fill: "\edda";
$ri-gps-line: "\eddb";
$ri-gradienter-fill: "\eddc";
$ri-gradienter-line: "\eddd";
$ri-grid-fill: "\edde";
$ri-grid-line: "\eddf";
$ri-group-2-fill: "\ede0";
$ri-group-2-line: "\ede1";
$ri-group-fill: "\ede2";
$ri-group-line: "\ede3";
$ri-guide-fill: "\ede4";
$ri-guide-line: "\ede5";
$ri-h-1: "\ede6";
$ri-h-2: "\ede7";
$ri-h-3: "\ede8";
$ri-h-4: "\ede9";
$ri-h-5: "\edea";
$ri-h-6: "\edeb";
$ri-hail-fill: "\edec";
$ri-hail-line: "\eded";
$ri-hammer-fill: "\edee";
$ri-hammer-line: "\edef";
$ri-hand-coin-fill: "\edf0";
$ri-hand-coin-line: "\edf1";
$ri-hand-heart-fill: "\edf2";
$ri-hand-heart-line: "\edf3";
$ri-hand-sanitizer-fill: "\edf4";
$ri-hand-sanitizer-line: "\edf5";
$ri-handbag-fill: "\edf6";
$ri-handbag-line: "\edf7";
$ri-hard-drive-2-fill: "\edf8";
$ri-hard-drive-2-line: "\edf9";
$ri-hard-drive-fill: "\edfa";
$ri-hard-drive-line: "\edfb";
$ri-hashtag: "\edfc";
$ri-haze-2-fill: "\edfd";
$ri-haze-2-line: "\edfe";
$ri-haze-fill: "\edff";
$ri-haze-line: "\ee00";
$ri-hd-fill: "\ee01";
$ri-hd-line: "\ee02";
$ri-heading: "\ee03";
$ri-headphone-fill: "\ee04";
$ri-headphone-line: "\ee05";
$ri-health-book-fill: "\ee06";
$ri-health-book-line: "\ee07";
$ri-heart-2-fill: "\ee08";
$ri-heart-2-line: "\ee09";
$ri-heart-3-fill: "\ee0a";
$ri-heart-3-line: "\ee0b";
$ri-heart-add-fill: "\ee0c";
$ri-heart-add-line: "\ee0d";
$ri-heart-fill: "\ee0e";
$ri-heart-line: "\ee0f";
$ri-heart-pulse-fill: "\ee10";
$ri-heart-pulse-line: "\ee11";
$ri-hearts-fill: "\ee12";
$ri-hearts-line: "\ee13";
$ri-heavy-showers-fill: "\ee14";
$ri-heavy-showers-line: "\ee15";
$ri-history-fill: "\ee16";
$ri-history-line: "\ee17";
$ri-home-2-fill: "\ee18";
$ri-home-2-line: "\ee19";
$ri-home-3-fill: "\ee1a";
$ri-home-3-line: "\ee1b";
$ri-home-4-fill: "\ee1c";
$ri-home-4-line: "\ee1d";
$ri-home-5-fill: "\ee1e";
$ri-home-5-line: "\ee1f";
$ri-home-6-fill: "\ee20";
$ri-home-6-line: "\ee21";
$ri-home-7-fill: "\ee22";
$ri-home-7-line: "\ee23";
$ri-home-8-fill: "\ee24";
$ri-home-8-line: "\ee25";
$ri-home-fill: "\ee26";
$ri-home-gear-fill: "\ee27";
$ri-home-gear-line: "\ee28";
$ri-home-heart-fill: "\ee29";
$ri-home-heart-line: "\ee2a";
$ri-home-line: "\ee2b";
$ri-home-smile-2-fill: "\ee2c";
$ri-home-smile-2-line: "\ee2d";
$ri-home-smile-fill: "\ee2e";
$ri-home-smile-line: "\ee2f";
$ri-home-wifi-fill: "\ee30";
$ri-home-wifi-line: "\ee31";
$ri-honor-of-kings-fill: "\ee32";
$ri-honor-of-kings-line: "\ee33";
$ri-honour-fill: "\ee34";
$ri-honour-line: "\ee35";
$ri-hospital-fill: "\ee36";
$ri-hospital-line: "\ee37";
$ri-hotel-bed-fill: "\ee38";
$ri-hotel-bed-line: "\ee39";
$ri-hotel-fill: "\ee3a";
$ri-hotel-line: "\ee3b";
$ri-hotspot-fill: "\ee3c";
$ri-hotspot-line: "\ee3d";
$ri-hq-fill: "\ee3e";
$ri-hq-line: "\ee3f";
$ri-html5-fill: "\ee40";
$ri-html5-line: "\ee41";
$ri-ie-fill: "\ee42";
$ri-ie-line: "\ee43";
$ri-image-2-fill: "\ee44";
$ri-image-2-line: "\ee45";
$ri-image-add-fill: "\ee46";
$ri-image-add-line: "\ee47";
$ri-image-edit-fill: "\ee48";
$ri-image-edit-line: "\ee49";
$ri-image-fill: "\ee4a";
$ri-image-line: "\ee4b";
$ri-inbox-archive-fill: "\ee4c";
$ri-inbox-archive-line: "\ee4d";
$ri-inbox-fill: "\ee4e";
$ri-inbox-line: "\ee4f";
$ri-inbox-unarchive-fill: "\ee50";
$ri-inbox-unarchive-line: "\ee51";
$ri-increase-decrease-fill: "\ee52";
$ri-increase-decrease-line: "\ee53";
$ri-indent-decrease: "\ee54";
$ri-indent-increase: "\ee55";
$ri-indeterminate-circle-fill: "\ee56";
$ri-indeterminate-circle-line: "\ee57";
$ri-information-fill: "\ee58";
$ri-information-line: "\ee59";
$ri-infrared-thermometer-fill: "\ee5a";
$ri-infrared-thermometer-line: "\ee5b";
$ri-ink-bottle-fill: "\ee5c";
$ri-ink-bottle-line: "\ee5d";
$ri-input-cursor-move: "\ee5e";
$ri-input-method-fill: "\ee5f";
$ri-input-method-line: "\ee60";
$ri-insert-column-left: "\ee61";
$ri-insert-column-right: "\ee62";
$ri-insert-row-bottom: "\ee63";
$ri-insert-row-top: "\ee64";
$ri-instagram-fill: "\ee65";
$ri-instagram-line: "\ee66";
$ri-install-fill: "\ee67";
$ri-install-line: "\ee68";
$ri-invision-fill: "\ee69";
$ri-invision-line: "\ee6a";
$ri-italic: "\ee6b";
$ri-kakao-talk-fill: "\ee6c";
$ri-kakao-talk-line: "\ee6d";
$ri-key-2-fill: "\ee6e";
$ri-key-2-line: "\ee6f";
$ri-key-fill: "\ee70";
$ri-key-line: "\ee71";
$ri-keyboard-box-fill: "\ee72";
$ri-keyboard-box-line: "\ee73";
$ri-keyboard-fill: "\ee74";
$ri-keyboard-line: "\ee75";
$ri-keynote-fill: "\ee76";
$ri-keynote-line: "\ee77";
$ri-knife-blood-fill: "\ee78";
$ri-knife-blood-line: "\ee79";
$ri-knife-fill: "\ee7a";
$ri-knife-line: "\ee7b";
$ri-landscape-fill: "\ee7c";
$ri-landscape-line: "\ee7d";
$ri-layout-2-fill: "\ee7e";
$ri-layout-2-line: "\ee7f";
$ri-layout-3-fill: "\ee80";
$ri-layout-3-line: "\ee81";
$ri-layout-4-fill: "\ee82";
$ri-layout-4-line: "\ee83";
$ri-layout-5-fill: "\ee84";
$ri-layout-5-line: "\ee85";
$ri-layout-6-fill: "\ee86";
$ri-layout-6-line: "\ee87";
$ri-layout-bottom-2-fill: "\ee88";
$ri-layout-bottom-2-line: "\ee89";
$ri-layout-bottom-fill: "\ee8a";
$ri-layout-bottom-line: "\ee8b";
$ri-layout-column-fill: "\ee8c";
$ri-layout-column-line: "\ee8d";
$ri-layout-fill: "\ee8e";
$ri-layout-grid-fill: "\ee8f";
$ri-layout-grid-line: "\ee90";
$ri-layout-left-2-fill: "\ee91";
$ri-layout-left-2-line: "\ee92";
$ri-layout-left-fill: "\ee93";
$ri-layout-left-line: "\ee94";
$ri-layout-line: "\ee95";
$ri-layout-masonry-fill: "\ee96";
$ri-layout-masonry-line: "\ee97";
$ri-layout-right-2-fill: "\ee98";
$ri-layout-right-2-line: "\ee99";
$ri-layout-right-fill: "\ee9a";
$ri-layout-right-line: "\ee9b";
$ri-layout-row-fill: "\ee9c";
$ri-layout-row-line: "\ee9d";
$ri-layout-top-2-fill: "\ee9e";
$ri-layout-top-2-line: "\ee9f";
$ri-layout-top-fill: "\eea0";
$ri-layout-top-line: "\eea1";
$ri-leaf-fill: "\eea2";
$ri-leaf-line: "\eea3";
$ri-lifebuoy-fill: "\eea4";
$ri-lifebuoy-line: "\eea5";
$ri-lightbulb-fill: "\eea6";
$ri-lightbulb-flash-fill: "\eea7";
$ri-lightbulb-flash-line: "\eea8";
$ri-lightbulb-line: "\eea9";
$ri-line-chart-fill: "\eeaa";
$ri-line-chart-line: "\eeab";
$ri-line-fill: "\eeac";
$ri-line-height: "\eead";
$ri-line-line: "\eeae";
$ri-link-m: "\eeaf";
$ri-link-unlink-m: "\eeb0";
$ri-link-unlink: "\eeb1";
$ri-link: "\eeb2";
$ri-linkedin-box-fill: "\eeb3";
$ri-linkedin-box-line: "\eeb4";
$ri-linkedin-fill: "\eeb5";
$ri-linkedin-line: "\eeb6";
$ri-links-fill: "\eeb7";
$ri-links-line: "\eeb8";
$ri-list-check-2: "\eeb9";
$ri-list-check: "\eeba";
$ri-list-ordered: "\eebb";
$ri-list-settings-fill: "\eebc";
$ri-list-settings-line: "\eebd";
$ri-list-unordered: "\eebe";
$ri-live-fill: "\eebf";
$ri-live-line: "\eec0";
$ri-loader-2-fill: "\eec1";
$ri-loader-2-line: "\eec2";
$ri-loader-3-fill: "\eec3";
$ri-loader-3-line: "\eec4";
$ri-loader-4-fill: "\eec5";
$ri-loader-4-line: "\eec6";
$ri-loader-5-fill: "\eec7";
$ri-loader-5-line: "\eec8";
$ri-loader-fill: "\eec9";
$ri-loader-line: "\eeca";
$ri-lock-2-fill: "\eecb";
$ri-lock-2-line: "\eecc";
$ri-lock-fill: "\eecd";
$ri-lock-line: "\eece";
$ri-lock-password-fill: "\eecf";
$ri-lock-password-line: "\eed0";
$ri-lock-unlock-fill: "\eed1";
$ri-lock-unlock-line: "\eed2";
$ri-login-box-fill: "\eed3";
$ri-login-box-line: "\eed4";
$ri-login-circle-fill: "\eed5";
$ri-login-circle-line: "\eed6";
$ri-logout-box-fill: "\eed7";
$ri-logout-box-line: "\eed8";
$ri-logout-box-r-fill: "\eed9";
$ri-logout-box-r-line: "\eeda";
$ri-logout-circle-fill: "\eedb";
$ri-logout-circle-line: "\eedc";
$ri-logout-circle-r-fill: "\eedd";
$ri-logout-circle-r-line: "\eede";
$ri-luggage-cart-fill: "\eedf";
$ri-luggage-cart-line: "\eee0";
$ri-luggage-deposit-fill: "\eee1";
$ri-luggage-deposit-line: "\eee2";
$ri-lungs-fill: "\eee3";
$ri-lungs-line: "\eee4";
$ri-mac-fill: "\eee5";
$ri-mac-line: "\eee6";
$ri-macbook-fill: "\eee7";
$ri-macbook-line: "\eee8";
$ri-magic-fill: "\eee9";
$ri-magic-line: "\eeea";
$ri-mail-add-fill: "\eeeb";
$ri-mail-add-line: "\eeec";
$ri-mail-check-fill: "\eeed";
$ri-mail-check-line: "\eeee";
$ri-mail-close-fill: "\eeef";
$ri-mail-close-line: "\eef0";
$ri-mail-download-fill: "\eef1";
$ri-mail-download-line: "\eef2";
$ri-mail-fill: "\eef3";
$ri-mail-forbid-fill: "\eef4";
$ri-mail-forbid-line: "\eef5";
$ri-mail-line: "\eef6";
$ri-mail-lock-fill: "\eef7";
$ri-mail-lock-line: "\eef8";
$ri-mail-open-fill: "\eef9";
$ri-mail-open-line: "\eefa";
$ri-mail-send-fill: "\eefb";
$ri-mail-send-line: "\eefc";
$ri-mail-settings-fill: "\eefd";
$ri-mail-settings-line: "\eefe";
$ri-mail-star-fill: "\eeff";
$ri-mail-star-line: "\ef00";
$ri-mail-unread-fill: "\ef01";
$ri-mail-unread-line: "\ef02";
$ri-mail-volume-fill: "\ef03";
$ri-mail-volume-line: "\ef04";
$ri-map-2-fill: "\ef05";
$ri-map-2-line: "\ef06";
$ri-map-fill: "\ef07";
$ri-map-line: "\ef08";
$ri-map-pin-2-fill: "\ef09";
$ri-map-pin-2-line: "\ef0a";
$ri-map-pin-3-fill: "\ef0b";
$ri-map-pin-3-line: "\ef0c";
$ri-map-pin-4-fill: "\ef0d";
$ri-map-pin-4-line: "\ef0e";
$ri-map-pin-5-fill: "\ef0f";
$ri-map-pin-5-line: "\ef10";
$ri-map-pin-add-fill: "\ef11";
$ri-map-pin-add-line: "\ef12";
$ri-map-pin-fill: "\ef13";
$ri-map-pin-line: "\ef14";
$ri-map-pin-range-fill: "\ef15";
$ri-map-pin-range-line: "\ef16";
$ri-map-pin-time-fill: "\ef17";
$ri-map-pin-time-line: "\ef18";
$ri-map-pin-user-fill: "\ef19";
$ri-map-pin-user-line: "\ef1a";
$ri-mark-pen-fill: "\ef1b";
$ri-mark-pen-line: "\ef1c";
$ri-markdown-fill: "\ef1d";
$ri-markdown-line: "\ef1e";
$ri-markup-fill: "\ef1f";
$ri-markup-line: "\ef20";
$ri-mastercard-fill: "\ef21";
$ri-mastercard-line: "\ef22";
$ri-mastodon-fill: "\ef23";
$ri-mastodon-line: "\ef24";
$ri-medal-2-fill: "\ef25";
$ri-medal-2-line: "\ef26";
$ri-medal-fill: "\ef27";
$ri-medal-line: "\ef28";
$ri-medicine-bottle-fill: "\ef29";
$ri-medicine-bottle-line: "\ef2a";
$ri-medium-fill: "\ef2b";
$ri-medium-line: "\ef2c";
$ri-men-fill: "\ef2d";
$ri-men-line: "\ef2e";
$ri-mental-health-fill: "\ef2f";
$ri-mental-health-line: "\ef30";
$ri-menu-2-fill: "\ef31";
$ri-menu-2-line: "\ef32";
$ri-menu-3-fill: "\ef33";
$ri-menu-3-line: "\ef34";
$ri-menu-4-fill: "\ef35";
$ri-menu-4-line: "\ef36";
$ri-menu-5-fill: "\ef37";
$ri-menu-5-line: "\ef38";
$ri-menu-add-fill: "\ef39";
$ri-menu-add-line: "\ef3a";
$ri-menu-fill: "\ef3b";
$ri-menu-fold-fill: "\ef3c";
$ri-menu-fold-line: "\ef3d";
$ri-menu-line: "\ef3e";
$ri-menu-unfold-fill: "\ef3f";
$ri-menu-unfold-line: "\ef40";
$ri-merge-cells-horizontal: "\ef41";
$ri-merge-cells-vertical: "\ef42";
$ri-message-2-fill: "\ef43";
$ri-message-2-line: "\ef44";
$ri-message-3-fill: "\ef45";
$ri-message-3-line: "\ef46";
$ri-message-fill: "\ef47";
$ri-message-line: "\ef48";
$ri-messenger-fill: "\ef49";
$ri-messenger-line: "\ef4a";
$ri-meteor-fill: "\ef4b";
$ri-meteor-line: "\ef4c";
$ri-mic-2-fill: "\ef4d";
$ri-mic-2-line: "\ef4e";
$ri-mic-fill: "\ef4f";
$ri-mic-line: "\ef50";
$ri-mic-off-fill: "\ef51";
$ri-mic-off-line: "\ef52";
$ri-mickey-fill: "\ef53";
$ri-mickey-line: "\ef54";
$ri-microscope-fill: "\ef55";
$ri-microscope-line: "\ef56";
$ri-microsoft-fill: "\ef57";
$ri-microsoft-line: "\ef58";
$ri-mind-map: "\ef59";
$ri-mini-program-fill: "\ef5a";
$ri-mini-program-line: "\ef5b";
$ri-mist-fill: "\ef5c";
$ri-mist-line: "\ef5d";
$ri-money-cny-box-fill: "\ef5e";
$ri-money-cny-box-line: "\ef5f";
$ri-money-cny-circle-fill: "\ef60";
$ri-money-cny-circle-line: "\ef61";
$ri-money-dollar-box-fill: "\ef62";
$ri-money-dollar-box-line: "\ef63";
$ri-money-dollar-circle-fill: "\ef64";
$ri-money-dollar-circle-line: "\ef65";
$ri-money-euro-box-fill: "\ef66";
$ri-money-euro-box-line: "\ef67";
$ri-money-euro-circle-fill: "\ef68";
$ri-money-euro-circle-line: "\ef69";
$ri-money-pound-box-fill: "\ef6a";
$ri-money-pound-box-line: "\ef6b";
$ri-money-pound-circle-fill: "\ef6c";
$ri-money-pound-circle-line: "\ef6d";
$ri-moon-clear-fill: "\ef6e";
$ri-moon-clear-line: "\ef6f";
$ri-moon-cloudy-fill: "\ef70";
$ri-moon-cloudy-line: "\ef71";
$ri-moon-fill: "\ef72";
$ri-moon-foggy-fill: "\ef73";
$ri-moon-foggy-line: "\ef74";
$ri-moon-line: "\ef75";
$ri-more-2-fill: "\ef76";
$ri-more-2-line: "\ef77";
$ri-more-fill: "\ef78";
$ri-more-line: "\ef79";
$ri-motorbike-fill: "\ef7a";
$ri-motorbike-line: "\ef7b";
$ri-mouse-fill: "\ef7c";
$ri-mouse-line: "\ef7d";
$ri-movie-2-fill: "\ef7e";
$ri-movie-2-line: "\ef7f";
$ri-movie-fill: "\ef80";
$ri-movie-line: "\ef81";
$ri-music-2-fill: "\ef82";
$ri-music-2-line: "\ef83";
$ri-music-fill: "\ef84";
$ri-music-line: "\ef85";
$ri-mv-fill: "\ef86";
$ri-mv-line: "\ef87";
$ri-navigation-fill: "\ef88";
$ri-navigation-line: "\ef89";
$ri-netease-cloud-music-fill: "\ef8a";
$ri-netease-cloud-music-line: "\ef8b";
$ri-netflix-fill: "\ef8c";
$ri-netflix-line: "\ef8d";
$ri-newspaper-fill: "\ef8e";
$ri-newspaper-line: "\ef8f";
$ri-node-tree: "\ef90";
$ri-notification-2-fill: "\ef91";
$ri-notification-2-line: "\ef92";
$ri-notification-3-fill: "\ef93";
$ri-notification-3-line: "\ef94";
$ri-notification-4-fill: "\ef95";
$ri-notification-4-line: "\ef96";
$ri-notification-badge-fill: "\ef97";
$ri-notification-badge-line: "\ef98";
$ri-notification-fill: "\ef99";
$ri-notification-line: "\ef9a";
$ri-notification-off-fill: "\ef9b";
$ri-notification-off-line: "\ef9c";
$ri-npmjs-fill: "\ef9d";
$ri-npmjs-line: "\ef9e";
$ri-number-0: "\ef9f";
$ri-number-1: "\efa0";
$ri-number-2: "\efa1";
$ri-number-3: "\efa2";
$ri-number-4: "\efa3";
$ri-number-5: "\efa4";
$ri-number-6: "\efa5";
$ri-number-7: "\efa6";
$ri-number-8: "\efa7";
$ri-number-9: "\efa8";
$ri-numbers-fill: "\efa9";
$ri-numbers-line: "\efaa";
$ri-nurse-fill: "\efab";
$ri-nurse-line: "\efac";
$ri-oil-fill: "\efad";
$ri-oil-line: "\efae";
$ri-omega: "\efaf";
$ri-open-arm-fill: "\efb0";
$ri-open-arm-line: "\efb1";
$ri-open-source-fill: "\efb2";
$ri-open-source-line: "\efb3";
$ri-opera-fill: "\efb4";
$ri-opera-line: "\efb5";
$ri-order-play-fill: "\efb6";
$ri-order-play-line: "\efb7";
$ri-organization-chart: "\efb8";
$ri-outlet-2-fill: "\efb9";
$ri-outlet-2-line: "\efba";
$ri-outlet-fill: "\efbb";
$ri-outlet-line: "\efbc";
$ri-page-separator: "\efbd";
$ri-pages-fill: "\efbe";
$ri-pages-line: "\efbf";
$ri-paint-brush-fill: "\efc0";
$ri-paint-brush-line: "\efc1";
$ri-paint-fill: "\efc2";
$ri-paint-line: "\efc3";
$ri-palette-fill: "\efc4";
$ri-palette-line: "\efc5";
$ri-pantone-fill: "\efc6";
$ri-pantone-line: "\efc7";
$ri-paragraph: "\efc8";
$ri-parent-fill: "\efc9";
$ri-parent-line: "\efca";
$ri-parentheses-fill: "\efcb";
$ri-parentheses-line: "\efcc";
$ri-parking-box-fill: "\efcd";
$ri-parking-box-line: "\efce";
$ri-parking-fill: "\efcf";
$ri-parking-line: "\efd0";
$ri-passport-fill: "\efd1";
$ri-passport-line: "\efd2";
$ri-patreon-fill: "\efd3";
$ri-patreon-line: "\efd4";
$ri-pause-circle-fill: "\efd5";
$ri-pause-circle-line: "\efd6";
$ri-pause-fill: "\efd7";
$ri-pause-line: "\efd8";
$ri-pause-mini-fill: "\efd9";
$ri-pause-mini-line: "\efda";
$ri-paypal-fill: "\efdb";
$ri-paypal-line: "\efdc";
$ri-pen-nib-fill: "\efdd";
$ri-pen-nib-line: "\efde";
$ri-pencil-fill: "\efdf";
$ri-pencil-line: "\efe0";
$ri-pencil-ruler-2-fill: "\efe1";
$ri-pencil-ruler-2-line: "\efe2";
$ri-pencil-ruler-fill: "\efe3";
$ri-pencil-ruler-line: "\efe4";
$ri-percent-fill: "\efe5";
$ri-percent-line: "\efe6";
$ri-phone-camera-fill: "\efe7";
$ri-phone-camera-line: "\efe8";
$ri-phone-fill: "\efe9";
$ri-phone-find-fill: "\efea";
$ri-phone-find-line: "\efeb";
$ri-phone-line: "\efec";
$ri-phone-lock-fill: "\efed";
$ri-phone-lock-line: "\efee";
$ri-picture-in-picture-2-fill: "\efef";
$ri-picture-in-picture-2-line: "\eff0";
$ri-picture-in-picture-exit-fill: "\eff1";
$ri-picture-in-picture-exit-line: "\eff2";
$ri-picture-in-picture-fill: "\eff3";
$ri-picture-in-picture-line: "\eff4";
$ri-pie-chart-2-fill: "\eff5";
$ri-pie-chart-2-line: "\eff6";
$ri-pie-chart-box-fill: "\eff7";
$ri-pie-chart-box-line: "\eff8";
$ri-pie-chart-fill: "\eff9";
$ri-pie-chart-line: "\effa";
$ri-pin-distance-fill: "\effb";
$ri-pin-distance-line: "\effc";
$ri-ping-pong-fill: "\effd";
$ri-ping-pong-line: "\effe";
$ri-pinterest-fill: "\efff";
$ri-pinterest-line: "\f000";
$ri-pinyin-input: "\f001";
$ri-pixelfed-fill: "\f002";
$ri-pixelfed-line: "\f003";
$ri-plane-fill: "\f004";
$ri-plane-line: "\f005";
$ri-plant-fill: "\f006";
$ri-plant-line: "\f007";
$ri-play-circle-fill: "\f008";
$ri-play-circle-line: "\f009";
$ri-play-fill: "\f00a";
$ri-play-line: "\f00b";
$ri-play-list-2-fill: "\f00c";
$ri-play-list-2-line: "\f00d";
$ri-play-list-add-fill: "\f00e";
$ri-play-list-add-line: "\f00f";
$ri-play-list-fill: "\f010";
$ri-play-list-line: "\f011";
$ri-play-mini-fill: "\f012";
$ri-play-mini-line: "\f013";
$ri-playstation-fill: "\f014";
$ri-playstation-line: "\f015";
$ri-plug-2-fill: "\f016";
$ri-plug-2-line: "\f017";
$ri-plug-fill: "\f018";
$ri-plug-line: "\f019";
$ri-polaroid-2-fill: "\f01a";
$ri-polaroid-2-line: "\f01b";
$ri-polaroid-fill: "\f01c";
$ri-polaroid-line: "\f01d";
$ri-police-car-fill: "\f01e";
$ri-police-car-line: "\f01f";
$ri-price-tag-2-fill: "\f020";
$ri-price-tag-2-line: "\f021";
$ri-price-tag-3-fill: "\f022";
$ri-price-tag-3-line: "\f023";
$ri-price-tag-fill: "\f024";
$ri-price-tag-line: "\f025";
$ri-printer-cloud-fill: "\f026";
$ri-printer-cloud-line: "\f027";
$ri-printer-fill: "\f028";
$ri-printer-line: "\f029";
$ri-product-hunt-fill: "\f02a";
$ri-product-hunt-line: "\f02b";
$ri-profile-fill: "\f02c";
$ri-profile-line: "\f02d";
$ri-projector-2-fill: "\f02e";
$ri-projector-2-line: "\f02f";
$ri-projector-fill: "\f030";
$ri-projector-line: "\f031";
$ri-psychotherapy-fill: "\f032";
$ri-psychotherapy-line: "\f033";
$ri-pulse-fill: "\f034";
$ri-pulse-line: "\f035";
$ri-pushpin-2-fill: "\f036";
$ri-pushpin-2-line: "\f037";
$ri-pushpin-fill: "\f038";
$ri-pushpin-line: "\f039";
$ri-qq-fill: "\f03a";
$ri-qq-line: "\f03b";
$ri-qr-code-fill: "\f03c";
$ri-qr-code-line: "\f03d";
$ri-qr-scan-2-fill: "\f03e";
$ri-qr-scan-2-line: "\f03f";
$ri-qr-scan-fill: "\f040";
$ri-qr-scan-line: "\f041";
$ri-question-answer-fill: "\f042";
$ri-question-answer-line: "\f043";
$ri-question-fill: "\f044";
$ri-question-line: "\f045";
$ri-question-mark: "\f046";
$ri-questionnaire-fill: "\f047";
$ri-questionnaire-line: "\f048";
$ri-quill-pen-fill: "\f049";
$ri-quill-pen-line: "\f04a";
$ri-radar-fill: "\f04b";
$ri-radar-line: "\f04c";
$ri-radio-2-fill: "\f04d";
$ri-radio-2-line: "\f04e";
$ri-radio-button-fill: "\f04f";
$ri-radio-button-line: "\f050";
$ri-radio-fill: "\f051";
$ri-radio-line: "\f052";
$ri-rainbow-fill: "\f053";
$ri-rainbow-line: "\f054";
$ri-rainy-fill: "\f055";
$ri-rainy-line: "\f056";
$ri-reactjs-fill: "\f057";
$ri-reactjs-line: "\f058";
$ri-record-circle-fill: "\f059";
$ri-record-circle-line: "\f05a";
$ri-record-mail-fill: "\f05b";
$ri-record-mail-line: "\f05c";
$ri-recycle-fill: "\f05d";
$ri-recycle-line: "\f05e";
$ri-red-packet-fill: "\f05f";
$ri-red-packet-line: "\f060";
$ri-reddit-fill: "\f061";
$ri-reddit-line: "\f062";
$ri-refresh-fill: "\f063";
$ri-refresh-line: "\f064";
$ri-refund-2-fill: "\f065";
$ri-refund-2-line: "\f066";
$ri-refund-fill: "\f067";
$ri-refund-line: "\f068";
$ri-registered-fill: "\f069";
$ri-registered-line: "\f06a";
$ri-remixicon-fill: "\f06b";
$ri-remixicon-line: "\f06c";
$ri-remote-control-2-fill: "\f06d";
$ri-remote-control-2-line: "\f06e";
$ri-remote-control-fill: "\f06f";
$ri-remote-control-line: "\f070";
$ri-repeat-2-fill: "\f071";
$ri-repeat-2-line: "\f072";
$ri-repeat-fill: "\f073";
$ri-repeat-line: "\f074";
$ri-repeat-one-fill: "\f075";
$ri-repeat-one-line: "\f076";
$ri-reply-all-fill: "\f077";
$ri-reply-all-line: "\f078";
$ri-reply-fill: "\f079";
$ri-reply-line: "\f07a";
$ri-reserved-fill: "\f07b";
$ri-reserved-line: "\f07c";
$ri-rest-time-fill: "\f07d";
$ri-rest-time-line: "\f07e";
$ri-restart-fill: "\f07f";
$ri-restart-line: "\f080";
$ri-restaurant-2-fill: "\f081";
$ri-restaurant-2-line: "\f082";
$ri-restaurant-fill: "\f083";
$ri-restaurant-line: "\f084";
$ri-rewind-fill: "\f085";
$ri-rewind-line: "\f086";
$ri-rewind-mini-fill: "\f087";
$ri-rewind-mini-line: "\f088";
$ri-rhythm-fill: "\f089";
$ri-rhythm-line: "\f08a";
$ri-riding-fill: "\f08b";
$ri-riding-line: "\f08c";
$ri-road-map-fill: "\f08d";
$ri-road-map-line: "\f08e";
$ri-roadster-fill: "\f08f";
$ri-roadster-line: "\f090";
$ri-robot-fill: "\f091";
$ri-robot-line: "\f092";
$ri-rocket-2-fill: "\f093";
$ri-rocket-2-line: "\f094";
$ri-rocket-fill: "\f095";
$ri-rocket-line: "\f096";
$ri-rotate-lock-fill: "\f097";
$ri-rotate-lock-line: "\f098";
$ri-rounded-corner: "\f099";
$ri-route-fill: "\f09a";
$ri-route-line: "\f09b";
$ri-router-fill: "\f09c";
$ri-router-line: "\f09d";
$ri-rss-fill: "\f09e";
$ri-rss-line: "\f09f";
$ri-ruler-2-fill: "\f0a0";
$ri-ruler-2-line: "\f0a1";
$ri-ruler-fill: "\f0a2";
$ri-ruler-line: "\f0a3";
$ri-run-fill: "\f0a4";
$ri-run-line: "\f0a5";
$ri-safari-fill: "\f0a6";
$ri-safari-line: "\f0a7";
$ri-safe-2-fill: "\f0a8";
$ri-safe-2-line: "\f0a9";
$ri-safe-fill: "\f0aa";
$ri-safe-line: "\f0ab";
$ri-sailboat-fill: "\f0ac";
$ri-sailboat-line: "\f0ad";
$ri-save-2-fill: "\f0ae";
$ri-save-2-line: "\f0af";
$ri-save-3-fill: "\f0b0";
$ri-save-3-line: "\f0b1";
$ri-save-fill: "\f0b2";
$ri-save-line: "\f0b3";
$ri-scales-2-fill: "\f0b4";
$ri-scales-2-line: "\f0b5";
$ri-scales-3-fill: "\f0b6";
$ri-scales-3-line: "\f0b7";
$ri-scales-fill: "\f0b8";
$ri-scales-line: "\f0b9";
$ri-scan-2-fill: "\f0ba";
$ri-scan-2-line: "\f0bb";
$ri-scan-fill: "\f0bc";
$ri-scan-line: "\f0bd";
$ri-scissors-2-fill: "\f0be";
$ri-scissors-2-line: "\f0bf";
$ri-scissors-cut-fill: "\f0c0";
$ri-scissors-cut-line: "\f0c1";
$ri-scissors-fill: "\f0c2";
$ri-scissors-line: "\f0c3";
$ri-screenshot-2-fill: "\f0c4";
$ri-screenshot-2-line: "\f0c5";
$ri-screenshot-fill: "\f0c6";
$ri-screenshot-line: "\f0c7";
$ri-sd-card-fill: "\f0c8";
$ri-sd-card-line: "\f0c9";
$ri-sd-card-mini-fill: "\f0ca";
$ri-sd-card-mini-line: "\f0cb";
$ri-search-2-fill: "\f0cc";
$ri-search-2-line: "\f0cd";
$ri-search-eye-fill: "\f0ce";
$ri-search-eye-line: "\f0cf";
$ri-search-fill: "\f0d0";
$ri-search-line: "\f0d1";
$ri-secure-payment-fill: "\f0d2";
$ri-secure-payment-line: "\f0d3";
$ri-seedling-fill: "\f0d4";
$ri-seedling-line: "\f0d5";
$ri-send-backward: "\f0d6";
$ri-send-plane-2-fill: "\f0d7";
$ri-send-plane-2-line: "\f0d8";
$ri-send-plane-fill: "\f0d9";
$ri-send-plane-line: "\f0da";
$ri-send-to-back: "\f0db";
$ri-sensor-fill: "\f0dc";
$ri-sensor-line: "\f0dd";
$ri-separator: "\f0de";
$ri-server-fill: "\f0df";
$ri-server-line: "\f0e0";
$ri-service-fill: "\f0e1";
$ri-service-line: "\f0e2";
$ri-settings-2-fill: "\f0e3";
$ri-settings-2-line: "\f0e4";
$ri-settings-3-fill: "\f0e5";
$ri-settings-3-line: "\f0e6";
$ri-settings-4-fill: "\f0e7";
$ri-settings-4-line: "\f0e8";
$ri-settings-5-fill: "\f0e9";
$ri-settings-5-line: "\f0ea";
$ri-settings-6-fill: "\f0eb";
$ri-settings-6-line: "\f0ec";
$ri-settings-fill: "\f0ed";
$ri-settings-line: "\f0ee";
$ri-shape-2-fill: "\f0ef";
$ri-shape-2-line: "\f0f0";
$ri-shape-fill: "\f0f1";
$ri-shape-line: "\f0f2";
$ri-share-box-fill: "\f0f3";
$ri-share-box-line: "\f0f4";
$ri-share-circle-fill: "\f0f5";
$ri-share-circle-line: "\f0f6";
$ri-share-fill: "\f0f7";
$ri-share-forward-2-fill: "\f0f8";
$ri-share-forward-2-line: "\f0f9";
$ri-share-forward-box-fill: "\f0fa";
$ri-share-forward-box-line: "\f0fb";
$ri-share-forward-fill: "\f0fc";
$ri-share-forward-line: "\f0fd";
$ri-share-line: "\f0fe";
$ri-shield-check-fill: "\f0ff";
$ri-shield-check-line: "\f100";
$ri-shield-cross-fill: "\f101";
$ri-shield-cross-line: "\f102";
$ri-shield-fill: "\f103";
$ri-shield-flash-fill: "\f104";
$ri-shield-flash-line: "\f105";
$ri-shield-keyhole-fill: "\f106";
$ri-shield-keyhole-line: "\f107";
$ri-shield-line: "\f108";
$ri-shield-star-fill: "\f109";
$ri-shield-star-line: "\f10a";
$ri-shield-user-fill: "\f10b";
$ri-shield-user-line: "\f10c";
$ri-ship-2-fill: "\f10d";
$ri-ship-2-line: "\f10e";
$ri-ship-fill: "\f10f";
$ri-ship-line: "\f110";
$ri-shirt-fill: "\f111";
$ri-shirt-line: "\f112";
$ri-shopping-bag-2-fill: "\f113";
$ri-shopping-bag-2-line: "\f114";
$ri-shopping-bag-3-fill: "\f115";
$ri-shopping-bag-3-line: "\f116";
$ri-shopping-bag-fill: "\f117";
$ri-shopping-bag-line: "\f118";
$ri-shopping-basket-2-fill: "\f119";
$ri-shopping-basket-2-line: "\f11a";
$ri-shopping-basket-fill: "\f11b";
$ri-shopping-basket-line: "\f11c";
$ri-shopping-cart-2-fill: "\f11d";
$ri-shopping-cart-2-line: "\f11e";
$ri-shopping-cart-fill: "\f11f";
$ri-shopping-cart-line: "\f120";
$ri-showers-fill: "\f121";
$ri-showers-line: "\f122";
$ri-shuffle-fill: "\f123";
$ri-shuffle-line: "\f124";
$ri-shut-down-fill: "\f125";
$ri-shut-down-line: "\f126";
$ri-side-bar-fill: "\f127";
$ri-side-bar-line: "\f128";
$ri-signal-tower-fill: "\f129";
$ri-signal-tower-line: "\f12a";
$ri-signal-wifi-1-fill: "\f12b";
$ri-signal-wifi-1-line: "\f12c";
$ri-signal-wifi-2-fill: "\f12d";
$ri-signal-wifi-2-line: "\f12e";
$ri-signal-wifi-3-fill: "\f12f";
$ri-signal-wifi-3-line: "\f130";
$ri-signal-wifi-error-fill: "\f131";
$ri-signal-wifi-error-line: "\f132";
$ri-signal-wifi-fill: "\f133";
$ri-signal-wifi-line: "\f134";
$ri-signal-wifi-off-fill: "\f135";
$ri-signal-wifi-off-line: "\f136";
$ri-sim-card-2-fill: "\f137";
$ri-sim-card-2-line: "\f138";
$ri-sim-card-fill: "\f139";
$ri-sim-card-line: "\f13a";
$ri-single-quotes-l: "\f13b";
$ri-single-quotes-r: "\f13c";
$ri-sip-fill: "\f13d";
$ri-sip-line: "\f13e";
$ri-skip-back-fill: "\f13f";
$ri-skip-back-line: "\f140";
$ri-skip-back-mini-fill: "\f141";
$ri-skip-back-mini-line: "\f142";
$ri-skip-forward-fill: "\f143";
$ri-skip-forward-line: "\f144";
$ri-skip-forward-mini-fill: "\f145";
$ri-skip-forward-mini-line: "\f146";
$ri-skull-2-fill: "\f147";
$ri-skull-2-line: "\f148";
$ri-skull-fill: "\f149";
$ri-skull-line: "\f14a";
$ri-skype-fill: "\f14b";
$ri-skype-line: "\f14c";
$ri-slack-fill: "\f14d";
$ri-slack-line: "\f14e";
$ri-slice-fill: "\f14f";
$ri-slice-line: "\f150";
$ri-slideshow-2-fill: "\f151";
$ri-slideshow-2-line: "\f152";
$ri-slideshow-3-fill: "\f153";
$ri-slideshow-3-line: "\f154";
$ri-slideshow-4-fill: "\f155";
$ri-slideshow-4-line: "\f156";
$ri-slideshow-fill: "\f157";
$ri-slideshow-line: "\f158";
$ri-smartphone-fill: "\f159";
$ri-smartphone-line: "\f15a";
$ri-snapchat-fill: "\f15b";
$ri-snapchat-line: "\f15c";
$ri-snowy-fill: "\f15d";
$ri-snowy-line: "\f15e";
$ri-sort-asc: "\f15f";
$ri-sort-desc: "\f160";
$ri-sound-module-fill: "\f161";
$ri-sound-module-line: "\f162";
$ri-soundcloud-fill: "\f163";
$ri-soundcloud-line: "\f164";
$ri-space-ship-fill: "\f165";
$ri-space-ship-line: "\f166";
$ri-space: "\f167";
$ri-spam-2-fill: "\f168";
$ri-spam-2-line: "\f169";
$ri-spam-3-fill: "\f16a";
$ri-spam-3-line: "\f16b";
$ri-spam-fill: "\f16c";
$ri-spam-line: "\f16d";
$ri-speaker-2-fill: "\f16e";
$ri-speaker-2-line: "\f16f";
$ri-speaker-3-fill: "\f170";
$ri-speaker-3-line: "\f171";
$ri-speaker-fill: "\f172";
$ri-speaker-line: "\f173";
$ri-spectrum-fill: "\f174";
$ri-spectrum-line: "\f175";
$ri-speed-fill: "\f176";
$ri-speed-line: "\f177";
$ri-speed-mini-fill: "\f178";
$ri-speed-mini-line: "\f179";
$ri-split-cells-horizontal: "\f17a";
$ri-split-cells-vertical: "\f17b";
$ri-spotify-fill: "\f17c";
$ri-spotify-line: "\f17d";
$ri-spy-fill: "\f17e";
$ri-spy-line: "\f17f";
$ri-stack-fill: "\f180";
$ri-stack-line: "\f181";
$ri-stack-overflow-fill: "\f182";
$ri-stack-overflow-line: "\f183";
$ri-stackshare-fill: "\f184";
$ri-stackshare-line: "\f185";
$ri-star-fill: "\f186";
$ri-star-half-fill: "\f187";
$ri-star-half-line: "\f188";
$ri-star-half-s-fill: "\f189";
$ri-star-half-s-line: "\f18a";
$ri-star-line: "\f18b";
$ri-star-s-fill: "\f18c";
$ri-star-s-line: "\f18d";
$ri-star-smile-fill: "\f18e";
$ri-star-smile-line: "\f18f";
$ri-steam-fill: "\f190";
$ri-steam-line: "\f191";
$ri-steering-2-fill: "\f192";
$ri-steering-2-line: "\f193";
$ri-steering-fill: "\f194";
$ri-steering-line: "\f195";
$ri-stethoscope-fill: "\f196";
$ri-stethoscope-line: "\f197";
$ri-sticky-note-2-fill: "\f198";
$ri-sticky-note-2-line: "\f199";
$ri-sticky-note-fill: "\f19a";
$ri-sticky-note-line: "\f19b";
$ri-stock-fill: "\f19c";
$ri-stock-line: "\f19d";
$ri-stop-circle-fill: "\f19e";
$ri-stop-circle-line: "\f19f";
$ri-stop-fill: "\f1a0";
$ri-stop-line: "\f1a1";
$ri-stop-mini-fill: "\f1a2";
$ri-stop-mini-line: "\f1a3";
$ri-store-2-fill: "\f1a4";
$ri-store-2-line: "\f1a5";
$ri-store-3-fill: "\f1a6";
$ri-store-3-line: "\f1a7";
$ri-store-fill: "\f1a8";
$ri-store-line: "\f1a9";
$ri-strikethrough-2: "\f1aa";
$ri-strikethrough: "\f1ab";
$ri-subscript-2: "\f1ac";
$ri-subscript: "\f1ad";
$ri-subtract-fill: "\f1ae";
$ri-subtract-line: "\f1af";
$ri-subway-fill: "\f1b0";
$ri-subway-line: "\f1b1";
$ri-subway-wifi-fill: "\f1b2";
$ri-subway-wifi-line: "\f1b3";
$ri-suitcase-2-fill: "\f1b4";
$ri-suitcase-2-line: "\f1b5";
$ri-suitcase-3-fill: "\f1b6";
$ri-suitcase-3-line: "\f1b7";
$ri-suitcase-fill: "\f1b8";
$ri-suitcase-line: "\f1b9";
$ri-sun-cloudy-fill: "\f1ba";
$ri-sun-cloudy-line: "\f1bb";
$ri-sun-fill: "\f1bc";
$ri-sun-foggy-fill: "\f1bd";
$ri-sun-foggy-line: "\f1be";
$ri-sun-line: "\f1bf";
$ri-superscript-2: "\f1c0";
$ri-superscript: "\f1c1";
$ri-surgical-mask-fill: "\f1c2";
$ri-surgical-mask-line: "\f1c3";
$ri-surround-sound-fill: "\f1c4";
$ri-surround-sound-line: "\f1c5";
$ri-survey-fill: "\f1c6";
$ri-survey-line: "\f1c7";
$ri-swap-box-fill: "\f1c8";
$ri-swap-box-line: "\f1c9";
$ri-swap-fill: "\f1ca";
$ri-swap-line: "\f1cb";
$ri-switch-fill: "\f1cc";
$ri-switch-line: "\f1cd";
$ri-sword-fill: "\f1ce";
$ri-sword-line: "\f1cf";
$ri-syringe-fill: "\f1d0";
$ri-syringe-line: "\f1d1";
$ri-t-box-fill: "\f1d2";
$ri-t-box-line: "\f1d3";
$ri-t-shirt-2-fill: "\f1d4";
$ri-t-shirt-2-line: "\f1d5";
$ri-t-shirt-air-fill: "\f1d6";
$ri-t-shirt-air-line: "\f1d7";
$ri-t-shirt-fill: "\f1d8";
$ri-t-shirt-line: "\f1d9";
$ri-table-2: "\f1da";
$ri-table-alt-fill: "\f1db";
$ri-table-alt-line: "\f1dc";
$ri-table-fill: "\f1dd";
$ri-table-line: "\f1de";
$ri-tablet-fill: "\f1df";
$ri-tablet-line: "\f1e0";
$ri-takeaway-fill: "\f1e1";
$ri-takeaway-line: "\f1e2";
$ri-taobao-fill: "\f1e3";
$ri-taobao-line: "\f1e4";
$ri-tape-fill: "\f1e5";
$ri-tape-line: "\f1e6";
$ri-task-fill: "\f1e7";
$ri-task-line: "\f1e8";
$ri-taxi-fill: "\f1e9";
$ri-taxi-line: "\f1ea";
$ri-taxi-wifi-fill: "\f1eb";
$ri-taxi-wifi-line: "\f1ec";
$ri-team-fill: "\f1ed";
$ri-team-line: "\f1ee";
$ri-telegram-fill: "\f1ef";
$ri-telegram-line: "\f1f0";
$ri-temp-cold-fill: "\f1f1";
$ri-temp-cold-line: "\f1f2";
$ri-temp-hot-fill: "\f1f3";
$ri-temp-hot-line: "\f1f4";
$ri-terminal-box-fill: "\f1f5";
$ri-terminal-box-line: "\f1f6";
$ri-terminal-fill: "\f1f7";
$ri-terminal-line: "\f1f8";
$ri-terminal-window-fill: "\f1f9";
$ri-terminal-window-line: "\f1fa";
$ri-test-tube-fill: "\f1fb";
$ri-test-tube-line: "\f1fc";
$ri-text-direction-l: "\f1fd";
$ri-text-direction-r: "\f1fe";
$ri-text-spacing: "\f1ff";
$ri-text-wrap: "\f200";
$ri-text: "\f201";
$ri-thermometer-fill: "\f202";
$ri-thermometer-line: "\f203";
$ri-thumb-down-fill: "\f204";
$ri-thumb-down-line: "\f205";
$ri-thumb-up-fill: "\f206";
$ri-thumb-up-line: "\f207";
$ri-thunderstorms-fill: "\f208";
$ri-thunderstorms-line: "\f209";
$ri-ticket-2-fill: "\f20a";
$ri-ticket-2-line: "\f20b";
$ri-ticket-fill: "\f20c";
$ri-ticket-line: "\f20d";
$ri-time-fill: "\f20e";
$ri-time-line: "\f20f";
$ri-timer-2-fill: "\f210";
$ri-timer-2-line: "\f211";
$ri-timer-fill: "\f212";
$ri-timer-flash-fill: "\f213";
$ri-timer-flash-line: "\f214";
$ri-timer-line: "\f215";
$ri-todo-fill: "\f216";
$ri-todo-line: "\f217";
$ri-toggle-fill: "\f218";
$ri-toggle-line: "\f219";
$ri-tools-fill: "\f21a";
$ri-tools-line: "\f21b";
$ri-tornado-fill: "\f21c";
$ri-tornado-line: "\f21d";
$ri-trademark-fill: "\f21e";
$ri-trademark-line: "\f21f";
$ri-traffic-light-fill: "\f220";
$ri-traffic-light-line: "\f221";
$ri-train-fill: "\f222";
$ri-train-line: "\f223";
$ri-train-wifi-fill: "\f224";
$ri-train-wifi-line: "\f225";
$ri-translate-2: "\f226";
$ri-translate: "\f227";
$ri-travesti-fill: "\f228";
$ri-travesti-line: "\f229";
$ri-treasure-map-fill: "\f22a";
$ri-treasure-map-line: "\f22b";
$ri-trello-fill: "\f22c";
$ri-trello-line: "\f22d";
$ri-trophy-fill: "\f22e";
$ri-trophy-line: "\f22f";
$ri-truck-fill: "\f230";
$ri-truck-line: "\f231";
$ri-tumblr-fill: "\f232";
$ri-tumblr-line: "\f233";
$ri-tv-2-fill: "\f234";
$ri-tv-2-line: "\f235";
$ri-tv-fill: "\f236";
$ri-tv-line: "\f237";
$ri-twitch-fill: "\f238";
$ri-twitch-line: "\f239";
$ri-twitter-fill: "\f23a";
$ri-twitter-line: "\f23b";
$ri-typhoon-fill: "\f23c";
$ri-typhoon-line: "\f23d";
$ri-u-disk-fill: "\f23e";
$ri-u-disk-line: "\f23f";
$ri-ubuntu-fill: "\f240";
$ri-ubuntu-line: "\f241";
$ri-umbrella-fill: "\f242";
$ri-umbrella-line: "\f243";
$ri-underline: "\f244";
$ri-uninstall-fill: "\f245";
$ri-uninstall-line: "\f246";
$ri-unsplash-fill: "\f247";
$ri-unsplash-line: "\f248";
$ri-upload-2-fill: "\f249";
$ri-upload-2-line: "\f24a";
$ri-upload-cloud-2-fill: "\f24b";
$ri-upload-cloud-2-line: "\f24c";
$ri-upload-cloud-fill: "\f24d";
$ri-upload-cloud-line: "\f24e";
$ri-upload-fill: "\f24f";
$ri-upload-line: "\f250";
$ri-usb-fill: "\f251";
$ri-usb-line: "\f252";
$ri-user-2-fill: "\f253";
$ri-user-2-line: "\f254";
$ri-user-3-fill: "\f255";
$ri-user-3-line: "\f256";
$ri-user-4-fill: "\f257";
$ri-user-4-line: "\f258";
$ri-user-5-fill: "\f259";
$ri-user-5-line: "\f25a";
$ri-user-6-fill: "\f25b";
$ri-user-6-line: "\f25c";
$ri-user-add-fill: "\f25d";
$ri-user-add-line: "\f25e";
$ri-user-fill: "\f25f";
$ri-user-follow-fill: "\f260";
$ri-user-follow-line: "\f261";
$ri-user-heart-fill: "\f262";
$ri-user-heart-line: "\f263";
$ri-user-line: "\f264";
$ri-user-location-fill: "\f265";
$ri-user-location-line: "\f266";
$ri-user-received-2-fill: "\f267";
$ri-user-received-2-line: "\f268";
$ri-user-received-fill: "\f269";
$ri-user-received-line: "\f26a";
$ri-user-search-fill: "\f26b";
$ri-user-search-line: "\f26c";
$ri-user-settings-fill: "\f26d";
$ri-user-settings-line: "\f26e";
$ri-user-shared-2-fill: "\f26f";
$ri-user-shared-2-line: "\f270";
$ri-user-shared-fill: "\f271";
$ri-user-shared-line: "\f272";
$ri-user-smile-fill: "\f273";
$ri-user-smile-line: "\f274";
$ri-user-star-fill: "\f275";
$ri-user-star-line: "\f276";
$ri-user-unfollow-fill: "\f277";
$ri-user-unfollow-line: "\f278";
$ri-user-voice-fill: "\f279";
$ri-user-voice-line: "\f27a";
$ri-video-add-fill: "\f27b";
$ri-video-add-line: "\f27c";
$ri-video-chat-fill: "\f27d";
$ri-video-chat-line: "\f27e";
$ri-video-download-fill: "\f27f";
$ri-video-download-line: "\f280";
$ri-video-fill: "\f281";
$ri-video-line: "\f282";
$ri-video-upload-fill: "\f283";
$ri-video-upload-line: "\f284";
$ri-vidicon-2-fill: "\f285";
$ri-vidicon-2-line: "\f286";
$ri-vidicon-fill: "\f287";
$ri-vidicon-line: "\f288";
$ri-vimeo-fill: "\f289";
$ri-vimeo-line: "\f28a";
$ri-vip-crown-2-fill: "\f28b";
$ri-vip-crown-2-line: "\f28c";
$ri-vip-crown-fill: "\f28d";
$ri-vip-crown-line: "\f28e";
$ri-vip-diamond-fill: "\f28f";
$ri-vip-diamond-line: "\f290";
$ri-vip-fill: "\f291";
$ri-vip-line: "\f292";
$ri-virus-fill: "\f293";
$ri-virus-line: "\f294";
$ri-visa-fill: "\f295";
$ri-visa-line: "\f296";
$ri-voice-recognition-fill: "\f297";
$ri-voice-recognition-line: "\f298";
$ri-voiceprint-fill: "\f299";
$ri-voiceprint-line: "\f29a";
$ri-volume-down-fill: "\f29b";
$ri-volume-down-line: "\f29c";
$ri-volume-mute-fill: "\f29d";
$ri-volume-mute-line: "\f29e";
$ri-volume-off-vibrate-fill: "\f29f";
$ri-volume-off-vibrate-line: "\f2a0";
$ri-volume-up-fill: "\f2a1";
$ri-volume-up-line: "\f2a2";
$ri-volume-vibrate-fill: "\f2a3";
$ri-volume-vibrate-line: "\f2a4";
$ri-vuejs-fill: "\f2a5";
$ri-vuejs-line: "\f2a6";
$ri-walk-fill: "\f2a7";
$ri-walk-line: "\f2a8";
$ri-wallet-2-fill: "\f2a9";
$ri-wallet-2-line: "\f2aa";
$ri-wallet-3-fill: "\f2ab";
$ri-wallet-3-line: "\f2ac";
$ri-wallet-fill: "\f2ad";
$ri-wallet-line: "\f2ae";
$ri-water-flash-fill: "\f2af";
$ri-water-flash-line: "\f2b0";
$ri-webcam-fill: "\f2b1";
$ri-webcam-line: "\f2b2";
$ri-wechat-2-fill: "\f2b3";
$ri-wechat-2-line: "\f2b4";
$ri-wechat-fill: "\f2b5";
$ri-wechat-line: "\f2b6";
$ri-wechat-pay-fill: "\f2b7";
$ri-wechat-pay-line: "\f2b8";
$ri-weibo-fill: "\f2b9";
$ri-weibo-line: "\f2ba";
$ri-whatsapp-fill: "\f2bb";
$ri-whatsapp-line: "\f2bc";
$ri-wheelchair-fill: "\f2bd";
$ri-wheelchair-line: "\f2be";
$ri-wifi-fill: "\f2bf";
$ri-wifi-line: "\f2c0";
$ri-wifi-off-fill: "\f2c1";
$ri-wifi-off-line: "\f2c2";
$ri-window-2-fill: "\f2c3";
$ri-window-2-line: "\f2c4";
$ri-window-fill: "\f2c5";
$ri-window-line: "\f2c6";
$ri-windows-fill: "\f2c7";
$ri-windows-line: "\f2c8";
$ri-windy-fill: "\f2c9";
$ri-windy-line: "\f2ca";
$ri-wireless-charging-fill: "\f2cb";
$ri-wireless-charging-line: "\f2cc";
$ri-women-fill: "\f2cd";
$ri-women-line: "\f2ce";
$ri-wubi-input: "\f2cf";
$ri-xbox-fill: "\f2d0";
$ri-xbox-line: "\f2d1";
$ri-xing-fill: "\f2d2";
$ri-xing-line: "\f2d3";
$ri-youtube-fill: "\f2d4";
$ri-youtube-line: "\f2d5";
$ri-zcool-fill: "\f2d6";
$ri-zcool-line: "\f2d7";
$ri-zhihu-fill: "\f2d8";
$ri-zhihu-line: "\f2d9";
$ri-zoom-in-fill: "\f2da";
$ri-zoom-in-line: "\f2db";
$ri-zoom-out-fill: "\f2dc";
$ri-zoom-out-line: "\f2dd";
$ri-zzz-fill: "\f2de";
$ri-zzz-line: "\f2df";
$ri-arrow-down-double-fill: "\f2e0";
$ri-arrow-down-double-line: "\f2e1";
$ri-arrow-left-double-fill: "\f2e2";
$ri-arrow-left-double-line: "\f2e3";
$ri-arrow-right-double-fill: "\f2e4";
$ri-arrow-right-double-line: "\f2e5";
$ri-arrow-turn-back-fill: "\f2e6";
$ri-arrow-turn-back-line: "\f2e7";
$ri-arrow-turn-forward-fill: "\f2e8";
$ri-arrow-turn-forward-line: "\f2e9";
$ri-arrow-up-double-fill: "\f2ea";
$ri-arrow-up-double-line: "\f2eb";
$ri-bard-fill: "\f2ec";
$ri-bard-line: "\f2ed";
$ri-bootstrap-fill: "\f2ee";
$ri-bootstrap-line: "\f2ef";
$ri-box-1-fill: "\f2f0";
$ri-box-1-line: "\f2f1";
$ri-box-2-fill: "\f2f2";
$ri-box-2-line: "\f2f3";
$ri-box-3-fill: "\f2f4";
$ri-box-3-line: "\f2f5";
$ri-brain-fill: "\f2f6";
$ri-brain-line: "\f2f7";
$ri-candle-fill: "\f2f8";
$ri-candle-line: "\f2f9";
$ri-cash-fill: "\f2fa";
$ri-cash-line: "\f2fb";
$ri-contract-left-fill: "\f2fc";
$ri-contract-left-line: "\f2fd";
$ri-contract-left-right-fill: "\f2fe";
$ri-contract-left-right-line: "\f2ff";
$ri-contract-right-fill: "\f300";
$ri-contract-right-line: "\f301";
$ri-contract-up-down-fill: "\f302";
$ri-contract-up-down-line: "\f303";
$ri-copilot-fill: "\f304";
$ri-copilot-line: "\f305";
$ri-corner-down-left-fill: "\f306";
$ri-corner-down-left-line: "\f307";
$ri-corner-down-right-fill: "\f308";
$ri-corner-down-right-line: "\f309";
$ri-corner-left-down-fill: "\f30a";
$ri-corner-left-down-line: "\f30b";
$ri-corner-left-up-fill: "\f30c";
$ri-corner-left-up-line: "\f30d";
$ri-corner-right-down-fill: "\f30e";
$ri-corner-right-down-line: "\f30f";
$ri-corner-right-up-fill: "\f310";
$ri-corner-right-up-line: "\f311";
$ri-corner-up-left-double-fill: "\f312";
$ri-corner-up-left-double-line: "\f313";
$ri-corner-up-left-fill: "\f314";
$ri-corner-up-left-line: "\f315";
$ri-corner-up-right-double-fill: "\f316";
$ri-corner-up-right-double-line: "\f317";
$ri-corner-up-right-fill: "\f318";
$ri-corner-up-right-line: "\f319";
$ri-cross-fill: "\f31a";
$ri-cross-line: "\f31b";
$ri-edge-new-fill: "\f31c";
$ri-edge-new-line: "\f31d";
$ri-equal-fill: "\f31e";
$ri-equal-line: "\f31f";
$ri-expand-left-fill: "\f320";
$ri-expand-left-line: "\f321";
$ri-expand-left-right-fill: "\f322";
$ri-expand-left-right-line: "\f323";
$ri-expand-right-fill: "\f324";
$ri-expand-right-line: "\f325";
$ri-expand-up-down-fill: "\f326";
$ri-expand-up-down-line: "\f327";
$ri-flickr-fill: "\f328";
$ri-flickr-line: "\f329";
$ri-forward-10-fill: "\f32a";
$ri-forward-10-line: "\f32b";
$ri-forward-15-fill: "\f32c";
$ri-forward-15-line: "\f32d";
$ri-forward-30-fill: "\f32e";
$ri-forward-30-line: "\f32f";
$ri-forward-5-fill: "\f330";
$ri-forward-5-line: "\f331";
$ri-graduation-cap-fill: "\f332";
$ri-graduation-cap-line: "\f333";
$ri-home-office-fill: "\f334";
$ri-home-office-line: "\f335";
$ri-hourglass-2-fill: "\f336";
$ri-hourglass-2-line: "\f337";
$ri-hourglass-fill: "\f338";
$ri-hourglass-line: "\f339";
$ri-javascript-fill: "\f33a";
$ri-javascript-line: "\f33b";
$ri-loop-left-fill: "\f33c";
$ri-loop-left-line: "\f33d";
$ri-loop-right-fill: "\f33e";
$ri-loop-right-line: "\f33f";
$ri-memories-fill: "\f340";
$ri-memories-line: "\f341";
$ri-meta-fill: "\f342";
$ri-meta-line: "\f343";
$ri-microsoft-loop-fill: "\f344";
$ri-microsoft-loop-line: "\f345";
$ri-nft-fill: "\f346";
$ri-nft-line: "\f347";
$ri-notion-fill: "\f348";
$ri-notion-line: "\f349";
$ri-openai-fill: "\f34a";
$ri-openai-line: "\f34b";
$ri-overline: "\f34c";
$ri-p2p-fill: "\f34d";
$ri-p2p-line: "\f34e";
$ri-presentation-fill: "\f34f";
$ri-presentation-line: "\f350";
$ri-replay-10-fill: "\f351";
$ri-replay-10-line: "\f352";
$ri-replay-15-fill: "\f353";
$ri-replay-15-line: "\f354";
$ri-replay-30-fill: "\f355";
$ri-replay-30-line: "\f356";
$ri-replay-5-fill: "\f357";
$ri-replay-5-line: "\f358";
$ri-school-fill: "\f359";
$ri-school-line: "\f35a";
$ri-shining-2-fill: "\f35b";
$ri-shining-2-line: "\f35c";
$ri-shining-fill: "\f35d";
$ri-shining-line: "\f35e";
$ri-sketching: "\f35f";
$ri-skip-down-fill: "\f360";
$ri-skip-down-line: "\f361";
$ri-skip-left-fill: "\f362";
$ri-skip-left-line: "\f363";
$ri-skip-right-fill: "\f364";
$ri-skip-right-line: "\f365";
$ri-skip-up-fill: "\f366";
$ri-skip-up-line: "\f367";
$ri-slow-down-fill: "\f368";
$ri-slow-down-line: "\f369";
$ri-sparkling-2-fill: "\f36a";
$ri-sparkling-2-line: "\f36b";
$ri-sparkling-fill: "\f36c";
$ri-sparkling-line: "\f36d";
$ri-speak-fill: "\f36e";
$ri-speak-line: "\f36f";
$ri-speed-up-fill: "\f370";
$ri-speed-up-line: "\f371";
$ri-tiktok-fill: "\f372";
$ri-tiktok-line: "\f373";
$ri-token-swap-fill: "\f374";
$ri-token-swap-line: "\f375";
$ri-unpin-fill: "\f376";
$ri-unpin-line: "\f377";
$ri-wechat-channels-fill: "\f378";
$ri-wechat-channels-line: "\f379";
$ri-wordpress-fill: "\f37a";
$ri-wordpress-line: "\f37b";
$ri-blender-fill: "\f37c";
$ri-blender-line: "\f37d";
$ri-emoji-sticker-fill: "\f37e";
$ri-emoji-sticker-line: "\f37f";
$ri-git-close-pull-request-fill: "\f380";
$ri-git-close-pull-request-line: "\f381";
$ri-instance-fill: "\f382";
$ri-instance-line: "\f383";
$ri-megaphone-fill: "\f384";
$ri-megaphone-line: "\f385";
$ri-pass-expired-fill: "\f386";
$ri-pass-expired-line: "\f387";
$ri-pass-pending-fill: "\f388";
$ri-pass-pending-line: "\f389";
$ri-pass-valid-fill: "\f38a";
$ri-pass-valid-line: "\f38b";
$ri-ai-generate: "\f38c";
$ri-calendar-close-fill: "\f38d";
$ri-calendar-close-line: "\f38e";
$ri-draggable: "\f38f";
$ri-font-family: "\f390";
$ri-font-mono: "\f391";
$ri-font-sans-serif: "\f392";
$ri-font-sans: "\f393";
$ri-hard-drive-3-fill: "\f394";
$ri-hard-drive-3-line: "\f395";
$ri-kick-fill: "\f396";
$ri-kick-line: "\f397";
$ri-list-check-3: "\f398";
$ri-list-indefinite: "\f399";
$ri-list-ordered-2: "\f39a";
$ri-list-radio: "\f39b";
$ri-openbase-fill: "\f39c";
$ri-openbase-line: "\f39d";
$ri-planet-fill: "\f39e";
$ri-planet-line: "\f39f";
$ri-prohibited-fill: "\f3a0";
$ri-prohibited-line: "\f3a1";
$ri-quote-text: "\f3a2";
$ri-seo-fill: "\f3a3";
$ri-seo-line: "\f3a4";
$ri-slash-commands: "\f3a5";
$ri-archive-2-fill: "\f3a6";
$ri-archive-2-line: "\f3a7";
$ri-inbox-2-fill: "\f3a8";
$ri-inbox-2-line: "\f3a9";
$ri-shake-hands-fill: "\f3aa";
$ri-shake-hands-line: "\f3ab";
$ri-supabase-fill: "\f3ac";
$ri-supabase-line: "\f3ad";
$ri-water-percent-fill: "\f3ae";
$ri-water-percent-line: "\f3af";
$ri-yuque-fill: "\f3b0";
$ri-yuque-line: "\f3b1";
$ri-crosshair-2-fill: "\f3b2";
$ri-crosshair-2-line: "\f3b3";
$ri-crosshair-fill: "\f3b4";
$ri-crosshair-line: "\f3b5";
$ri-file-close-fill: "\f3b6";
$ri-file-close-line: "\f3b7";
$ri-infinity-fill: "\f3b8";
$ri-infinity-line: "\f3b9";
$ri-rfid-fill: "\f3ba";
$ri-rfid-line: "\f3bb";
$ri-slash-commands-2: "\f3bc";
$ri-user-forbid-fill: "\f3bd";
$ri-user-forbid-line: "\f3be";
$ri-beer-fill: "\f3bf";
$ri-beer-line: "\f3c0";
$ri-circle-fill: "\f3c1";
$ri-circle-line: "\f3c2";
$ri-dropdown-list: "\f3c3";
$ri-file-image-fill: "\f3c4";
$ri-file-image-line: "\f3c5";
$ri-file-pdf-2-fill: "\f3c6";
$ri-file-pdf-2-line: "\f3c7";
$ri-file-video-fill: "\f3c8";
$ri-file-video-line: "\f3c9";
$ri-folder-image-fill: "\f3ca";
$ri-folder-image-line: "\f3cb";
$ri-folder-video-fill: "\f3cc";
$ri-folder-video-line: "\f3cd";
$ri-hexagon-fill: "\f3ce";
$ri-hexagon-line: "\f3cf";
$ri-menu-search-fill: "\f3d0";
$ri-menu-search-line: "\f3d1";
$ri-octagon-fill: "\f3d2";
$ri-octagon-line: "\f3d3";
$ri-pentagon-fill: "\f3d4";
$ri-pentagon-line: "\f3d5";
$ri-rectangle-fill: "\f3d6";
$ri-rectangle-line: "\f3d7";
$ri-robot-2-fill: "\f3d8";
$ri-robot-2-line: "\f3d9";
$ri-shapes-fill: "\f3da";
$ri-shapes-line: "\f3db";
$ri-square-fill: "\f3dc";
$ri-square-line: "\f3dd";
$ri-tent-fill: "\f3de";
$ri-tent-line: "\f3df";
$ri-threads-fill: "\f3e0";
$ri-threads-line: "\f3e1";
$ri-tree-fill: "\f3e2";
$ri-tree-line: "\f3e3";
$ri-triangle-fill: "\f3e4";
$ri-triangle-line: "\f3e5";
$ri-twitter-x-fill: "\f3e6";
$ri-twitter-x-line: "\f3e7";
$ri-verified-badge-fill: "\f3e8";
$ri-verified-badge-line: "\f3e9";
$ri-armchair-fill: "\f3ea";
$ri-armchair-line: "\f3eb";
$ri-bnb-fill: "\f3ec";
$ri-bnb-line: "\f3ed";
$ri-bread-fill: "\f3ee";
$ri-bread-line: "\f3ef";
$ri-btc-fill: "\f3f0";
$ri-btc-line: "\f3f1";
$ri-calendar-schedule-fill: "\f3f2";
$ri-calendar-schedule-line: "\f3f3";
$ri-dice-1-fill: "\f3f4";
$ri-dice-1-line: "\f3f5";
$ri-dice-2-fill: "\f3f6";
$ri-dice-2-line: "\f3f7";
$ri-dice-3-fill: "\f3f8";
$ri-dice-3-line: "\f3f9";
$ri-dice-4-fill: "\f3fa";
$ri-dice-4-line: "\f3fb";
$ri-dice-5-fill: "\f3fc";
$ri-dice-5-line: "\f3fd";
$ri-dice-6-fill: "\f3fe";
$ri-dice-6-line: "\f3ff";
$ri-dice-fill: "\f400";
$ri-dice-line: "\f401";
$ri-drinks-fill: "\f402";
$ri-drinks-line: "\f403";
$ri-equalizer-2-fill: "\f404";
$ri-equalizer-2-line: "\f405";
$ri-equalizer-3-fill: "\f406";
$ri-equalizer-3-line: "\f407";
$ri-eth-fill: "\f408";
$ri-eth-line: "\f409";
$ri-flower-fill: "\f40a";
$ri-flower-line: "\f40b";
$ri-glasses-2-fill: "\f40c";
$ri-glasses-2-line: "\f40d";
$ri-glasses-fill: "\f40e";
$ri-glasses-line: "\f40f";
$ri-goggles-fill: "\f410";
$ri-goggles-line: "\f411";
$ri-image-circle-fill: "\f412";
$ri-image-circle-line: "\f413";
$ri-info-i: "\f414";
$ri-money-rupee-circle-fill: "\f415";
$ri-money-rupee-circle-line: "\f416";
$ri-news-fill: "\f417";
$ri-news-line: "\f418";
$ri-robot-3-fill: "\f419";
$ri-robot-3-line: "\f41a";
$ri-share-2-fill: "\f41b";
$ri-share-2-line: "\f41c";
$ri-sofa-fill: "\f41d";
$ri-sofa-line: "\f41e";
$ri-svelte-fill: "\f41f";
$ri-svelte-line: "\f420";
$ri-vk-fill: "\f421";
$ri-vk-line: "\f422";
$ri-xrp-fill: "\f423";
$ri-xrp-line: "\f424";
$ri-xtz-fill: "\f425";
$ri-xtz-line: "\f426";
$ri-archive-stack-fill: "\f427";
$ri-archive-stack-line: "\f428";
$ri-bowl-fill: "\f429";
$ri-bowl-line: "\f42a";
$ri-calendar-view: "\f42b";
$ri-carousel-view: "\f42c";
$ri-code-block: "\f42d";
$ri-color-filter-fill: "\f42e";
$ri-color-filter-line: "\f42f";
$ri-contacts-book-3-fill: "\f430";
$ri-contacts-book-3-line: "\f431";
$ri-contract-fill: "\f432";
$ri-contract-line: "\f433";
$ri-drinks-2-fill: "\f434";
$ri-drinks-2-line: "\f435";
$ri-export-fill: "\f436";
$ri-export-line: "\f437";
$ri-file-check-fill: "\f438";
$ri-file-check-line: "\f439";
$ri-focus-mode: "\f43a";
$ri-folder-6-fill: "\f43b";
$ri-folder-6-line: "\f43c";
$ri-folder-check-fill: "\f43d";
$ri-folder-check-line: "\f43e";
$ri-folder-close-fill: "\f43f";
$ri-folder-close-line: "\f440";
$ri-folder-cloud-fill: "\f441";
$ri-folder-cloud-line: "\f442";
$ri-gallery-view-2: "\f443";
$ri-gallery-view: "\f444";
$ri-hand: "\f445";
$ri-import-fill: "\f446";
$ri-import-line: "\f447";
$ri-information-2-fill: "\f448";
$ri-information-2-line: "\f449";
$ri-kanban-view-2: "\f44a";
$ri-kanban-view: "\f44b";
$ri-list-view: "\f44c";
$ri-lock-star-fill: "\f44d";
$ri-lock-star-line: "\f44e";
$ri-puzzle-2-fill: "\f44f";
$ri-puzzle-2-line: "\f450";
$ri-puzzle-fill: "\f451";
$ri-puzzle-line: "\f452";
$ri-ram-2-fill: "\f453";
$ri-ram-2-line: "\f454";
$ri-ram-fill: "\f455";
$ri-ram-line: "\f456";
$ri-receipt-fill: "\f457";
$ri-receipt-line: "\f458";
$ri-shadow-fill: "\f459";
$ri-shadow-line: "\f45a";
$ri-sidebar-fold-fill: "\f45b";
$ri-sidebar-fold-line: "\f45c";
$ri-sidebar-unfold-fill: "\f45d";
$ri-sidebar-unfold-line: "\f45e";
$ri-slideshow-view: "\f45f";
$ri-sort-alphabet-asc: "\f460";
$ri-sort-alphabet-desc: "\f461";
$ri-sort-number-asc: "\f462";
$ri-sort-number-desc: "\f463";
$ri-stacked-view: "\f464";
$ri-sticky-note-add-fill: "\f465";
$ri-sticky-note-add-line: "\f466";
$ri-swap-2-fill: "\f467";
$ri-swap-2-line: "\f468";
$ri-swap-3-fill: "\f469";
$ri-swap-3-line: "\f46a";
$ri-table-3: "\f46b";
$ri-table-view: "\f46c";
$ri-text-block: "\f46d";
$ri-text-snippet: "\f46e";
$ri-timeline-view: "\f46f";
$ri-blogger-fill: "\f470";
$ri-blogger-line: "\f471";
$ri-chat-thread-fill: "\f472";
$ri-chat-thread-line: "\f473";
$ri-discount-percent-fill: "\f474";
$ri-discount-percent-line: "\f475";
$ri-exchange-2-fill: "\f476";
$ri-exchange-2-line: "\f477";
$ri-git-fork-fill: "\f478";
$ri-git-fork-line: "\f479";
$ri-input-field: "\f47a";
$ri-progress-1-fill: "\f47b";
$ri-progress-1-line: "\f47c";
$ri-progress-2-fill: "\f47d";
$ri-progress-2-line: "\f47e";
$ri-progress-3-fill: "\f47f";
$ri-progress-3-line: "\f480";
$ri-progress-4-fill: "\f481";
$ri-progress-4-line: "\f482";
$ri-progress-5-fill: "\f483";
$ri-progress-5-line: "\f484";
$ri-progress-6-fill: "\f485";
$ri-progress-6-line: "\f486";
$ri-progress-7-fill: "\f487";
$ri-progress-7-line: "\f488";
$ri-progress-8-fill: "\f489";
$ri-progress-8-line: "\f48a";
$ri-remix-run-fill: "\f48b";
$ri-remix-run-line: "\f48c";
$ri-signpost-fill: "\f48d";
$ri-signpost-line: "\f48e";
$ri-time-zone-fill: "\f48f";
$ri-time-zone-line: "\f490";
$ri-arrow-down-wide-fill: "\f491";
$ri-arrow-down-wide-line: "\f492";
$ri-arrow-left-wide-fill: "\f493";
$ri-arrow-left-wide-line: "\f494";
$ri-arrow-right-wide-fill: "\f495";
$ri-arrow-right-wide-line: "\f496";
$ri-arrow-up-wide-fill: "\f497";
$ri-arrow-up-wide-line: "\f498";
$ri-bluesky-fill: "\f499";
$ri-bluesky-line: "\f49a";
$ri-expand-height-fill: "\f49b";
$ri-expand-height-line: "\f49c";
$ri-expand-width-fill: "\f49d";
$ri-expand-width-line: "\f49e";
$ri-forward-end-fill: "\f49f";
$ri-forward-end-line: "\f4a0";
$ri-forward-end-mini-fill: "\f4a1";
$ri-forward-end-mini-line: "\f4a2";
$ri-friendica-fill: "\f4a3";
$ri-friendica-line: "\f4a4";
$ri-git-pr-draft-fill: "\f4a5";
$ri-git-pr-draft-line: "\f4a6";
$ri-play-reverse-fill: "\f4a7";
$ri-play-reverse-line: "\f4a8";
$ri-play-reverse-mini-fill: "\f4a9";
$ri-play-reverse-mini-line: "\f4aa";
$ri-rewind-start-fill: "\f4ab";
$ri-rewind-start-line: "\f4ac";
$ri-rewind-start-mini-fill: "\f4ad";
$ri-rewind-start-mini-line: "\f4ae";
$ri-scroll-to-bottom-fill: "\f4af";
$ri-scroll-to-bottom-line: "\f4b0";
$ri-add-large-fill: "\f4b1";
$ri-add-large-line: "\f4b2";
$ri-aed-electrodes-fill: "\f4b3";
$ri-aed-electrodes-line: "\f4b4";
$ri-aed-fill: "\f4b5";
$ri-aed-line: "\f4b6";
$ri-alibaba-cloud-fill: "\f4b7";
$ri-alibaba-cloud-line: "\f4b8";
$ri-align-item-bottom-fill: "\f4b9";
$ri-align-item-bottom-line: "\f4ba";
$ri-align-item-horizontal-center-fill: "\f4bb";
$ri-align-item-horizontal-center-line: "\f4bc";
$ri-align-item-left-fill: "\f4bd";
$ri-align-item-left-line: "\f4be";
$ri-align-item-right-fill: "\f4bf";
$ri-align-item-right-line: "\f4c0";
$ri-align-item-top-fill: "\f4c1";
$ri-align-item-top-line: "\f4c2";
$ri-align-item-vertical-center-fill: "\f4c3";
$ri-align-item-vertical-center-line: "\f4c4";
$ri-apps-2-add-fill: "\f4c5";
$ri-apps-2-add-line: "\f4c6";
$ri-close-large-fill: "\f4c7";
$ri-close-large-line: "\f4c8";
$ri-collapse-diagonal-2-fill: "\f4c9";
$ri-collapse-diagonal-2-line: "\f4ca";
$ri-collapse-diagonal-fill: "\f4cb";
$ri-collapse-diagonal-line: "\f4cc";
$ri-dashboard-horizontal-fill: "\f4cd";
$ri-dashboard-horizontal-line: "\f4ce";
$ri-expand-diagonal-2-fill: "\f4cf";
$ri-expand-diagonal-2-line: "\f4d0";
$ri-expand-diagonal-fill: "\f4d1";
$ri-expand-diagonal-line: "\f4d2";
$ri-firebase-fill: "\f4d3";
$ri-firebase-line: "\f4d4";
$ri-flip-horizontal-2-fill: "\f4d5";
$ri-flip-horizontal-2-line: "\f4d6";
$ri-flip-horizontal-fill: "\f4d7";
$ri-flip-horizontal-line: "\f4d8";
$ri-flip-vertical-2-fill: "\f4d9";
$ri-flip-vertical-2-line: "\f4da";
$ri-flip-vertical-fill: "\f4db";
$ri-flip-vertical-line: "\f4dc";
$ri-formula: "\f4dd";
$ri-function-add-fill: "\f4de";
$ri-function-add-line: "\f4df";
$ri-goblet-2-fill: "\f4e0";
$ri-goblet-2-line: "\f4e1";
$ri-golf-ball-fill: "\f4e2";
$ri-golf-ball-line: "\f4e3";
$ri-group-3-fill: "\f4e4";
$ri-group-3-line: "\f4e5";
$ri-heart-add-2-fill: "\f4e6";
$ri-heart-add-2-line: "\f4e7";
$ri-id-card-fill: "\f4e8";
$ri-id-card-line: "\f4e9";
$ri-information-off-fill: "\f4ea";
$ri-information-off-line: "\f4eb";
$ri-java-fill: "\f4ec";
$ri-java-line: "\f4ed";
$ri-layout-grid-2-fill: "\f4ee";
$ri-layout-grid-2-line: "\f4ef";
$ri-layout-horizontal-fill: "\f4f0";
$ri-layout-horizontal-line: "\f4f1";
$ri-layout-vertical-fill: "\f4f2";
$ri-layout-vertical-line: "\f4f3";
$ri-menu-fold-2-fill: "\f4f4";
$ri-menu-fold-2-line: "\f4f5";
$ri-menu-fold-3-fill: "\f4f6";
$ri-menu-fold-3-line: "\f4f7";
$ri-menu-fold-4-fill: "\f4f8";
$ri-menu-fold-4-line: "\f4f9";
$ri-menu-unfold-2-fill: "\f4fa";
$ri-menu-unfold-2-line: "\f4fb";
$ri-menu-unfold-3-fill: "\f4fc";
$ri-menu-unfold-3-line: "\f4fd";
$ri-menu-unfold-4-fill: "\f4fe";
$ri-menu-unfold-4-line: "\f4ff";
$ri-mobile-download-fill: "\f500";
$ri-mobile-download-line: "\f501";
$ri-nextjs-fill: "\f502";
$ri-nextjs-line: "\f503";
$ri-nodejs-fill: "\f504";
$ri-nodejs-line: "\f505";
$ri-pause-large-fill: "\f506";
$ri-pause-large-line: "\f507";
$ri-play-large-fill: "\f508";
$ri-play-large-line: "\f509";
$ri-play-reverse-large-fill: "\f50a";
$ri-play-reverse-large-line: "\f50b";
$ri-police-badge-fill: "\f50c";
$ri-police-badge-line: "\f50d";
$ri-prohibited-2-fill: "\f50e";
$ri-prohibited-2-line: "\f50f";
$ri-shopping-bag-4-fill: "\f510";
$ri-shopping-bag-4-line: "\f511";
$ri-snowflake-fill: "\f512";
$ri-snowflake-line: "\f513";
$ri-square-root: "\f514";
$ri-stop-large-fill: "\f515";
$ri-stop-large-line: "\f516";
$ri-tailwind-css-fill: "\f517";
$ri-tailwind-css-line: "\f518";
$ri-tooth-fill: "\f519";
$ri-tooth-line: "\f51a";
$ri-video-off-fill: "\f51b";
$ri-video-off-line: "\f51c";
$ri-video-on-fill: "\f51d";
$ri-video-on-line: "\f51e";
$ri-webhook-fill: "\f51f";
$ri-webhook-line: "\f520";
$ri-weight-fill: "\f521";
$ri-weight-line: "\f522";
