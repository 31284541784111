.sf-sidebar-social {
	display: flex;
	justify-content: center;
	justify-items: center;
	padding: $pad--small;
	&__link {
		display: block;
		width: 45px;
		height: 45px;
		position: relative;
		top: 1px;
		&:not(:last-child) {
			margin-right: $pad--min;
		}
		img {
			width: 100%;
		}
	}
}
