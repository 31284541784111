.sf-hero-nav {
	display: flex;
	flex-direction: column; // Mobile-first approach: stack elements vertically

	&__right {
		background-color: $sf-green--darker; // Distinguish right side with darker background

		ul {
			li {
				// Highlight current, ancestor, hero-parent-aliases and hover state:
				&.current-menu-item,
				&.current-menu-ancestor,
				&:hover {
					background-color: $sf-orange--light;
				}

				a {
					padding: $pad--small; // Consistent padding for right side links
				}

				transition: background-color 0.3s ease-in-out;
				flex-grow: 1; // Distribute space evenly among right side items
			}

			// remove background colour from current and ancestor items when hovering the menu
			&:hover {
				li {
					&.current-menu-item,
					&.current-menu-ancestor {
						background-color: transparent !important;
						&:hover {
							background-color: $sf-orange--light !important; // ensure hover state is orange on hover for current and ancestor items
						}
					}
				}
			}
		}
	}

	&__left {
		ul {
			li {
				a {
					position: relative;
					overflow: hidden;
					padding: $pad--small $pad--min; // Consistent padding for left side links

					// Create triangle indicator (to indicate current page):
					&:after {
						@include triangle(#fff, up, 10px);
						// ensure the triangle is the same colour as the background (grey when hero nav is contained within an explictly grey background):
						.sf-bg--grey & {
							@include triangle($sf-bg--grey, up, 10px);
						}
						position: absolute;
						transition: transform 0.3s ease-in-out;
						top: 100%;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				// Show triangle for current and ancestor items:
				&.current-menu-item,
				&.current-menu-ancestor {
					a:after {
						transform: translate(-50%, -100%);
					}
				}

				// Show triangle on hover:
				&:hover {
					a:after {
						transform: translate(-50%, -100%) !important;
					}
				}
			}

			// Reset triangle position when hovering the menu:
			&:hover {
				.current-menu-item,
				.current-menu-ancestor {
					a:after {
						transform: translateX(-50%);
					}
				}
			}
		}

		&--welcome-message {
			padding: $pad--min $pad--min 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			p {
				@include p(#fff, $sf-weight--light, $sf-font-size--small);
				b {
					font-size: $sf-font-size--large;
					font-weight: $sf-weight--med;
				}
				a {
					color: #fff;
					text-decoration: underline;
					&:hover {
						color: $sf-orange--light;
					}
				}
			}
		}
	}

	// Common styles for both left and right menus:
	ul {
		display: flex;
		li {
			a {
				display: block;
				white-space: nowrap;
				text-align: center;

				// Typography styles with responsive font sizes:
				@include p(#fff, $sf-weight--med, $sf-font-size--small);
				@include respond-to-min(sm) {
					font-size: $sf-font-size--standard;
					@include respond-to-min(xl) {
						font-size: $sf-font-size--large;
					}
				}
			}
		}
	}

	// Styles for the mobile select dropdown:
	&__select {
		display: none; // Hidden by default, shown on mobile
		padding: $pad--min;
		select {
			width: 100%;
			padding: $pad--min;
			border: none;
			@include p();
			font-weight: 700;
			background-color: #fff;
		}
	}

	// Mobile styles:
	@include respond-to-max(md) {
		&__left--menu,
		&__right {
			display: none; // Hide regular menus on mobile
		}
		&__select {
			display: block; // Show select dropdown on mobile
		}
	}

	// Tablet styles:
	@include respond-min-to-max(md, lg) {
		position: relative; // Ensure relative positioning for fade effect

		// Add fade effect to indicate scrollable content:
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 30px;
			pointer-events: none;
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0) 0%,
				rgba($sf-green--dark, 1) 100%
			);
			z-index: 1;
		}

		&__left {
			&--welcome-message {
				order: 0; // display welcome message above right menu
				padding: $pad--min;
				width: 100%;
			}
			&--menu {
				order: 2; // display left menu below right menu
			}
			margin: 0 auto;
			max-width: 100%;
			ul {
				overflow-x: auto; // Allow horizontal scrolling
				padding: 0 $pad--min;
			}
		}
		&__right {
			order: 1; // display right menu above left menu or below welcome message
			z-index: 2; // Ensure right menu is above fade effect
			ul {
				li {
					&:not(:last-child) {
						border-right: 1px solid $sf-green--dark; // Separate right menu items
					}
				}
			}
		}
	}

	// for small screens that may encounter wrapping:
	@include respond-min-to-max(lg, xl) {
		&__left {
			flex-grow: 1; // Allow left menu to grow and fill available space when wrapping occurs
			padding-right: 60px !important; // Add space for right menu (important to override &--welcome-message styles)
		}
		&__right {
			flex-grow: 1; // Allow right menu to grow and fill available space when wrapping occurs
			margin: 0 -30px; // Negative margin to align with container edge
		}
	}

	// Desktop styles:
	@include respond-to-min(lg) {
		flex-direction: row; // Switch to horizontal layout
		justify-content: space-between; // Space out items to left and right hand side of container
		flex-wrap: wrap-reverse; // Ensure right menu is on top if wrapping occurs
		&__left {
			&--welcome-message {
				padding: $pad--min 0;
			}
		}
	}
}
