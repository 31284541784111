.sf-price {
	span {
		white-space: nowrap;
	}

	&--toggle {
		.woocommerce-Price-currencySymbol {
			display: none; // Hide currency symbol as it is displayed in the toggle dropdown
		}
	}

	&.sf-bg {
		* {
			background-color: inherit !important; // ensure bg colour is inherited from parent
		}
	}
}
