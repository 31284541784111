$sf-related-posts-nav__count--w: 25px;

.sf-related-posts-nav {
	&__list {
		&-item {
			padding: $pad--min $pad--small;

			border-bottom: $sf-border;

			//dropdown functionality
			&[data-dropdown] {
				i {
					float: right;
					position: relative;
					top: 0;
					transition: top 0.3s ease-in-out;
				}
				&:hover {
					i {
						top: 3px;
					}
				}
				&[data-dropdown="expanded"] {
					&:hover {
						i {
							top: -3px;
						}
					}

					i {
						transform: rotate(180deg);
					}
				}

				&:not([data-dropdown="expanded"]) ~ li {
					display: none;
				}
			}

			&:hover:not(.sf-related-posts-nav__list-item--header),
			&--header[data-dropdown]:hover,
			&--current {
				cursor: pointer;
				background-color: $sf-grey--lightest;

				.sf-related-posts-nav__count {
					background-color: $sf-blue--dark;
				}

				.sf-related-posts-nav--green &,
				.sf-related-posts-nav--blue &,
				.sf-related-posts-nav--orange & {
					.sf-related-posts-nav__link {
						color: #fff;
					}
				}

				.sf-related-posts-nav--green & {
					background-color: $sf-green;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--green & {
					background-color: $learnSEO-green;
				}
				.sf-related-posts-nav--orange & {
					background-color: $sf-orange--light;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--orange & {
					background-color: $learnSEO-orange;
				}
				.sf-related-posts-nav--blue & {
					background-color: $sf-blue--light;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--blue & {
					background-color: $learnSEO-blue;
				}
			}

			&--header {
				padding: $pad--small;
				@include h4();

				.sf-related-posts-nav--green & {
					border-top: 5px solid $sf-green;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--green & {
					border-top: 5px solid $learnSEO-green;
				}
				.sf-related-posts-nav--orange & {
					border-top: 5px solid $sf-orange--light;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--orange & {
					border-top: 5px solid $learnSEO-orange;
				}
				.sf-related-posts-nav--blue & {
					border-top: 5px solid $sf-blue--light;
				}
				.sf-related-posts-nav--learn-seo.sf-related-posts-nav--blue & {
					border-top: 5px solid $learnSEO-blue;
				}
			}
		}
	}

	&__link {
		display: grid;
		justify-items: space-between;
		justify-content: space-between;
		align-items: center;
		grid-template-columns: 1fr;
		column-gap: 5px;

		@include p();

		img {
			width: 100%; // ensure on IOS to not expand beyond containter
		}

		@for $i from 1 through 5 {
			&--#{$i}-images {
				grid-template-columns: 1fr repeat(#{$i}, 20px);
			}
		}
	}

	&__count {
		width: $sf-related-posts-nav__count--w;
		flex-basis: $sf-related-posts-nav__count--w;
		height: $sf-related-posts-nav__count--w;
		@include p($font-size: 15px, $color: #fff);
		background-color: $sf-blue--light;
		border-radius: 1000px;
		display: flex;
		justify-items: center;
		justify-content: center;
		align-items: center;
		align-content: center;
	}
}
