//@import "../config/config";

///*=====================================================================</editor-fold>=
// TABS
///=====================================================================<editor-fold>=*/

ul.tabs {
	max-width: 670px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

ul.tabs input[type="radio"] {
	display: none;
}

ul.tabs .tab-content {
	border: 2px solid #e1e1e1;
	z-index: 2;
	display: none;
	padding: 50px;
}

@media (max-width: 500px) {
	ul.tabs .tab-content {
		padding: 50px 30px;
	}
}

ul.tabs .tab-content .profile-input {
	margin-bottom: 20px;
}

ul.tabs .tab-content .profile-input:after {
	display: table;
	content: "";
	line-height: 0;
	clear: both;
}

ul.tabs .tab-content .profile-input > label {
	display: inline-block;
	font-size: 18px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 52px;
	position: relative;
}

ul.tabs .tab-content .profile-input > label .error {
	position: absolute;
	right: -10px;
	top: -10px;
}

ul.tabs .tab-content .profile-input input,
ul.tabs .tab-content .profile-input select {
	font-size: 18px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	display: inline-block;
	float: right;
	width: 100% !important;
	max-width: 380px;
	padding: 13px 15px;
	border: 1px solid #e1e1e1;
}

ul.tabs .tab-content .profile-input input#pass1-text,
ul.tabs .tab-content .profile-input select#pass1-text {
	display: none;
}

ul.tabs .tab-content .profile-input input[disabled="disabled"],
ul.tabs .tab-content .profile-input select[disabled="disabled"] {
	background: #f7f7f7;
	font-style: italic;
}

ul.tabs .tab-content .profile-input select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	border-radius: 0;
}

ul.tabs .tab-content .profile-input.country-input {
	position: relative;
}

ul.tabs .tab-content .profile-input.country-input:after {
	content: "\f078";
	font-family: fontAwesome;
	display: block;
	position: absolute;
	bottom: 16px;
	right: 20px;
	color: #8a8a8a;
	line-height: 1;
}

ul.tabs .tab-content .profile-input .billingstates {
	display: inline-block;
	width: 100%;
	float: right;
	max-width: 380px;
}

ul.tabs .tab-content .profile-input .description {
	display: none;
}

ul.tabs .tab-content .profile-input.password-strength {
	max-width: 380px;
	float: right;
	width: 100%;
}

ul.tabs .tab-content .profile-input.password-strength > label {
	font-size: 14px;
	float: left;
}

ul.tabs .tab-content .profile-input.password-strength .description {
	display: block;
	clear: both;
	font-size: 13px;
	line-height: 19px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar {
	width: 100%;
	display: block;
	max-width: 245px;
	float: right;
	font-size: 12px;
	background: #f8f8f8;
	margin-top: 13px;
	text-align: center;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div {
	font-size: 12px;
	display: block;
	width: 100%;
	text-align: center;
	position: relative;
	padding: 5px 0;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div p {
	position: relative;
	z-index: 9;
	color: #000;
	font-weight: $sf-weight--light;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background: #ccc;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	z-index: 0;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div.short:before {
	width: 25%;
	background: red;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div.bad:before {
	width: 50%;
	background: #ff7b00;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div.good:before {
	width: 75%;
	background: #ffd900;
}

ul.tabs .tab-content .profile-input.password-strength .pw-bar > div.strong:before {
	width: 100%;
	background: $sf-green;
}

@media (max-width: 700px) {
	ul.tabs .tab-content .profile-input {
		max-width: none;
	}

	ul.tabs .tab-content .profile-input input,
	ul.tabs .tab-content .profile-input select,
	ul.tabs .tab-content .profile-input .billingstates {
		float: none;
		display: block;
		max-width: none;
	}

	ul.tabs .tab-content .profile-input.password-strength {
		float: none;
	}
}

ul.tabs [id^="tab"]:checked + label {
	top: -64px;
	border-left: 2px solid #e1e1e1;
	border-top: 2px solid #e1e1e1;
	border-right: 2px solid #e1e1e1;
	border-bottom: 2px solid #fff;
	color: $sf-font-grey;
}

ul.tabs [id^="tab"]:checked ~ [id^="tab-content"] {
	display: block;
}

.tab-content.active {
	display: block;
}

ul.tabs li > label {
	display: block;
	position: absolute;
	font-size: 18px;
	left: 0;
	top: -64px;
	padding: 20px;
	color: #9d9d9d;
	cursor: pointer;
}

ul.tabs li > label[for="tab2"] {
	left: 170px;
}

@media (max-width: 500px) {
	ul.tabs li > label {
		width: 50%;
		padding: 20px 0;
		text-align: center;
	}
}
//</editor-fold>
