// overrides for reCaptcha Pro by BestWebSoft

// style notices to the user to match theme:
.google-captcha-notice,
.gglcptch_allowlist_message {
	@include p();
	a {
		@include link();
	}
}

.grecaptcha-badge {
	display: none !important; //hide .grecaptcha-badge added by plugin
}

.gglcptch_error_text {
	margin-bottom: $pad--min; // add margin-bottom to error (time out) message:
}
