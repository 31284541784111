// SF TODO: refactor product -> software
@mixin product-nav-select-text() {
	font-size: $sf-font-size--standard;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

@mixin product-nav-dropdown-arrow($bottom, $right) {
	content: "\f078";
	font-family: fontAwesome;
	display: block;
	position: absolute;
	bottom: $bottom;
	right: $right;
	color: #8a8a8a;
	line-height: 1;

	font-size: $sf-font-size--standard;
}
