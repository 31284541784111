.training-btn {
	padding: 1em;
	font-size: 18px;
	text-align: center;
	margin: 0 auto;
	color: $white;
	background: $sf-orange--light;
	text-transform: capitalize;
	width: 100%;
	line-height: 1.2;
	cursor: pointer;
	display: block;

	&--book {
		max-width: 300px;
	}

	&--more {
		border-radius: 25px;
		max-width: 200px;
		margin: $gutter auto 0 auto;
		background: $screaming-blue;
	}
}
