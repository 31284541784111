.sf-services-menu {
	display: grid;
	column-gap: 2px;
	row-gap: 2px;
	grid-template-columns: 1fr;
	&__item {
		background-color: #fff;
		text-align: center;
		padding: $pad--small + $pad--min $pad--small $pad--large;
		transform: scale(1);
		box-shadow: none;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		&:hover {
			z-index: 1;
			transform: scale(1.1);
			transition: all 0.3s ease-in-out;
			box-shadow: $sf-shadow;
			i {
				opacity: 1;
			}
		}
		img {
			width: 175px;
		}
		h2 {
			@include h2();
			margin: $pad--min 0;
		}
		p {
			@include p();
			flex-grow: 1;
		}
	}

	&__arrow {
		color: $sf-green;
		font-size: 30px;
		line-height: 0;
		height: 0;
		margin-top: $pad--small;
		i {
			opacity: 0;
		}
	}

	@include respond-to-min(md) {
		grid-template-columns: repeat(2, 1fr);
		@include respond-to-min(xl) {
			&__item {
				padding: $pad--large;
			}
		}
	}
}
