.sf-portfolio-work {
	padding: $pad--small;

	p {
		max-width: $sf-paragraph-max-w;
		margin: 0 auto;
	}
	&__header {
		margin-bottom: $pad--small;
	}
	&__footer {
		margin-top: $pad--small;
	}

	&__grid {
		display: grid;
		column-gap: $pad--small;
		row-gap: $pad--small;
		grid-template-columns: 1fr;
	}

	@include respond-to-min(md) {
		&__grid {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond-to-min(xl) {
			padding: $pad--large;
			&__header {
				margin-bottom: $pad--large;
			}
			&__footer {
				margin-top: $pad--large;
			}
			&__grid {
				column-gap: $pad--large;
				row-gap: $pad--large;
			}
		}
	}

	&-item {
		width: 100%;
		overflow: hidden;
		position: relative;
		text-align: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		aspect-ratio: 1 / 1;
		@include respond-to-min(sm) {
			aspect-ratio: 1 / 0.75;
			@include respond-to-min(md) {
				aspect-ratio: 1 / 1.5;
				@include respond-to-min(lg) {
					aspect-ratio: 1 / 1;
					@include respond-to-min(xl) {
						aspect-ratio: 1 / 0.75;
					}
				}
			}
		}
		&__top {
			position: absolute;
			bottom: 50%;
			left: 0;
			right: 0;
			transform: translateY(50%);
			transition: all 0.5s;
		}
		&__bottom {
			padding: $pad--min;
			position: absolute;
			top: 50%;
			height: 50%;
			bottom: 0;
			left: 0;
			right: 0;
			transition: all 0.5s;
			opacity: 0;
			transform: translateY(100%);
			@include p($color: #fff, $font-size: $sf-font-size--large);
			i {
				margin-top: $pad--min;
				font-size: $sf-font-size--x-large;
				display: inline-block;
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $sf-green;
			opacity: 0;
			transition: all 0.5s;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			.sf-portfolio-work-item__top,
			.sf-portfolio-work-item__bottom {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
