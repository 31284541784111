// remix icon mixin
// $icon is a sass variable, a full list of which can be found in scss/vendor/_remixicon.scss
@mixin remixicon($icon) {
	font-family: "remixicon" !important;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: $icon;
}

// SF adjustments to vendor styles
.ri-adjust-top {
	position: relative;
	top: 2px;
}

//ensure animations can apply to the icons
[class^="ri-"],
[class*=" ri-"] {
	&.animated:not(.hvr-hide) {
		display: inline-block;
	}
}

.hvr-parent {
	&:hover {
		.ri-hvr-fill {
			// add icons here from as required
			&.ri-folder-6-line:before {
				//code for .ri-folder-6-fill
				content: "\f43b";
			}
		}
	}
}
