$sf-homepage-services-nav--bp: 550px;
$sf-homepage-animated-icons-bg-color: #fdbb3e;
.sf-homepage-services-nav {
	background-color: $sf-green;
	&__grid {
		background-color: #7db938;
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 2px;
		column-gap: 2px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		justify-content: flex-start;
		justify-items: flex-start;
		text-align: center;
		transform-origin: bottom center;
		background-color: $sf-green;
		border-top: 2px solid $sf-green;
		overflow: hidden;
		&:hover {
			transform: scale(1.1);
			background-color: $sf-homepage-animated-icons-bg-color;
			border-top-color: #fff;
			.sf-homepage-services-nav__arrow {
				opacity: 1;
				// display: flex;
			}
		}
	}
	&__text {
		color: #fff;
		font-size: $sf-font-size--standard;
		flex-grow: 1;
		word-break: normal;
		padding: 0 5px;
		span {
			display: block;
			white-space: nowrap;
		}
	}
	&__icon {
		width: 100px;
		height: 100px;
		// max-width: 100px;
	}
	&__arrow {
		width: 100%;
		height: 30px;
		display: flex;
		justify-self: flex-end;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		color: #fff;
		font-size: $sf-font-size--large;
		opacity: 0;
		// display: none;
	}
	&__show-all {
		display: none;
		width: 100%;
		@include border(none, bottom, left, right);
		border-top: 1px solid #fff;
		padding: $pad--min;
		text-align: center;
		cursor: pointer;
		@include p(#fff, $sf-weight--med);
		background-color: $sf-green;
		&:hover {
			background-color: $sf-homepage-animated-icons-bg-color;
		}
	}
	@include respond-to-max($sf-homepage-services-nav--bp) {
		// show button and hide items over 6
		&__show-all {
			display: block;
		}
		&__item {
			&:nth-child(n + 7) {
				display: none;
			}
		}
		//hide button and show all items
		&--show-all {
			.sf-homepage-services-nav__item {
				display: flex !important;
			}
			.sf-homepage-services-nav__show-all {
				display: none;
			}
		}
	}
	@include respond-to-min(sm) {
		&__grid {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond-to-min($sf-homepage-services-nav--bp) {
			&__grid {
				grid-template-columns: repeat(4, 1fr);
			}
			@include respond-to-min(md) {
				&__grid {
					grid-template-columns: repeat(6, 1fr);
				}
				@include respond-to-max(lg) {
					&__text {
						font-size: $sf-font-size--small;
					}
				}
			}
		}
	}
}
