.wrap-account {
	// for /my-account/orders
	.woocommerce-orders-table__cell-order-actions {
		.woocommerce-button {
			&.view {
				@include pill-btn--fill($sf-green, #fff !important, $sf-green--dark, 10px);
				margin-right: 5px;
			}
			&.invoice,
			&.pay {
				@include pill-btn--fill($sf-blue--light, #fff !important, $sf-blue--dark, 10px);
			}
		}
	}
	// for /my-account/view-order/[order-number]
	.woocommerce-order-details {
		.view_invoice {
			.button {
				@include pill-btn--fill($sf-blue--light, #fff !important, $sf-blue--dark);
			}
		}
	}

	// for /my-account/view-order/[order-number]
	mark {
		padding: 5px 7px;
		border-radius: 5px;
		margin: 0 3px;
		font-size: 14px;
		font-weight: $sf-weight--med;
		background-color: #e6ffc9;
	}

	.woocommerce-order-details {
		margin-top: 30px;

		thead,
		tfoot {
			th {
				background-color: $sf-table-bg-grey;
			}
		}

		&__title {
			margin: 10px 0;
		}
	}

	table.shop_table {
		tbody th,
		tfoot td,
		tfoot th {
			font-weight: 600;
		}
	}

	@media (max-width: 640px) {
		.woocommerce-MyAccount-content table.shop_table td {
			white-space: normal;
		}
	}
}
