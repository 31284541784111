.sf-recruit {
	&__list {
		padding: 0 $pad--small $pad--small;
	}
	&__content {
		padding: $pad--small;
	}
	// @include respond-to-max(lg) {
	// 	&__list {
	// 		padding-top: $pad--small;
	// 	}
	// }
	@include respond-to-min(lg) {
		display: grid;
		grid-template-columns: 45% 55%;

		&__content {
			padding: $pad--large;
			padding-right: $pad--small;
		}
		&__list {
			padding: $pad--large;
			padding-left: $pad--small;
			background: $sf-bg-shadow--to-right;
		}
	}
}
