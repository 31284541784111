@mixin pill-btn--outline($color, $bg: #fff, $padding: $pad--min, $elongate: 2, $border-width: 1px) {
	border-radius: 1000px;
	border: $border-width solid $color;
	color: $color;
	padding: $padding $padding * $elongate;
	text-align: center;
	background-color: $bg;
	transition: 0.5s;
	cursor: pointer;
	@include ensureLinkNoUnderline();

	&:hover {
		background-color: $color;
		color: $bg;
	}
	@content;
}

@mixin pill-btn--fill($bg, $color: #fff, $bg-hover: false, $padding: $pad--min, $elongate: 2) {
	color: $color;
	background-color: $bg;
	padding: $padding $padding * $elongate;
	border-radius: 1000px;
	border: none;
	transition: background-color 0.5s;
	text-align: center;
	cursor: pointer;
	@include ensureLinkNoUnderline();
	&:hover {
		@if ($bg-hover) {
			background-color: $bg-hover;
		} @else {
			background-color: darken($bg, 5%);
		}
	}
	@content;
}

@mixin account-edit-btn($icon: true, $size: 40px, $padding: 5px) {
	background-color: #fff;
	border: 1px solid $sf-grey--light;
	border-radius: 5px;

	position: relative;
	padding: $padding;

	cursor: pointer;
	display: inline-block;
	transition: all 0.5s;

	font-weight: $sf-weight--med;
	color: $sf-grey--light !important;

	@if $icon {
		display: flex;
		justify-content: center;
		align-items: center;
		// width: $size;
		// height: $size;
		i {
			font-size: 20px;
			line-height: 20px;
			display: inline-block;
			// z-index: 1;

			// &:not(.ri-pencil-fill) {
			// 	color: $sf-grey--light;
			// 	position: absolute;
			// 	left: 50%;
			// 	top: 50%;
			// 	transform: translate(-50%, -50%);
			// 	z-index: -1;
			// }
		}

		// svg {
		// 	width: calc(100% - 20px);
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	transform: translate(-50%, -50%);
		// 	.st0 {
		// 		fill: $sf-grey--light;
		// 		transition: all 0.5s;
		// 	}
		// }
	}

	&:hover {
		color: $sf-blue--dark !important;
		box-shadow: 0px 0px 10px 0px rgba($sf-blue--dark, 0.75);
		// border-color: $sf-green;
		// @if $icon {
		// 	svg {
		// 		.st0 {
		// 			fill: $sf-green;
		// 		}
		// 	}
		// }
	}
	@content;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px; // Optional: Add padding to prevent collapse

	i,
	span {
		font-size: 30px; // Adjust as needed
		display: inline-block;
	}
}
