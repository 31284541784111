.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output,
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
	border: none;
	@include notification-message($sf-color--error!important);
}

.wpcf7 form.sent .wpcf7-response-output {
	border: none;
	@include notification-message($sf-color--success);
}
