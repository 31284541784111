.sf-carousel {
	overflow: hidden;
	@include frog-wallpaper(true);

	/* needed to allow the carousel to be visible outside container */
	.tns-ovh {
		overflow: visible !important;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-content: center;
		flex-wrap: wrap;
		margin-top: $pad--small;
		@include respond-to-min(md) {
			margin-top: $pad--large;
		}

		h2 {
			@include h2($color: #fff);
		}

		a {
			@include pill-btn--fill($bg: $sf-orange--light);
			align-self: center;
		}
	}

	&__items {
		margin-top: $pad--small;
		@include respond-to-min(md) {
			margin-top: $pad--large;
		}
		position: relative;
		img {
			width: 100%;
			box-shadow: $sf-shadow;
		}
	}

	&__control {
		position: relative;
		z-index: 1;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: space-between;

		&-btn {
			width: 95px;
			text-align: center;
			padding: 23px 0;
			font-size: 24px;
			color: #ffbc2b;
			transition: all 0.2s ease-in-out;
			cursor: pointer;
			user-select: none;
			background-color: #fff;
			border: none;
			position: relative;
			z-index: 99;
			height: 75px;
			box-shadow: $sf-shadow;

			i {
				font-size: 34px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				width: 110px;
			}
			&--next {
				&[disabled] {
					transform: translate(100%);
				}
			}

			&--prev {
				&[disabled] {
					transform: translate(-100%);
				}
			}
		}
	}
}
