.sf-inline-download {
	background-color: $sf-green;
	position: relative;
	text-align: center;
	padding: 35px;
	width: 100%;
	max-width: 400px;
	margin: 0 auto 30px auto;
	border-radius: 50px / 50px;

	@include respond-to-max(bs-lg) {
		display: none;
	}

	&:before {
		content: "";
		position: absolute;
		background: url(../../public/images/download-icons/frog@2x.png) no-repeat;
		background-size: auto;
		width: 90px;
		height: 105px;
		left: 0;
		top: -4px;
		background-size: 100%;
	}

	&__btn {
		@include pill-btn--outline(#fff !important, $sf-green !important) {
			// text-decoration: none !important;
		}
	}
}
