//@import "../config/config";

///*=====================================================================</editor-fold>=
// RESPONSES
///=====================================================================<editor-fold>=*/

.spider-success {
	padding: 50px 50px 70px;
	text-align: center;
	border-bottom: 2px solid #eaeaea;
}

.spider-success p {
	color: $sf-font-grey;
	font-size: 22px;
	margin-bottom: 15px;
}

.spider-success span {
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	font-size: 15px;
}

.spider-success span a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.spider-success span a:hover {
	color: #599f08;
}

.spider-failed {
	padding: 50px 50px 70px;
	text-align: center;
}

.spider-failed h2 {
	font-size: 26px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	text-align: center;
	margin-bottom: 20px;
}

.spider-failed p {
	color: $sf-font-grey;
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: $sf-weight--light;
}

.spider-failed p a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.spider-failed p a:hover {
	color: #599f08;
}
//</editor-fold>
