//SF TODO: TIDY (NEILS CODE)
#checkout_cart {
	.wc-proceed-to-checkout .checkout-button {
		float: right;
		font-size: 15px !important;
		width: auto;
		@include pill-btn--fill($screaming-green, #fff);
	}

	.wc-proceed-to-checkout #wc_braintree_paypal_container {
		display: none;
	}
}
#checkout_cart .cart-collaterals .cart_totals {
	width: 100% !important;
}

#checkout_cart .woocommerce-notices-wrapper a.button {
	margin-left: 15px;
}

#checkout_cart table.shop_table tbody tr td.actions button {
	display: inline-block;
	vertical-align: middle;
}

#checkout_cart table.shop_table {
	width: 100%;
	margin: 15px 0 !important;
	border-radius: 0 !important;
	border-collapse: collapse !important;
	border: 0;
}

#checkout_cart table.shop_table tr td,
#checkout_cart table.shop_table tr th {
	text-align: center !important;
	border: 1px solid #e1e1e1 !important;
	font-weight: $sf-weight--med !important;
	padding: 10px 5px !important;
	color: $sf-font-grey !important;
	font-size: 14px !important;
	word-break: break-all !important;
	vertical-align: middle !important;
}

#checkout_cart table.shop_table tbody tr td.product-quantity input {
	padding: 10px 0 !important;
	font-size: 16px !important;
	color: $sf-font-grey !important;
	border: 1px solid #e1e1e1 !important;
	width: 40px !important;
	text-align: center !important;
	-webkit-appearance: none;
	-moz-appearance: textfield;
}

#checkout_cart
	table.shop_table
	tbody
	tr
	td.product-quantity
	input[type="number"]::-webkit-inner-spin-button,
#checkout_cart
	table.shop_table
	tbody
	tr
	td.product-quantity
	input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0 !important;
}

#checkout_cart table.shop_table tbody tr td.product-quantity input[type="number"] {
	-moz-appearance: textfield;
}

#checkout_cart table.shop_table tbody tr td.actions input[type="submit"],
#checkout_cart table.shop_table tbody tr td.actions button {
	background-color: #7180e4;
	border-radius: 50px !important;
	text-align: center;
	color: #fff !important;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	float: right;
	border: 0 !important;
	padding: 12px 20px !important;
	font-size: 14px !important;
	display: inline-block !important;
	cursor: pointer;
	width: auto !important;
}

#checkout_cart table.shop_table tbody tr td.actions input[type="submit"]:hover,
#checkout_cart table.shop_table tbody tr td.actions button:hover {
	background-color: #3f52d5 !important;
}

#checkout_cart table.shop_table tbody tr th:nth-last-child(1) {
	min-width: 90px !important;
}

#checkout_cart table.shop_table tbody tr td.product-remove a.remove {
	text-align: center !important;
	margin: 0 auto;
}

#checkout_cart table.shop_table tbody tr td.product-remove a.remove:hover {
	background: none !important;
	color: #000 !important;
}

#checkout_cart table.shop_table tbody tr td.product-name a {
	color: #75b42c;
	display: block;
	margin-bottom: 5px;
	font-weight: $sf-weight--med;
	padding-left: 20px;
	text-align: left;
	box-sizing: border-box;
}

#checkout_cart table.shop_table tbody tr td.product-name a:hover {
	text-decoration: none;
}

#checkout_cart table.shop_table tbody tr td.product-name dl.variation {
	padding-left: 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

#checkout_cart table.shop_table tbody tr td.product-name a:hover {
	background: none !important;
	color: #75b42c;
}

#checkout_cart table.shop_table tbody tr td.actions div.coupon input.input-text {
	padding: 12px 15px !important;
	font-size: 14px !important;
	color: $sf-font-grey !important;
	border: 1px solid #e1e1e1 !important;
	width: auto !important;
	text-align: center !important;
	width: auto !important;
	max-width: 150px !important;
	-webkit-appearance: none;
	-moz-appearance: none;
}

#checkout_cart table.shop_table tbody tr td.actions div.coupon {
	padding-bottom: 0 !important;
}

#checkout_cart table.shop_table tbody tr td.product-price del,
.price del {
	text-decoration: line-through;
}

#checkout_cart
	table.shop_table.wpcocart.woocommerce-cart-form__contents
	td.product-price
	.subscription-details {
	display: none;
}

@media (max-width: 1100px) {
	#checkout_cart table.shop_table tr td,
	#checkout_cart table.shop_table tr th {
		font-size: 12px !important;
	}

	#checkout_cart table.shop_table tbody tr td.product-quantity input {
		font-size: 12px !important;
	}
}

@media (max-width: 768px) {
	#checkout_cart table.shop_table tbody tr td.product-name a {
		text-align: right;
		padding-left: 0;
	}

	#checkout_cart table.shop_table tr td {
		text-align: right !important;
	}

	#checkout_cart table.shop_table tbody tr td.product-name dl.variation {
		padding-left: 0;
	}

	#checkout_cart table.shop_table tbody tr td.product-remove a.remove {
		text-align: right !important;
		margin: 0 0 0 auto;
	}

	#checkout_cart table.shop_table tr + tr td,
	#checkout_cart table.shop_table tr + tr th,
	#checkout_cart table.shop_table tr td + td,
	#checkout_cart table.shop_table tr th + th {
		border-top: 0 !important;
	}
}

@media (max-width: 640px) {
	#checkout_cart table.shop_table tbody tr td.actions div.coupon {
		padding-bottom: 20px !important;
	}

	#checkout_cart .woocommerce-message {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	#checkout_cart .woocommerce-message .button {
		order: 2;
		margin-top: 15px;
	}

	#checkout_cart .cart-btm-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media (max-width: 480px) {
	#checkout_cart table.shop_table tbody tr td.actions div.coupon {
		display: flex;
		flex-direction: column;
	}

	#checkout_cart table.shop_table tbody tr td.actions div.coupon input.input-text {
		max-width: 100% !important;
		margin: 0 0 10px 0 !important;
	}
}

.checkout-spider--inner div#checkout_cart {
	padding: 50px 50px 30px;
}

@media (max-width: 1023px) {
	.checkout-spider--inner div#checkout_cart {
		padding: 30px 30px 15px 30px;
	}
}

@media (max-width: 750px) {
	.checkout-spider--inner div#checkout_cart {
		padding: 50px 15px 30px;
	}
}

.checkout-spider--inner table#checkout_cart tbody tr td a,
.checkout-spider--inner table#checkout_cart tbody tr td p {
	padding-left: 20px;
}

.checkout-spider--inner table#checkout_cart tbody tr td a {
	color: #75b42c;
	display: block;
	margin-bottom: 5px;
	font-weight: $sf-weight--med;
}

.checkout-spider--inner table#checkout_cart tbody tr td p {
	color: $sf-font-grey;
	font-size: 14px;
	font-weight: $sf-weight--light;
}

.checkout-spider--inner table#checkout_cart tbody tr td.wpco_columntitle p span {
	font-weight: $sf-weight--med;
}

.checkout-spider--inner table#checkout_cart tbody tr td.wpco_columnremove a {
	padding-left: 0 !important;
}

.checkout-spider--inner table#checkout_cart tbody tr td.wpco_columnremove a:before {
	content: "\f00d";
	font-family: fontAwesome;
	color: red;
}

.checkout-spider--inner table#checkout_cart tbody tr td.wpco_columnqty input {
	padding: 10px 0;
	font-size: 16px;
	color: $sf-font-grey;
	border: 1px solid #e1e1e1;
}

.checkout-spider--inner table#checkout_cart th,
.checkout-spider--inner table#checkout_cart td {
	width: 100%;
}

.checkout-spider--inner table#checkout_cart th:nth-last-child(1),
.checkout-spider--inner table#checkout_cart td:nth-last-child(1) {
	min-width: 90px;
}

.checkout-spider--inner table#checkout_cart th.wpco_columntotal,
.checkout-spider--inner table#checkout_cart td.wpco_columntotal {
	min-width: 100px;
}

.checkout-spider--inner table#checkout_cart th.wpco_columnqty,
.checkout-spider--inner table#checkout_cart td.wpco_columnqty {
	min-width: 80px;
}

.checkout-spider--inner table#checkout_cart th.wpco_columnprice,
.checkout-spider--inner table#checkout_cart td.wpco_columnprice {
	min-width: 100px;
}

.checkout-spider--inner table#checkout_cart th[colspan="2"],
.checkout-spider--inner table#checkout_cart td[colspan="2"] {
	display: none;
}

@media (max-width: 750px) {
	.checkout-spider--inner table#checkout_cart th.wpco_columnprice,
	.checkout-spider--inner table#checkout_cart td.wpco_columnprice,
	.checkout-spider--inner table#checkout_cart th.checkout_head_price,
	.checkout-spider--inner table#checkout_cart td.checkout_head_price {
		display: none;
	}

	.checkout-spider--inner table#checkout_cart th[colspan="2"],
	.checkout-spider--inner table#checkout_cart td[colspan="2"] {
		display: table-cell;
	}

	.checkout-spider--inner table#checkout_cart th[colspan="3"],
	.checkout-spider--inner table#checkout_cart td[colspan="3"] {
		display: none;
	}
}

@media (max-width: 500px) {
	.checkout-spider--inner table#checkout_cart th:nth-last-child(1),
	.checkout-spider--inner table#checkout_cart td:nth-last-child(1) {
		display: none;
	}
}

@media (max-width: 430px) {
	.checkout-spider--inner table#checkout_cart tbody,
	.checkout-spider--inner table#checkout_cart thead {
		display: block;
	}

	.checkout-spider--inner table#checkout_cart tbody tr,
	.checkout-spider--inner table#checkout_cart thead tr {
		display: block;
		font-size: 0;
	}

	.checkout-spider--inner table#checkout_cart tbody td,
	.checkout-spider--inner table#checkout_cart thead td,
	.checkout-spider--inner table#checkout_cart tbody td[colspan="2"],
	.checkout-spider--inner table#checkout_cart thead td[colspan="2"] {
		display: inline-block;
		border-width: 0;
	}

	.checkout-spider--inner table#checkout_cart tbody .wpco_columntitle,
	.checkout-spider--inner table#checkout_cart thead .wpco_columntitle {
		border-top-width: 1px;
		padding-bottom: 0 !important;
	}

	.checkout-spider--inner table#checkout_cart tbody .wpco_columnqty,
	.checkout-spider--inner table#checkout_cart thead .wpco_columnqty,
	.checkout-spider--inner table#checkout_cart tbody .wpco_columntotal,
	.checkout-spider--inner table#checkout_cart thead .wpco_columntotal {
		width: 50%;
		padding: 20px 0 !important;
	}

	.checkout-spider--inner table#checkout_cart tbody .wpco_columntotal,
	.checkout-spider--inner table#checkout_cart thead .wpco_columntotal {
		text-align: right;
	}

	.checkout-spider--inner table#checkout_cart tbody .total td,
	.checkout-spider--inner table#checkout_cart thead .total td {
		border-width: 0;
		text-align: right;
		padding: 20px 0 !important;
	}

	.checkout-spider--inner table#checkout_cart tbody .total td.wpco_totaltext,
	.checkout-spider--inner table#checkout_cart thead .total td.wpco_totaltext {
		text-align: left;
		border-top-width: 1px;
		padding-bottom: 0 !important;
	}

	.checkout-spider--inner table#checkout_cart tbody .total:last-child td:not(.wpco_totaltext),
	.checkout-spider--inner table#checkout_cart thead .total:last-child td:not(.wpco_totaltext) {
		border-bottom-width: 1px;
	}

	.checkout-spider--inner table#checkout_cart tbody th,
	.checkout-spider--inner table#checkout_cart thead th {
		display: none;
	}

	.checkout-spider--inner table#checkout_cart tbody th.checkout_head_product,
	.checkout-spider--inner table#checkout_cart thead th.checkout_head_product {
		display: block;
		border: none;
	}
}

.woocommerce button.button:disabled {
	pointer-events: none;
}
// .checkout-spider {
// 	background: #f8f8f8;
// 	position: relative;
// 	padding: 70px 0;
// }
// .checkout-spider--inner {
// 	position: relative;
// 	background-color: #fff;
// 	// @media (min-width: 1000px) {
// 	// 	width: calc(100% - 370px);
// 	// 	float: left;
// 	// }
// }
