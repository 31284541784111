$sf-post-table__row--pad: 5px;
$sf-post-table__cell--min-w: 100px;

@mixin sf-post-table--color-rows($name, $color) {
	&--#{$name} {
		@if ($name == "grey") {
			background-color: $sf-bg--grey;
		} @else {
			background-color: rgba($color, 0.1);
		}
		&.sf-post-table__row--head {
			background-color: $color;
			color: #fff;
		}
	}
}

.sf-post-table {
	overflow-x: auto;

	//hide br's which are added by wpautop
	br {
		display: none;
	}

	&__row {
		display: flex;
		@include p();

		@include sf-post-table--color-rows("grey", $sf-bg--dark-grey);
		@include sf-post-table--color-rows("green", $sf-green);
		@include sf-post-table--color-rows("orange", $sf-orange--dark);
		@include sf-post-table--color-rows("blue", $sf-blue--dark);

		&--head {
			padding: $sf-post-table__row--pad 0;
			font-weight: $sf-weight--heavy;
			background-color: $sf-bg--grey;
		}

		@for $i from 1 through 10 {
			&--#{$i}-cells {
				min-width: calc($i * $sf-post-table__cell--min-w);
				.sf-post-table__cell {
					min-width: $sf-post-table__cell--min-w;
					width: calc(100% / #{$i});
				}
			}
		}
	}

	&__cell {
		padding: $sf-post-table__row--pad;
		text-align: center;

		@for $n from 1 through 10 {
			$x: calc($n * 10);
			&.sf-post-table__cell--width-#{$x} {
				width: $x * 1%;
			}
		}

		&:not(:last-child) {
			border-right: 1px solid $sf-border--grey;
			.sf-post-table__row--grey.sf-post-table__row--head & {
				border-color: #fff;
			}
		}

		&--justify {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
		}
	}
}
