.sf-add-to-cart {
	text-align: center;
	padding: $pad--large 0;
	display: grid;
	row-gap: $pad--small;
	column-gap: $pad--min;

	h2 {
		white-space: nowrap;
		font-size: 20px;
		font-weight: $sf-weight--light;
		margin-bottom: $pad--min;
		padding: 0;
		color: $sf-font-grey;
		width: 100%;
	}

	&__quantity {
		&-control {
			display: inline-flex;
			justify-content: center;

			input,
			&-btn {
				color: $add-to-cart-inputs--color;
				border: 1px solid $add-to-cart-inputs--color;
				background-color: #fff;
				text-align: center;
				height: $add-to-cart-inputs--height;
				width: 55px;
				line-height: $add-to-cart-inputs--line-height;
				font-size: $add-to-cart-inputs--font-size;
				font-weight: $add-to-cart-inputs--font-weight;
			}

			input {
				border-radius: 1000px;

				/* Chrome, Safari, Edge, Opera */
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				&[type="number"] {
					-moz-appearance: textfield;
				}

				position: relative;
				z-index: 1;
			}

			&-btn {
				display: flex;
				justify-content: center;
				justify-items: center;
				align-items: center;
				align-content: center;

				transition: all 0.3s;

				cursor: pointer;

				&:hover {
					background-color: $sf-green--dark;
					border-color: $sf-green--dark;
					color: #fff;
				}

				&[disabled] {
					opacity: 0.5;
					pointer-events: none;
				}

				$quantity-control--btn-shift: 18px;
				$quantity-control--btn-pad: 12px;
				&--increase {
					@include round-corners(1000px, "right");
					position: relative;
					right: $quantity-control--btn-shift;
					padding-left: $quantity-control--btn-pad;
				}
				&--decrease {
					@include round-corners(1000px, "left");
					position: relative;
					left: $quantity-control--btn-shift;
					padding-right: $quantity-control--btn-pad;
				}
			}
		}
	}

	&__price {
		.price {
			font-size: 45px;
			line-height: 42px;
			font-weight: 900;
			color: $sf-font-grey;
			white-space: nowrap;

			&.calculating {
				font-size: 18px;
			}
		}
	}

	&__subscription {
		&-control {
			position: relative; // ensure all children are positioned relative to this
			display: inline-block; // ensure switch is adopts center justification
			width: $add-to-cart-inputs--height * 3;
			height: $add-to-cart-inputs--height;

			label {
				cursor: pointer;
				position: absolute; // fill the parent container
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $add-to-cart-inputs--color;
				border-radius: 1000px; // round the corners
				border: 1px solid $add-to-cart-inputs--color;
				overflow: hidden; // hide overflow of pseudo element acting as ON/OFF switch indicator
				transition: 0.4s;

				// pseudo element acting as ON/OFF switch indicator:
				&:before {
					position: absolute; // position the switch indicator
					content: ""; // required for pseudo elements
					height: $add-to-cart-inputs--height;
					width: 100%;
					left: -1px; // align with left edge of parent container (-1px to offset border)
					top: -1px; // align with top edge of parent container (-1px to offset border)

					border-radius: 1000px; // round the corners
					background-color: #fff;
					border: 1px solid $add-to-cart-inputs--color;

					transition: 0.4s;
					transform: translate(-35%); // move switch to left
				}
				&:hover:before {
					transform: translate(-25%); // move switch right slightly on hover
				}
			}

			input[type="checkbox"] {
				display: none; // hide the checkbox input

				// Switch ON state styles:
				&:checked + label {
					background-color: $sf-green;
					&:before {
						transform: translate(35%); // move switch to right
					}
					&:hover:before {
						transform: translate(25%); // move switch left slightly on hover
					}
				}
			}
		}
	}

	&__submit {
		padding: $pad--min 0 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		align-content: flex-end;

		button {
			@include pill-btn--fill($bg: $sf-green, $elongate: 4);
			font-size: $add-to-cart-inputs--font-size;
			font-weight: $add-to-cart-inputs--font-weight;
			white-space: nowrap;
			width: 100%;

			max-width: 300px;
			.sf-add-to-cart--subscription-enabled & {
				@include respond-to-min(500px) {
					max-width: 200px;
					@include respond-to-min(lg) {
						max-width: 300px;
						@include respond-to-min(xl) {
							max-width: unset;
						}
					}
				}
			}
		}
	}

	// media queries for when subscription is enabled:
	&--subscription-enabled {
		@include respond-to-min(500px) {
			grid-template-columns: 1fr 1fr;

			.sf-add-to-cart__submit {
				order: 6;
			}

			.sf-add-to-cart__currency {
				order: 5;
			}

			@include respond-to-min(md) {
				grid-template-columns: 1fr 1fr 1fr;

				.sf-add-to-cart__price {
					order: 5;
				}

				@include respond-to-min(lg) {
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
					grid-template-areas:
						". . . . ."
						". . . submit submit";

					@include respond-to-max(xl) {
						.sf-add-to-cart__submit {
							grid-area: submit;
						}
					}

					@include respond-to-min(xl) {
						grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
						column-gap: $pad--small;
						row-gap: 0;
					}
				}
			}
		}
	}

	&--subscription-disabled {
		@include respond-min-to-max(500px, md) {
			grid-template-columns: 1fr 1fr;

			.sf-add-to-cart__submit {
				grid-column: 1 / -1; // span all columns
				button {
					max-width: 300px;
				}
			}
		}
		@include respond-min-to-max(md, lg) {
			grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
			grid-template-areas:
				". quantity . price-per-user price-total ."
				". currency . submit submit .";

			.sf-add-to-cart__quantity {
				grid-area: quantity;
			}
			.sf-add-to-cart__submit {
				grid-area: submit;
			}
			.sf-add-to-cart__currency {
				grid-area: currency;
			}
			.sf-add-to-cart__price {
				&--per-user {
					grid-area: price-per-user;
				}
				&--total {
					grid-area: price-total;
				}
			}
		}
		@include respond-to-min(lg) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}
}
