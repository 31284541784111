//@import "../config/config";

///*=====================================================================</editor-fold>=
// PRICING
///=====================================================================<editor-fold>=*/

.pricing-features--multiple {
	padding: 100px 0 40px;
}

.pricing-features--multiple.licence-pricing {
	padding: 50px 0;
}

.pricing-features--multiple_left h3 {
	font-size: 31px;
	color: $sf-font-grey;
	margin-bottom: 15px;
}

.pricing-features--multiple_left p {
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 28px;
}

.pricing-features--multiple_right .licence-amount {
	*zoom: 1;
	float: left;
	clear: none;
	width: 22.75%;
	margin-left: 0%;
	margin-right: 3%;
	text-align: center;
}

.pricing-features--multiple_right .licence-amount:before {
	content: "";
	display: table;
}

.pricing-features--multiple_right .licence-amount:after {
	content: "";
	display: table;
	clear: both;
}

.pricing-features--multiple_right .licence-amount:nth-child(4n) {
	margin-right: 0%;
	float: right;
}

.pricing-features--multiple_right .licence-amount:nth-child(4n + 1) {
	clear: both;
}

.pricing-features--multiple_right .licence-amount img {
	margin-bottom: 20px;
	max-height: 60px;
}

.pricing-features--multiple_right .licence-amount h3 {
	font-size: 22px;
	color: $sf-font-grey;
}

.pricing-features--multiple_right .licence-amount p {
	font-size: 18px;
	color: $sf-font-grey;
	padding: 15px 0;
	font-weight: $sf-weight--light;
}

.pricing-features--multiple_right .licence-amount p span {
	color: #75b42c;
	font-size: 18px;
}

.pricing-features--multiple_right .licence-amount span {
	font-size: 14px;
	color: #acacac;
}

@media (max-width: 600px) {
	.pricing-features--multiple_right .licence-amount {
		*zoom: 1;
		float: left;
		clear: none;
		width: 48.5%;
		margin-left: 0%;
		margin-right: 3%;
		margin-bottom: 40px;
		text-align: center;
	}

	.pricing-features--multiple_right .licence-amount:before {
		content: "";
		display: table;
	}

	.pricing-features--multiple_right .licence-amount:after {
		content: "";
		display: table;
		clear: both;
	}

	.pricing-features--multiple_right .licence-amount:nth-child(2n) {
		margin-right: 0%;
		float: right;
	}

	.pricing-features--multiple_right .licence-amount:nth-child(2n + 1) {
		clear: both;
	}
}

@media (max-width: 1000px) {
	.pricing-features--multiple_right,
	.pricing-features--multiple_left {
		float: none;
		width: 100%;
	}

	.pricing-features--multiple_right {
		margin-top: 50px;
	}
}

@include respond-to-max("bs-md") {
	.pricing-features--multiple {
		padding: 60px 0 40px;
	}

	.pricing-features--multiple_left {
		padding: 0 20px;
	}

	.pricing-features--multiple_left p {
		padding: 0;
	}
}

//new

.pricing-features--multiple_left {
	&-title {
		display: flex;
		justify-content: space-between;
	}
}

//</editor-fold>
