// SF TODO: refactor:

// SF TODO: are these keyframes actually used anywhere
@keyframes slide-in-from-left {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0%);
	}
}

@keyframes slide-in-from-right {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0%);
	}
}

@mixin accordion_icon($path, $size: 30px, $mob-size: 20px) {
	&::before {
		content: "";
		background: url($path) no-repeat center;
		display: block;
		background-size: contain;
		flex: 0 $mob-size;
		height: $mob-size;
		transform: rotate(0deg);
		transition: transform 0.4s ease;

		@include respond-to-min(bs-md) {
			flex: 0 $size;
			height: $size;
		}

		@content;
	}
}

.accordion {
	&__panel {
		margin: 0 0 1rem;

		&:last-of-type {
			margin: 0;
		}

		&-wrap {
			max-width: $sf-wrap--max-w;
			padding: 0 20px;
			margin: 0 auto;
			width: 100%;
		}

		&--header {
			user-select: none;
			border: 1px solid $color-accent1;
			padding: 15px;
			display: flex;
			align-items: center;
			cursor: pointer;

			@include accordion_icon("../../public/images/accordion/faq-list-icon.svg");

			&-title {
				color: $sf-font-grey;
				font-size: 20px;
				flex: 1;
				margin-left: 10px;
			}
		}

		&--content {
			display: none;

			&-body {
				padding: 20px;
				background: $white;

				&-inner {
					max-width: calc($sf-wrap--max-w - 40px);
					margin: 0 auto;
					padding: 0 10px 0 10px;

					@include respond-to-min(bs-sm) {
						padding: 0 30px 0 30px;
					}

					@include respond-to-min(bs-md) {
						padding: 0 56px 0 56px;
					}
				}

				&.faq-answers--inner_answer {
					margin: 0;
					p {
						font-size: 16px;
					}
				}
			}

			&.panel-transitioning {
				transition: height 0.3s ease;
				height: 0;
				overflow: hidden;
			}

			&.show-panel {
				display: block;
			}
		}

		.outer {
			transition: all 0.5s ease;
		}

		// faq main panels
		&.faqs-main {
			&.active {
				> .outer > .accordion__panel-wrap {
					> .accordion__panel--header {
						border: 1px solid $sf-orange--light;

						@include accordion_icon("../../public/images/accordion/faq-list-icon-close.svg") {
							transform: rotate(180deg);
						}
					}
				}

				.outer {
					box-shadow: 0px 10px 7px -10px rgba(0, 0, 0, 0.31);
					margin-bottom: 10px;
				}

				.accordion__panel--content-body-inner {
					@include respond-to-min(bs-sm) {
						&::before {
							transition: height 0.5s ease;
						}
					}
				}

				&.open {
					.accordion__panel--content-body-inner {
						position: relative;

						@include respond-to-min(bs-sm) {
							&::before {
								height: calc(100% + 50px);
							}
						}
					}
				}
			}

			.accordion__panel--content-body {
				&-inner {
					position: relative;

					@include respond-to-min(bs-sm) {
						&::before {
							content: "";
							position: absolute;
							top: -30px;
							left: 10px;
							background: $sf-orange--light;
							height: 0;
							width: 2px;

							@include respond-to-min(bs-md) {
								left: 30px;
							}
						}
					}
				}
			}
		}

		// faqs sub panels
		&.faqs-sub {
			.accordion {
				&__panel {
					&-wrap {
						padding: 0;
					}

					&--header {
						@include accordion_icon("../../public/images/accordion/faq-list-subcategory-icon.svg");

						&-title {
							font-size: 16px;

							@include respond-to-min(bs-md) {
								font-size: 18px;
							}
						}
					}

					&--content {
						&-body {
							padding: 1rem 10px 0 10px;
						}
					}
				}
			}

			&.active {
				.accordion__panel {
					&-wrap {
						> .accordion__panel--header {
							@include accordion_icon(
								"../../public/images/accordion/faq-list-subcategory-icon-close.svg"
							) {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}
}
