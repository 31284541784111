@mixin triangle($color, $direction, $size: 6px, $position: absolute) {
	position: $position;
	content: "";
	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		border-bottom: unset;
		width: $size * 2;
		height: $size;
	} @else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		border-top: unset;
		width: $size * 2;
		height: $size;
	} @else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		border-right: unset;
		width: $size;
		height: $size * 2;
	} @else if $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		border-left: unset;
		width: $size;
		height: $size * 2;
	}
}

@mixin all-but-last() {
	&:not(:last-child) {
		@content;
	}
}

@mixin all-but-first() {
	&:not(:first-child) {
		@content;
	}
}

@mixin stretch-across-grid() {
	grid-column: 1 / -1;
}

@mixin font-awesome-icon($code) {
	font-family: fontAwesome;
	content: $code;
}

@mixin frog-wallpaper($small: false) {
	@if ($small) {
		background: url("../../public/images/backgrounds/frog-pattern-repeat-double--small.png");
	} @else {
		background: url("../../public/images/backgrounds/frog-pattern-repeat-double.png");
	}
}

@mixin ensureLinkNoUnderline($allowOnHover: false, $force: true) {
	$important: if($force, "!important", "");

	text-decoration: none #{$important};

	&:hover {
		@if ($allowOnHover) {
			text-decoration: underline #{$important};
		} @else {
			text-decoration: none #{$important};
		}
	}
}
///***********************************</editor-fold>*/
// Functions
///**********************************<editor-fold>*/

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}
