//@import "../config/config";

///*=====================================================================</editor-fold>=
// LIST
///=====================================================================<editor-fold>=*/

.faq-list--left {
	float: left;
}

.faq-list--left,
.faq-list--right {
	*zoom: 1;
	clear: none;
	text-align: inherit;
	margin-left: 0%;
	margin-right: 0%;
	max-width: 610px;
	width: 48.5%;
}

.faq-list--left:before,
.faq-list--right:before,
.faq-list--left:after,
.faq-list--right:after {
	content: "";
	display: table;
}

.faq-list--left:after,
.faq-list--right:after {
	clear: both;
}

.faq-list--right {
	float: right;
}

@media (max-width: 800px) {
	.faq-list--left,
	.faq-list--right {
		max-width: none;
		width: 100%;
		float: none;
	}
}

.faq-list--box {
	background: #fff;
	padding: 30px;
	margin-bottom: 50px;
	overflow: hidden;
}

.faq-list--box h3 {
	color: $sf-font-grey;
	font-size: 26px;
	margin-bottom: 25px;
	display: block;
}

.faq-list--box a {
	color: #75b42c;
	text-decoration: underline;
	clear: both;
	float: left;
	margin-bottom: 7px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.faq-list--box a:hover {
	color: #599f08;
}

@media (max-width: 600px) {
	.faq-list--left,
	.faq-list--right {
		float: none;
		width: 100%;
	}
}
//</editor-fold>
