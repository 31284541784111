// Margins

$gutter: 2rem;

// Widths

$max-content-width: 1024px;


///***********************************</editor-fold>*/
// MIXINS
///**********************************<editor-fold>*/

@function third($no: 1) {
  @return (100/3 * $no) * 1%;
}
