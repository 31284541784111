.payment-methods-table {
	td.woocommerce-PaymentMethod--details {
		// white-space: normal !important;
		min-width: 130px; // prevent card number going on to multiple lines
	}

	a.button {
		color: #fff !important;
		margin: 2px;
		&.default,
		&.save {
			@include pill-btn--fill($bg: $sf-green, $padding: 5px, $elongate: 6);
		}
		&.delete {
			@include pill-btn--fill($bg: $sf-color--error, $padding: 5px, $elongate: 6);
		}
		&.edit {
			@include pill-btn--fill($bg: $sf-blue--light, $padding: 5px, $elongate: 6);
		}
	}
	a.cancel-edit {
		line-height: 1em !important; // match other buttons
		display: inline-block; // match other buttons
		color: #fff !important;
		margin: 2px;
		@include pill-btn--fill($bg: $sf-color--error, $padding: 5px, $elongate: 6);
	}
}

// add new payment method button on my-account/payment-methods:
a.add-new-payment-method {
	color: #fff !important;
	@include pill-btn--fill($sf-green);
	display: inline-block;
}

// add payment method UI on my-account/add-payment-method:
#add_payment_method {
	ul {
		li.woocommerce-PaymentMethod {
			padding: $pad--min 0;

			border-top: 1px solid $sf-border--grey;
			&:last-child {
				border-bottom: 1px solid $sf-border--grey;
			}

			label {
				cursor: pointer; // indicate label is clickable
			}
			.input-radio {
				display: none; // hide 0 width/height radio input causing layout disruption
			}
		}

		.payment_box > p {
			display: none; // hide Sandbox messages
		}
	}
}
