.wrap-account {
	form:not(.assign-user__form) {
		.form-row {
			@include checkoutFormRow();

			@include respond-to-max(700px) {
				width: 100%;
			}
		}

		.sf-change-password {
			border: 1px solid $checkout-border-grey;
			padding: 20px $pad--min 0;
			position: relative;
			margin: $pad--min * 3 0 $pad--min;
			&__legend {
				position: absolute;
				left: $pad--min;
				top: 0;
				transform: translateY(-50%);
				padding: 10px $pad--min;
				color: #fff;
				background-color: $sf-green;
			}
		}

		button[type="submit"] {
			@include pill-btn--fill($sf-blue--light, #fff !important, $sf-blue--dark);
			margin-top: $pad--min;
		}
	}
}
