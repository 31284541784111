.sf-scroll-to-nav {
	max-height: calc(100vh - #{$pad--small});
	overflow-y: auto;
	&__link {
		display: block;
		padding: 10px;
		&:not(:first-child) {
			margin-top: $pad--min;
		}
		font-size: 18px;
		font-weight: $sf-weight--med;
		cursor: pointer;
		color: $sf-font-grey;

		//default
		@include border(3px solid $sf-green, left);

		&--green {
			@include border(3px solid $sf-green, left);
			.sf-scroll-to-nav--learn-seo & {
				@include border(3px solid $learnSEO-green, left);
			}
		}

		&--blue {
			@include border(3px solid $sf-blue--light, left);
			.sf-scroll-to-nav--learn-seo & {
				@include border(3px solid $learnSEO-blue, left);
			}
		}

		&--orange {
			@include border(3px solid $sf-orange--light, left);
			.sf-scroll-to-nav--learn-seo & {
				@include border(3px solid $learnSEO-orange, left);
			}
		}

		&:hover {
			border-left-width: 6px;
		}
	}
}
