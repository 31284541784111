//@import "../config/config";

///*=====================================================================</editor-fold>=
// USER GUIDES
///=====================================================================<editor-fold>=*/

.user-guides {
	padding: 40px 0;
	background: #f8f8f8;
}

.user-guides--inner {
	padding: 50px 0;
}

.faq-answers--inner_answer ol li a,
.faq-answers--inner_answer ul li a {
	color: #75b42c;
	border-bottom: 1px solid #75b42c;
}

.faq-answers--inner_answer ul li:before,
.faq-answers--inner_answer ol li:before {
	content: "";
	width: 10px;
	height: 10px;
	border: 2px solid #75b42c;
	border-radius: 100px;
	display: block;
	position: absolute;
	left: 0;
	top: 9px;
}

.faq-answers--inner_answer > div ul li,
.faq-answers--inner_answer > div ol li {
	font-size: 16px;
	color: $sf-font-grey;
	line-height: 26px;
	font-weight: $sf-weight--light;
}

@include respond-to-max("bs-md") {
	.user-guides,
	.user-guides .wrap {
		padding: 0;
	}
}
//</editor-fold>
