@mixin notification-message($border-color: $sf-color--notice) {
	border-left: 3px solid $border-color;
	padding: $pad--min;
	margin: $pad--min 0;
	overflow: hidden;
	text-align: left;
	font-size: 15px;
	line-height: 20px;
	color: $sf-font-grey;
	animation-name: fade-in;

	p,
	li {
		margin-bottom: 0 !important;
		animation-duration: 1s;
		animation-name: fade-in-left;
	}
}
