@mixin sf-list($bullet-color: $sf-green) {
	ol,
	ul {
		margin-top: $pad--small;
		margin-bottom: $pad--small;
		@content;

		li {
			@include p();
			line-height: 28px;
			position: relative;
			font-weight: $sf-weight--light;
			margin-bottom: 0.7em;
		}
	}

	ul {
		padding: 0 0 0 20px;
		> li {
			padding-left: 28px; // match line-height
			&:before {
				border: 2px solid $bullet-color;
				border-radius: 1000px;
				content: "";
				width: 10px;
				height: 10px;
				display: block;
				position: absolute;
				left: 0;
				top: 9px;
			}
		}
	}

	ol {
		margin-left: 33px; // random number but makes it align with the ul
		padding: 0;

		> li {
			// margin: 0;
			padding-left: 15px; // random number but makes the content align with content in a ul
			&::marker {
				@include p($bullet-color, $sf-weight--heavy);
			}
		}
	}
}
