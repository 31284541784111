//@import "../config/config";

///*=====================================================================</editor-fold>=
// ERRORS
///=====================================================================<editor-fold>=*/

// SF TODO: check for usage and remove?
.error-wrap {
	@include frog-wallpaper();
	padding: 190px 20px;
	display: table;
	width: 100%;
}

.error-wrap--inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-weight: $sf-weight--light;
}

.error-wrap--inner h1 {
	color: #fff;
	font-size: 170px;
	line-height: 110px;
	margin-bottom: 15px;
	font-weight: $sf-weight--light;
}

.error-wrap--inner p {
	color: #d1ff9b;
	max-width: 665px;
	margin: 0 auto;
	line-height: 28px;
}

//</editor-fold>
