.sf-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
	padding: $pad--small 0;

	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; // Fill modal container
		background: rgba(#fff, 0.8); // Semi-transparent white background
		z-index: -1; // Place it behind the dialog
	}

	&__dialog {
		max-height: calc(100vh - calc($pad--small * 2));
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
	}

	&__close {
		font-size: 25px;
		padding: 5px;
		background-color: transparent;
		color: $sf-grey--light;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 1;
		i {
			transition: all 0.3s ease;
			transform: scale(1) rotate(0);
			display: inline-block;
		}
		&:hover {
			color: $sf-grey;
			i {
				transform: scale(1.1) rotate(90deg);
			}
		}
	}
}
