.sf-download-dialog {
	width: 100%;
	max-width: 400px;
	@include respond-to-min(400px) {
		min-width: 400px;
	}
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	padding: $pad--small;
	position: relative;
	&__mobile-message {
		@include h3();
	}
	&__os {
		// hide/show correct download links
		display: none;
		@each $os in ("windows", "mac", "linux") {
			[data-downloadlinks="#{$os}"] & {
				&--#{$os} {
					display: block;
				}
			}
		}
	}

	&__links {
		a {
			display: block;
			font-size: $sf-font-size--standard;
			&:not(:last-child) {
				margin-bottom: $pad--min;
			}
			@include pill-btn--fill($bg: $sf-green, $bg-hover: $sf-green--dark);
		}
	}

	&__title {
		margin-bottom: $pad--small;
	}

	&__change-os {
		p {
			margin: $pad--small 0 $pad--min;
			// @include p($font-size: $sf-font-size--small);
		}
		select {
			border: $sf-border;
			padding: $pad--min;
			background-color: #fff;
			border-radius: 10px;
		}
		// hide currently selected option from the dropdown
		option {
			@each $os in ("windows", "mac", "linux") {
				[data-downloadlinks="#{$os}"] & {
					&[value="#{$os}"] {
						display: none;
					}
				}
			}
		}
	}
	@include respond-to-min(xl) {
		max-width: 500px;
		min-width: 500px;
		padding: $pad--large;
		&__change-os {
			p {
				margin: $pad--large 0 $pad--min;
			}
		}
		&__title {
			margin-bottom: $pad--small $pad--large;
		}
	}
}
