//@import "../config/config";

///*=====================================================================</editor-fold>=
// CONTACT
///=====================================================================<editor-fold>=*/
$wpcf7-list-item--spacing: 10px;

.contact-wrap {
	background: #f8f8f8;
	padding: 70px 0;

	&--inner {
		margin-bottom: 70px;

		&_left,
		&_right {
			position: relative;
			float: left;
			clear: none;
			text-align: inherit;
			width: 48.5%;
			margin-left: 0%;
			margin-right: 3%;

			@media (max-width: 950px) {
				width: 100%;
			}

			&:last-child {
				margin-right: 0%;
			}
		}

		&_left {
			form {
				// position: relative;

				.wpcf7-response-output {
					margin: 0 0.5em 1em;
					padding: 20px;
					position: absolute;
					bottom: -140px;
					width: 100%;

					@media (max-width: 950px) {
						position: relative;
						bottom: 0;
						clear: both;
						top: 20px;
					}
				}

				input:not([type="submit"]),
				textarea {
					transition: all 0.2s ease-in-out;
				}

				input:not([type="submit"]),
				textarea,
				.service-required--button {
					box-shadow: 0 11px 65px 0 rgba(0, 0, 0, 0.07);
					padding: 30px;
					color: $sf-font-grey;
					font-size: 18px;
					font-family: $sf-font-family;
					margin-bottom: 30px;
					width: 100%;
					background: #fff;
					border: 1px solid transparent;

					&:focus {
						border: 1px solid #bbb;
					}

					&.wpcf7-not-valid {
						border: 1px solid $sf-color--error;
					}
				}

				input[type="submit"] {
					border-radius: 50px;
					text-align: center;
					color: #fff;
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					width: 100%;
					// max-width: 165px;
					// float: right;
					font-family: $sf-font-family;
					background: $sf-green;
					border: 0;
					padding: 14px 0;
					font-size: 15px;
					font-weight: $sf-weight--med;
					cursor: pointer;

					&:hover {
						background: $sf-green--dark;
					}

					// @media (max-width: 600px) {
					// 	margin-top: 20px;
					// }
				}

				.service-required--button {
					cursor: pointer;
					position: relative;

					&:after {
						content: "\f0da";
						font-family: fontAwesome;
						position: relative;
						top: 1px;
						font-size: 20px;
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				.form-msg {
					// float: left;
					color: $sf-font-grey;
					// line-height: 46px;
					margin-bottom: $pad--min;
					@media (max-width: 600px) {
						// float: none;
						// line-height: 1.6;
					}
				}
			}

			.modal-overlay {
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: -2;
				background: rgba(255, 255, 255, 0.8);
				opacity: 0;
				visibility: hidden;
				-webkit-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				display: none;

				&.show {
					z-index: 98;
					visibility: visible;
					opacity: 1;
					display: block;
				}
			}

			.service-required--modal {
				position: fixed;
				top: -50%;
				left: 50%;
				visibility: hidden;
				z-index: 0;
				-webkit-transition: all 0.6s ease-in-out;
				transition: all 0.6s ease-in-out;
				opacity: 0;
				background: #fff;
				box-shadow: 0 11px 65px 0 rgba(0, 0, 0, 0.07);
				max-width: 640px;
				width: 100%;
				margin-left: -320px;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
				max-height: 100%;
				overflow-y: auto;
				display: none;
				animation: slideDownKeepCenter 0.6s ease;

				@media (max-width: 640px) {
					left: 0;
					margin-left: 0;
				}

				&.block {
					display: block;
				}

				&.show {
					visibility: visible;
					opacity: 1;
					z-index: 9999;
					display: block;
					top: 50%;
				}

				&_top {
					p.modal-title {
						font-size: 32px;
						font-weight: $sf-weight--light;
						color: $sf-font-grey;
						padding: 50px;
						border-bottom: 1px solid #e1e1e1;

						@media (max-width: 500px) {
							padding: 30px;
						}
					}

					a.close-modal {
						position: absolute;
						top: 20px;
						right: 20px;

						&:before {
							content: "\f00d";
							font-family: fontAwesome;
							color: #e1e1e1;
						}
					}
				}

				&_bottom {
					padding: 50px;
					@media (max-width: 500px) {
						padding: 30px;
					}

					h2 {
						color: $sf-font-grey;
						font-weight: 900;
						margin-bottom: calc($wpcf7-list-item--spacing / 2);
						&:not(:nth-of-type(1)) {
							margin-top: 20px;
						}
					}

					span.wpcf7-list-item {
						display: block;
						margin: $wpcf7-list-item--spacing 0;

						label span {
							display: block;
							width: 100%;
							border: 1px solid $font-color-default;
							color: $font-color-default;
							padding: 5px;
							cursor: pointer;
							transition: all 0.3s;

							&:hover {
								color: $sushi;
								border: 1px solid $sushi;
							}
						}

						input {
							display: none;

							&:checked + span {
								background-color: $sushi;
								color: #fff;
								border: 1px solid $sushi;
							}
						}
					}

					@include respond-to-min(600px) {
						.wpcf7-form-control {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
						}

						span.wpcf7-list-item {
							width: calc(50% - #{calc($wpcf7-list-item--spacing / 2)});
							margin: calc($wpcf7-list-item--spacing) / 2 0;
						}
					}
				}

				&_accept {
					border-top: 1px solid #e1e1e1;
					padding: 40px;

					.accept {
						border-radius: 50px;
						text-align: center;
						color: #fff;
						font-weight: $sf-weight--heavy;
						-webkit-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						width: 100%;
						max-width: 175px;
						padding: 14px 0;
						display: block;
						margin: 0 auto;
						background: #75b42c;

						&:hover {
							background: #599f08;
						}
					}
				}
			}
		}

		&_right {
			@media (max-width: 950px) {
				margin-top: 40px;
			}

			&-info {
				background: #75b42c;
				padding: 60px;

				h3 {
					font-size: 32px;
					color: #fff;
					margin-bottom: 20px;
					font-weight: $sf-weight--light;
				}

				ul li {
					font-weight: $sf-weight--light;
					color: #d1ff9b;
					line-height: 28px;
					font-size: 16px;
				}

				@media (max-width: 500px) {
					padding: 60px 30px;
				}

				&-left {
					width: 45%;
					float: left;

					@media (max-width: 600px) {
						width: 100%;
						float: none;
					}
				}

				&-right {
					width: 55%;
					float: right;

					span {
						font-weight: $sf-weight--med;
						color: #fff;
						line-height: 27px;
					}

					a,
					b {
						color: #d1ff9b;
						font-weight: $sf-weight--light;
						display: block;
						clear: both;
					}

					a:first-child {
						margin-bottom: 10px;
					}

					@media (max-width: 600px) {
						width: 100%;
						float: none;
						margin-top: 20px;
					}
				}
			}

			&-map {
				margin-top: 30px;
				padding: 10px;
				background: #fff;

				#map {
					height: 470px;
				}
			}
		}
	}
}
//</editor-fold>
