//@import "../config/config";

///*=====================================================================</editor-fold>=
// COMMENTS AND RESPONSES
///=====================================================================<editor-fold>=*/

#comments {
	background: #fff;
	margin-bottom: 70px;
}

#comments h4 {
	padding: 40px;
	border-bottom: 1px solid #e5e5e5;
	font-size: 30px;
	color: $sf-font-grey;
}

@media (max-width: 500px) {
	#comments h4 {
		padding: 40px 25px;
	}
}

#comments #respond {
	box-shadow: none;
	border: 1px solid #e5e5e5;
	overflow: hidden;
}

#comments .respond #cancel-comment-reply-link {
	display: block;
}

#comments .clearfix {
	pointer-events: none;
}

#comments ul.commentlist .comm {
	padding: 25px 0;
	position: relative;
}

#comments ul.commentlist .comm .avatar {
	float: left;
	width: 100px;
}

#comments ul.commentlist .comm .avatar img {
	border-radius: 150px;
	width: 100px;
	height: auto;
}

#comments ul.commentlist .comm .comment-meta {
	float: right;
	width: calc(100% - 100px);
	padding-left: 30px;
}

#comments ul.commentlist .comm .comment-meta .comhead {
	margin-bottom: 15px;
	font-size: 20px;
	color: $sf-font-grey;
	padding-right: 70px;
}

#comments ul.commentlist .comm .comment-meta .comhead span {
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	font-size: 15px;
	margin-left: 10px;
	display: inline-block;
}

#comments ul.commentlist .comm .comment-meta .comhead a {
	font-size: 20px;
	color: $sf-font-grey;
}

#comments ul.commentlist .comm .comment-meta .says p {
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	font-size: 16px;
	line-height: 25px;
}

#comments ul.commentlist .comm .comment-meta .says p a {
	color: #75b42c;
	border-bottom: 1px solid #75b42c;
}

#comments ul.commentlist .comm .comment-meta .comment-reply-link {
	position: absolute;
	top: 20px;
	right: 0;
	font-size: 14px;
	color: #8d8b86;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

#comments ul.commentlist .comm .comment-meta .comment-reply-link:before {
	content: "\f112";
	font-family: fontAwesome;
	margin-right: 10px;
}

#comments ul.commentlist .comm .comment-meta .comment-reply-link:hover {
	color: #75b42c;
}

@media (max-width: 600px) {
	#comments ul.commentlist .comm .comhead {
		padding-right: 0;
	}

	#comments ul.commentlist .comm .avatar {
		float: none;
	}

	#comments ul.commentlist .comm .comment-meta {
		float: none;
		width: 100%;
		padding-left: 0;
		margin-top: 10px;
	}
}

#comments ul.commentlist > li {
	border-bottom: 1px solid #e5e5e5;
	padding: 20px 40px;
	overflow: hidden;
}

#comments ul.commentlist > li > ul > li {
	padding-left: 130px;
}

#comments ul.commentlist > li > ul > li .comm {
	padding-top: 5px;
}

#comments ul.commentlist > li > ul > li > ul > li {
	padding-left: 130px;
}

#comments ul.commentlist > li > ul > li > ul > li .comm {
	padding-top: 5px;
}

@media (max-width: 600px) {
	#comments ul.commentlist > li > ul > li,
	#comments ul.commentlist > li > ul > li > ul > li {
		padding-left: 30px;
	}
}

@media (max-width: 500px) {
	#comments ul.commentlist > li {
		padding: 20px 25px;
	}
}

#respond,
.respond {
	background: #fff;
	margin-bottom: 70px;
	position: relative;
}

#respond h4,
.respond h4 {
	padding: 40px;
	border-bottom: 1px solid #e5e5e5;
	font-size: 30px;
	color: $sf-font-grey;
}

#respond form,
.respond form {
	padding: 40px;
}

#respond form:after,
.respond form:after {
	display: table;
	content: "";
	line-height: 0;
	clear: both;
}

#respond form input,
.respond form input,
#respond form textarea,
.respond form textarea {
	border: 1px solid #e5e5e5;
	background: #fff;
	width: 100%;
	padding: 15px 20px;
	font-family: $sf-font-family;
	margin-bottom: 35px;
	font-size: 15px;
	font-weight: $sf-weight--light;
}

#respond form input.comment-name,
.respond form input.comment-name,
#respond form textarea.comment-name,
.respond form textarea.comment-name,
#respond form input.comment-email,
.respond form input.comment-email,
#respond form textarea.comment-email,
.respond form textarea.comment-email {
	width: 48%;
}

#respond form input.comment-email,
.respond form input.comment-email,
#respond form textarea.comment-email,
.respond form textarea.comment-email {
	float: right;
}

@media (max-width: 600px) {
	#respond form input.comment-name,
	.respond form input.comment-name,
	#respond form textarea.comment-name,
	.respond form textarea.comment-name,
	#respond form input.comment-email,
	.respond form input.comment-email,
	#respond form textarea.comment-email,
	.respond form textarea.comment-email {
		width: 100%;
		float: none;
	}
}

#respond form input.submit-comment,
.respond form input.submit-comment,
#respond form textarea.submit-comment,
.respond form textarea.submit-comment {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 195px;
	background: #75b42c;
	display: block;
	font-weight: $sf-weight--med;
	margin-bottom: 40px;
	float: right;
	border: 0;
	cursor: pointer;
}

#respond form input.submit-comment:hover,
.respond form input.submit-comment:hover,
#respond form textarea.submit-comment:hover,
.respond form textarea.submit-comment:hover {
	background: #599f08;
}

#respond form .logged-in,
.respond form .logged-in {
	position: absolute;
	bottom: 58px;
	color: $sf-font-grey;
}

#respond form .logged-in a,
.respond form .logged-in a {
	color: #75b42c;
}

#respond form .logged-in a span,
.respond form .logged-in a span {
	margin-left: 10px;
}

@media (max-width: 600px) {
	#respond form .logged-in,
	.respond form .logged-in {
		bottom: 32px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}
}

@media (max-width: 500px) {
	#respond form,
	.respond form,
	#respond h4,
	.respond h4 {
		padding: 40px 25px;
	}
}

#respond #cancel-comment-reply-link,
.respond #cancel-comment-reply-link {
	color: #d73232;
	position: absolute;
	top: 50px;
	right: 40px;
}

#respond #cancel-comment-reply-link:before,
.respond #cancel-comment-reply-link:before {
	content: "";
	background: url("../../public/images/close-comments.png") no-repeat;
	display: block;
	width: 20px;
	height: 20px;
	float: left;
	background-size: 100%;
	margin: -2px 7px 0 0;
}

.respond #cancel-comment-reply-link {
	display: none;
}

//</editor-fold>
