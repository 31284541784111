.sf-social-share {
	display: flex;
	justify-content: flex-start;
	justify-items: flex-start;
	align-content: center;
	align-items: center;
	&__btn {
		margin-right: 10px;
		@each $social, $value in $social-colors {
			&--#{$social} {
				$light-color: map-deep-get($social-colors, "#{$social}", "light");
				$dark-color: map-deep-get($social-colors, "#{$social}", "dark");

				background-color: $light-color;
				padding: 5px 10px;
				border-radius: 5px;
				transition: all 0.3s ease-in-out;
				text-decoration: none !important;
				@include p(#fff !important, $font-size: $sf-font-size--standard);
				display: flex;
				justify-content: center;
				justify-items: center;
				align-content: center;
				align-items: center;
				&:hover {
					background-color: $dark-color;
				}
				i {
					margin-right: 5px;
				}
			}
		}
	}
}
