.sf-offerings {
	padding: $pad--small;
	text-align: center;
	&__header {
		max-width: $sf-max-p-width;
		margin: 0 auto;
		h2 {
			@include h2();
		}
		p {
			@include p();
			margin-top: $pad--min;
			a {
				@include link();
			}
		}
		@include respond-to-min(xl) {
			padding: $pad--small $pad--small 0;
		}
	}

	&__icon {
		width: 100px;
		&:not(.animated) {
			opacity: 0;
		}
	}

	&__grid {
		padding-top: $pad--small;
		display: grid;
		row-gap: $pad--small;
		grid-template-columns: repeat(1, 1fr);

		@include respond-to-min(lg) {
			grid-template-columns: repeat(2, 1fr);
			column-gap: $pad--large;
			padding: $pad--small;
			@include respond-to-max(xl) {
				@each $n in (3, 9) {
					&--#{$n}-items {
						.sf-offerings__grid-item {
							//center any items that are left alone on a single row when the grid is 2 columns wide
							&:nth-child(#{$n}n + #{$n}) {
								grid-column: span 2;
							}
						}
					}
				}

				&-item {
					//every 2nd item:
					&:nth-child(even) {
						.sf-offerings__icon {
							animation-delay: 200ms;
						}
					}
				}
			}
			@include respond-to-min(xl) {
				grid-template-columns: repeat(3, 1fr);
				&-item {
					//every 2nd item on each row
					&:nth-child(3n + 2) {
						.sf-offerings__icon {
							animation-delay: 200ms !important;
						}
					}
					//every 3rd item on each row
					&:nth-child(3n + 3) {
						.sf-offerings__icon {
							animation-delay: 400ms !important;
						}
					}
				}
			}
		}

		&-item {
			max-width: 500px;
			margin: 0 auto;
			h3 {
				@include h3();
				margin-bottom: $pad--min;
			}
			p {
				@include p();
			}
			a {
				@include link();
			}
		}
	}

	&--white-text {
		* {
			color: white !important;
		}
	}
}
