// SF TODO: refractor/edit for new account layout:
.theme-screamingfrog {
	.wrap-account {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: stretch;
		align-items: stretch;
		border: 1px solid #d6d6d6;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
		padding: 0;
		margin: 40px 0;
		background-color: #fff;
	}

	.wrap-account .woocommerce-MyAccount-content {
		padding: 30px;
		width: 100%;
	}

	.wrap-account .woocommerce-MyAccount-content {
		a {
			color: #75b42c;

			// &:hover {
			// 	// color: #000;
			// }
		}

		.account-wrap--inner_licence {
			> a {
				color: #fff;

				:hover {
					color: #fff;
				}
			}
		}
	}

	.wrap-account .woocommerce-MyAccount-content p {
		color: $sf-font-grey;
		margin-bottom: 15px;
	}

	.woocommerce-MyAccount-content .woocommerce-notices-wrapper {
		margin-bottom: 25px;

		// collapse if no content:
		&:empty {
			margin-bottom: 0;
			display: none;
		}
	}

	@include respond-to-max("bs-md") {
		.wrap-account .woocommerce-MyAccount-content {
			padding: 20px;
		}
	}
}
