/**
  * Helper classes to make easy adjustments to content (mainly via wordPress)
  */

.sf-font-size {
	font-size: $sf-font-size--standard !important;
	&--x-small {
		font-size: $sf-font-size--x-small !important;
	}
	&--small {
		font-size: $sf-font-size--small !important;
	}
	&--standard {
		font-size: $sf-font-size--standard !important;
	}
	&--large {
		font-size: $sf-font-size--large !important;
	}
	&--x-large {
		font-size: $sf-font-size--x-large !important;
	}
}
