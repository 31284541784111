.sf-team--dialog {
	//overrides .sf-team styles from /blocks/_sf-team.scss
	padding: $pad--small;
	width: 100%;
	max-width: 500px;
	.sf-team__member {
		h2 {
			@include h3();
		}
		h3 {
			margin-bottom: $pad--small;
		}
		p {
			display: block;
			text-align: left;
		}

		&-image {
			pointer-events: none;
		}
	}

	@include respond-to-min(500px) {
		min-width: 500px;
		@include respond-to-min(xl) {
			max-width: 600px;
			min-width: 600px;
			padding: $pad--large;
		}
	}
}
