.sf-issues-key {
	display: grid;
	column-gap: $pad--min;
	grid-template-rows: auto;

	&__section {
		display: grid;
		grid-template-rows: auto;
		column-gap: $pad--min;
		row-gap: $pad--min;
		&-title {
			margin: $pad--small 0 0;
			@include h3();
		}
	}

	&__item {
		border: $sf-border;
		padding: $pad--min;
		display: grid;
		grid-template-columns: 20px 1fr;
		grid-template-rows: auto;
		column-gap: $pad--min;
		row-gap: $pad--min;
		align-items: center;
		img {
			width: 100%; // ensure on IOS to not expand beyond containter
		}
		p {
			@include p();
		}
	}

	@include respond-to-min(md) {
		grid-template-columns: 1fr 1fr;

		@include respond-to-min(lg) {
			grid-template-columns: auto auto;
			&__section {
				grid-template-areas: "title title title" ". . .";
				grid-template-columns: auto auto auto;
				&-title {
					grid-area: title;
				}
			}
		}
	}
}
