//@import "../config/config";

///*=====================================================================</editor-fold>=
// OLD
///=====================================================================<editor-fold>=*/
// SF TODO: Remove?
.wrap.spider-tesimonals {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

//</editor-fold>
