.sf-homepage-hero {
	background-color: $sf-green;
	width: 100%;
	position: relative;
	padding: $pad--large 0;
	overflow: hidden;

	// background: url('../../public/images/home-page/hero-graph.svg') no-repeat
	// 	center bottom;

	&__graph {
		position: absolute;
		bottom: -5px;
		width: 100%;
		height: 100%;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			top: 0;
			width: 120%;
			background: linear-gradient(90deg, transparent 0%, $sf-green 20%);
			transition-delay: 2s;
			transition: all 3s ease-in-out;
			transform: translateX(0);
		}

		&--revealed {
			&:after {
				transform: translateX(100%);
			}
		}

		svg {
			background: linear-gradient(90deg, $sf-green 50%, rgba(154, 199, 91, 0.22) 50%);
			width: 100%;
			max-height: calc(100% + 35px);
			position: absolute;
			bottom: 0;

			.st0 {
				fill: $sf-green;
			}

			.st1 {
				fill: none;
				stroke: #9ac75b;
				stroke-width: 3;
				stroke-miterlimit: 10;
			}

			.st2 {
				opacity: 0.22;
				fill: #9ac75b;
				enable-background: new;
			}

			.st3 {
				fill: #82b145;
				stroke: #9ac75b;
				stroke-width: 3;
				stroke-miterlimit: 10;
			}

			.st4 {
				fill: #82b145;
				stroke: #9ac75b;
				stroke-width: 2;
				stroke-miterlimit: 10;
			}

			.st5 {
				fill: #ffffff;
				opacity: 0;
				transition: all 0.3s ease;
			}

			.st6 {
				opacity: 0;
				fill: #ffffff;
				enable-background: new;
				transition: all 0.6s ease;
			}

			.sf-homepage-hero__graph-dots--white > g {
				cursor: pointer;
				&:hover {
					> .st5 {
						opacity: 1;
					}
					> .st6 {
						opacity: 0.1;
					}
				}
			}
		}

		//use bg image until screen large enough for svg:
		@include respond-to-max(lg) {
			background-image: backgroundImageURL("home-page/hero-graph.png");
			background-repeat: no-repeat;
			background-size: 100% 70%;
			background-position: bottom;
			svg {
				display: none;
			}
		}
	}

	&__title {
		text-align: center;
		margin: 0 auto;
		max-width: 1150px;
		line-height: 40px;
		@include h1();

		span {
			position: relative;
		}
	}

	&__btns {
		margin: calc($pad--large / 2) 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		a {
			position: relative;
			margin: $pad--min $pad--min * 1.5;
			display: block;
			@include pill-btn--fill($sf-orange--light, $elongate: 3);
			font-weight: 600;
		}
	}
}
