//@import "../config/config";

///*=====================================================================</editor-fold>=
// BILLING FORM
///=====================================================================<editor-fold>=*/

#wpcobillingform .eu-vat .globaloptions h3,
#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info {
	margin-top: 30px;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info p {
	color: $sf-font-grey;
	max-width: 620px;
	line-height: 28px;
	font-weight: $sf-weight--light;
	clear: both;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info label {
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	font-size: 16px;
	color: #616161;
	font-weight: $sf-weight--med;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info label:before {
	content: "\f10c";
	font-family: fontAwesome;
	position: absolute;
	top: 1px;
	left: 0;
	color: #616161;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info label[for="residencebox"] {
	margin: 20px 0;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info input:checked + label:before {
	content: "\f05d";
	font-family: fontAwesome;
	color: #616161;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info #business table.wpco tr td,
#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info #consumer table.wpco tr td {
	border: 0;
	padding: 0 0 0 25px !important;
}

#wpcobillingform
	.eu-vat
	.globaloptions
	.stepsholder.billing-info
	#business
	table.wpco
	tr
	td
	.country-code-wrap,
#wpcobillingform
	.eu-vat
	.globaloptions
	.stepsholder.billing-info
	#consumer
	table.wpco
	tr
	td
	.country-code-wrap {
	display: block;
	margin: 20px 0;
}

#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info #business table.wpco tr td input,
#wpcobillingform .eu-vat .globaloptions .stepsholder.billing-info #consumer table.wpco tr td input {
	font-size: 18px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	display: inline-block;
	float: left;
	width: 100%;
	padding: 13px 15px;
	border: 1px solid #e1e1e1;
	max-width: 500px;
}

#wpcobillingform
	.eu-vat
	.globaloptions
	.stepsholder.billing-info
	#business
	table.wpco
	tr
	td
	input.country-code,
#wpcobillingform
	.eu-vat
	.globaloptions
	.stepsholder.billing-info
	#consumer
	table.wpco
	tr
	td
	input.country-code {
	margin-right: -1px !important;
	text-align: center;
}

#wpcobillingform .eu-vat #business table.wpco {
	float: none;
}

table.wpco tbody tr td {
	word-break: break-all;
}

table.wpco_orders tbody tr td {
	font-size: 12px !important;
}
//</editor-fold>
