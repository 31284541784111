//@import "../config/config";

///*=====================================================================</editor-fold>=
// FOOTER
///=====================================================================<editor-fold>=*/

footer {
	position: relative;
}

footer .footer-wrap {
	background: $sf-grey--dark;
	padding: 85px 0;
}

@media (max-width: 940px) {
	footer .footer-wrap {
		padding: 45px 0;
	}
}

@media (max-width: 615px) {
	footer .footer-wrap {
		padding: 30px 0;
	}
}

footer .footer-wrap .footer-inner > div {
	float: left;
	display: inline-block;
	padding-right: 25px;
}

footer .footer-wrap .footer-inner > div:first-child {
	width: 21%;
}

footer .footer-wrap .footer-inner > div:nth-child(2) {
	width: 30%;
}

footer .footer-wrap .footer-inner > div:nth-child(3) {
	width: 25%;
}

footer .footer-wrap .footer-inner > div:last-child {
	width: 24%;
	padding-right: 0;
}

@media (max-width: 940px) {
	footer .footer-wrap .footer-inner > div:first-child {
		width: 50%;
	}

	footer .footer-wrap .footer-inner > div:nth-child(2) {
		width: 50%;
	}

	footer .footer-wrap .footer-inner > div:nth-child(3) {
		clear: left;
		width: 50%;
		margin-top: 50px;
	}

	footer .footer-wrap .footer-inner > div:last-child {
		width: 50%;
		margin-top: 50px;
	}
}

@media (max-width: 615px) {
	footer .footer-wrap .footer-inner > div:first-child,
	footer .footer-wrap .footer-inner > div:nth-child(2),
	footer .footer-wrap .footer-inner > div:nth-child(3),
	footer .footer-wrap .footer-inner > div:last-child {
		width: 100%;
		margin-top: 20px;
		clear: both;
		float: none;
	}
}

footer .footer-wrap .footer-inner h3 {
	font-size: 22px;
	color: #fff;
	font-weight: $sf-weight--med;
	margin-bottom: 20px;
	margin-top: 5px;
}

footer .footer-wrap .footer-inner h3.footer-subhead {
	margin-top: 25px;
}

footer .footer-wrap .footer-inner h3.about-us-footer {
	margin-bottom: 15px;
}

footer .footer-wrap .footer-inner p {
	color: #a3a3a3;
	line-height: 30px;
	font-weight: $sf-weight--light;
}

footer .footer-wrap .footer-inner .footer-disclaimer {
	color: #a3a3a3;
	text-decoration: underline;
	display: block;
	margin: 15px 0 30px;
}

footer .footer-wrap .footer-inner form.search {
	position: relative;
}

footer .footer-wrap .footer-inner form.search input[type="text"] {
	background: 0 0;
	border: 1px solid #636363;
	display: block;
	width: 100%;
	margin-top: 25px;
	color: #acacab;
	font-size: 15px;
	padding: 20px 20px 20px 55px;
	font-family: $sf-font-family;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

footer .footer-wrap .footer-inner form.search input[type="text"]:focus {
	border-color: #bbb;
}

footer .footer-wrap .footer-inner form.search input.search-image {
	position: absolute;
	top: 20px;
	left: 18px;
	max-width: 18px;
}

footer .footer-wrap .footer-inner form.search input.search-image:before {
	font-family: fontAwesome;
	content: "\f002";
	display: block;
}

footer .footer-wrap .footer-inner ul li {
	margin-bottom: 12px;
	color: #a3a3a3;
	font-weight: $sf-weight--light;
}

footer .footer-wrap .footer-inner ul li a {
	color: #a3a3a3;
	font-weight: $sf-weight--light;
	position: relative;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	text-decoration: underline;
}

footer .footer-wrap .footer-inner ul li a:hover {
	color: $sf-green;
}

footer .footer-wrap .footer-inner ul.footer-guides li {
	overflow: hidden;
}

footer .footer-wrap .footer-inner ul.footer-guides li a {
	float: right;
	width: calc(100% - 30px);
}

footer .footer-wrap .footer-inner ul.footer-guides li:before {
	content: "\f0f6";
	font-family: fontAwesome;
	margin-right: 12px;
}

footer .footer-wrap .footer-inner ul.contact-details {
	margin-top: 35px;
}

footer .footer-wrap .footer-inner ul.contact-details li {
	color: #fff;
}

footer .footer-wrap .footer-inner ul.contact-details li:before {
	font-family: fontAwesome;
	display: inline-block;
	margin: 0 12px 0 0;
	font-size: 16px;
	position: relative;
	top: 2px;
}

footer .footer-wrap .footer-inner ul.contact-details li.contact-details--telephone:before {
	content: "";
}

footer .footer-wrap .footer-inner ul.contact-details li.contact-details--fax:before {
	content: "";
}

footer .footer-wrap .footer-inner ul.contact-details li.contact-details--email:before {
	content: "";
}

@media (max-width: 1080px) {
	footer .footer-wrap .footer-inner ul.contact-details li.contact-details--email:before {
		display: none;
	}
}

footer .footer-wrap .footer-inner ul.contact-details li a {
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

footer .footer-wrap .footer-inner ul.contact-details li a:hover {
	color: $sf-green;
}

footer .footer-bottom {
	background: #41433f;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
}

@media (max-width: 600px) {
	footer .footer-bottom {
		padding: 30px 0;
	}
}

footer .footer-bottom--inner .wrap {
	position: relative;
}

footer .footer-bottom--inner .footer-social {
	float: left;
	position: relative;
	// z-index: 99;
}

footer .footer-bottom--inner .footer-social ul li {
	display: inline-block;
	margin-right: 20px;
}

@media (max-width: 360px) {
	footer .footer-bottom--inner .footer-social ul li {
		margin-right: 10px;
	}
}

footer .footer-bottom--inner .footer-social ul li:last-child {
	margin-right: 0;
}

footer .footer-bottom--inner .footer-social ul li a {
	display: block;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

footer .footer-bottom--inner .footer-social ul li a img {
	max-width: 42px;
}

footer .footer-bottom--inner .footer-social ul li a:hover {
	opacity: 0.9;
}

@media (max-width: 600px) {
	footer .footer-bottom--inner .footer-social {
		float: none;
		text-align: center;
		width: 100%;
	}
}

footer .footer-bottom--inner .footer-text {
	text-align: center;
	width: 100%;
	position: absolute;
	line-height: 18px;
	padding-top: 5px;
}

@media (max-width: 900px) {
	footer .footer-bottom--inner .footer-text {
		text-align: right;
		position: relative;
		width: auto;
		float: right;
	}
}

@media (max-width: 600px) {
	footer .footer-bottom--inner .footer-text {
		padding-right: 0;
		text-align: center;
		margin-bottom: 20px;
		float: none;
	}
}

footer .footer-bottom--inner .footer-text p:first-child {
	font-size: 14px;
	color: #fff;
}

footer .footer-bottom--inner .footer-text p:last-child {
	font-size: 12px;
	color: #a3a3a3;
}

@media (max-width: 615px) {
	footer .footer-wrap,
	footer .footer-wrap .wrap {
		padding: 0;
	}

	footer .footer-wrap .footer-inner > div {
		border-bottom: 1px solid #61635f;
	}

	footer .footer-wrap .footer-inner > div > div,
	footer .footer-wrap .footer-inner > div > ul {
		-webkit-transition: max-height 0.5s ease-in-out;
		transition: max-height 0.5s ease-in-out;
		max-height: 0;
		overflow: hidden;
		padding: 0 25px;
	}

	footer .footer-wrap .footer-inner > div > div > :last-child,
	footer .footer-wrap .footer-inner > div > ul > :last-child {
		padding-bottom: 20px;
	}

	footer .footer-wrap .footer-inner > div:last-child {
		border-bottom: none;
	}

	footer .footer-wrap .footer-inner > div,
	footer .footer-wrap .footer-inner > div:last-child {
		padding: 0 0 5px;
	}

	footer .footer-wrap .footer-inner h3 {
		padding: 20px 25px;
		margin-bottom: 0;
		margin-top: -15px;
		position: relative;
		cursor: pointer;
	}

	footer .footer-wrap .footer-inner h3:after {
		-webkit-transition: -webkit-transform 0.5s ease-in-out;
		transition: transform 0.5s ease-in-out;
		content: "\f078";
		font-family: FontAwesome;
		display: inline-block;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
		position: absolute;
		right: 25px;
	}

	footer .footer-wrap .footer-inner h3.footer-subhead {
		padding-top: 25px;
		margin-top: 0;
		border-top: 1px solid #61635f;
	}

	footer .footer-wrap .footer-inner h3.about-us-footer {
		margin-bottom: 0;
	}

	footer .footer-wrap .footer-inner > div .footer-head--visible:after {
		-webkit-transform: rotate(-180deg);
		-ms-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}

	footer .footer-wrap .footer-inner > div .footer-head--visible + * {
		max-height: 500px;
	}
}

footer .footer-animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@media (max-width: 1300px) {
	footer .footer-animation {
		display: none;
	}
}

footer .footer-animation--inner {
	width: 100%;
	position: absolute;
	max-width: 1300px;
	margin: auto;
	left: 0;
	right: 0;
	height: 455px;
	pointer-events: none;
}

footer .footer-animation--inner img {
	position: absolute;
	padding: 15px;
	pointer-events: all;
	cursor: pointer;
}

footer .footer-animation--inner img.animate-fly {
	-webkit-animation: fly 30s ease infinite;
	animation: fly 30s ease infinite;
}

footer .footer-animation--inner img.remove {
	display: none;
}

footer .footer-frog {
	position: absolute;
	width: 1300px;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	pointer-events: none;
	min-height: 200px;
	overflow: hidden;
}

@media (max-width: 1300px) {
	footer .footer-frog {
		width: 100%;
		max-width: 1300px;
	}
}

@media (max-width: 900px) {
	footer .footer-frog {
		display: none;
	}
}

footer .footer-frog--innerbg {
	position: absolute;
	right: 0;
	bottom: -4px;
}

footer .footer-frog--outerbg {
	position: absolute;
	bottom: -4px;
	right: 10px;
	z-index: 9;
}

footer .footer-frog--outerbg:before {
	content: "";
	display: block;
}

footer .footer-frog--outerbg img {
	max-width: 180px;
}

footer .footer-frog--innerbg {
	pointer-events: all;
}

footer .footer-frog--innerbg img {
	max-width: 225px;
}

footer .footer-frog--innerbg_eyes {
	position: absolute;
	width: 100%;
	top: 16px;
}

footer .footer-frog--innerbg_eyes-right,
footer .footer-frog--innerbg_eyes-left {
	width: 20px;
	height: 20px;
	background: $sf-green;
	border-radius: 50px;
	position: absolute;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

footer .footer-frog--innerbg_eyes-right.animated-eyes,
footer .footer-frog--innerbg_eyes-left.animated-eyes {
	-webkit-animation: eyes 30s ease infinite;
	animation: eyes 30s ease infinite;
}

footer .footer-frog--innerbg_eyes-right:before,
footer .footer-frog--innerbg_eyes-left:before {
	content: "";
	height: 11px;
	width: 11px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	display: block;
	left: 3px;
	top: -2px;
}

footer .footer-frog--innerbg_eyes-right {
	left: 145px;
	top: 8px;
}

footer .footer-frog--innerbg_eyes-right,
footer .footer-frog--innerbg_eyes-left {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
}

footer .footer-frog--innerbg_eyes-left {
	left: 94px;
	top: 3px;
}

footer .footer-frog--mouth {
	position: absolute;
	right: 44px;
	width: 113px;
	height: 40px;
	background: #666;
	top: 90px;
	border-radius: 100px/50px;
	-webkit-transform: rotateZ(8deg);
	transform: rotateZ(8deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

footer .footer-frog--mouth.open-mouth {
	-webkit-transform: rotateZ(8deg) translate(-3px, -21px);
	transform: rotateZ(8deg) translate(-3px, -21px);
}

footer #frog-svg {
	max-width: 1300px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

@-webkit-keyframes fly {
	0% {
		-webkit-transform: translate(130px, 45px);
		transform: translate(130px, 45px);
	}

	10% {
		-webkit-transform: translate(1040px, 227px);
		transform: translate(1040px, 227px);
	}

	20% {
		-webkit-transform: translate(0, 91px);
		transform: translate(0, 91px);
	}

	30% {
		-webkit-transform: translate(520px, 409px);
		transform: translate(520px, 409px);
	}

	40% {
		-webkit-transform: translate(390px, 0);
		transform: translate(390px, 0);
	}

	50% {
		-webkit-transform: translate(1040px, 227px);
		transform: translate(1040px, 227px);
	}

	60% {
		-webkit-transform: translate(390px, 318px);
		transform: translate(390px, 318px);
	}

	70% {
		-webkit-transform: translate(520px, 273px);
		transform: translate(520px, 273px);
	}

	80% {
		-webkit-transform: translate(0, 318px);
		transform: translate(0, 318px);
	}

	90% {
		-webkit-transform: translate(1170px, 227px);
		transform: translate(1170px, 227px);
	}

	100% {
		-webkit-transform: translate(130px, 45px);
		transform: translate(130px, 45px);
	}
}

@keyframes fly {
	0% {
		-webkit-transform: translate(130px, 45px);
		transform: translate(130px, 45px);
	}

	10% {
		-webkit-transform: translate(1040px, 227px);
		transform: translate(1040px, 227px);
	}

	20% {
		-webkit-transform: translate(0, 91px);
		transform: translate(0, 91px);
	}

	30% {
		-webkit-transform: translate(520px, 409px);
		transform: translate(520px, 409px);
	}

	40% {
		-webkit-transform: translate(390px, 0);
		transform: translate(390px, 0);
	}

	50% {
		-webkit-transform: translate(1040px, 227px);
		transform: translate(1040px, 227px);
	}

	60% {
		-webkit-transform: translate(390px, 318px);
		transform: translate(390px, 318px);
	}

	70% {
		-webkit-transform: translate(520px, 273px);
		transform: translate(520px, 273px);
	}

	80% {
		-webkit-transform: translate(0, 318px);
		transform: translate(0, 318px);
	}

	90% {
		-webkit-transform: translate(1170px, 227px);
		transform: translate(1170px, 227px);
	}

	100% {
		-webkit-transform: translate(130px, 45px);
		transform: translate(130px, 45px);
	}
}

@-webkit-keyframes eyes {
	0% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	1% {
		-webkit-transform: translate(-5px, -4px) scaleY(0);
		transform: translate(-5px, -4px) scaleY(0);
	}

	2% {
		-webkit-transform: translate(-4px, -4px) scaleY(1);
		transform: translate(-4px, -4px) scaleY(1);
	}

	10% {
		-webkit-transform: translate(-1px, -5px) scaleY(1);
		transform: translate(-1px, -5px) scaleY(1);
	}

	20% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	30% {
		-webkit-transform: translate(-5px, 0) scaleY(1);
		transform: translate(-5px, 0) scaleY(1);
	}

	31% {
		-webkit-transform: translate(-5px, 0) scaleY(0);
		transform: translate(-5px, 0) scaleY(0);
	}

	32% {
		-webkit-transform: translate(-5px, -1px) scaleY(1);
		transform: translate(-5px, -1px) scaleY(1);
	}

	40% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	50% {
		-webkit-transform: translate(-2px, -6px) scaleY(1);
		transform: translate(-2px, -6px) scaleY(1);
	}

	60% {
		-webkit-transform: translate(-5px, -3px) scaleY(1);
		transform: translate(-5px, -3px) scaleY(1);
	}

	70% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	71% {
		-webkit-transform: translate(-5px, -4px) scaleY(0);
		transform: translate(-5px, -4px) scaleY(0);
	}

	72% {
		-webkit-transform: translate(-5px, -3px) scaleY(1);
		transform: translate(-5px, -3px) scaleY(1);
	}

	80% {
		-webkit-transform: translate(-5px, -2px) scaleY(1);
		transform: translate(-5px, -2px) scaleY(1);
	}

	90% {
		-webkit-transform: translate(0, -5px) scaleY(1);
		transform: translate(0, -5px) scaleY(1);
	}

	100% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}
}

@keyframes eyes {
	0% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	1% {
		-webkit-transform: translate(-5px, -4px) scaleY(0);
		transform: translate(-5px, -4px) scaleY(0);
	}

	2% {
		-webkit-transform: translate(-4px, -4px) scaleY(1);
		transform: translate(-4px, -4px) scaleY(1);
	}

	10% {
		-webkit-transform: translate(-1px, -5px) scaleY(1);
		transform: translate(-1px, -5px) scaleY(1);
	}

	20% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	30% {
		-webkit-transform: translate(-5px, 0) scaleY(1);
		transform: translate(-5px, 0) scaleY(1);
	}

	31% {
		-webkit-transform: translate(-5px, 0) scaleY(0);
		transform: translate(-5px, 0) scaleY(0);
	}

	32% {
		-webkit-transform: translate(-5px, -1px) scaleY(1);
		transform: translate(-5px, -1px) scaleY(1);
	}

	40% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	50% {
		-webkit-transform: translate(-2px, -6px) scaleY(1);
		transform: translate(-2px, -6px) scaleY(1);
	}

	60% {
		-webkit-transform: translate(-5px, -3px) scaleY(1);
		transform: translate(-5px, -3px) scaleY(1);
	}

	70% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}

	71% {
		-webkit-transform: translate(-5px, -4px) scaleY(0);
		transform: translate(-5px, -4px) scaleY(0);
	}

	72% {
		-webkit-transform: translate(-5px, -3px) scaleY(1);
		transform: translate(-5px, -3px) scaleY(1);
	}

	80% {
		-webkit-transform: translate(-5px, -2px) scaleY(1);
		transform: translate(-5px, -2px) scaleY(1);
	}

	90% {
		-webkit-transform: translate(0, -5px) scaleY(1);
		transform: translate(0, -5px) scaleY(1);
	}

	100% {
		-webkit-transform: translate(-5px, -4px) scaleY(1);
		transform: translate(-5px, -4px) scaleY(1);
	}
}
//</editor-fold>
