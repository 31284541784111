.sf-agency-overview {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: $pad--small;
	align-items: center;
	justify-items: center;
	&__text {
		text-align: center;
	}

	&__client-img {
		padding: 0 $pad--min;
		width: 100%;
		max-width: 560px;
	}
	&__accreditations {
		display: none;
	}

	@include respond-to-min(sm) {
		@include respond-to-min(md) {
			row-gap: $pad--large;
			&__accreditations {
				display: grid;
				grid-template-columns: repeat(3, 140px);
				column-gap: $pad--large;
				row-gap: $pad--large;
			}
			@include respond-to-min(lg) {
				grid-template-columns: 500px 1fr;
				grid-template-areas: "top-left top-right" "bottom-left bottom-right";
				column-gap: $pad--large;
				&__text {
					text-align: left;
					grid-area: top-left;
				}
				&__testimonial {
					grid-area: top-right;
				}
				&__client-img {
					grid-area: bottom-left;
				}
				&__accreditations {
					grid-area: bottom-right;
					column-gap: $pad--small;
					@include respond-to-max(xl) {
						grid-template-columns: repeat(3, 120px);
					}
				}

				@include respond-to-min(xl) {
					column-gap: $pad--large;
					grid-template-columns: 1fr 1fr;
				}
			}
		}
	}

	// &__clients {
	// 	display: grid;
	// 	grid-template-columns: repeat(3, 1fr);
	// 	column-gap: $pad--large;
	// 	row-gap: $pad--large;
	// 	// border: 1px solid red;
	// }
}
