//@import "../config/config";

///*=====================================================================</editor-fold>=
// CLIENTS
///=====================================================================<editor-fold>=*/

.spider-clients {
	margin-top: 70px;
	display: flex;
	justify-content: space-between;
}

.spider-clients--single {
	padding: 50px;
	background: #fff;
	width: calc(50% - calc($block-spacing-v--sm / 2));
	@include respond-to-min("bs-md") {
		width: calc(50% - calc($block-spacing-v--md / 2));
	}
	text-align: center;
}

.spider-clients--single:before {
	content: "";
	display: table;
}

.spider-clients--single:after {
	content: "";
	display: table;
	clear: both;
}

.spider-clients--single:last-child {
	margin-right: 0%;
}

.spider-clients--single h3 {
	font-size: 32px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 20px;
}

.spider-clients--single p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.spider-clients--single_image {
	max-width: 480px;
	margin: 20px auto 0;
}

.spider-clients--single_image img {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 33.33333333333333%;
	margin-left: 0%;
	margin-right: 0%;
	max-width: 160px;
}

.spider-clients--single_image img:before {
	content: "";
	display: table;
}

.spider-clients--single_image img:after {
	content: "";
	display: table;
	clear: both;
}

.spider-clients--single_image img:nth-child(3n) {
	float: right;
}

.spider-clients--single_image img:nth-child(3n + 1) {
	clear: both;
}

.spider-clients--single_image-right {
	max-width: 480px;
	margin: 20px auto 0;
}

.spider-clients--single_image-right img {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 33.33333333333333%;
	margin-left: 0%;
	margin-right: 0%;
	max-width: 160px;
}

.spider-clients--single_image-right img:before {
	content: "";
	display: table;
}

.spider-clients--single_image-right img:after {
	content: "";
	display: table;
	clear: both;
}

.spider-clients--single_image-right img:nth-child(3n) {
	float: right;
}

.spider-clients--single_image-right img:nth-child(3n + 1) {
	clear: both;
}

@media (max-width: 900px) {
	.spider-clients {
		display: block;
	}
	.spider-clients--single {
		width: 100%;
		margin-bottom: 40px;
	}
}

@media (max-width: 500px) {
	.spider-clients--single_image img {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 50%;
		margin-left: 0%;
		margin-right: 0%;
	}

	.spider-clients--single_image img:before {
		content: "";
		display: table;
	}

	.spider-clients--single_image img:after {
		content: "";
		display: table;
		clear: both;
	}

	.spider-clients--single_image img:nth-child(2n) {
		float: right;
	}

	.spider-clients--single_image img:nth-child(2n + 1) {
		clear: both;
	}

	.spider-clients--single_image img:nth-child(3n) {
		float: left;
	}

	.spider-clients--single_image img:nth-child(3n + 1) {
		clear: none;
	}

	.spider-clients--single_image img:nth-child(6) {
		float: right;
	}

	.spider-clients--single_image-right img {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 50%;
		margin-left: 0%;
		margin-right: 0%;
	}

	.spider-clients--single_image-right img:before {
		content: "";
		display: table;
	}

	.spider-clients--single_image-right img:after {
		content: "";
		display: table;
		clear: both;
	}

	.spider-clients--single_image-right img:nth-child(2n) {
		float: right;
	}

	.spider-clients--single_image-right img:nth-child(2n + 1) {
		clear: both;
	}

	.spider-clients--single_image-right img:nth-child(3n) {
		float: left;
	}

	.spider-clients--single_image-right img:nth-child(3n + 1) {
		clear: none;
	}

	.spider-clients--single_image-right img:nth-child(6) {
		float: right;
	}
}
//</editor-fold>
