// SF TODO: check used under sf-standard-typography and remove once all instances of .main-blog--posts_single are removed
//MADE GLOBAL:
.main-blog--posts_single {
	code,
	span.code {
		background-color: #f8f8f8;
		color: #74b42d;
		font-family: "Courier new", Courier, "Andale Mono", monospace !important;
	}
	span.code {
		display: inline-block;
		padding: 0 5px;
	}
	code {
		padding: 15px;
		display: block;
	}
}
