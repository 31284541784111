//@import "../config/config";

///*=====================================================================</editor-fold>=
// PRODUCT (SPIDER / LFA) NAV
///=====================================================================<editor-fold>=*/

.spider-sub {
  background: #5c941c;
}

@media (max-width: 970px) {
  .spider-sub {
    text-align: center;
  }
}

.spider-sub--navigation ul li,
.spider-sub--downloads ul li {
  float: left;
}

.spider-sub--navigation a,
.spider-sub--downloads a {
  color: #beea8b;
  font-size: 18px;
  display: block;
  padding: 30px 0;
  margin: 0 20px;
  position: relative;
  -webkit-transition: all .2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 1088px) {
  .spider-sub--navigation a,
  .spider-sub--downloads a {
    font-size: 15px;
  }
}

.spider-sub--navigation a.active,
.spider-sub--downloads a.active {
  color: #fff;
}

.spider-sub--navigation a.active:after,
.spider-sub--downloads a.active:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -10px;
}

.spider-sub--navigation a.active.grey:after,
.spider-sub--downloads a.active.grey:after {
  border-color: transparent transparent #f8f8f8 transparent;
}

.spider-sub--navigation a:hover,
.spider-sub--downloads a:hover {
  color: #fff;
}

@media (max-width: 970px) {
  .spider-sub--downloads a.active:after {
    display: none;
  }

  .spider-sub .wrap {
    padding: 0;
  }
}

.spider-sub--navigation {
  float: left;
}

.spider-sub--navigation .select-wrapper {
  display: none;
  position: relative;
  margin: $pad--min 0;
}

.spider-sub--navigation .select-wrapper:after {
 @include product-nav-dropdown-arrow($pad--min + 1px, $pad--min);
}

.spider-sub--navigation .select-wrapper select {
  @include product-nav-select-text;
  display: inline-block;
  width: 100%;
  padding: 13px 15px;
  border: 1px solid #e1e1e1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border-radius: 0;
}

.spider-sub--navigation .select-wrapper select[disabled='disabled'] {
  background: #f7f7f7;
  font-style: italic;
}

@media (max-width: 1215px) {
  .spider-sub--navigation a {
    margin: 0 15px;
  }
}

@media (max-width: 970px) {
  .spider-sub--navigation {
    float: none;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 580px) {
  .spider-sub--navigation ul {
    display: none;
  }

  .spider-sub--navigation .select-wrapper {
    display: block;
  }
}

.spider-sub--downloads {
  float: right;
}

@media (max-width: 970px) {
  .spider-sub--downloads {
    float: none;
  }

  .spider-sub--downloads ul,
  .spider-sub--downloads ul li a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .spider-sub--downloads ul li a {
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 970px) {
  .spider-sub--downloads li {
    *zoom: 1;
    float: left;
    clear: none;
    text-align: inherit;
    width: 33.33333333333333%;
    margin-left: 0%;
    margin-right: 0%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .spider-sub--downloads li:before {
    content: '';
    display: table;
  }

  .spider-sub--downloads li:after {
    content: '';
    display: table;
    clear: both;
  }
}

.spider-sub--downloads li a {
  background: #508019;
  padding: 30px 35px;
  width: 100%;
  margin: 0 0 0 1px;
}

.spider-sub--downloads li a:last-child {
  margin-right: 0;
}

@media (max-width: 1215px) {
  .spider-sub--downloads li a {
    padding: 30px 25px;
  }
}

.spider-sub--downloads li a:hover {
  background: #ffbc2b;
}

.spider-sub--downloads li a.active {
  background: #ffbc2b;
}

.spider-sub--downloads li:last-child a {
  border-right: 0;
}

//</editor-fold>
