.sf-quotation {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	&__quote {
		@include quote();
	}

	&__source {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		align-content: flex-end;
		@include respond-to-max(sm) {
			flex-wrap: wrap;
		}
		@include respond-to-min(xl) {
			padding: 0 $pad--small $pad--small;
		}

		&-details {
			margin-right: $pad--min;
		}
		&-name {
			@include p($sf-green);
			margin-bottom: 5px;
		}
		&-role {
			@include p();
		}
		&-image {
			margin-top: 5px;
			max-width: 150px;
		}

		&--customer,
		&--employee {
			.sf-quotation__source-image {
				max-width: 100px;
				border-radius: 1000px;
			}
		}
	}
}
