.sf-sidebar-contact {
	padding: $pad--small;
	text-align: center;
	h2 {
		@include h4($color: #fff) {
			margin-bottom: $pad--small;
		}
	}
	p {
		@include p($color: #fff);
	}
	a {
		@include pill-btn--outline(#fff, $sf-green);
		display: block;
		background-color: transparent;
		margin: $pad--small auto;
		max-width: 300px;
	}
}
