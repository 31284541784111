.sf-refine-search-nav {
	h2 {
		@include h3();
		padding: $pad--small;
		border-bottom: $sf-border;
	}
	&__item {
		display: block;
		padding: $pad--small;
		@include p($font-size: $sf-font-size--large);
		&:not(:last-of-type) {
			border-bottom: $sf-border;
		}
		&:hover {
			background: $sf-bg--grey;
		}
		.sf-refine-search-nav__icon {
			&--main {
				color: $sf-color--error;
			}
			&--hover {
				color: $sf-green;
			}
		}
		&--active {
			.sf-refine-search-nav__icon {
				&--main {
					color: $sf-green;
				}
				&--hover {
					color: $sf-color--error;
				}
			}
		}
		&:hover {
			.sf-refine-search-nav__icon {
				&--main {
					display: none !important;
				}
				&--hover {
					display: inline-block !important;
				}
			}
		}
	}
	&__icon {
		transform: scale(1.4);
		margin-right: 15px;
		display: inline-block;
		&.sf-refine-search-nav__icon--hover {
			display: none !important;
		}
	}
}
