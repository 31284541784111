//@import "../config/config";

///*=====================================================================</editor-fold>=
// MAIN BLOG STRUCTURE ADJUST
///=====================================================================<editor-fold>=*/

.main-blog--posts_single-inner_faqs {
	padding: 50px 35px 35px;
	background: #fff;
	margin-bottom: 50px;
}

.main-blog--posts_single-inner_faqs h2,
.main-blog--posts_single-inner_faqs h3 {
	font-size: 24px;
	color: $sf-font-grey;
	font-weight: $sf-weight--med;
	margin-bottom: 20px;
}

.main-blog--posts_single-inner_faqs h4 {
	font-size: 16px;
	margin: 0 0 20px;
	font-weight: bold;
	color: $sf-font-grey;
}

.main-blog--posts_single-inner_faqs p {
	font-weight: $sf-weight--light;
	line-height: 26px;
	margin-bottom: 20px;
}

.main-blog--posts_single-inner_faqs iframe {
	max-width: 100%;
}

.main-blog--posts_single-inner_faqs h3 a {
	color: $sf-font-grey;
}

.main-blog--posts_single-inner_faqs h3 a:hover {
	color: $sf-font-grey;
}

.main-blog--posts_single-inner_faqs a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.main-blog--posts_single-inner_faqs a:hover {
	color: #599f08;
}

.main-blog--posts_single-inner_faqs img {
	margin: 20px 0;
	width: auto;
	max-width: 100%;
	height: auto;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 2px -2px rgba(0, 0, 0, 0.15);
}

.main-blog--posts_single-inner_faqs .main-blog--posts_single-inner--text--content-faqs h3 {
	font-size: 20px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

//</editor-fold>
