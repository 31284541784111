@keyframes checkoutMessageReveal {
	from {
		right: 0;
	}
	to {
		right: 100%;
	}
}

@mixin hideWhenFormis($choice: open) {
	@if $choice == open {
		[data-formopen="true"] & {
			display: none;
		}
	} @else {
		[data-formopen="false"] & {
			display: none;
		}
	}
}

@mixin checkoutFormRow() {
	margin-bottom: $pad--min;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@include p($font-size: 15px);
	a {
		@include link($sushi, false, "on-hover");
	}

	.required {
		display: none;
	}

	label {
		display: block;
		padding: 5px 0;
		position: relative;
	}

	.woocommerce-input-wrapper,
	.password-input {
		display: block;
		width: 100%;
	}

	.input-text,
	.wc-braintree-hosted-field {
		width: 100%;
		padding: 10px;
		@include p();
		border: 1px solid $checkout-border-grey;
		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	&[data-severity="0"] {
		input,
    // dynamic dropdowns added by select2/woocommerce
    .select2-container--default .select2-selection--single {
			border: 1px solid $sushi;
		}
	}
	&[data-severity="1"] {
		.ri-question-fill {
			color: $sf-orange--light;
		}
		input,
    // dynamic dropdowns added by select2/woocommerce
    .select2-container--default .select2-selection--single {
			border: 1px solid $sf-orange--light;
		}
	}
	&[data-severity="2"] {
		.ri-question-fill {
			color: $bittersweet;
		}
		input,
    // dynamic dropdowns added by select2/woocommerce
    .select2-container--default .select2-selection--single {
			border: 1px solid $bittersweet;
		}
	}

	//COUNTRY SELECT OVERRIDES
	$checkout-input--h: 44px;
	.select2-container--default .select2-selection--single {
		border-radius: 0;
		height: $checkout-input--h;
	}
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: $checkout-input--h;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		padding: 7px 20px 7px 10px;
	}
}
