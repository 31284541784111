//@import "../config/config";

///*=====================================================================</editor-fold>=
// ANSWERS
///=====================================================================<editor-fold>=*/

.faq-answers {
	background: #fff;
	padding: 100px 0;
}

@media (max-width: 800px) {
	.faq-answers {
		padding: 70px 0;
	}
}

.faq-answers--column {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	max-width: 600px;
}

.faq-answers--column:before {
	content: "";
	display: table;
}

.faq-answers--column:after {
	content: "";
	display: table;
	clear: both;
}

.faq-answers--column:nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.faq-answers--column:nth-child(2n + 1) {
	clear: both;
}

@media (max-width: 850px) {
	.faq-answers--column {
		*zoom: 1;
		clear: none;
		text-align: inherit;
		width: 100%;
		max-width: 700px;
		margin: 0 auto;
		float: none;
	}

	.faq-answers--column:before {
		content: "";
		display: table;
	}

	.faq-answers--column:after {
		content: "";
		display: table;
		clear: both;
	}

	.faq-answers--column:nth-child(2n) {
		margin-right: 3%;
		float: left;
	}

	.faq-answers--column:nth-child(2n + 1) {
		clear: none;
	}

	.faq-answers--column:last-child {
		margin-right: 0%;
	}
}

.faq-answers--inner {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 27px;
}

.faq-answers--inner_answer {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	display: block;
}

.faq-answers--inner_answer h3 {
	font-size: 18px;
	color: $sf-font-grey;
	font-weight: $sf-weight--med;
	margin-bottom: 20px;
	display: block;
}

.faq-answers--inner_answer p {
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 27px;
}

.faq-answers--inner_answer ul {
	padding: 20px 0 20px 20px;
}

.faq-answers--inner_answer ul li {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 27px;
	margin-bottom: 8px;
}

.faq-answers--inner_answer a {
	color: #75b42c;
}

.faq-answers--inner_answer a:hover {
	color: #599f08;
}

.faq-answers--inner_answer a.back-to-top {
	display: none;
}

.faq-answers--inner_answer img {
	display: block;
	margin: 30px auto;
	width: auto;
	height: auto;
	max-width: 100%;
}

.faq-answers--inner_answer.active {
	margin-bottom: 70px;
	padding: 35px;
	background: #f9f9f9;
}

.faq-answers--inner_answer.active .back-to-top {
	display: block;
	position: absolute;
	bottom: -40px;
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 0 0 0 15px;
}

.faq-answers--inner_answer.active .back-to-top:before {
	content: "\f176";
	font-family: fontAwesome;
	position: absolute;
	left: 0;
}

.faq-answers--inner_answer.active .back-to-top:hover {
	color: #599f08;
}

@media (max-width: 800px) {
	.faq-answers--inner_answer,
	.faq-answers--inner_answer:nth-child(even) {
		width: 100%;
		float: none;
		max-width: none;
	}
}
//</editor-fold>
