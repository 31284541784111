.sf-subscribe {
	text-align: center;
	padding: $pad--small;

	h2 {
		@include h4();
		margin-bottom: $pad--small;
	}

	&__form {
		@import "../plugin-overrides/email-octopus";
		a {
			@include p($font-size: $sf-font-size--small);
			text-decoration: underline;

			.sf-subscribe--sidebar &,
			.sf-subscribe--banner & {
				color: #fff;
			}
			&:hover {
				font-weight: $sf-weight--med;
			}
		}
	}

	&.sf-subscribe--sidebar,
	&.sf-subscribe--banner {
		h2 {
			color: #fff;
		}
	}
	&.sf-subscribe--sidebar {
		padding: $pad--small;
		text-align: center;
	}
	@include respond-to-min(xl) {
		padding: $pad--large;
		h2 {
			margin-bottom: $pad--small;
		}
	}
}
