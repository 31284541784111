//@import "../config/config";

///*=====================================================================</editor-fold>=
// ABOUT
///=====================================================================<editor-fold>=*/

.spider-about {
	background: #f8f8f8;
	padding: 1px 0;
}

@include respond-to-max("bs-md") {
	.spider-about {
		padding: 1px 0;
	}
}

@include respond-to-min(750px) {
	.spider-about_left {
		float: left;
		padding-right: 30px;
	}
	.spider-about_right {
		padding-left: 30px;
	}
}

.spider-about {
	padding-bottom: 30px;
	@include respond-to-max("bs-md") {
		padding: 0 30px 30px;
	}
}

.spider-about_left,
.spider-about_right {
	clear: none;
	text-align: inherit;
	width: 50%;
	// margin-left: 0%;
	// margin-right: 3%;
	// max-width: 580px;
}

.spider-about_left:before,
.spider-about_right:before,
.spider-about_left:after,
.spider-about_right:after {
	content: "";
	display: table;
}

.spider-about_left:after,
.spider-about_right:after {
	clear: both;
}

.spider-about_left:last-child,
.spider-about_right:last-child {
	margin-right: 0%;
}

@media (max-width: 750px) {
	.spider-about_left,
	.spider-about_right {
		*zoom: 1;
		float: left;
		clear: none;
		text-align: inherit;
		width: 100%;
		margin-left: 0%;
		margin-right: 3%;
		max-width: 100%;
	}

	.spider-about_left:before,
	.spider-about_right:before,
	.spider-about_left:after,
	.spider-about_right:after {
		content: "";
		display: table;
	}

	.spider-about_left:after,
	.spider-about_right:after {
		clear: both;
	}

	.spider-about_left:last-child,
	.spider-about_right:last-child {
		margin-right: 0%;
	}
}

.spider-about_left h3,
.spider-about_right h3 {
	font-size: 32px;
	font-weight: $sf-weight--light;
	color: $sf-font-grey;
	margin-bottom: 25px;
}

.spider-about_left p,
.spider-about_right p {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 30px;
	margin-bottom: 15px;
}

.spider-about_left a,
.spider-about_right a {
	color: #75b42c;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	text-decoration: underline;
}

.spider-about_left a:hover,
.spider-about_right a:hover {
	color: #599f08;
}

.spider-about_right {
	float: right;
}

@media (max-width: 700px) {
	.spider-about_right {
		margin-top: 30px;
	}
}

.spider-about_right ul {
	margin-top: 20px;
}

.spider-about_right ul li {
	font-size: 16px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	line-height: 25px;
	padding-left: 35px;
	position: relative;
	margin-bottom: 15px;
}

.spider-about_right ul li:before {
	content: "\f00c";
	font-family: fontAwesome;
	color: #75b42c;
	position: absolute;
	left: 0;
}
//</editor-fold>
