@mixin sf-pad($padding) {
	&:not(.sf-pad--h, .sf-pad--v, .sf-pad--t, .sf-pad--b, .sf-pad--l, .sf-pad--r) {
		padding: $padding;
	}
	&.sf-pad--h {
		padding-left: $padding;
		padding-right: $padding;
	}
	&.sf-pad--v {
		padding-top: $padding;
		padding-bottom: $padding;
	}
	&.sf-pad--t {
		padding-top: $padding;
	}
	&.sf-pad--b {
		padding-bottom: $padding;
	}
	&.sf-pad--l {
		padding-left: $padding;
	}
	&.sf-pad--r {
		padding-right: $padding;
	}
}

@mixin sf-marg($margin) {
	&:not(.sf-marg--h, .sf-marg--v, .sf-marg--t, .sf-marg--b, .sf-marg--l, .sf-marg--r) {
		margin: $margin;
	}
	&.sf-marg--h {
		margin-left: $margin;
		margin-right: $margin;
	}
	&.sf-marg--v {
		margin-top: $margin;
		margin-bottom: $margin;
	}
	&.sf-marg--t {
		margin-top: $margin;
	}
	&.sf-marg--b {
		margin-bottom: $margin;
	}
	&.sf-marg--l {
		margin-left: $margin;
	}
	&.sf-marg--r {
		margin-right: $margin;
	}
}
