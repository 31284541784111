$sf-secondary-col--w: 330px;

@mixin sidebar-widths() {
	@for $i from 1 through 10 {
		$w: $i * 50;
		&--w-#{$w} {
			width: $w * 1px;
			min-width: $w * 1px;
		}
	}
}
.sf-lateral-layout {
	@include respond-to-max(lg) {
		&--right-first-under-lg {
			display: flex;
			flex-direction: column;

			.sf-lateral-layout__left {
				margin-top: 0 !important;
				order: 2;
			}
			.sf-lateral-layout__right {
				margin-bottom: 0 !important;
				order: 1;
			}
		}
	}
	@include respond-to-min(lg) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__left {
			width: 100%;
			margin-right: $block-spacing-v--md;
		}
		&__right {
			width: $sf-secondary-col--w;
			min-width: $sf-secondary-col--w;
			@include sidebar-widths();
		}
		&--right-focus {
			.sf-lateral-layout__left {
				width: $sf-secondary-col--w;
				min-width: $sf-secondary-col--w;
				@include sidebar-widths();
			}
			.sf-lateral-layout__right {
				width: 100%;
				min-width: unset;
			}
		}

		@include sticky-lateral-content();
	}
}
