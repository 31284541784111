.sf-hint-help {
	color: $sf-grey--light; // light grey initial state
	line-height: inherit; // inherit line height from parent (fill height)
	position: relative; // set relative position for absolute positioning of icon
	transition: color 0.3s; // smooth color transition on hover

	display: inline-block; // ensure width stays consistent
	width: initial; // add width back in if required
	&--collapsed {
		width: 0; // make collapse by setting width to 0
	}

	&:hover {
		color: $sf-font-grey; // darken color on hover
	}

	// style the actual question mark icon nested in the container:
	i {
		font-size: $sf-font-size--small; // icon size
		position: absolute;
	}

	// setup a phantom icon to keep the container width and height with an absolute positioned icon:
	&:after {
		@include remixicon($ri-question-fill); // match question mark icon
		font-size: $sf-font-size--small; // match font size of <i> element
		color: transparent !important; // hide pseudo but maintain space
	}

	// add offset adjustment classes for top and left:
	&--offset-top {
		i {
			top: calc($sf-font-size--small / 2) * -1; // set top minus half icon height
		}
	}
	&--offset-left {
		i {
			left: calc($sf-font-size--small / 2) * -1; // set left minus half icon height
		}
	}
}
