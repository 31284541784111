//@import "../config/config";

///*=====================================================================</editor-fold>=
// PPC
///=====================================================================<editor-fold>=*/

.ppc-audit-form .support-input {
	*zoom: 1;
	float: left;
	clear: none;
	text-align: inherit;
	width: 48.5%;
	margin-left: 0%;
	margin-right: 3%;
	margin-bottom: 30px;
}

.ppc-audit-form .support-input:before {
	content: "";
	display: table;
}

.ppc-audit-form .support-input:after {
	content: "";
	display: table;
	clear: both;
}

.ppc-audit-form .support-input:nth-child(2n) {
	margin-right: 0%;
	float: right;
}

.ppc-audit-form .support-input:nth-child(2n + 1) {
	clear: both;
}

.ppc-audit-form .support-input input {
	width: 100%;
	border: 1px solid #e1e1e1;
	padding: 10px;
	font-size: 15px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.ppc-audit-form .support-textarea {
	width: 100%;
	display: block;
	clear: both;
	margin-bottom: 30px;
}

.ppc-audit-form .support-textarea input,
.ppc-audit-form .support-textarea select {
	width: 100%;
	border: 1px solid #e1e1e1;
	padding: 10px;
	font-size: 15px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
}

.ppc-audit-form .support-textarea select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	background: #fff;
}

.ppc-audit-form .support-textarea .menu-676:after {
	content: "\f107";
	font-family: fontAwesome;
	display: block;
	color: $sf-font-grey;
	position: absolute;
	top: 2px;
	right: 10px;
}

.ppc-audit-form label {
	font-size: 14px;
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	display: block;
	margin-bottom: 10px;
}

.ppc-audit-form .wpcf7-submit {
	font-size: 16px;
}

.ppc-logos {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px 0;
}

.ppc-logos_left {
	float: left;
}

.ppc-logos_right {
	float: right;
}

@media (max-width: 550px) {
	.ppc-logos_left,
	.ppc-logos_right {
		width: 100%;
		float: none;
	}

	.ppc-logos_left img,
	.ppc-logos_right img {
		display: block;
		margin: 0 auto;
	}

	.ppc-logos_right {
		margin-top: 20px;
	}
}
//</editor-fold>
