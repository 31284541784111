//@import "../config/config";

///*=====================================================================</editor-fold>=
// COL STRUCTURE
///=====================================================================<editor-fold>=*/

.col-1-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 7.3%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-1-10:before,
.col-1-10:after {
  content: '';
  display: table;
}

.col-1-10:after {
  clear: both;
}

.col-1-10:last-child {
  margin-right: 0%;
}

.col-2-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 17.6%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-2-10:before,
.col-2-10:after {
  content: '';
  display: table;
}

.col-2-10:after {
  clear: both;
}

.col-2-10:last-child {
  margin-right: 0%;
}

.col-3-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 27.9%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-3-10:before,
.col-3-10:after {
  content: '';
  display: table;
}

.col-3-10:after {
  clear: both;
}

.col-3-10:last-child {
  margin-right: 0%;
}

.col-4-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 38.2%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-4-10:before,
.col-4-10:after {
  content: '';
  display: table;
}

.col-4-10:after {
  clear: both;
}

.col-4-10:last-child {
  margin-right: 0%;
}

.col-5-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-5-10:before,
.col-5-10:after {
  content: '';
  display: table;
}

.col-5-10:after {
  clear: both;
}

.col-5-10:last-child {
  margin-right: 0%;
}

.col-6-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 58.8%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-6-10:before,
.col-6-10:after {
  content: '';
  display: table;
}

.col-6-10:after {
  clear: both;
}

.col-6-10:last-child {
  margin-right: 0%;
}

.col-7-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 69.1%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-7-10:before,
.col-7-10:after {
  content: '';
  display: table;
}

.col-7-10:after {
  clear: both;
}

.col-7-10:last-child {
  margin-right: 0%;
}

.col-8-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 79.4%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-8-10:before,
.col-8-10:after {
  content: '';
  display: table;
}

.col-8-10:after {
  clear: both;
}

.col-8-10:last-child {
  margin-right: 0%;
}

.col-9-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 89.7%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-9-10:before,
.col-9-10:after {
  content: '';
  display: table;
}

.col-9-10:after {
  clear: both;
}

.col-9-10:last-child {
  margin-right: 0%;
}

.col-10-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 100%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-10-10:before,
.col-10-10:after {
  content: '';
  display: table;
}

.col-10-10:after {
  clear: both;
}

.col-10-10:last-child {
  margin-right: 0%;
}

.col-1-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 3.4375%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-1-16:before,
.col-1-16:after {
  content: '';
  display: table;
}

.col-1-16:after {
  clear: both;
}

.col-1-16:last-child {
  margin-right: 0%;
}

.col-2-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 9.875%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-2-16:before,
.col-2-16:after {
  content: '';
  display: table;
}

.col-2-16:after {
  clear: both;
}

.col-2-16:last-child {
  margin-right: 0%;
}

.col-3-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 16.3125%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-3-16:before,
.col-3-16:after {
  content: '';
  display: table;
}

.col-3-16:after {
  clear: both;
}

.col-3-16:last-child {
  margin-right: 0%;
}

.col-4-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 22.75%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-4-16:before,
.col-4-16:after {
  content: '';
  display: table;
}

.col-4-16:after {
  clear: both;
}

.col-4-16:last-child {
  margin-right: 0%;
}

.col-5-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 29.1875%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-5-16:before,
.col-5-16:after {
  content: '';
  display: table;
}

.col-5-16:after {
  clear: both;
}

.col-5-16:last-child {
  margin-right: 0%;
}

.col-6-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 35.625%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-6-16:before,
.col-6-16:after {
  content: '';
  display: table;
}

.col-6-16:after {
  clear: both;
}

.col-6-16:last-child {
  margin-right: 0%;
}

.col-7-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 42.0625%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-7-16:before,
.col-7-16:after {
  content: '';
  display: table;
}

.col-7-16:after {
  clear: both;
}

.col-7-16:last-child {
  margin-right: 0%;
}

.col-8-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-8-16:before,
.col-8-16:after {
  content: '';
  display: table;
}

.col-8-16:after {
  clear: both;
}

.col-8-16:last-child {
  margin-right: 0%;
}

.col-9-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 54.9375%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-9-16:before,
.col-9-16:after {
  content: '';
  display: table;
}

.col-9-16:after {
  clear: both;
}

.col-9-16:last-child {
  margin-right: 0%;
}

.col-10-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 61.375%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-10-16:before,
.col-10-16:after {
  content: '';
  display: table;
}

.col-10-16:after {
  clear: both;
}

.col-10-16:last-child {
  margin-right: 0%;
}

.col-11-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 67.8125%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-11-16:before,
.col-11-16:after {
  content: '';
  display: table;
}

.col-11-16:after {
  clear: both;
}

.col-11-16:last-child {
  margin-right: 0%;
}

.col-12-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 74.25%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-12-16:before,
.col-12-16:after {
  content: '';
  display: table;
}

.col-12-16:after {
  clear: both;
}

.col-12-16:last-child {
  margin-right: 0%;
}

.col-13-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 80.6875%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-13-16:before,
.col-13-16:after {
  content: '';
  display: table;
}

.col-13-16:after {
  clear: both;
}

.col-13-16:last-child {
  margin-right: 0%;
}

.col-14-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 87.125%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-14-16:before,
.col-14-16:after {
  content: '';
  display: table;
}

.col-14-16:after {
  clear: both;
}

.col-14-16:last-child {
  margin-right: 0%;
}

.col-15-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 93.5625%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-15-16:before,
.col-15-16:after {
  content: '';
  display: table;
}

.col-15-16:after {
  clear: both;
}

.col-15-16:last-child {
  margin-right: 0%;
}

.col-16-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 100%;
  margin-left: 0%;
  margin-right: 3%;
}

.col-16-16:before,
.col-16-16:after {
  content: '';
  display: table;
}

.col-16-16:after {
  clear: both;
}

.col-16-16:last-child {
  margin-right: 0%;
}

.span-1-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 10%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-1-10:before,
.span-1-10:after {
  content: '';
  display: table;
}

.span-1-10:after {
  clear: both;
}

.span-2-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 20%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-2-10:before,
.span-2-10:after {
  content: '';
  display: table;
}

.span-2-10:after {
  clear: both;
}

.span-3-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 30%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-3-10:before,
.span-3-10:after {
  content: '';
  display: table;
}

.span-3-10:after {
  clear: both;
}

.span-4-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 40%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-4-10:before,
.span-4-10:after {
  content: '';
  display: table;
}

.span-4-10:after {
  clear: both;
}

.span-5-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 50%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-5-10:before,
.span-5-10:after {
  content: '';
  display: table;
}

.span-5-10:after {
  clear: both;
}

.span-6-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 60%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-6-10:before,
.span-6-10:after {
  content: '';
  display: table;
}

.span-6-10:after {
  clear: both;
}

.span-7-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 70%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-7-10:before,
.span-7-10:after {
  content: '';
  display: table;
}

.span-7-10:after {
  clear: both;
}

.span-8-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 80%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-8-10:before,
.span-8-10:after {
  content: '';
  display: table;
}

.span-8-10:after {
  clear: both;
}

.span-9-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 90%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-9-10:before,
.span-9-10:after {
  content: '';
  display: table;
}

.span-9-10:after {
  clear: both;
}

.span-10-10 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-10-10:before,
.span-10-10:after {
  content: '';
  display: table;
}

.span-10-10:after {
  clear: both;
}

.span-1-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 6.25%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-1-16:before,
.span-1-16:after {
  content: '';
  display: table;
}

.span-1-16:after {
  clear: both;
}

.span-2-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 12.5%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-2-16:before,
.span-2-16:after {
  content: '';
  display: table;
}

.span-2-16:after {
  clear: both;
}

.span-3-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 18.75%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-3-16:before,
.span-3-16:after {
  content: '';
  display: table;
}

.span-3-16:after {
  clear: both;
}

.span-4-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 25%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-4-16:before,
.span-4-16:after {
  content: '';
  display: table;
}

.span-4-16:after {
  clear: both;
}

.span-5-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.25%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-5-16:before,
.span-5-16:after {
  content: '';
  display: table;
}

.span-5-16:after {
  clear: both;
}

.span-6-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 37.5%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-6-16:before,
.span-6-16:after {
  content: '';
  display: table;
}

.span-6-16:after {
  clear: both;
}

.span-7-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 43.75%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-7-16:before,
.span-7-16:after {
  content: '';
  display: table;
}

.span-7-16:after {
  clear: both;
}

.span-8-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 50%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-8-16:before,
.span-8-16:after {
  content: '';
  display: table;
}

.span-8-16:after {
  clear: both;
}

.span-9-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 56.25%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-9-16:before,
.span-9-16:after {
  content: '';
  display: table;
}

.span-9-16:after {
  clear: both;
}

.span-10-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 62.5%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-10-16:before,
.span-10-16:after {
  content: '';
  display: table;
}

.span-10-16:after {
  clear: both;
}

.span-11-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 68.75%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-11-16:before,
.span-11-16:after {
  content: '';
  display: table;
}

.span-11-16:after {
  clear: both;
}

.span-12-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 75%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-12-16:before,
.span-12-16:after {
  content: '';
  display: table;
}

.span-12-16:after {
  clear: both;
}

.span-13-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 81.25%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-13-16:before,
.span-13-16:after {
  content: '';
  display: table;
}

.span-13-16:after {
  clear: both;
}

.span-14-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 87.5%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-14-16:before,
.span-14-16:after {
  content: '';
  display: table;
}

.span-14-16:after {
  clear: both;
}

.span-15-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 93.75%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-15-16:before,
.span-15-16:after {
  content: '';
  display: table;
}

.span-15-16:after {
  clear: both;
}

.span-16-16 {
  *zoom: 1;
  float: left;
  clear: none;
  text-align: inherit;
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
}

.span-16-16:before,
.span-16-16:after {
  content: '';
  display: table;
}

.span-16-16:after {
  clear: both;
}
//</editor-fold>
