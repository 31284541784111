@mixin checkbox() {
	input[type="checkbox"] {
		width: 20px;
		height: 20px;
		position: relative;
		border: 1px solid $checkout-border-grey;
		@content;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $checkout-border-grey;
			background-image: backgroundImageURL("checkout/checkbox-tick.png");
			background-position: center;
			background-size: cover;
			opacity: 0;
			transition: all 0.5s;
		}
		&:hover {
			&:after {
				opacity: 0.5;
			}
		}
		&:checked {
			&:after {
				background-color: $sushi;
				opacity: 1;
			}
		}
	}
}
