//@import "../config/config";

///*=====================================================================</editor-fold>=
// CLIENTS
///=====================================================================<editor-fold>=*/

.client-top {
	@include frog-wallpaper();
	height: 285px;
	padding: 20px;
	display: table;
	width: 100%;
}

.client-top--inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-weight: $sf-weight--light;
}

.client-top--inner h1 {
	color: #fff;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 15px;
	font-weight: $sf-weight--light;
}

.client-top--inner p {
	color: #d1ff9b;
	max-width: 665px;
	margin: 0 auto;
	line-height: 28px;
}

.client-top--inner p a {
	text-decoration: underline;
	color: #fff;
}

@include respond-to-max("bs-md") {
	.clients-contact .wrap {
		padding: 0;
	}
}

.clients-contact--inner {
	background: #fff;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.clients-contact--inner_left,
.clients-contact--inner_right {
	padding: 50px;
}

@media (max-width: 880px) {
	.clients-contact--inner {
		display: block;
	}

	.clients-contact--inner_left,
	.clients-contact--inner_right {
		float: none;
		width: 100%;
		background: 0 0 !important;
		padding: 30px !important;
	}
}

.clients-contact--inner_left {
	background: #fff;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(88%, #fff),
		color-stop(88%, #fff),
		color-stop(100%, #f7f7f7)
	);
	background: linear-gradient(to right, #fff 88%, #fff 88%, #f7f7f7 100%);
}

.clients-contact--inner_left h2 {
	color: $sf-font-grey;
	font-size: 32px;
	margin-bottom: 25px;
}

.clients-contact--inner_left p {
	color: $sf-font-grey;
	font-weight: $sf-weight--light;
	margin-bottom: 25px;
	line-height: 28px;
	font-size: 16px;
}

.clients-contact--inner_left p a {
	color: #75b42c;
	text-decoration: underline;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.clients-contact--inner_left p a:hover {
	color: #599f08;
}

.clients-contact--inner_left p.testimonial_text {
	font-size: 24px;
	color: #6d6d6d;
	font-weight: $sf-weight--light;
	margin-bottom: 30px;
	line-height: 38px;
}

@media (max-width: 500px) {
	.clients-contact--inner_left p.testimonial_text {
		font-size: 21px;
		line-height: 32px;
	}
}

.clients-contact--inner_left .testimonial-quote {
	position: absolute;
	right: 0;
	bottom: 0;
}

.clients-contact--inner_left .testimonial-quote img {
	margin-right: 0;
}

.clients-contact--inner_right .wpcf7 .screen-reader-response {
	display: none;
}

.clients-contact--inner_right .wpcf7 input,
.clients-contact--inner_right .wpcf7 textarea,
.clients-contact--inner_right .wpcf7 select {
	border: 1px solid #e1e1e1;
	width: 100%;
	padding: 12px 20px;
	font-size: 15px;
	margin-bottom: 20px;
	font-family: $sf-font-family;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.clients-contact--inner_right .wpcf7 input:focus,
.clients-contact--inner_right .wpcf7 textarea:focus,
.clients-contact--inner_right .wpcf7 select:focus {
	border: 1px solid #bbb;
}

.clients-contact--inner_right .wpcf7 input.wpcf7-not-valid,
.clients-contact--inner_right .wpcf7 textarea.wpcf7-not-valid,
.clients-contact--inner_right .wpcf7 select.wpcf7-not-valid {
	border: 1px solid $sf-color--error;
}

.clients-contact--inner_right .wpcf7 textarea {
	max-height: 165px;
}

.clients-contact--inner_right .wpcf7 input[type="submit"] {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 180px;
	display: block;
	margin: 20px auto 0;
	border: 0;
	background: #75b42c;
	cursor: pointer;
}

.clients-contact--inner_right .wpcf7 input[type="submit"]:hover {
	background: #599f08;
}

.clients-contact--inner_right .wpcf7 .wpcf7-response-output {
	margin: 1em 0.5em 0;
}
//</editor-fold>
