.sf-assistive-action {
	background-color: #fff;
	border: 1px solid $sf-grey--light;
	border-radius: 5px;

	position: relative;
	padding: 5px;

	cursor: pointer;
	display: inline-block;
	transition: all 0.5s;

	font-weight: $sf-weight--med;
	color: $sf-grey--light !important;

	display: flex;
	justify-content: center;
	align-items: center;
	i {
		font-size: 20px;
		line-height: 20px;
		display: inline-block;
	}

	&:hover {
		color: $sf-green !important;
		box-shadow: 0px 0px 10px 0px rgba($sf-green, 0.75);
	}
}
