.sf-searchbar {
	flex-grow: 2;

	$search-icon--w: 20px;
	&__field {
		display: block;
		margin: 0 auto;
		@include text-input();
		.sf-searchbar--dark & {
			@include text-input($dark: true);
		}
		.sf-searchbar--sidebar & {
			@include text-input($border: false);
		}

		background-image: backgroundImageURL("icons/search@2x.png");
		background-size: 20px;
		background-repeat: no-repeat;
		background-color: transparent;

		background-position: center right $pad--min;
		padding: $pad--min $pad--min + $search-icon--w + $pad--min $pad--min $pad--min;
		.sf-searchbar--icon-at-start & {
			background-position: center left $pad--min;
			padding: $pad--min $pad--min $pad--min $pad--min + $search-icon--w + $pad--min;
		}

		width: 100%;
		max-width: 700px;
		.sf-searchbar--full-w & {
			max-width: unset;
		}
	}

	&--sidebar {
		padding: $pad--min;
	}
}
