//@import "../config/config";

///*=====================================================================</editor-fold>=
// ASPECT
///=====================================================================<editor-fold>=*/


.aspect {
  position: relative;
}

.aspect:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}

.aspect:before {
  content: '';
  float: left;
}

.aspect.l-16x9:before {
  padding-bottom: 56.25%;
}

.aspect iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


//</editor-fold>
