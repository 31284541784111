// indicatros from https://www.cssportal.com/css-loader-generator/

@mixin loadingSpinner($min-height: 100px, $pos: relative) {
	position: $pos;
	min-height: $min-height;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: rgba($checkout-light-grey, 0.7);
	}

	&:after {
		content: "";
		z-index: 1;
		position: absolute;
		width: 8px;
		left: calc(50% - 4px);
		height: 8px;
		top: calc(50% - 4px);
		border-radius: 100%;
		color: $sf-green;
		$loadingSpacing: 35px;
		box-shadow: calc(1 * $loadingSpacing) calc(0 * $loadingSpacing) 0 0,
			calc(0.707 * $loadingSpacing) calc(0.707 * $loadingSpacing) 0 1px,
			calc(0 * $loadingSpacing) calc(1 * $loadingSpacing) 0 2px,
			calc(-0.707 * $loadingSpacing) calc(0.707 * $loadingSpacing) 0 3px,
			calc(-1 * $loadingSpacing) calc(0 * $loadingSpacing) 0 4px,
			calc(-0.707 * $loadingSpacing) calc(-0.707 * $loadingSpacing) 0 5px,
			calc(0 * $loadingSpacing) calc(-1 * $loadingSpacing) 0 6px;
		animation: loading-spinner 1s infinite steps(8);
	}
}

@mixin loadingDots($color: #fff, $height: 24px, $width: 50px) {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		height: $height;
		top: calc(50% - #{calc($height / 2)});
		width: $width;
		left: calc(50% - #{calc($width / 2)});
		background: radial-gradient(circle closest-side, $color 90%, #0000) 0% 50%,
			radial-gradient(circle closest-side, $color 90%, #0000) 50% 50%,
			radial-gradient(circle closest-side, $color 90%, #0000) 100% 50%;
		background-size: calc(100% / 3) 12px;
		background-repeat: no-repeat;
		animation: loading-dots 1s infinite linear;
	}
}
