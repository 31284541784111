// prevents the PayPal Logo from being pushed to the right:
#payment ul.payment_methods li label[for="payment_method_braintree_paypal"] > img {
	margin-left: unset !important;
}

// PASSWORD INPUTS:
.theme-screamingfrog {
	$show-password-input--w: 30px;
	.woocommerce form .password-input {
		position: relative;
		input {
			width: 100% !important;
			padding-right: $show-password-input--w !important;
			position: relative;
		}
	}

	.woocommerce form .show-password-input,
	.woocommerce-page form .show-password-input {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: $show-password-input--w;
		cursor: pointer;
		font-size: 14px;
		color: $sf-font-grey;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;

		background-image: url(publicImagePath("checkout/show-pw.png"));
		background-size: calc(100% - 10px);
		background-repeat: no-repeat;
		background-position: center;

		opacity: 0.5;
		transition: opacity 0.3s;
		&:hover,
		&.display-password {
			opacity: 1;
		}

		&:after {
			margin: 0;
		}
	}
}
