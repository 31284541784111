.sf-software-overview {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: $pad--small;
	column-gap: $pad--small;
	text-align: center;

	&__cta {
		display: grid;
		grid-gap: $pad--small;
		width: 100%;
		max-width: 500px;
		margin: $pad--small auto 0;
	}
	&__preview {
		iframe {
			width: 100%;
			border: none;
		}
	}

	@include respond-to-max(md) {
		&__preview,
		&__operating-systems {
			display: grid;
			grid-template-columns: 1fr;
			row-gap: $pad--min;
		}
	}

	@include respond-to-min(sm) {
		&__text,
		&__preview,
		&__operating-systems {
			width: 100%;
			max-width: 780px;
			margin: 0 auto;
		}
		@include respond-to-min(md) {
			&__cta {
				grid-template-columns: repeat(2, 1fr); /* Creates a grid with 3 columns */
				&-btn {
					&--full-width {
						@include stretch-across-grid(); /* Spans the full width of the grid */
					}
				}
			}
			@include respond-to-min(lg) {
				text-align: left;
				grid-template-columns: 500px 1fr;
				grid-template-areas: "left-top right" "left-bottom right";
				&--reverse {
					grid-template-columns: 1fr 500px;
					grid-template-areas: "right left-top" "right left-bottom";
				}
				&__text {
					grid-area: left-top;
				}
				&__preview {
					grid-area: right;
				}
				&__operating-systems {
					grid-area: left-bottom;
				}
				@include respond-to-min(xl) {
					column-gap: $pad--large;
					grid-template-columns: 1fr 1.4fr;
					&--reverse {
						grid-template-columns: 1.4fr 1fr;
					}
				}
			}
		}
	}
}
