//@import "../config/config";

///*=====================================================================</editor-fold>=
// TIMELINE
///=====================================================================<editor-fold>=*/

.story-wrap {
	overflow: hidden;
}

.timeline-text {
	width: 100%;
	max-width: 1170px;
	top: 395px;
	z-index: 96;
	height: 5500px;

	a {
		color: #75b42c;
		text-decoration: underline;
		transition: all 0.2s ease-in-out;

		&:hover {
			color: #599f08;
		}
	}
}

.timeline-text,
.timeline-text--line {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
}

.timeline-text--line {
	width: 1px;
	height: 0;
	top: 525px;
	background: rgba(255, 255, 255, 0.6);
	max-height: 4700px;
}

.timeline-text--line:after {
	content: "";
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 0.6) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
	height: 20px;
	display: block;
	position: absolute;
	bottom: -20px;
	width: 1px;
}

@media (max-width: 1100px) {
	.timeline-text--line {
		display: none;
	}
}

.timeline-text--line_down {
	width: 1px;
	height: 0;
	position: absolute;
	bottom: 90px;
	left: 0;
	right: 0;
	margin: auto;
	background: rgba(255, 255, 255, 0.6);
	max-height: 4790px;
	top: auto;
}

.timeline-text--line_down:after {
	content: "";
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 0.6) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
	height: 20px;
	display: block;
	position: absolute;
	bottom: -20px;
	width: 1px;
}

@media (max-width: 1100px) {
	.timeline-text--line_down {
		display: none;
	}
}

.timeline-text h2 {
	font-size: 32px;
	color: #fff;
	font-weight: $sf-weight--light;
	margin-bottom: 20px;
}

.timeline-text p {
	font-size: 16px;
	color: #d8f4f8;
	font-weight: $sf-weight--light;
	line-height: 26px;
	margin-bottom: 15px;
}

.timeline-text > div > * {
	margin-bottom: 20px;
}

.timeline-text--right {
	opacity: 0;
	max-width: 500px;
	right: 0;
	position: absolute;
}

@media (max-width: 1140px) {
	.timeline-text--right {
		right: 15px;
	}
}

.timeline-text--left {
	opacity: 0;
	text-align: right;
	position: absolute;
	left: 0;
	max-width: 500px;
}

@media (max-width: 1140px) {
	.timeline-text--left {
		left: 15px;
	}
}

.timeline-text--point {
	height: 1px;
	width: 0;
	position: absolute;
	left: 50%;
	margin: auto;
	background: #fff;
	background: -webkit-linear-gradient(left, #fff 0%, #fff 58%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to right, #fff 0%, #fff 58%, rgba(255, 255, 255, 0) 100%);
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.timeline-text--point.draw-line {
	width: 80px;
}

@media (max-width: 1140px) {
	.timeline-text--point.draw-line {
		width: 30px;
	}
}

@media (max-width: 1100px) {
	.timeline-text--point {
		display: none;
	}
}

.timeline-text--point_left {
	right: 50%;
	left: auto;
	background: rgba(255, 255, 255, 0);
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 42%, #fff 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 42%, #fff 100%);
}

.timeline-text--point:before {
	content: "";
	display: block;
	background: #75b42c;
	border: 3px solid #fff;
	width: 11px;
	height: 11px;
	border-radius: 50px;
	position: absolute;
	left: -5px;
	top: -5px;
}

.timeline-text--point_left:before {
	left: auto;
	right: -6px;
}

@media (max-width: 1100px) {
	.timeline-text--show {
		padding: 0 20px;
		max-width: 620px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}
}

.timeline-text--intro {
	text-align: center;
	max-width: 620px;
	margin: auto;
	left: 0;
	right: 0;
	position: absolute;
	top: 250px;
	width: 100%;

	@include respond-to-max("bs-md") {
		top: 150px;
	}
}

.timeline-text--intro a {
	border-radius: 50px;
	text-align: center;
	color: #fff;
	font-weight: $sf-weight--heavy;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 100%;
	max-width: 230px;
	background: #75b42c;
	display: block;
	margin: 30px auto 0;
	padding: 14px 0;
	text-decoration: none;
}

.timeline-text--intro a:hover {
	background: #599f08;
	color: #fff;
}

.timeline-text--intro p {
	color: #a0b3b4;
}

@include respond-to-max("bs-md") {
	.timeline-text--intro {
		top: 50px;
		padding: 0 20px;
	}
}

.timeline-text--thirteen {
	top: 600px;
}

.timeline-text--thirteen--point {
	top: 620px;
}

@media (max-width: 1100px) {
	.timeline-text--thirteen {
		top: 525px;
	}
}

@include respond-to-max("bs-md") {
	.timeline-text--thirteen {
		top: 375px;
	}
}

@media (max-width: 450px) {
	.timeline-text--thirteen {
		top: 380px;
	}
}

//end

.timeline-text--twelve {
	top: 900px;
}

.timeline-text--twelve--point {
	top: 920px;
}

@media (max-width: 1100px) {
	.timeline-text--twelve {
		top: 750px;
	}
}

@include respond-to-max("bs-md") {
	.timeline-text--twelve {
		top: 625px;
	}
}

@media (max-width: 450px) {
	.timeline-text--twelve {
		top: 675px;
	}
}

//end

.timeline-text--eleven {
	top: 1140px;
}

.timeline-text--eleven--point {
	top: 1160px;
}

@media (max-width: 1100px) {
	.timeline-text--eleven {
		top: 1000px;
	}
}

@include respond-to-max("bs-md") {
	.timeline-text--eleven {
		top: 900px;
	}
}

@media (max-width: 450px) {
	.timeline-text--eleven {
		top: 975px;
	}
}

.timeline-text--ten {
	top: 1600px;
}

.timeline-text--ten--point {
	top: 1620px;
}

@media (max-width: 1100px) {
	.timeline-text--ten {
		top: 1215px;
	}
}

@include respond-to-max("bs-md") {
	.timeline-text--ten {
		top: 1125px;
	}
}

@media (max-width: 450px) {
	.timeline-text--ten {
		top: 1275px;
	}
}

.timeline-text--nine {
	top: 2135px;
}

.timeline-text--nine--point {
	top: 2155px;
}

@media (max-width: 1100px) {
	.timeline-text--nine {
		top: 1415px;
	}
}

@include respond-to-max("bs-md") {
	.timeline-text--nine {
		top: 1315px;
	}
}

@media (max-width: 450px) {
	.timeline-text--nine {
		top: 1575px;
	}
}

.timeline-text--eight {
	top: 2530px;
}

.timeline-text--eight--point {
	top: 2550px;
}

@media (max-width: 1100px) {
	.timeline-text--eight {
		top: 1920px;
	}
}

@media (max-width: 450px) {
	.timeline-text--eight {
		top: 2250px;
	}
}

.timeline-text--seven {
	top: 2820px;
}

.timeline-text--seven--point {
	top: 2840px;
}

@media (max-width: 1100px) {
	.timeline-text--seven {
		top: 2620px;
	}
}

.timeline-text--six {
	top: 3420px;
}

.timeline-text--six--point {
	top: 3440px;
}

@media (max-width: 1100px) {
	.timeline-text--six {
		top: 3420px;
	}
}

@media (max-width: 450px) {
	.timeline-text--six {
		top: 3370px;
	}
}

.timeline-text--five {
	top: 3930px;
}

.timeline-text--five--point {
	top: 3950px;
}

@media (max-width: 1100px) {
	.timeline-text--five {
		top: 3980px;
	}
}

@media (max-width: 450px) {
	.timeline-text--five {
		top: 4110px;
	}
}

.timeline-text--four {
	top: 4240px;
}

.timeline-text--four--point {
	top: 4260px;
}

@media (max-width: 1100px) {
	.timeline-text--four {
		top: 4410px;
	}
}

@media (max-width: 450px) {
	.timeline-text--four {
		top: 4590px;
	}
}

.timeline-text--three {
	top: 4565px;
}

.timeline-text--three--point {
	top: 4585px;
}

@media (max-width: 1100px) {
	.timeline-text--three {
		top: 4975px;
	}
}

@media (max-width: 450px) {
	.timeline-text--three {
		top: 5105px;
	}
}

.timeline-text--two {
	top: 4890px;
}

.timeline-text--two--point {
	top: 4935px;
}

@media (max-width: 1100px) {
	.timeline-text--two {
		top: 5260px;
	}
}

@media (max-width: 450px) {
	.timeline-text--two {
		top: 5430px;
	}
}

.timeline-text--one {
	top: 5215px;
}

.timeline-text--one--point {
	top: 5235px;
}

@media (max-width: 1100px) {
	.timeline-text--one {
		top: 5540px;
	}
}

@media (max-width: 450px) {
	.timeline-text--one {
		top: 5755px;
	}
}

.timeline-sky {
	background: rgba(32, 32, 34, 0.86);
	background: -webkit-linear-gradient(
		top,
		rgba(32, 32, 34, 0.86) 0%,
		#202022 0%,
		#3e324a 45%,
		#068bb5 90%,
		#eaa825 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(32, 32, 34, 0.86) 0%,
		#202022 0%,
		#3e324a 45%,
		#068bb5 90%,
		#eaa825 100%
	);
	min-height: 3370px;
	position: relative;
}

.timeline-sky--planet {
	width: 200px;
	height: 200px;
	border-radius: 300px;
	background: #689aca;
	position: absolute;
	top: 100px;
	left: 100px;
	overflow: hidden;
	-webkit-transform: rotateZ(-10deg);
	transform: rotateZ(-10deg);
	-webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

@include respond-to-max("bs-md") {
	.timeline-sky--planet {
		top: 30px;
		left: -110px;
	}
}

.timeline-sky--planet--inner .top-cloud {
	position: absolute;
	background: url("../../public/images/story/planet-clouds.png") repeat-x;
	height: 128px;
	width: 476px;
	top: 35px;
	-webkit-animation: planetSpin 5s linear infinite;
	animation: planetSpin 5s linear infinite;
	background-size: 238px 127px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.timeline-sky--planet--inner .top-cloud {
		background: url("../../public/images/story/planet-clouds@2x.png") repeat-x;
		background-size: 238px 127px;
	}
}

.timeline-sky--planet--inner img {
	position: absolute;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
}

.timeline-sky--planet--inner img.top {
	top: 35px;
}

.timeline-sky--planet--inner img.top.two {
	left: 260px;
}

.timeline-sky--planet--inner img.bottom {
	bottom: 35px;
}

.timeline-sky--planet--inner img.bottom.two {
	left: 260px;
}

.timeline-sky--asteroid_large {
	position: absolute;
	top: 800px;
	left: -250px;
	-webkit-animation: asteroidLargeMove 35s linear infinite;
	animation: asteroidLargeMove 35s linear infinite;
}

.timeline-sky--asteroid_large img {
	-webkit-animation: asteroidSmall 5s linear infinite;
	animation: asteroidSmall 5s linear infinite;
	max-width: 184px;
}

.timeline-sky--asteroid_small {
	position: absolute;
	right: -220px;
	top: 340px;
	-webkit-animation: asteroidSmallMove 10s linear infinite;
	animation: asteroidSmallMove 10s linear infinite;
}

.timeline-sky--asteroid_small img {
	-webkit-animation: asteroidSmall 3s linear infinite;
	animation: asteroidSmall 3s linear infinite;
	max-width: 228px;
}

.timeline-sky--spacefrog {
	position: absolute;
	right: 310px;
	top: 1420px;
	-webkit-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
	z-index: 0;
}

.timeline-sky--spacefrog.slideUp {
	-webkit-transform: translateY(-840px);
	-ms-transform: translateY(-840px);
	transform: translateY(-840px);
}

.timeline-sky--spacefrog.animate {
	-webkit-animation: spaceFrog 1s ease-in-out infinite alternate;
	animation: spaceFrog 1s ease-in-out infinite alternate;
}

.timeline-sky--spacefrog img {
	z-index: 99;
	position: relative;
	max-width: 187px;
}

.timeline-sky--spacefrog:before {
	content: "";
	background: url("../../public/images/story/fire.png") no-repeat;
	background-size: 100%;
	width: 46px;
	height: 107px;
	display: block;
	position: absolute;
	right: 7px;
	bottom: -10px;
	z-index: 0;
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	transform-origin: top;
	-webkit-animation: spaceFrogJetpack 0.2s ease-in-out infinite;
	animation: spaceFrogJetpack 0.2s ease-in-out infinite;
}

@media (max-width: 1570px) {
	.timeline-sky--spacefrog {
		right: 50px;
	}
}

@media (max-width: 1100px) {
	.timeline-sky--spacefrog {
		top: 900px;
		right: 0;
	}

	.timeline-sky--spacefrog img {
		width: 70%;
	}

	.timeline-sky--spacefrog:before {
		width: 15px;
		bottom: -50px;
		right: 60px;
	}
}

.timeline-sky--rocket {
	position: absolute;
	top: 990px;
	left: 280px;
	-webkit-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
	transform: rotate(20deg);
}

.timeline-sky--rocket.fire {
	-webkit-animation: skyRocket 15s ease-in-out;
	animation: skyRocket 15s ease-in-out;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.timeline-sky--rocket img {
	max-width: 88px;
}

.timeline-sky--rocket:before {
	content: "";
	background: url("../../public/images/story/rocket-fire.png") no-repeat;
	width: 26px;
	height: 47px;
	bottom: -35px;
	left: 30px;
	display: block;
	position: absolute;
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	transform-origin: top;
	-webkit-animation: spaceFrogJetpack 0.2s ease-in-out infinite;
	animation: spaceFrogJetpack 0.2s ease-in-out infinite;
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
	background-size: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.timeline-sky--rocket:before {
		background: url("../../public/images/story/rocket-fire@2x.png") no-repeat;
		background-size: 100%;
	}
}

.timeline-sky--sun {
	position: absolute;
	bottom: -210px;
	margin: auto;
	left: 0;
	right: 0;
}

.timeline-sky--sun img {
	display: block;
	margin: 0 auto;
}

@media (max-width: 450px) {
	.timeline-sky--sun {
		bottom: -100px;
	}

	.timeline-sky--sun img {
		width: 100%;
	}
}

.timeline-sky--branch {
	position: relative;
	height: 2445px;
	pointer-events: none;
}

@media (max-width: 1100px) {
	.timeline-sky--branch {
		height: 1805px;
	}
}

@media (max-width: 450px) {
	.timeline-sky--branch {
		height: 2205px;
	}
}

.timeline-sky--branch img {
	position: absolute;
	bottom: 0;
	left: -200px;
}

.timeline-sky--branch .svg-flag {
	position: absolute;
	bottom: 40px;
	z-index: 2;
	left: 200px;
	-webkit-transform-origin: 50px bottom;
	-ms-transform-origin: 50px bottom;
	transform-origin: 50px bottom;
}

.timeline-sky--branch .svg-flag svg {
	width: 80px;
	height: 190px;
	display: block;
}

@media (max-width: 480px) {
	.timeline-sky--branch .svg-flag {
		left: 80px;
	}
}

.timeline-sky--branch .svg-flag:before {
	content: "";
	background: url("../../public/images/story/uksearch-flag.png") no-repeat;
	display: block;
	width: 0;
	height: 88px;
	position: absolute;
	top: -11px;
	left: 32px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	background-size: 165px 88px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.timeline-sky--branch .svg-flag:before {
		background: url("../../public/images/story/uksearch-flag@2x.png") no-repeat;
		background-size: 165px 88px;
	}
}

.timeline-sky--branch .svg-flag.show-flag:before {
	width: 165px;
}

.timeline-sky--branch .svg-flag.wave-flag {
	-webkit-animation: flagWave 1s ease-in-out;
	animation: flagWave 1s ease-in-out;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
}

.timeline-sky--tree {
	position: absolute;
	height: 2026px;
	width: 1449px;
	right: -20px;
	bottom: 75px;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.timeline-sky--tree-inner {
	max-width: 1600px;
	width: 100%;
	position: relative;
}

.timeline-sky--tree-main {
	position: absolute;
	height: 2026px;
	width: 1449px;
	right: 0;
	background: url("../../public/images/story/big-tree.png") no-repeat;
	background-size: 100%;
	left: 690px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

@media (max-width: 1100px) {
	.timeline-sky--tree-main {
		display: none;
	}
}

.timeline-sky--tree-main_spider {
	position: absolute;
	top: 640px;
	left: 500px;
	z-index: -1;
	-webkit-transform: rotate(-1deg);
	-ms-transform: rotate(-1deg);
	transform: rotate(-1deg);
	-webkit-animation: sway 4s ease-in-out infinite;
	animation: sway 4s ease-in-out infinite;
	-webkit-transform-origin: top center;
	-ms-transform-origin: top center;
	transform-origin: top center;
}

.timeline-sky--tree-main_spider:before {
	content: "";
	height: 10px;
	width: 1px;
	background: #fff;
	display: block;
	left: 38px;
	position: absolute;
	z-index: 0;
	top: -2px;
	-webkit-animation: web 15s ease-in-out infinite;
	animation: web 15s ease-in-out infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.timeline-sky--tree-main_spider img {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-animation: spider 15s ease-in-out infinite;
	animation: spider 15s ease-in-out infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
	max-width: 77px;
}

@media (max-width: 1245px) {
	.timeline-sky--tree-main {
		left: 970px;
	}
}

.timeline-sky--tree-branch {
	background: url("../../public/images/story/frog-branch.png") no-repeat;
	width: 893px;
	height: 203px;
	background-size: 100%;
	position: absolute;
	right: -75px;
	z-index: 0;
	bottom: 580px;
}

@media (max-width: 1245px) {
	.timeline-sky--tree-branch {
		right: -185px;
	}
}

@media (max-width: 1100px) {
	.timeline-sky--tree-branch {
		right: 0;
		bottom: 855px;
	}
}

@media (max-width: 500px) {
	.timeline-sky--tree-branch {
		bottom: 495px;
	}
}

.timeline-sky--tree-branch-inner {
	position: relative;
}

.timeline-sky--tree-branch .branch-frog {
	position: absolute;
}

.timeline-sky--tree-branch .branch-frog_eyes {
	position: absolute;
	top: 0;
	width: 100%;
}

.timeline-sky--tree-branch .branch-frog_eyes-right,
.timeline-sky--tree-branch .branch-frog_eyes-left {
	width: 8px;
	height: 8px;
	background: #75b42c;
	border-radius: 50px;
	position: absolute;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.timeline-sky--tree-branch .branch-frog_eyes-right:before,
.timeline-sky--tree-branch .branch-frog_eyes-left:before {
	content: "";
	height: 4px;
	width: 4px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	display: block;
	left: 2px;
	top: -1px;
}

.timeline-sky--tree-branch-frog_one {
	left: 170px;
	top: 18px;
}

.timeline-sky--tree-branch-frog_one img {
	max-width: 177px;
}

.timeline-sky--tree-branch-frog_one_eyes-left {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	left: 60px;
	top: 13px;
	width: 15px !important;
	height: 15px !important;
}

.timeline-sky--tree-branch-frog_one_eyes-left:before {
	left: 6px;
	top: 0;
	height: 8px !important;
	width: 8px !important;
}

.timeline-sky--tree-branch-frog_one_eyes-right {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	left: 95px;
	top: 14px;
	width: 15px !important;
	height: 15px !important;
}

.timeline-sky--tree-branch-frog_one_eyes-right:before {
	left: 6px;
	top: 0;
	height: 8px !important;
	width: 8px !important;
}

.timeline-sky--tree-branch-frog_one_eyes-left,
.timeline-sky--tree-branch-frog_one_eyes-right {
	-webkit-animation: eyes 20s ease-in-out infinite;
	animation: eyes 20s ease-in-out infinite;
}

.timeline-sky--tree-branch-frog_two {
	left: 390px;
	top: 53px;
}

.timeline-sky--tree-branch-frog_two img {
	max-width: 78px;
}

.timeline-sky--tree-branch-frog_two_eyes-left {
	width: 8px;
	height: 8px;
	left: 29px;
	top: 6px;
}

.timeline-sky--tree-branch-frog_two_eyes-right {
	left: 48px;
	top: 6px;
}

.timeline-sky--tree-branch-frog_two_eyes-left,
.timeline-sky--tree-branch-frog_two_eyes-right {
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
	-webkit-animation-delay: 5s;
	animation-delay: 5s;
}

.timeline-sky--tree-branch-frog_three {
	right: 350px;
	top: 62px;
}

.timeline-sky--tree-branch-frog_three img {
	max-width: 73px;
}

.timeline-sky--tree-branch-frog_three_eyes-left {
	left: 24px;
	top: 4px;
}

.timeline-sky--tree-branch-frog_three_eyes-right {
	left: 47px;
	top: 4px;
}

.timeline-sky--tree-branch-frog_three_eyes-left,
.timeline-sky--tree-branch-frog_three_eyes-right {
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.timeline-sky--tree-branch-frog_four {
	right: 260px;
	top: 51px;
}

.timeline-sky--tree-branch-frog_four img {
	max-width: 91px;
}

.timeline-sky--tree-branch-frog_four_eyes-left {
	left: 25px;
	top: 6px;
}

.timeline-sky--tree-branch-frog_four_eyes-right {
	left: 52px;
	top: 7px;
}

.timeline-sky--tree-branch-frog_four_eyes-left,
.timeline-sky--tree-branch-frog_four_eyes-right {
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
	-webkit-animation-delay: 7s;
	animation-delay: 7s;
}

.timeline-sky--tree-branch-frog_five {
	right: 180px;
	top: 18px;
}

.timeline-sky--tree-branch-frog_five img {
	max-width: 90px;
}

.timeline-sky--tree-branch-frog_five_eyes-left {
	left: 35px;
	top: 6px;
}

.timeline-sky--tree-branch-frog_five_eyes-right {
	left: 62px;
	top: 7px;
}

.timeline-sky--tree-branch-frog_five_eyes-left,
.timeline-sky--tree-branch-frog_five_eyes-right {
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
	-webkit-animation-delay: 8s;
	animation-delay: 8s;
}

.timeline-sky--tree-branch-spider {
	position: absolute;
	right: 340px;
	top: 170px;
	-webkit-transform: rotate(-1deg);
	-ms-transform: rotate(-1deg);
	transform: rotate(-1deg);
	-webkit-animation: sway 4s ease-in-out infinite;
	animation: sway 4s ease-in-out infinite;
	-webkit-transform-origin: top center;
	-ms-transform-origin: top center;
	transform-origin: top center;
}

.timeline-sky--tree-branch-spider img {
	position: relative;
	z-index: 1;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-animation: spider 15s ease-in-out infinite;
	animation: spider 15s ease-in-out infinite;
	-webkit-animation-delay: 8s;
	animation-delay: 8s;
	max-width: 37px;
}

.timeline-sky--tree-branch-spider:before {
	content: "";
	height: 10px;
	width: 1px;
	background: #fff;
	display: block;
	left: 18px;
	position: absolute;
	z-index: 0;
	top: -2px;
	-webkit-animation: web 15s ease-in-out infinite;
	animation: web 15s ease-in-out infinite;
	-webkit-animation-delay: 8s;
	animation-delay: 8s;
}

@media (max-width: 450px) {
	.timeline-sky--tree-branch-spider {
		display: none;
	}
}

@media (max-width: 1540px) {
	.timeline-sky--tree {
		right: -175px;
	}
}

.timeline-sea {
	min-height: 2450px;
	background: #276e92;
	background: -webkit-linear-gradient(top, #276e92 0%, #0b2e40 100%);
	background: linear-gradient(to bottom, #276e92 0%, #0b2e40 100%);
	border-bottom: 4px solid #427e37;
	position: relative;
}

@media (max-width: 1100px) {
	.timeline-sea {
		min-height: 2490px;
	}
}

.timeline-sea--top {
	height: 910px;
	position: relative;
}

.timeline-sea--top svg {
	stroke: #276e92;
	fill: #276e92;
	-webkit-transition: -webkit-transform 0.8s, stroke 0.7s, stroke-dashoffset 2s;
	transition: transform 0.8s, stroke 0.7s, stroke-dashoffset 2s;
	-webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
	top: -56px;
	position: absolute;
	display: block;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: wave 20s ease-in-out infinite alternate;
	animation: wave 20s ease-in-out infinite alternate;
}

@media (max-width: 450px) {
	.timeline-sea--top svg {
		display: none;
	}
}

.timeline-sea--lilypad {
	width: 100%;
}

.timeline-sea--lilypad-one {
	position: absolute;
	left: 10%;
	margin: auto;
	top: -100px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-animation: lilypad-two 20s ease-in-out infinite alternate;
	animation: lilypad-two 20s ease-in-out infinite alternate;
}

@media (max-width: 450px) {
	.timeline-sea--lilypad-one {
		-webkit-animation: none;
		animation: none;
		top: -50px;
	}
}

.timeline-sea--lilypad-one img {
	max-width: 158px;
}

.timeline-sea--lilypad-one-eyes {
	position: absolute;
	top: 0;
	width: 100%;
}

.timeline-sea--lilypad-one-eyes_right,
.timeline-sea--lilypad-one-eyes_left {
	width: 8px;
	height: 8px;
	background: #75b42c;
	border-radius: 50px;
	position: absolute;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
}

.timeline-sea--lilypad-one-eyes_right:before,
.timeline-sea--lilypad-one-eyes_left:before {
	content: "";
	height: 4px;
	width: 4px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	display: block;
	left: 2px;
	top: -1px;
}

.timeline-sea--lilypad-one-eyes_left {
	left: 54px;
	top: 10px;
}

.timeline-sea--lilypad-one-eyes_right {
	left: 76px;
	top: 8px;
}

.timeline-sea--lilypad-two {
	position: absolute;
	right: 270px;
	margin: auto;
	top: -100px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-animation: lilypad 20s ease-in-out infinite alternate;
	animation: lilypad 20s ease-in-out infinite alternate;
}

.timeline-sea--lilypad-two img {
	width: 221px;
}

@media (max-width: 1540px) {
	.timeline-sea--lilypad-two {
		right: 5%;
	}
}

@media (max-width: 1100px) {
	.timeline-sea--lilypad-two {
		display: none;
	}
}

.timeline-sea--lilypad-two-eyes {
	position: absolute;
	top: 0;
	width: 100%;
}

.timeline-sea--lilypad-two-eyes_right,
.timeline-sea--lilypad-two-eyes_left {
	width: 14px;
	height: 14px;
	background: #75b42c;
	border-radius: 50px;
	position: absolute;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	-webkit-animation: eyes 15s ease-in-out infinite;
	animation: eyes 15s ease-in-out infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.timeline-sea--lilypad-two-eyes_right:before,
.timeline-sea--lilypad-two-eyes_left:before {
	content: "";
	height: 7px;
	width: 7px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	display: block;
	left: 2px;
	top: -1px;
}

.timeline-sea--lilypad-two-eyes_left {
	left: 99px;
	top: 12px;
}

.timeline-sea--lilypad-two-eyes_right {
	left: 135px;
	top: 15px;
}

.timeline-sea--bottom {
	height: 1540px;
	position: relative;
}

@media (max-width: 1100px) {
	.timeline-sea--bottom {
		height: 1776px;
	}
}

@media (max-width: 500px) {
	.timeline-sea--bottom {
		height: 2110px;
	}
}

.timeline-sea--fish-wrap {
	position: absolute;
	bottom: 280px;
	height: 106px;
	width: 100%;
	-webkit-animation: swim 25s linear 8s infinite;
	animation: swim 25s linear 8s infinite;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
	opacity: 0;
}

.timeline-sea--fish-wrap-top {
	-webkit-animation: swim 25s linear infinite;
	animation: swim 25s linear infinite;
}

.timeline-sea--fish {
	width: 137px;
	height: 106px;
	position: absolute;
	left: 0;
}

@media (max-width: 450px) {
	.timeline-sea--fish-wrap {
		bottom: 350px;
	}
}

.timeline-sea--fish_single {
	background: url("../../public/images/story/single-fish@2x.png") no-repeat;
	height: 21px;
	width: 48px;
	background-size: 100%;
	display: block;
	position: absolute;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.timeline-sea--fish_single:after {
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 8px 0;
	border-color: transparent #305274 transparent transparent;
	position: absolute;
	right: -6px;
	top: 2px;
	-webkit-animation: fins 0.5s ease-in-out infinite;
	animation: fins 0.5s ease-in-out infinite;
}

.timeline-sea--fish_single:nth-child(even):after {
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.timeline-sea--fish_single:nth-child(2n + 1) {
	-webkit-animation: fishStop 3s ease-in-out infinite;
	animation: fishStop 3s ease-in-out infinite;
}

.timeline-sea--fish_single-0 {
	left: 22px;
	right: 0;
	margin: auto;
}

.timeline-sea--fish_single-1 {
	top: 25px;
	left: 27px;
}

.timeline-sea--fish_single-2 {
	top: 25px;
	right: 0;
}

.timeline-sea--fish_single-3 {
	top: 54px;
}

.timeline-sea--fish_single-4 {
	top: 54px;
	left: 63px;
}

.timeline-sea--fish_single-5 {
	left: 27px;
	top: 84px;
}

.timeline-sea--fish_single-6 {
	top: 84px;
	right: 0;
}

.timeline-sea--fish_single-7 {
	bottom: -29px;
	left: 23px;
	right: 0;
	margin: auto;
}

.timeline-sea--frogspawn--outer {
	position: absolute;
	left: 25%;
	width: 140px;
	height: 150px;
	bottom: 450px;
}

@media (max-width: 1100px) {
	.timeline-sea--frogspawn--outer {
		left: -40px;
		right: auto;
		margin: auto;
		bottom: 170px;
	}
}

.timeline-sea--frogspawn {
	background: url("../../public/images/story/frogspawn-chunk@2x.png") no-repeat;
	background-size: 100%;
	-webkit-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
	left: 50px;
}

.timeline-sea--frogspawn,
.timeline-sea--frogspawn-low {
	width: 84px;
	height: 108px;
	display: block;
	position: relative;
	-webkit-animation: pulse 4s ease-in-out infinite;
	animation: pulse 4s ease-in-out infinite;
	z-index: 3;
}

.timeline-sea--frogspawn-low {
	background: url("../../public/images/story/frogspawn-chunk2@2x.png") no-repeat;
	background-size: 100% !important;
	top: -70px;
	left: 0;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.timeline-sea--frogspawn-glow {
	position: relative;
	display: inline-block;
	width: 0;
	height: 0;
	border-radius: 50%;
	border-color: transparent;
	box-shadow: 0 0 40px 20px rgba(255, 255, 255, 0.5), 0 0 120px 55px rgba(0, 255, 255, 0.6);
	top: -150px;
	z-index: 0;
	left: 65px;
	opacity: 0.3;
	-webkit-animation: glow 2s ease-in-out infinite;
	animation: glow 2s ease-in-out infinite;
}

.timeline-sea--seaweed {
	background: url("../../public/images/story/seaweed.png") no-repeat;
	background-size: 100%;
	width: 1730px;
	height: 219px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.timeline-sea--tadpoles {
	position: absolute;
	top: 70px;
	width: 100%;
	margin: auto;
	left: 0;
	right: 0;
	max-width: 1600px;
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles {
		top: 600px;
	}
}

@media (max-width: 450px) {
	.timeline-sea--tadpoles {
		display: none;
	}
}

.timeline-sea--tadpoles-inner {
	width: 150px;
	height: 170px;
	position: relative;
	float: right;
	right: 20%;
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles-inner {
		float: none;
		left: 30px;
		margin: 0 auto;
		right: auto;
	}
}

.timeline-sea--tadpoles_single {
	background: url("../../public/images/story/tadpole-head@2x.png") no-repeat;
	width: 55px;
	height: 76px;
	background-size: 100%;
	-webkit-transform: translate(0, 0) rotate(0deg);
	-ms-transform: translate(0, 0) rotate(0deg);
	transform: translate(0, 0) rotate(0deg);
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	-webkit-animation: tadpoleBob 3s ease-in-out infinite alternate;
	animation: tadpoleBob 3s ease-in-out infinite alternate;
}

.timeline-sea--tadpoles_single,
.timeline-sea--tadpoles_single:before {
	position: absolute;
	-webkit-transform-origin: top right;
	-ms-transform-origin: top right;
	transform-origin: top right;
}

.timeline-sea--tadpoles_single:before {
	content: "";
	background: url("../../public/images/story/tadpole-tail@2x.png") no-repeat;
	display: block;
	width: 24px;
	height: 47px;
	background-size: 100% !important;
	-webkit-animation: tadpoleSwim 0.5s ease-in-out infinite;
	animation: tadpoleSwim 0.5s ease-in-out infinite;
}

.timeline-sea--tadpoles_single:after {
	content: "";
	width: 10px;
	height: 10px;
	background: 0 0;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 50px;
	display: block;
	position: absolute;
	top: -80px;
	left: 80px;
	opacity: 0;
}

.timeline-sea--tadpoles_single:nth-child(2n + 1) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles_single:nth-child(2n + 1):before {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles_single-0 {
	width: 26px;
	left: 40px;
}

.timeline-sea--tadpoles_single-0:before {
	width: 24px;
	top: 13px;
	left: -7px;
}

.timeline-sea--tadpoles_single-0:after {
	left: 60px;
	-webkit-animation: tadpoleBubbles 1s ease-in-out infinite;
	animation: tadpoleBubbles 1s ease-in-out infinite;
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles_single-1 {
	width: 22px;
	left: 55px;
	top: 30px;
}

.timeline-sea--tadpoles_single-1:before {
	width: 18px;
	top: 14px;
	left: -6px;
}

.timeline-sea--tadpoles_single-1:after {
	-webkit-animation: tadpoleBubbles 1s ease-in-out infinite;
	animation: tadpoleBubbles 1s ease-in-out infinite;
	left: 190px;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles_single-2 {
	width: 17px;
	top: 30px;
	left: 8px;
}

.timeline-sea--tadpoles_single-2:before {
	width: 14px;
	top: 11px;
	left: -5px;
}

.timeline-sea--tadpoles_single-2:after {
	-webkit-animation: tadpoleBubbles 1s ease-in-out infinite;
	animation: tadpoleBubbles 1s ease-in-out infinite;
	left: 170px;
}

.timeline-sea--tadpoles_single-3 {
	width: 20px;
	top: 52px;
	left: 17px;
}

.timeline-sea--tadpoles_single-3:before {
	width: 17px;
	top: 11px;
	left: -5px;
}

.timeline-sea--tadpoles_single-3:after {
	-webkit-animation: tadpoleBubbles 1s ease-in-out infinite;
	animation: tadpoleBubbles 1s ease-in-out infinite;
	left: -50px;
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s;
}

.timeline-sea--tadpoles_single-4 {
	width: 23px;
	left: 43px;
	top: 70px;
}

.timeline-sea--tadpoles_single-4:before {
	width: 17px;
	top: 17px;
	left: -6px;
}

.timeline-sea--tadpoles_single-5 {
	width: 25px;
	top: 80px;
	left: -19px;
}

.timeline-sea--tadpoles_single-5:before {
	width: 21px;
	top: 14px;
	left: -6px;
}

.timeline-sea--tadpoles_single-6 {
	width: 16px;
	top: 90px;
	left: 15px;
}

.timeline-sea--tadpoles_single-6:before {
	width: 14px;
	top: 10px;
	left: -5px;
}

.timeline-sea--tadpoles_single-7 {
	width: 23px;
	top: 118px;
	left: 20px;
}

.timeline-sea--tadpoles_single-7:before {
	width: 21px;
	top: 13px;
	left: -7px;
}

.timeline-sea--tadpoles_single-8 {
	width: 23px;
	top: 108px;
	left: 57px;
}

.timeline-sea--tadpoles_single-8:before {
	left: -6px;
	top: 14px;
	width: 19px;
}

.timeline-sea--tadpoles_single-9 {
	width: 18px;
	top: 100px;
	left: -54px;
}

.timeline-sea--tadpoles_single-9:before {
	width: 14px;
	top: 11px;
	left: -4px;
}

.timeline-sea--tadpoles_single-10 {
	width: 22px;
	top: 125px;
	left: -45px;
}

.timeline-sea--tadpoles_single-10:before {
	width: 19px;
	top: 13px;
	left: -6px;
}

.timeline-sea--tadpoles_single-11 {
	width: 21px;
	top: 127px;
	left: -12px;
}

.timeline-sea--tadpoles_single-11:before {
	width: 18px;
	top: 11px;
	left: -5px;
}

.timeline-sea--tadpoles-medium {
	position: absolute;
	bottom: 210px;
	max-width: 1600px;
	width: 100%;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-transform: rotate(-18deg);
	-ms-transform: rotate(-18deg);
	transform: rotate(-18deg);
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles-medium {
		bottom: -230px;
	}
}

@media (max-width: 450px) {
	.timeline-sea--tadpoles-medium {
		bottom: -440px;
	}
}

.timeline-sea--tadpoles-medium-inner {
	position: relative;
	float: left;
	left: 30%;
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles-medium-inner {
		left: 48%;
	}
}

.timeline-sea--tadpoles-medium_single {
	background: url("../../public/images/story/tadpole/head.png") no-repeat;
	background-size: 34px 34px;
	position: relative;
	-webkit-animation: tadpoleBob 3s ease-in-out infinite alternate;
	animation: tadpoleBob 3s ease-in-out infinite alternate;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.timeline-sea--tadpoles-medium_single {
		background: url("../../public/images/story/tadpole/head@2x.png") no-repeat;
		background-size: 34px 34px;
	}
}

.timeline-sea--tadpoles-medium_single img {
	position: relative;
}

.timeline-sea--tadpoles-medium_single img.body {
	top: 26px;
	left: -35px;
	-webkit-animation: tadpoleSwim 2s ease-in-out infinite alternate;
	animation: tadpoleSwim 2s ease-in-out infinite alternate;
	-webkit-transform-origin: 17px top;
	-ms-transform-origin: 17px top;
	transform-origin: 17px top;
	z-index: 1;
	max-width: 24px;
}

.timeline-sea--tadpoles-medium_single img.left-arm {
	left: -17px;
	max-width: 24px;
}

.timeline-sea--tadpoles-medium_single img.right-arm {
	left: -43px;
	top: 12px;
	z-index: 0;
	max-width: 14px;
}

.timeline-sea--tadpoles-medium_single:after,
.timeline-sea--tadpoles-medium_single:before {
	content: "";
	width: 10px;
	height: 10px;
	background: 0 0;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 50px;
	display: block;
	position: absolute;
	top: -120px;
	left: 80px;
	opacity: 0;
}

.timeline-sea--tadpoles-medium_single:nth-child(2n + 1),
.timeline-sea--tadpoles-medium_single:nth-child(2n + 1) img.body {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles-medium_single:nth-child(3) img.body {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles-medium_single-one {
	left: 10px;
}

.timeline-sea--tadpoles-medium_single-one:after {
	left: 60px;
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles-medium_single-one:after,
.timeline-sea--tadpoles-medium_single-one:before {
	-webkit-animation: tadpoleBubblesMedium 1s ease-in-out infinite;
	animation: tadpoleBubblesMedium 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-medium_single-one:before {
	left: 190px;
	-webkit-animation-delay: 1.9s;
	animation-delay: 1.9s;
}

.timeline-sea--tadpoles-medium_single-two {
	left: -50px;
	top: -20px;
}

.timeline-sea--tadpoles-medium_single-two:after {
	left: 190px;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles-medium_single-two:after,
.timeline-sea--tadpoles-medium_single-two:before {
	-webkit-animation: tadpoleBubblesMedium 1s ease-in-out infinite;
	animation: tadpoleBubblesMedium 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-medium_single-two:before {
	left: 120px;
	-webkit-animation-delay: 1.7s;
	animation-delay: 1.7s;
}

.timeline-sea--tadpoles-medium_single-three {
	top: -30px;
	left: 25px;
}

.timeline-sea--tadpoles-medium_single-three:after {
	left: -50px;
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s;
}

.timeline-sea--tadpoles-medium_single-three:after,
.timeline-sea--tadpoles-medium_single-three:before {
	-webkit-animation: tadpoleBubblesMedium 1s ease-in-out infinite;
	animation: tadpoleBubblesMedium 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-medium_single-three:before {
	left: -80px;
	-webkit-animation-delay: 2.3s;
	animation-delay: 2.3s;
}

.timeline-sea--tadpoles-large {
	position: absolute;
	top: 200px;
	max-width: 1600px;
	width: 100%;
	left: 0;
	right: 0;
	margin: auto;
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles-large {
		top: 470px;
	}
}

@media (max-width: 450px) {
	.timeline-sea--tadpoles-large {
		top: 640px;
	}
}

.timeline-sea--tadpoles-large-inner {
	position: relative;
	float: right;
	right: 25%;
}

@media (max-width: 1100px) {
	.timeline-sea--tadpoles-large-inner {
		float: none;
		right: -70px;
		left: auto;
		margin: 0 auto;
		max-width: 188px;
	}
}

.timeline-sea--tadpoles-large_single {
	background: url("../../public/images/story/tadpole-large/head.png") no-repeat;
	background-size: 34px 34px;
	position: relative;
	-webkit-animation: tadpoleBobLarge 3s ease-in-out infinite alternate;
	animation: tadpoleBobLarge 3s ease-in-out infinite alternate;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2) {
	.timeline-sea--tadpoles-large_single {
		background: url("../../public/images/story/tadpole-large/head@2x.png") no-repeat;
		background-size: 34px 34px;
	}
}

.timeline-sea--tadpoles-large_single img {
	position: relative;
}

.timeline-sea--tadpoles-large_single img.body {
	top: -8px;
	left: 16px;
	z-index: 1;
	max-width: 30px;
}

.timeline-sea--tadpoles-large_single img.left-arm {
	left: 3px;
	top: 32px;
	max-width: 17px;
}

.timeline-sea--tadpoles-large_single img.right-arm {
	left: 8px;
	top: 20px;
	z-index: 0;
	max-width: 27px;
}

.timeline-sea--tadpoles-large_single .body-wrap {
	-webkit-animation: tadpoleSwim 2s ease-in-out infinite alternate;
	animation: tadpoleSwim 2s ease-in-out infinite alternate;
	-webkit-transform-origin: 30px top;
	-ms-transform-origin: 30px top;
	transform-origin: 30px top;
	position: relative;
}

.timeline-sea--tadpoles-large_single .body-wrap:before {
	content: "";
	background: url("../../public/images/story/tadpole-large/left-leg.png") no-repeat;
	width: 15px;
	height: 31px;
	display: block;
	position: absolute;
	top: 25px;
	left: 18px;
}

.timeline-sea--tadpoles-large_single .body-wrap:after {
	content: "";
	background: url("../../public/images/story/tadpole-large/right-leg.png") no-repeat;
	width: 27px;
	height: 23px;
	display: block;
	position: absolute;
	top: 20px;
	left: 38px;
}

.timeline-sea--tadpoles-large_single:after,
.timeline-sea--tadpoles-large_single:before {
	content: "";
	width: 10px;
	height: 10px;
	background: 0 0;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 50px;
	display: block;
	position: absolute;
	top: -150px;
	left: -80px;
	opacity: 0;
}

.timeline-sea--tadpoles-large_single:nth-child(2n + 1),
.timeline-sea--tadpoles-large_single:nth-child(2n + 1) .body-wrap {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles-large_single:nth-child(3) .body-wrap {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles-large_single-one {
	left: 10px;
}

.timeline-sea--tadpoles-large_single-one:after {
	left: -10px;
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.timeline-sea--tadpoles-large_single-one:after,
.timeline-sea--tadpoles-large_single-one:before {
	-webkit-animation: tadpoleBubblesLarge 1s ease-in-out infinite;
	animation: tadpoleBubblesLarge 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-large_single-one:before {
	left: -190px;
	-webkit-animation-delay: 1.9s;
	animation-delay: 1.9s;
}

.timeline-sea--tadpoles-large_single-two {
	left: -50px;
	top: -20px;
}

.timeline-sea--tadpoles-large_single-two:after {
	left: -220px;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.timeline-sea--tadpoles-large_single-two:after,
.timeline-sea--tadpoles-large_single-two:before {
	-webkit-animation: tadpoleBubblesLarge 1s ease-in-out infinite;
	animation: tadpoleBubblesLarge 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-large_single-two:before {
	left: -120px;
	-webkit-animation-delay: 1.7s;
	animation-delay: 1.7s;
}

.timeline-sea--tadpoles-large_single-three {
	top: -70px;
	left: 25px;
}

.timeline-sea--tadpoles-large_single-three:after {
	left: -50px;
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s;
}

.timeline-sea--tadpoles-large_single-three:after,
.timeline-sea--tadpoles-large_single-three:before {
	-webkit-animation: tadpoleBubblesLarge 1s ease-in-out infinite;
	animation: tadpoleBubblesLarge 1s ease-in-out infinite;
}

.timeline-sea--tadpoles-large_single-three:before {
	left: -80px;
	-webkit-animation-delay: 2.3s;
	animation-delay: 2.3s;
}

.timeline-sky--stars {
	position: absolute;
	top: 0;
	width: 100%;
	right: 0;
	left: 0;
	margin: auto;
	max-width: 2000px;
}

.timeline-sky--stars:after {
	content: "";
	position: absolute;
	height: 2px;
	width: 2px;
	top: -2px;
	box-shadow: 1947px 1888px rgba(255, 255, 255, 0.4), 117px 504px rgba(255, 255, 255, 0.8),
		1755px 352px rgba(255, 255, 255, 0.5), 1145px 94px rgba(255, 255, 255, 0.3),
		44px 751px rgba(255, 255, 255, 0.2), 291px 1784px rgba(255, 255, 255, 0.5),
		451px 1897px rgba(255, 255, 255, 0.6), 294px 592px rgba(255, 255, 255, 0.8),
		765px 1435px rgba(255, 255, 255, 0.8), 394px 1471px rgba(255, 255, 255, 0.4),
		308px 1278px rgba(255, 255, 255, 0.8), 1918px 491px rgba(255, 255, 255, 0.5),
		1699px 1809px rgba(255, 255, 255, 0.4), 1502px 1151px rgba(255, 255, 255, 0.7),
		1901px 1397px rgba(255, 255, 255, 0.6), 747px 879px rgba(255, 255, 255, 0.3),
		893px 243px rgba(255, 255, 255, 0.6), 1156px 1088px rgba(255, 255, 255, 0.4),
		1866px 430px rgba(255, 255, 255, 0.9), 1865px 817px rgba(255, 255, 255, 0.8),
		731px 1281px rgba(255, 255, 255, 0.2), 1924px 1290px rgba(255, 255, 255, 0.2),
		1683px 1654px rgba(255, 255, 255, 0.7), 216px 416px rgba(255, 255, 255, 0.4),
		1032px 680px rgba(255, 255, 255, 0.2), 754px 1376px rgba(255, 255, 255, 0.4),
		295px 1761px rgba(255, 255, 255, 0.4), 413px 1145px rgba(255, 255, 255, 0.1),
		634px 1194px rgba(255, 255, 255, 0.9), 1392px 1368px rgba(255, 255, 255, 0.2),
		622px 1698px rgba(255, 255, 255, 0.4), 816px 477px rgba(255, 255, 255, 0.4),
		672px 355px rgba(255, 255, 255, 0.9), 1084px 1550px rgba(255, 255, 255, 0.9),
		760px 1427px rgba(255, 255, 255, 0.3), 1152px 1301px rgba(255, 255, 255, 0.1),
		78px 1929px rgba(255, 255, 255, 0.6), 204px 814px rgba(255, 255, 255, 0.8),
		749px 1173px rgba(255, 255, 255, 0.6), 951px 1039px rgba(255, 255, 255, 0.9),
		351px 1120px rgba(255, 255, 255, 0.4), 573px 812px rgba(255, 255, 255, 0.2),
		1560px 1478px rgba(255, 255, 255, 0.3), 1790px 1928px rgba(255, 255, 255, 0.4),
		1600px 22px rgba(255, 255, 255, 0.5), 1524px 1693px rgba(255, 255, 255, 0.9),
		1279px 1104px rgba(255, 255, 255, 0.4), 1565px 1456px rgba(255, 255, 255, 0.1),
		1347px 1976px rgba(255, 255, 255, 0.3), 954px 508px rgba(255, 255, 255, 0.3);
}

html.no-csstransitions .timeline-text--show {
	display: block;
	opacity: 1;
	visibility: visible;
}

html.no-csstransitions .timeline-text--point.draw-line {
	width: 80px;
}

@-webkit-keyframes swim {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}

	10% {
		opacity: 1;
		-webkit-transform: translateX(90%);
		transform: translateX(90%);
	}

	90% {
		opacity: 1;
		-webkit-transform: translateX(10%);
		transform: translateX(10%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@keyframes swim {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}

	10% {
		opacity: 1;
		-webkit-transform: translateX(90%);
		transform: translateX(90%);
	}

	90% {
		opacity: 1;
		-webkit-transform: translateX(10%);
		transform: translateX(10%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@-webkit-keyframes fishStop {
	0% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	50% {
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);
	}

	100% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}
}

@keyframes fishStop {
	0% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	50% {
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);
	}

	100% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}
}

@-webkit-keyframes fins {
	from {
		-webkit-transform: rotateY(-60deg);
		transform: rotateY(-60deg);
	}

	to {
		-webkit-transform: rotateY(60deg);
		transform: rotateY(60deg);
	}
}

@keyframes fins {
	from {
		-webkit-transform: rotateY(-60deg);
		transform: rotateY(-60deg);
	}

	to {
		-webkit-transform: rotateY(60deg);
		transform: rotateY(60deg);
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
}

@-webkit-keyframes glow {
	0% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 55px #0ff;
	}

	50% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 50px #0ff;
	}

	100% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 55px #0ff;
	}
}

@keyframes glow {
	0% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 55px #0ff;
	}

	50% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 50px #0ff;
	}

	100% {
		box-shadow: 0 0 40px 20px #fff, 0 0 120px 55px #0ff;
	}
}

@-webkit-keyframes tadpoleGroup {
	0%,
	10% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate(160px, -300px);
		transform: translate(160px, -300px);
	}

	90%,
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes tadpoleGroup {
	0%,
	10% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate(160px, -300px);
		transform: translate(160px, -300px);
	}

	90%,
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@-webkit-keyframes tadpoleSwim {
	0% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	50% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	100% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}
}

@keyframes tadpoleSwim {
	0% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	50% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	100% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}
}

@-webkit-keyframes tadpoleSlither {
	0% {
		-webkit-transform: translate(0, 0) rotate(0deg);
		transform: translate(0, 0) rotate(0deg);
	}

	40% {
		-webkit-transform: translate(0, -30px) rotate(-30deg);
		transform: translate(0, -30px) rotate(-30deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
	}

	80% {
		-webkit-transform: translate(170px, 30px) rotate(90deg);
		transform: translate(170px, 30px) rotate(90deg);
		-webkit-transform-origin: top right;
		transform-origin: top right;
	}

	100% {
		-webkit-transform: translate(230px, -110px) rotate(-60deg);
		transform: translate(230px, -110px) rotate(-60deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
	}
}

@keyframes tadpoleSlither {
	0% {
		-webkit-transform: translate(0, 0) rotate(0deg);
		transform: translate(0, 0) rotate(0deg);
	}

	40% {
		-webkit-transform: translate(0, -30px) rotate(-30deg);
		transform: translate(0, -30px) rotate(-30deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
	}

	80% {
		-webkit-transform: translate(170px, 30px) rotate(90deg);
		transform: translate(170px, 30px) rotate(90deg);
		-webkit-transform-origin: top right;
		transform-origin: top right;
	}

	100% {
		-webkit-transform: translate(230px, -110px) rotate(-60deg);
		transform: translate(230px, -110px) rotate(-60deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
	}
}

@-webkit-keyframes tadpoleBob {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(15px, -15px);
		transform: translate(15px, -15px);
	}
}

@keyframes tadpoleBob {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(15px, -15px);
		transform: translate(15px, -15px);
	}
}

@-webkit-keyframes tadpoleBobMedium {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	20% {
		-webkit-transform: translate(5px, -15px);
		transform: translate(5px, -15px);
	}

	60% {
		-webkit-transform: translate(15px, -15px);
		transform: translate(15px, -15px);
	}

	80% {
		-webkit-transform: translate(10px, -10px);
		transform: translate(10px, -10px);
	}

	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes tadpoleBobMedium {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	20% {
		-webkit-transform: translate(5px, -15px);
		transform: translate(5px, -15px);
	}

	60% {
		-webkit-transform: translate(15px, -15px);
		transform: translate(15px, -15px);
	}

	80% {
		-webkit-transform: translate(10px, -10px);
		transform: translate(10px, -10px);
	}

	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@-webkit-keyframes tadpoleBobLarge {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(-15px, -15px);
		transform: translate(-15px, -15px);
	}
}

@keyframes tadpoleBobLarge {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(-15px, -15px);
		transform: translate(-15px, -15px);
	}
}

@-webkit-keyframes tadpoleBubbles {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(-340px, 400px);
		transform: translate(-340px, 400px);
	}
}

@keyframes tadpoleBubbles {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(-340px, 400px);
		transform: translate(-340px, 400px);
	}
}

@-webkit-keyframes tadpoleBubblesMedium {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(-240px, 400px);
		transform: translate(-240px, 400px);
	}
}

@keyframes tadpoleBubblesMedium {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(-240px, 400px);
		transform: translate(-240px, 400px);
	}
}

@-webkit-keyframes tadpoleBubblesLarge {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(340px, 400px);
		transform: translate(340px, 400px);
	}
}

@keyframes tadpoleBubblesLarge {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	90% {
		opacity: 0;
	}

	100% {
		-webkit-transform: translate(340px, 400px);
		transform: translate(340px, 400px);
	}
}

@-webkit-keyframes wave {
	0% {
		-webkit-transform: translate3d(-500px, 0, 0);
		transform: translate3d(-500px, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(500px, 0, 0);
		transform: translate3d(500px, 0, 0);
	}
}

@keyframes wave {
	0% {
		-webkit-transform: translate3d(-500px, 0, 0);
		transform: translate3d(-500px, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(500px, 0, 0);
		transform: translate3d(500px, 0, 0);
	}
}

@-webkit-keyframes lilypad {
	0% {
		-webkit-transform: translate3d(0, 0, 0) rotateZ(0);
		transform: translate3d(0, 0, 0) rotateZ(0);
	}

	100% {
		-webkit-transform: translate3d(0, -40px, 0) rotateZ(-2deg);
		transform: translate3d(0, -40px, 0) rotateZ(-2deg);
	}
}

@keyframes lilypad {
	0% {
		-webkit-transform: translate3d(0, 0, 0) rotateZ(0);
		transform: translate3d(0, 0, 0) rotateZ(0);
	}

	100% {
		-webkit-transform: translate3d(0, -40px, 0) rotateZ(-2deg);
		transform: translate3d(0, -40px, 0) rotateZ(-2deg);
	}
}

@-webkit-keyframes lilypad-two {
	0% {
		-webkit-transform: translate3d(0, 0, 0) rotateZ(0);
		transform: translate3d(0, 0, 0) rotateZ(0);
	}

	100% {
		-webkit-transform: translate3d(0, 45px, 0) rotateZ(2deg);
		transform: translate3d(0, 45px, 0) rotateZ(2deg);
	}
}

@keyframes lilypad-two {
	0% {
		-webkit-transform: translate3d(0, 0, 0) rotateZ(0);
		transform: translate3d(0, 0, 0) rotateZ(0);
	}

	100% {
		-webkit-transform: translate3d(0, 45px, 0) rotateZ(2deg);
		transform: translate3d(0, 45px, 0) rotateZ(2deg);
	}
}

@-webkit-keyframes eyes {
	0%,
	10%,
	20%,
	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	31% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	32%,
	40%,
	50%,
	60%,
	70% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	71% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	72%,
	80%,
	90%,
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

@keyframes eyes {
	0%,
	10%,
	20%,
	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	31% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	32%,
	40%,
	50%,
	60%,
	70% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	71% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	72%,
	80%,
	90%,
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

@-webkit-keyframes flagWave {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	20% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	40% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	60% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	80% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	100% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
}

@keyframes flagWave {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	20% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	40% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	60% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	80% {
		-webkit-transform: rotate(-10deg);
		transform: rotate(-10deg);
	}

	100% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
}

@-webkit-keyframes sway {
	0% {
		-webkit-transform: rotate(-2deg);
		transform: rotate(-2deg);
	}

	50% {
		-webkit-transform: rotate(2deg);
		transform: rotate(2deg);
	}

	100% {
		-webkit-transform: rotate(-2deg);
		transform: rotate(-2deg);
	}
}

@keyframes sway {
	0% {
		-webkit-transform: rotate(-2deg);
		transform: rotate(-2deg);
	}

	50% {
		-webkit-transform: rotate(2deg);
		transform: rotate(2deg);
	}

	100% {
		-webkit-transform: rotate(-2deg);
		transform: rotate(-2deg);
	}
}

@-webkit-keyframes web {
	0% {
		height: 10px;
	}

	40%,
	60% {
		height: 200px;
	}

	100% {
		height: 10px;
	}
}

@keyframes web {
	0% {
		height: 10px;
	}

	40%,
	60% {
		height: 200px;
	}

	100% {
		height: 10px;
	}
}

@-webkit-keyframes spider {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40%,
	60% {
		-webkit-transform: translateY(190px);
		transform: translateY(190px);
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes spider {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	40%,
	60% {
		-webkit-transform: translateY(190px);
		transform: translateY(190px);
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes asteroidSmall {
	from {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}

	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@keyframes asteroidSmall {
	from {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}

	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@-webkit-keyframes asteroidSmallMove {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(-580px, -550px);
		transform: translate(-580px, -550px);
	}
}

@keyframes asteroidSmallMove {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	to {
		-webkit-transform: translate(-580px, -550px);
		transform: translate(-580px, -550px);
	}
}

@-webkit-keyframes asteroidLargeMove {
	from {
		transfrom: translate(0, 0);
	}

	to {
		-webkit-transform: translate(1200px, -1000px);
		transform: translate(1200px, -1000px);
	}
}

@keyframes asteroidLargeMove {
	from {
		transfrom: translate(0, 0);
	}

	to {
		-webkit-transform: translate(1200px, -1000px);
		transform: translate(1200px, -1000px);
	}
}

@-webkit-keyframes spaceFrog {
	from {
		-webkit-transform: translateY(-840px);
		transform: translateY(-840px);
	}

	to {
		-webkit-transform: translateY(-848px);
		transform: translateY(-848px);
	}
}

@keyframes spaceFrog {
	from {
		-webkit-transform: translateY(-840px);
		transform: translateY(-840px);
	}

	to {
		-webkit-transform: translateY(-848px);
		transform: translateY(-848px);
	}
}

@-webkit-keyframes spaceFrogJetpack {
	0% {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}

	20% {
		-webkit-transform: scale(0.5, 1.2);
		transform: scale(0.5, 1.2);
	}

	40% {
		-webkit-transform: scale(0.8, 1);
		transform: scale(0.8, 1);
	}

	60% {
		-webkit-transform: scale(1.2, 0.7);
		transform: scale(1.2, 0.7);
	}

	80% {
		-webkit-transform: scale(0.9, 1.1);
		transform: scale(0.9, 1.1);
	}

	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@keyframes spaceFrogJetpack {
	0% {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}

	20% {
		-webkit-transform: scale(0.5, 1.2);
		transform: scale(0.5, 1.2);
	}

	40% {
		-webkit-transform: scale(0.8, 1);
		transform: scale(0.8, 1);
	}

	60% {
		-webkit-transform: scale(1.2, 0.7);
		transform: scale(1.2, 0.7);
	}

	80% {
		-webkit-transform: scale(0.9, 1.1);
		transform: scale(0.9, 1.1);
	}

	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

@-webkit-keyframes spinPlanet {
	from {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}

	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

@keyframes spinPlanet {
	from {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}

	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

@-webkit-keyframes skyRocket {
	from {
		-webkit-transform: translate(0, 0) rotate(20deg);
		transform: translate(0, 0) rotate(20deg);
	}

	to {
		-webkit-transform: translate(600px, -1200px) rotate(25deg) scale(0.4, 0.2);
		transform: translate(600px, -1200px) rotate(25deg) scale(0.4, 0.2);
	}
}

@keyframes skyRocket {
	from {
		-webkit-transform: translate(0, 0) rotate(20deg);
		transform: translate(0, 0) rotate(20deg);
	}

	to {
		-webkit-transform: translate(600px, -1200px) rotate(25deg) scale(0.4, 0.2);
		transform: translate(600px, -1200px) rotate(25deg) scale(0.4, 0.2);
	}
}

@-webkit-keyframes planetSpin {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(-238px);
		transform: translateX(-238px);
	}
}

@keyframes planetSpin {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(-238px);
		transform: translateX(-238px);
	}
}
//</editor-fold>
