.sf-message {
	@include notification-message($sf-color--notice);
	&--error {
		border-left-color: $sf-color--error;
	}
	&--warning {
		border-left-color: $sf-color--warning;
	}
	&--success {
		border-left-color: $sf-color--success;
	}
}
